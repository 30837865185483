var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"leg-information"}},[_c('div',{staticClass:"section-header"},[_c('span',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t("legInformation_titleLegs")))]),(_vm.isReissueable)?_c('div',{staticClass:"mode-wrapper"},[_c('UISwitch',{attrs:{"switchInitStatus":_vm.editMode},on:{"update-switch-status":_vm.updateSwitchStatus},scopedSlots:_vm._u([{key:"switch-left-text",fn:function(){return [_c('span',{staticClass:"mode-wrapper__text"},[_vm._v(_vm._s(_vm.$t("legInformation_lblEdit")))])]},proxy:true}],null,false,3084512011)})],1):_vm._e()]),_c('div',{staticClass:"transfer-vehicle-card-container-wrapper"},_vm._l((_vm.LegInformation),function(legItem,index){return _c('div',{key:index,staticClass:"transfer-vehicle-card-container"},[_c('div',{staticClass:"vehicle-type-wrapper",class:'vehicle-type-' + index},[_c('span',{staticClass:"vehicle-type"},[_vm._v(" "+_vm._s(_vm._f("ETrfVehicleType")(legItem.vehicleType))+" ")]),_c('span',{staticClass:"txt-normal vehicle-type-mobile-onyl d-lg-none"},[_vm._v(" ("+_vm._s(legItem.vehicleTypeInfo)+" "+_vm._s(_vm.$t("transfer_phOrSimilar"))+")")])]),_c('div',{staticClass:"transfer-vehicle-image-mobile d-block d-md-none"},[(_vm.pageLoad)?_c('img',{staticClass:"vehicle-image",attrs:{"src":_vm.$constants.cdn + '/transfer/vehicle_type/' + legItem.vehicleTypeId + '.png',"srcset":`${_vm.$constants.cdn + '/transfer/vehicle_type/' + legItem.vehicleTypeId + '.png'} 300w, 
                  ${_vm.$constants.cdn + '/transfer/vehicle_type/' + legItem.vehicleTypeId + '.png'} 600w, 
                  ${_vm.$constants.cdn + '/transfer/vehicle_type/' + legItem.vehicleTypeId + '.png'} 900w`,"sizes":"\n                              (max-width: 300px) 300px,\n                              (max-width: 600px) 600px,\n                              (max-width: 900px) 900px,","loading":"lazy","alt":"Vehicle Image"}}):_vm._e(),_c('div',{staticClass:"vehicle-representative"},[_c('span',{staticClass:"representative-text"},[_vm._v(_vm._s(_vm.$t("transfer_RepresentativeVehicle")))])])]),_c('div',{staticClass:"route-informations-wrapper"},[_c('div',{staticClass:"route-card-wrapper"},[_c('div',{staticClass:"route-information-body-wrapper"},[_c('div',{staticClass:"location-wrapper"},[_vm._m(0,true),_c('div',{staticClass:"location-informations"},[_c('span',{staticClass:"location-name masked"},[_vm._v(_vm._s(legItem.fromDestinationName))]),_c('div',{staticClass:"location-fullname-wrapper"},[_c('span',{staticClass:"location-fullname masked"},[_vm._v(" "+_vm._s(legItem.fromLocationName)+" ")])])])]),_c('div',{staticClass:"transfer-card-image-wrapper d-none d-md-flex"},[(!_vm.pageLoad)?_c('div',{staticClass:"transfer-dummy-image"}):_vm._e(),(_vm.pageLoad)?_c('img',{staticClass:"vehicle-image",attrs:{"src":_vm.$constants.cdn + '/transfer/vehicle_type/' + legItem.vehicleTypeId + '.png',"srcset":`${_vm.$constants.cdn + '/transfer/vehicle_type/' + legItem.vehicleTypeId + '.png'} 300w, 
                  ${_vm.$constants.cdn + '/transfer/vehicle_type/' + legItem.vehicleTypeId + '.png'} 600w, 
                  ${_vm.$constants.cdn + '/transfer/vehicle_type/' + legItem.vehicleTypeId + '.png'} 900w`,"sizes":"\n                              (max-width: 300px) 300px,\n                              (max-width: 600px) 600px,\n                              (max-width: 900px) 900px,","loading":"lazy","alt":"Vehicle Image"}}):_vm._e(),_c('div',{staticClass:"vehicle-representative"},[_c('span',{staticClass:"representative-text"},[_vm._v(_vm._s(_vm.$t("transfer_RepresentativeVehicle")))])])]),_c('div',{staticClass:"location-wrapper txt-right"},[_c('div',{staticClass:"location-informations"},[_c('span',{staticClass:"location-name masked"},[_vm._v(_vm._s(legItem.toDestinationName))]),_c('div',{staticClass:"location-fullname-wrapper"},[_c('span',{staticClass:"location-fullname masked"},[_vm._v(" "+_vm._s(legItem.toLocationName)+" ")])])]),_vm._m(1,true)])]),_vm._m(2,true)]),_c('div',{attrs:{"id":"validation-error-auto-scroller"}}),_c('LegInputFields',{attrs:{"transferIds":_vm.transferIds,"legInfoArr":_vm.LegInformation,"legs":_vm.legs,"leg":legItem,"travelers":_vm.travelers,"legIndex":index,"editMode":_vm.editMode}}),_c('div',{staticClass:"transfer-pickup-info masked"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.handlerTransferCheckoutPickupInfo(index))}})])],1)])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"location-icon-wrapper"},[_c('i',{staticClass:"gts-icon icon-transfer-basket-location"}),_c('span',{staticClass:"location-mark-name"},[_vm._v(" A ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"location-icon-wrapper"},[_c('i',{staticClass:"gts-icon icon-transfer-basket-location"}),_c('span',{staticClass:"location-mark-name"},[_vm._v(" B ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"route-informations-footer-wrapper"},[_c('div',{staticClass:"transfer-facilities-wrapper"},[_c('div',{staticClass:"icon-wrapper"},[_c('i',{staticClass:"gts-icon icon-transfer-air-condition icon-sm"}),_c('i',{staticClass:"gts-icon icon-transfer-lightning icon-sm"})])])])
}]

export { render, staticRenderFns }