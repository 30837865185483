<template>
   <div class="invoice-list-page-container sub-page-wrapper">
      <Aside />
      <Main>
         <Navbar />

         <div class="container-fluid">
            <div class="row">
               <div class="col gts-box mx-3">
                  <h1 class="sub-page-header">{{ $t("invoiceList_title") }}</h1>

                  <div class="sub-page-row-wrapper row gy-3">
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIDatePicker2 :label="$t('invoiceList_filter_lblFromDate')" :min="minDate" :max="maxDate" v-model="searchQuery.date1" />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIDatePicker2 :label="$t('invoiceList_filter_lblToDate')" :min="minDate" :max="maxDate" v-model="searchQuery.date2" />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIInput
                           v-model="searchQuery.orderId"
                           :placeholder="$t('invoiceList_filter_phOrderId')"
                           :label="$t('invoiceList_filter_lblOrderId')"
                           allow-clear />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIInput
                           v-model="searchQuery.pnr"
                           :placeholder="$t('invoiceList_filter_phPnr')"
                           :label="$t('invoiceList_filter_lblPnr')"
                           allow-clear />
                     </div>
                  </div>

                  <!--  CTA -->
                  <div class="sub-page-actions-row row">
                     <div class="sub-pages-actions-wrapper col-12">
                        <UIButton className="default" :text="$t('invoiceList_filter_btnList')" @click.native="funcListInvoices()" />
                     </div>
                  </div>
               </div>
            </div>

            <section class="page-section gts-box" v-if="mainTableOptions.rows.length > 0">
               <GtsGrid :table-options="mainTableOptions" :item-per-page="-1" :title="$t('invoiceList_title')" />
            </section>
         </div>
      </Main>
   </div>
</template>

<script>
import moment from "moment";
import i18n from "@/fmt/i18n.js";
import Popup from "@/gts-popup.js";
import { financeService } from "@/service/index.js";

import Main from "@/components/unitary/Main.vue";
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import GtsGrid from "@/components/utils/GtsGrid.vue";
import UIInput from "@/components/unitary/form2/UIInput.vue";
import UIButton from "@/components/unitary/Button.vue";
import UIDatePicker2 from "@/components/unitary/form2/UIDatePicker2.vue";

export default {
   name: "InvoiceList",
   components: {
      Aside,
      Navbar,
      Main,
      GtsGrid,
      UIInput,
      UIButton,
      UIDatePicker2,
   },
   data() {
      return {
         isFirstSearch: true,
         show: false,
         maxDate: moment().format(this.$constants.dateFormat),
         minDate: moment().subtract(2, "year").format(this.$constants.dateFormat),
         searchQuery: {
            date1: moment().format(this.$constants.dateFormat),
            date2: moment().format(this.$constants.dateFormat),
            orderId: "",
            pnr: "",
         },
         mainTableOptions: {
            columns: [
               { label: `${i18n.t("invoiceList_tblMain_thInvoiceId")}` },
               { label: `${i18n.t("invoiceList_tblMain_thInvoiceNumber")}` },
               { label: `${i18n.t("invoiceList_tblMain_thInvoiceDate")}` },
               { label: `${i18n.t("invoiceList_tblMain_thInvoiceTotal")}` },
               { label: `${i18n.t("invoiceList_tblMain_thInvoiceInfo")}` },
            ],
            rows: [],
         },
      };
   },
   created() {
      this.$store.commit("app/setDocRequestToInitialState");
   },
   watch: {
      "$i18n.locale"() {
         if (this.isFirstSearch == false) {
            this.funcListInvoices();
         }
         this.updateTableColumns();
      },
   },
   methods: {
      getTableColumns() {
         return [
            { label: `${i18n.t("invoiceList_tblMain_thInvoiceId")}` },
            { label: `${i18n.t("invoiceList_tblMain_thInvoiceNumber")}` },
            { label: `${i18n.t("invoiceList_tblMain_thInvoiceDate")}` },
            { label: `${i18n.t("invoiceList_tblMain_thInvoiceTotal")}` },
            { label: `${i18n.t("invoiceList_tblMain_thInvoiceInfo")}` },
         ];
      },
      updateTableColumns() {
         this.mainTableOptions.columns = this.getTableColumns();
      },
      funcListInvoices() {
         const self = this;

         self.show = false;
         self.mainTableOptions.rows.length = 0;
         self.$store.commit("app/setIsProgressGifActive", true);

         let request = {
            date1: self.searchQuery.date1,
            date2: self.searchQuery.date2,
            orderId: self.searchQuery.orderId && self.searchQuery.orderId.length > 0 ? parseInt(self.searchQuery.orderId) : -1,
            pnr: self.searchQuery.pnr && self.searchQuery.pnr.length > 0 ? self.searchQuery.pnr : null,
         };

         // console.log(request);
         financeService.listInvoices(request).then((response) => {
            // console.log(response);

            self.$store.commit("app/setIsProgressGifActive", false);

            if (!response.result.success) {
               response.result.service = self.$options.name;
               Popup.result(response.result);
               return;
            }
            this.isFirstSearch = false;
            if (response.invoices.length > 0) {
               //HEADER_ROW
               self.mainTableOptions.rows.push({
                  invoiceId: "",
                  invoiceNumber: "",
                  invoiceDate: "",
                  invoiceTotal: {
                     iteration: [response.grandTotal],
                     className: "is-price",
                     filter: "price",
                  },
                  invoiceInfo: {
                     iteration: [`${i18n.t("invoiceList_tblMain_thGrandTotal")}`],
                     className: "txt-black",
                  },
                  tags: "",
               });
            }

            response.invoices.map((invoice) => {
               self.mainTableOptions.rows.push({
                  invoiceId: {
                     iteration: [invoice.id],
                     icon: "invoice",
                     method() {
                        self.$store.state.app.documentViewer.request.documentType = 3;
                        self.$store.state.app.documentViewer.request.fileType = 2;
                        self.$store.state.app.documentViewer.request.showPrice = false;
                        self.$store.state.app.documentViewer.hasPrice = false;

                        self.$store.state.app.documentViewer.request.identifiers = [{ identifier: invoice.uuid, subIdentifiers: [] }];

                        self.$store.commit("app/setDocumentViewer", true);
                     },
                  },
                  invoiceNumber: invoice.invoiceNumber,
                  invoiceDate: self.$options.filters.FormatterDate(invoice.invoiceDate),
                  invoiceTotal: {
                     iteration: [invoice.totalAmount],
                     className: "is-price",
                     filter: "price",
                  },
                  invoiceInfo: {
                     iteration: [invoice.description, i18n.t("invoiceList_info_" + invoice.identifier)],
                  },
                  tags: JSON.stringify(invoice),
               });
            });
            self.show = true;
         });
      },
   },
};
</script>

<style lang="scss" scoped>
.page-container {
   .page-inner {
      width: 90%;
      margin: auto;
      .page-form-row {
         display: grid;
         grid-template-columns: repeat(6, 1fr);
         grid-column-gap: 1rem;
      }
      .page-action-row {
         margin-top: 1rem;
         display: flex;
         justify-content: flex-end;
      }
   }
}
</style>

<style lang="scss">
#fixedFooter {
   position: inherit !important;
}
</style>
