<template>
   <div class="form-item g-select-box-container g-input-container" v-click-outside="handlerClickOutside">
      <label :for="id" v-if="label">{{ label }}</label>

      <!-- Select Box -->
      <div class="g-select-box-wrapper">
         <div
            class="g-select-box"
            :class="[lightTheme ? 'light-theme' : '', small ? 'small' : commisionVariant ? 'commision' : 'normal']"
            @click="dropdown = !dropdown">
            <div class="g-select-box-display">
               <i class="flag-icon" :class="'flag-icon-' + Selected.icon" v-if="hasIcons"></i>
               <span> {{ Selected.name || placeholder }}</span>
            </div>

            <span class="g-radial-loader loader-accent-primary" v-if="isLoadingOptions"></span>
            <i class="g-select-box-arrow gts-icon icon-arrow-down icon-xs" v-else></i>
         </div>

         <!-- Options -->
         <div class="g-select-box-options-wrapper" :class="upper ? 'upper' : ''" v-if="dropdown">
            <div class="search-box-container" v-if="allowSearch">
               <input type="text" v-model="search" />
               <i class="gts-icon icon-close-rounded gts-animation icon-xs" @click.self="handlerClearInput" :class="search.length > 0"></i>
            </div>

            <div class="g-select-box-options-loop-wrapper">
               <template v-for="(option, optionIndex) in Options">
                  <div
                     class="g-select-box-option"
                     :key="optionIndex"
                     @click="handlerSelectOption(optionIndex, option)"
                     :class="denyClickOutside ? 'deny-click-outside' : ''">
                     <i class="flag-icon" :class="'flag-icon-' + option.icon" v-if="hasIcons"></i>
                     {{ option.name }}
                  </div>
               </template>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "UISelectBox",
   props: {
      isRentacar: Boolean,
      label: String,
      id: String,
      small: Boolean,
      commisionVariant: Boolean,
      placeholder: String,
      selectedOpt: { type: Number },
      options: {
         type: Array,
      },
      allowSearch: Boolean,
      hasIcons: Boolean,
      value: {
         type: [String, Number, Boolean],
      },
      lightTheme: Boolean,
      denyClickOutside: Boolean,
      hasHiddenKey: Boolean,
      upper: Boolean,
   },

   data() {
      return {
         dropdown: false,
         selected: {
            icon: null,
            name: null,
            hidden: null,
         },
         isLoadingOptions: true,
         search: "",
      };
   },
   created() {
      if (this.selectedOpt) {
         this.handlerSelectOption(this.selectedOpt, this.options[this.selectedOpt]);
      }
   },
   computed: {
      Options() {
         if (this.search == "") {
            return this.options;
         } else {
            return this.options.filter((option) => JSON.stringify(option).toLowerCase().includes(this.search.toLowerCase()));
         }
      },
      Selected() {
         return this.selected;
      },
   },
   methods: {
      handlerClickOutside() {
         this.dropdown = false;
      },
      handlerClearInput() {
         this.search = "";
         this.dropdown = true;
      },
      handlerSelectOption(optionIndex, option) {
         console.log("handlerSelection");
         //set selected
         if (option != undefined) {
            this.selected.name = option.name;
            this.$emit("input", option.value);
            //hasHiddenKey --> Ancillary!!
            if (this.hasHiddenKey) {
               this.selected.hidden = option?.hidden;
               this.$emit("hidden", option.hidden);
            }

            //hasIcon ?
            if (this.hasIcons) {
               this.selected.icon = this.options[optionIndex].icon;
            }
         }

         //close dropdown
         this.dropdown = false;
      },
      handlerSelection(value) {
         const selected = this.options.filter((option) => {
            return value == option.value;
         });

         if (this.hasIcons) {
            this.selected.icon = selected[0]?.icon;
         }
         this.selected.name = selected[0]?.name;
         this.isLoadingOptions = false;
      },
   },
   watch: {
      selectedOpt: {
         handler: function (value) {
            let index = this.options.findIndex((option) => option.value == value);
            if (!this.isRentacar) {
               this.handlerSelectOption(index, this.options[index]);
            }
            this.handlerSelection(value);
         },
      },
      options: {
         handler: function () {
            if (!this.selected.name) {
               if (!this.value) {
                  this.selected.name = this.options[0]?.name;
               } else {
                  this.handlerSelection(this.value);
               }
            }
         },
         deep: true,
      },
      value: {
         handler: function (value) {
            if (this.options) {
               this.handlerSelection(value);
            }

            if (this.value == null) {
               this.$emit("input", this.options[0]?.value);
            }
         },
         immediate: true,
      },
   },
};
</script>
<style lang="scss" scoped>
.g-select-box-container {
   width: 100%;
   .g-select-box-label {
      margin-bottom: 8px;
      font-weight: 500;
   }
   .g-select-box-wrapper {
      position: relative;
      .g-select-box {
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: space-between;
         background: #eaeaea;
         border: 2px solid #ccc;
         padding: 0 0.75rem;
         box-sizing: border-box;
         border-radius: 0.5rem;
         cursor: pointer;

         &.small {
            min-height: 24px;
         }

         &.normal {
            min-height: 48px;
         }

         &.commision {
            min-height: 32px;
            max-width: 72px;
            margin-left: auto;
         }

         .g-select-box-display {
            display: flex;
            white-space: nowrap;

            span {
               color: var(--txt-black) !important;
            }
         }

         i.g-select-box-arrow {
            margin-left: 0.5rem;
         }
         i.flag-icon {
            margin-right: 0.5rem;
         }

         &.light-theme {
            background: #fff !important;
         }
      }
      .g-select-box-options-wrapper {
         border: 1px solid #ccc;
         background: #fff;
         position: absolute;
         border-radius: 0.5rem;
         left: 0;
         right: 0;
         z-index: 99;
         min-width: 200px;

         &.upper {
            bottom: 100%;
            min-width: auto;
         }

         .search-box-container {
            position: relative;
            padding: 0.25rem 1rem;
            input {
               display: flex;
               align-items: center;
               justify-content: center;
               border: 1px solid #ccc;
               border-radius: 24rem;
               box-sizing: border-box;
               overflow: hidden;
               margin: 0.5rem 0;
               height: 42px;
               width: 100%;
               text-indent: 0.5rem;
               outline: none !important;
               text-transform: capitalize;

               &:focus-visible {
                  border: 1px solid var(--accent-primary);
                  background-color: var(--accent-primary-lightest);
               }
            }
            i {
               position: absolute;
               top: 0;
               bottom: 0;
               right: 1.7rem;
               margin: auto;
               cursor: pointer;
            }
         }
         .g-select-box-options-loop-wrapper {
            max-height: 300px;
            overflow: hidden;
            overflow-y: auto;

            .g-select-box-option {
               padding: 0.25rem 1.25rem;
               font-size: var(--normal);
               color: var(--txt-black);
               display: flex;
               &:hover {
                  background-color: var(--hover);
                  cursor: pointer;
               }

               i {
                  margin-right: 0.75rem;
               }
            }
         }
      }
   }

   &.g-select-active {
      .g-select-box-wrapper {
         .g-select-box {
            border: 1px solid var(--accent-primary);
            outline: 0.25rem solid var(--accent-primary-lightest);
            i.g-select-box-arrow {
               animation: rotation180 0.25s 1;
               animation-fill-mode: forwards;
               animation-timing-function: ease-in-out;
            }
         }
      }
   }
}
</style>
