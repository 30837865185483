<template>
   <div class="widget-account-container account-container">
      <div class="agency-account-icon d-none d-xl-flex">
         <router-link to="/finance/balanceUpdate">
            <UITooltip
               :tooltip-title="$t('agencyAccountComponent_balanceUpdate')"
               tooltip-icon="gts-icon icon-dashboard-balance"
               tooltip-position="bottom"></UITooltip>
         </router-link>
      </div>
      <template v-if="ShowLoan">
         <div class="agency-account-balance-item">
            <span class="account-title">{{ $t("agencyAccountComponent_definedCredit") }}</span>
            <span class="account-amount" :class="Balance?.userType == 3 ? 'no-balance' : ''">{{
               Balance?.userType == 3 ? "—" : Balance?.loan | price
            }}</span>
         </div>
      </template>
      <div class="agency-account-balance-item">
         <span class="account-title">{{ $t("agencyAccountComponent_availableBalance") }}</span>
         <span class="account-amount" :class="Balance?.userType == 3 ? 'no-balance' : ''">{{
            Balance?.userType == 3 ? "—" : Balance?.availableBalance | price
         }}</span>
      </div>
      <template v-if="ShowLoan">
         <div class="agency-account-balance-item">
            <span class="account-title">{{ $t("agencyAccountComponent_balance") }}</span>
            <span class="account-amount" :class="Balance?.userType == 3 ? 'no-balance' : ''">{{
               Balance?.userType == 3 ? "—" : Balance?.balance | price
            }}</span>
         </div>
      </template>
   </div>
</template>

<script>
import UITooltip from "@/components/utils/GtsToolTips.vue";
export default {
   name: "AccountCard",
   components: {
      UITooltip,
   },
   props: {
      properties: [Object, Array],
   },
   computed: {
      Balance() {
         return this.properties.content;
      },
      ShowLoan() {
         return this.properties.content?.showLoan;
      },
   },
};
</script>

<style lang="scss" scoped>
.widget-body-container {
   display: none;
}
.widget-account-container {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   align-items: center;

   @media (min-width: 1200px) {
      grid-template-columns: 0.45fr 1fr 1fr 1fr;
   }

   .agency-account-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 56px !important;
      height: 56px !important;
      background: black;
      border-radius: 0.5rem;
      i {
         width: 52px !important;
         height: 52px !important;
         filter: brightness(0);
      }
   }
   .agency-account-balance-item {
      display: flex;
      flex-direction: column;
      // padding-left: 80px;
      span.account-title {
         font-size: var(--normal);
         opacity: 0.75;
      }
      span.account-amount {
         font-size: 18px;
         font-weight: 800;

         &.no-balance {
            opacity: 0.75 !important;
            font-weight: 600 !important;
         }

         @media (min-width: 1400px) {
            font-size: 24px;
         }
      }
   }
}
</style>
<style lang="scss">
.account-container {
   background-color: #fff;
   box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px;
   border-radius: 1rem;
   padding: 1.5rem 1.5rem;
}
</style>
