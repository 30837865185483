<template>
   <div class="seat-map-modal-footer">
      <template v-if="footerControls.skip">
         <UIButton
            :className="'default_outline'"
            :text="$t('ancillarySeatMap_btnPreviousStep')"
            @click.native="handlerPrevStep"
            :class="step.activeStep != step.firstStep ? '' : 'invisible'"
            v-if="!PaymentStatus" />

         <template v-if="this.step.activeStep == this.step.lastStep && SelectionControl">
            <UIButton :className="'default'" :text="$t('ancillarySeatMap_btnPayment')" @click.native="handlerSkip" v-show="step.activeStep != 0" />
         </template>
         <template v-else-if="this.step.activeStep != this.step.lastStep">
            <UIButton :className="'default'" :text="$t('ancillarySeatMap_btnNextStep')" @click.native="handlerSkip" v-show="step.activeStep != 0" />
         </template>
      </template>
   </div>
</template>

<script>
import UIButton from "@/components/unitary/Button.vue";
export default {
   name: "SeatMapModalFooter",
   props: {
      footerControls: Object,
      step: Object,
   },
   components: {
      UIButton,
   },
   computed: {
      PaymentStatus() {
         return this.$store.state.seatmap.paymentStatus;
      },
      SelectionControl() {
         return Object.keys(this.$store.state.seatmap.selectSeatQ.flightsSegmentsPaxesSeats).length > 0 && this.step.activeStep == this.step.lastStep;
      },
   },
   methods: {
      handlerSkip() {
         this.$emit("skip");
      },
      handlerPrevStep() {
         this.$emit("previousStep");
      },
   },
};
</script>

<style lang="scss" scoped>
.seat-map-modal-footer {
   display: flex;
   justify-content: space-between;

   button {
      margin-right: 1rem;
   }
}
</style>
