<template>
   <div class="autocomplete-wrapper" v-click-outside="handlerOutClick">
      <label>{{ label }}</label>
      <div class="autocomplete-inner-wrapper">
         <div class="input-and-chips v-required">
            <div class="chips-wrapper">
               <div class="chip" v-for="(chip, index) in chips" :key="index">
                  <!-- DUS! = Dusseldorf all city. Do not show it on ui-->

                  <span>{{ chip.code.substring(0, 3) }}</span>
                  <div class="chip-pop" @click="handlerPopChip(index)"></div>
               </div>
            </div>
            <!-- computed autoCompleteInput -->
            <input
               type="text"
               v-model="autocompleteInput"
               @input="handleSearchInput"
               @keydown="handlerArrowNavigation"
               ref="input"
               autocomplete="off"
               :tabindex="tabindex"
               @focus="handlerInputFocused" />
         </div>
         <ul class="autocomplete-dropdown-container" v-if="dropdownStatus">
            <li
               v-for="(item, index) in dropdownList"
               :key="index"
               @click="handlerPushChip(index, item.code)"
               :class="[
                  arrowNavigation.isActive && arrowNavigation.navIndex == index ? 'highlighted' : '',
                  item.type == 2 ? 'allcity' : 'airport',
                  item.related ? 'autocomplete-indent' : '',
                  item.isLast ? 'autocomplete-city-last-child' : '',
               ]">
               <i class="gts-icon me-2" :class="[item.type == 2 ? 'icon-flight-search-2' : 'icon-flight-search-1']"></i>

               <div class="autocomplete-item-informations">
                  <div class="autocomplete-item-informations-row">
                     <span class="airport-code">{{ item.code }} </span>
                     <span class="airport-location"> ({{ item.name }})</span>
                  </div>
                  <span class="airport-city">{{ item.location }} </span>
               </div>
            </li>

            <li>
               <span class="g-radial-loader loader-accent-gray" v-if="loading"></span>
            </li>
         </ul>
         <div class="g-form-validation">{{ $t("common_cannotBeEmpty") }}</div>
      </div>
   </div>
</template>

<script>
import { flightService } from "@/service/index.js";
import debounce from "lodash.debounce";
export default {
   name: "FlightLocation",
   props: {
      label: String,
      chips: Array,
      tabindex: Number,
      allowChips: Boolean,
      legIndex: Number,
   },
   data() {
      return {
         loading: false,
         search: {
            value: "",
            noWay: false,
         },
         arrowNavigation: {
            isActive: false,
            navIndex: -1,
         },
         dropdownStatus: false,
         dropdownList: [],
         isInputFocused: false,
      };
   },
   computed: {
      autocompleteInput: {
         get() {
            const self = this;
            return self.search.value;
         },
         set(inputValue) {
            const self = this;
            const regex = /^[a-zA-ZğüşöçİıĞÜŞÖÇ\s]*$/;
            self.search.value = inputValue;
            if (!regex.test(inputValue)) {
               // if it is number delete last char
               self.search.value = self.search.value.slice(0, -1);
            }
         },
      },
   },
   methods: {
      handlerArrowNavigation(event) {
         const self = this;

         switch (event.keyCode) {
            case 40:
            case 39:
               self.arrowNavigation.isActive = true;
               if (self.arrowNavigation.navIndex < self.dropdownList.length - 1) {
                  self.arrowNavigation.navIndex++;

                  const scrollPositionY = self.arrowNavigation.navIndex * 50;
                  if (scrollPositionY > 300) {
                     document.querySelector(".autocomplete-dropdown-container").scrollTop = scrollPositionY;
                  }
               }
               break;
            case 38:
            case 37:
               self.arrowNavigation.isActive = true;
               if (self.arrowNavigation.navIndex > 0) {
                  self.arrowNavigation.navIndex--;
                  const scrollPositionY = self.arrowNavigation.navIndex * 50;
                  document.querySelector(".autocomplete-dropdown-container").scrollTop = scrollPositionY;
               }
               break;
            case 13:
               if (self.arrowNavigation.navIndex != -1) {
                  self.handlerPushChip(self.arrowNavigation.navIndex);
               } else {
                  self.handlerPushChip(0);
               }

               break;
            case 8:
               //Deleting chars of input
               if (self.search.value.length == 0) {
                  self.chips.pop();
               }
               break;
         }
      },
      handlerPushChip(selected) {
         //if chip has already exist
         let push2parent = true;
         this.chips.forEach((chipdata) => {
            if (this.dropdownList[selected] && chipdata.id == this.dropdownList[selected].id) {
               push2parent = false;
            }
         });
         if (push2parent) {
            this.$emit("update-destination", { selection: this.dropdownList[selected], legIndex: this.legIndex });
            this.search.value = "";
            this.dropdownStatus = false;
         }
      },

      handlerPopChip(selected) {
         this.chips.splice(selected, 1);
      },

      handleSearchInput: debounce(function (value) {
         const self = this;
         this.$emit("is-form-valid");
         if (this.chips.length == 1 && !this.allowChips) {
            self.search.value = "";
         } else if (this.chips.length == 2 && this.allowChips) {
            self.search.value = "";
         } else {
            if (value.target.value.length >= 2 && value.target.value != "") {
               this.dropdownStatus = true;
               //autocomplete request model
               var searchAutocompleteReqModel = {
                  keyword: value.target.value.clearSpaces(),
               };

               flightService.flightAutocomplete(searchAutocompleteReqModel).then((res) => {
                  this.handlerGenerateAutoCompleteList(res.airports);
                  this.arrowNavigation.navIndex = -1;
               });
            }
         }
      }, 300),
      handlerGenerateAutoCompleteList(ac) {
         if (ac != undefined) {
            const autoCompleteList = [];
            ac.forEach((city) => {
               if (city.type == 2) {
                  const cityObj = {
                     code: city.code,
                     groupCode: city.groupCode,
                     id: city.id,
                     location: city.location,
                     name: city.name,
                     type: city.type,
                  };
                  autoCompleteList.push(cityObj);

                  city.airports.forEach((airport, index) => {
                     const airportObj = {
                        code: airport.code,
                        groupCode: airport.groupCode,
                        id: airport.id,
                        location: airport.location,
                        name: airport.name,
                        type: airport.type,
                        related: true,
                        isLast: index == city.airports.length - 1,
                     };
                     autoCompleteList.push(airportObj);
                  });
               } else {
                  const airportObj = {
                     code: city.code,
                     groupCode: city.groupCode,
                     id: city.id,
                     location: city.location,
                     name: city.name,
                     type: city.type,
                  };
                  autoCompleteList.push(airportObj);
               }
            });
            this.dropdownList = autoCompleteList;
            this.loading = false;
         }
      },
      handlerOutClick() {
         const self = this;
         self.dropdownStatus = false;
         self.search.value = "";
         self.isInputFocused = false;
      },
      handlerInputFocused() {
         const self = this;
         self.isInputFocused = true;
      },
      // handlerFocus() {
      //   const self = this;
      //   self.$refs.input.focus();
      // },
   },
   watch: {
      allowChips: {
         handler: function (status) {
            if (!status && this.chips.length == 2) {
               this.chips.pop();
            }
         },
      },
      "search.value": {
         handler: function (searchValue) {
            if (searchValue.length <= 1) {
               this.dropdownStatus = false;
               this.dropdownList = [];
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.autocomplete-wrapper {
   display: flex;
   flex-direction: column;
   width: 100%;

   label {
      margin-bottom: 0.5rem;
      font-weight: 500;
   }

   .autocomplete-inner-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      padding: 0.5rem;
      box-sizing: border-box;
      border-radius: 20rem;
      position: relative;
      cursor: text !important;

      .input-and-chips {
         display: flex;
         white-space: nowrap;
         width: 100%;
         input {
            background-color: transparent;
            outline: 0;
            border: 0;
            width: 100%;
            text-transform: uppercase;
            font-size: 16px !important;

            &:focus {
               border: none !important;
            }
         }
         .chips-wrapper {
            height: 32px;
            position: relative;
            width: fit-content;

            .chip {
               background-color: var(--primary);
               border-radius: 0.5rem;
               display: inline-flex;
               align-items: center;
               padding: 0.25rem 1rem;
               color: #fff;
               margin: 0 0.125rem;
               height: inherit;
               cursor: default;

               .chip-pop {
                  width: 12px;
                  height: 12px;
                  background-color: #fff;
                  border-radius: 50%;
                  margin-left: 0.5rem;
                  cursor: pointer;
               }

               &:nth-child(2) {
                  background: var(--accent-primary);
               }
            }
         }
      }
      ul {
         position: absolute;
         left: 0;
         right: 0;
         padding: 0;
         border-radius: 8px;
         background-clip: padding-box;
         background-color: #fff;
         background-color: var(--c-primary);
         top: 100%;
         box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px 0px;
         overflow: hidden;
         z-index: 101;
         top: 50px;
         max-height: 50vh;
         overflow-y: auto;
         width: 100%;
         min-width: 100%;

         @media (min-width: 576px) {
            width: fit-content;
         }

         @keyframes airport {
            from {
               opacity: 0;
            }
            to {
               opacity: 1;
            }
         }
         li {
            animation-name: airport;
            animation-timing-function: ease-in-out;
            animation-duration: 0.25s;
            white-space: nowrap;
            padding: 0.35rem 1rem;
            border-bottom: 1px dotted #ccc;
            cursor: pointer;
            display: flex;
            align-items: center;

            &.autocomplete-indent {
               padding-left: 2rem;

               &.autocomplete-city-last-child {
                  border-bottom: 2px dashed #ccc;
               }
            }

            .autocomplete-item-informations {
               line-height: 1.125rem;

               .autocomplete-item-informations-row {
               }
            }

            &:hover,
            &.highlighted {
               color: #fff;
               background-color: #4da6ff !important;

               i {
                  filter: brightness(8);
               }

               .airport-city {
                  color: #fff;
               }
            }
            i {
               opacity: 0.95;
               $autocomplete: 23px !important;
               width: $autocomplete;
               height: $autocomplete;
               margin-top: -5px !important;
               // // filter: brightness(1.3);
               // filter: invert(53%) sepia(85%) saturate(70%) hue-rotate(186deg) brightness(100%) contrast(103%);
            }
            .airport-code {
               font-weight: 600;
               font-size: 0.9rem;
               display: inline-block;
            }
            .airport-city {
               color: #758191;
               font-size: 0.75rem;
            }
            .airport-location {
               font-size: 0.9rem;
               margin-left: 0.25rem;

               &.city-location {
                  font-weight: 500;
               }
            }

            &:nth-child(even) {
               background-color: #fbfbfb;
            }

            &:last-child {
               padding: 0;
               display: flex;
               align-items: center;
               justify-content: center;

               span {
                  margin: 1rem 0;
               }

               &:hover {
                  background-color: none !important;
               }
            }

            i {
               &.icon-flight-search-1 {
                  // transform: rotate(90deg);
               }
            }
         }
      }

      &.focused {
         //border: var(--c-border-accent);
         //box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 6px;
      }
   }
}

/* old ones */

// .leg-move,
// .leg-enter-active,
// .leg-leave-active {
//     transition: all 0.15s ease-in-out;
// }
// .leg-enter-from,
// .leg-leave-to {
//     opacity: 0;
// }
// .leg-leave-active {
//     position: relative;
// }
</style>
