<template>
   <div class="smart-table-pagination-container">
      <!-- Prev Button -->
      <template v-if="Pages.length != 0">
         <div class="pagination-prev-page" @click="handlerUpdatePage(activepage - 1)">
            {{ $t("gtsDataTableComponent_prevLabel") }}
         </div>
      </template>
      <ul>
         <template v-if="this.Pages.length > 20">
            <li
               v-for="(page, index) in visiblePages"
               :key="index"
               @click="handlerUpdatePage(page)"
               :class="index + 1 == activepage ? 'active-page' : ''">
               {{ page }}
            </li>
            <li v-if="lastVisiblePage < Pages.length - 1">...</li>
            <li v-for="(page, index) in lastPages" :key="index" @click="handlerUpdatePage(page)" :class="page == activepage ? 'active-page' : ''">
               {{ page }}
            </li>
         </template>
         <template v-else>
            <li v-for="(page, index) in Pages" :key="index" @click="handlerUpdatePage(page)" :class="index + 1 == activepage ? 'active-page' : ''">
               {{ page }}
            </li>
         </template>
      </ul>

      <!-- Next Button -->
      <template v-if="Pages.length != 0">
         <div class="pagination-next-page" @click="handlerUpdatePage(activepage + 1)">
            {{ $t("gtsDataTableComponent_nextLabel") }}
         </div>
      </template>
   </div>
</template>

<script>
export default {
   name: "SmartTablePagination",
   data() {
      return {
         activepage: 1,
         maxVisiblePages: 20,
      };
   },
   computed: {
      Pages() {
         return this.$parent.settings.pages;
      },
      visiblePages() {
         if (this.Pages.length <= this.maxVisiblePages) {
            return this.Pages;
         } else {
            const start = Math.max(this.activepage - Math.floor(this.maxVisiblePages / 2), 1);
            const end = Math.min(start + this.maxVisiblePages - 1, this.Pages.length);
            return this.Pages.slice(start - 1, end);
         }
      },
      lastVisiblePage() {
         return this.visiblePages[this.visiblePages.length - 1];
      },
      lastPages() {
         return this.Pages.slice(-5);
      },
   },
   methods: {
      handlerUpdatePage(index) {
         if (index != 0 && index != this.Pages.length + 1) {
            this.activepage = index;
         }
      },
   },
   watch: {
      activepage: {
         handler: function () {
            this.$parent.$emit("activepage", this.activepage);
         },
         immediate: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.smart-table-pagination-container {
   display: flex;
   justify-content: center;
   align-items: center;

   ul {
      display: flex;
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
         width: 32px;
         height: 32px;
         border: 2px solid #cccccc;
         border-radius: 8px;
         margin: 0 0.25rem;
         display: flex;
         justify-content: center;
         align-items: center;
         cursor: pointer;

         &:hover {
            background-color: #eee;
         }

         &.active-page {
            background-color: #363636;
            color: #fff;
            font-weight: 600;
         }
      }
   }

   .pagination-prev-page,
   .pagination-next-page {
      height: 32px;
      line-height: 16px;
      font-weight: 500;
      cursor: pointer;
      border-radius: 0.5rem;
      padding: 0.5rem;
      border: 1px solid #ccc;

      &:hover {
         background-color: #363636;
         color: #fff;
      }
   }

   .pagination-prev-page {
      margin-right: 0.75rem;
   }

   .pagination-next-page {
      margin-left: 0.75rem;
   }
}
</style>
