<template>
   <section class="hotel-order-detail-section voucher-information-wrapper">
      <div class="voucher-information-inner">
         <div class="voucher-information-header-wrapper">
            <span class="section-title">{{ $t("orderDetail_lblAccommodationInfoTitle") }}</span>

            <div class="pdf-action-buttons-wrapper">
               <template v-if="voucherInformation.status == 3">
                  <button id="document-voucher" :class="voucherLoading ? 'loading' : ''" @click="handlerDocumentViewer(5)">
                     <span>{{ $t("orderDetail_btnVoucher") }}</span>
                     <span class="g-radial-loader" v-if="voucherLoading"></span>
                     <i class="gts-icon icon-eticket" v-else></i>
                  </button>
               </template>

               <template v-if="voucherInformation.status == 2">
                  <button id="document-voucher" :class="voucherLoading ? 'loading' : ''" @click="handlerDocumentViewer(5)">
                     <span>{{ $t("orderDetail_btnVoucher") }}</span>
                     <span class="g-radial-loader" v-if="voucherLoading"></span>
                     <i class="gts-icon icon-eticket" v-else></i>
                  </button>
               </template>
            </div>
         </div>
         <div class="hotel-order-detail-section-content">
            <VoucherAndRoomInformationSummary :voucherSummary="vouchers" />
            <CancellationPolicies
               className="hotel-order-detail-inner-section"
               :cancellationDate="vouchers.lastVoidDateTime"
               :policies="cancellationPolicies" />
            <VoucherAndRoomInformationRoomType :roomTypeSummary="rooms" />
         </div>
      </div>
   </section>
</template>

<script>
import VoucherAndRoomInformationSummary from "@/components/structural/hotel/order-detail/VoucherAndRoomInformationSummary.vue";
import CancellationPolicies from "@/components/structural/hotel/common/CancellationPolicies.vue";
import VoucherAndRoomInformationRoomType from "@/components/structural/hotel/order-detail/VoucherAndRoomInformationRoomType.vue";

export default {
   name: "VoucherAndRoomInformation",
   components: {
      VoucherAndRoomInformationSummary,
      CancellationPolicies,
      VoucherAndRoomInformationRoomType,
   },
   props: {
      voucherInformation: { type: Object },
      cancellationPoliciesInformation: { type: Array },
      roomsInformation: { type: Array },
   },
   data() {
      return {
         voucherLoading: false,
         vouchers: {},
         cancellationPolicies: [],
         rooms: [],
      };
   },
   created() {
      const self = this;

      self.vouchers = this.voucherInformation;
      self.cancellationPolicies = this.cancellationPoliciesInformation;
      self.rooms = this.roomsInformation;
   },
   methods: {
      handlerDocumentViewer(type) {
         const self = this;
         self.voucherLoading = true;
         self.$store.state.app.documentViewer.request.showPrice = false;
         self.$store.state.app.documentViewer.hasPrice = true;
         self.$store.state.app.documentViewer.request.documentType = type;
         self.$store.state.app.documentViewer.request.fileType = 2;
         self.$store.state.app.documentViewer.activeProductStatus = self.voucherInformation.status;
         self.$store.commit("app/setDocumentViewer", true);
      },
   },
   watch: {
      "$store.state.app.documentViewer.isLoaded": {
         handler: function (status) {
            if (status) {
               this.voucherLoading = false;
            }
         },
      },
      voucherInformation: {
         handler: function (newVoucherInformation) {
            const self = this;

            self.vouchers = newVoucherInformation;
         },
         deep: true,
      },
      cancellationPoliciesInformation: {
         handler: function (newCancellationPoliciesInformation) {
            const self = this;

            self.cancellationPolicies = newCancellationPoliciesInformation;
         },
         deep: true,
      },
      roomsInformation: {
         handler: function (newRoomsInformation) {
            const self = this;

            self.rooms = newRoomsInformation;
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.voucher-information-wrapper {
   .voucher-information-inner {
      .hotel-order-detail-section-title {
         margin-bottom: 24px;
         & > h4 {
            font-weight: 700;
         }
      }
      .hotel-order-detail-section-content {
      }
   }
}
.voucher-information-header-wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 1.5rem;

   .section-title {
      margin-bottom: 0 !important;
   }

   .pdf-action-buttons-wrapper {
      display: flex;

      button {
         margin: 0 0.5rem;
         height: 44px;
         display: flex;
         align-items: center;
         border-radius: 24rem;
         padding-left: 1.75rem;
         padding-right: 1.125rem;
         font-size: var(--large);
         font-weight: 600;
         transform: scale(1);
         transition-property: transform;
         transition-timing-function: ease-in;
         transition-duration: 0.05s;

         i {
            margin-left: 1rem;
         }

         &:last-child {
            margin-right: 0 !important;
         }

         &#document-voucher {
            background-color: #fff;
            border: 2px solid var(--primary);
            color: var(--primary);

            &:hover {
               background-color: var(--primary);
               border: 2px solid var(--primary);
               color: #fff;

               i {
                  filter: brightness(10);
               }
            }
         }

         &#document-booking-confirmation {
            background-color: var(--success);
            color: #2d4c77;
         }

         &:hover {
            transform: scale(1.1);
         }

         &.loading {
            background-color: var(--accent-primary) !important;
            border: 2px solid var(--accent-primary) !important;
            transform: scale(1.1);
            color: #fff !important;

            .g-radial-loader {
               margin-left: 1rem;
            }
         }
      }
   }
}
</style>
