<template>
   <div id="smarttable-header-container">
      <Section id="smarttable-header-left-wrapper">
         <!-- <template v-if="settings.viewOptions.status"> -->

         <template v-if="settings.viewOptions.status">
            <UISelectBox id="report-type" :options="settings.viewOptions.selects" v-model="settings.viewOptions.perPage" />
         </template>
         <template v-if="settings.viewModes">
            <SmartTableViewMode :viewModes="settings.viewModes" :viewMode="settings.viewMode" @update-mode="handleMode" />
         </template>
         <template>
            <SmartTableExportButtons :tableTitle="settings.title" />
         </template>
      </Section>
      <Section id="smarttable-header-right-wrapper">
         <SmartTableSearch @searchKeyword="handlerSearchKeyword" />
      </Section>
   </div>
</template>

<script>
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import SmartTableSearch from "@/components/utils/SmartTable/helpers/SmartTableSearch.vue";

import SmartTableViewMode from "@/components/utils/SmartTable/helpers/SmartTableViewMode.vue";
import SmartTableExportButtons from "@/components/utils/SmartTable/helpers/SmartTableExportButtons.vue";

export default {
   name: "SmartTableHeader",
   props: {
      settings: Object,
   },
   components: {
      SmartTableSearch,
      UISelectBox,
      SmartTableViewMode,
      SmartTableExportButtons,
   },
   data() {
      return {
         options: {
            searchKeyword: "",
         },
         viewMode: "",
      };
   },
   methods: {
      handlerSearchKeyword(value) {
         this.options.searchKeyword = value;
      },
      handleMode(value) {
         this.viewMode = value;
         this.$emit("update-mode", this.viewMode);
      },
   },
   watch: {
      "options.searchKeyword": {
         handler: function (search) {
            this.$emit("viewmode", search.length > 0);

            this.$emit("options", this.options);
         },
         immediate: true,
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#smarttable-header-container {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   // background: #eee;
   padding: 1rem 0;

   #smarttable-header-left-wrapper {
      display: flex;
      #smarttable-view-container {
         margin-left: 1rem;
      }
   }
}
</style>
