const response = {
   orderDetail: {
      header: {
         orderId: Number,
         orderUuid: null,
         orderStatus: null,
         agencyId: null,
         agencyName: null,
         agencyEmail: null,
         agencyPhone: {
            iso: null,
            country: null,
            city: null,
            number: null,
         },
         users: [
            {
               email: null,
               phone: {
                  iso: null,
                  country: null,
                  city: null,
                  number: null,
               },
            },
         ],
         contacts: [
            {
               email: null,
               phone: {
                  iso: null,
                  country: null,
                  city: null,
                  number: null,
               },
            },
         ],
      },
      pnrs: [
         {
            pnr: {
               searchUuid: null,
               orderId: null,
               providerId: null,
               providerName: null,
               channelId: null,
               channelName: null,
               status: null,
               tripType: null,
               countryType: null,
               pnrIndex: null,
               pnr: null,
               airlinePnr: null,
               reserveDateTime: null,
               lastTicketDateTime: null,
               ticketDateTime: null,
               lastVoidDateTime: null,
               reverseDateTime: null,
               reReservable: null,
               id: null,
               dbState: null,
               insertedUserId: null,
               insertedUserEmail: null,
               insertedDatetime: null,
               updatedUserId: null,
               updatedUserEmail: null,
               updatedDatetime: null,
            },
            error: null,
            contact: {
               pnrId: null,
               gender: null,
               name: null,
               surname: null,
               email: null,
               phone: {
                  iso: null,
                  country: null,
                  city: null,
                  number: null,
               },
               id: null,
               dbState: null,
               insertedUserId: null,
               insertedUserEmail: null,
               insertedDatetime: null,
               updatedUserId: null,
               updatedUserEmail: null,
               updatedDatetime: null,
            },
            flights: [
               {
                  flight: {
                     pnrId: null,
                     status: null,
                     cabinType: null,
                     issueIndex: null,
                     flightIndex: null,
                     flightClass: null,
                     marketingAirline: null,
                     operatingAirline: null,
                     departureAirport: null,
                     arrivalAirport: null,
                     departureDateTime: null,
                     arrivalDateTime: null,
                     id: null,
                     dbState: null,
                     insertedUserId: null,
                     insertedUserEmail: null,
                     insertedDatetime: null,
                     updatedUserId: null,
                     updatedUserEmail: null,
                     updatedDatetime: null,
                  },
                  segments: [
                     {
                        flightId: null,
                        status: null,
                        segmentIndex: null,
                        flightNumber: null,
                        flightClass: null,
                        marketingAirline: null,
                        marketingAirlineName: null,
                        operatingAirline: null,
                        operatingAirlineName: null,
                        departureAirportCode: null,
                        arrivalAirportCode: null,
                        departureAirportName: null,
                        arrivalAirportName: null,
                        departureDateTime: null,
                        arrivalDateTime: null,
                        id: null,
                        dbState: null,
                        insertedUserId: null,
                        insertedUserEmail: null,
                        insertedDatetime: null,
                        updatedUserId: null,
                        updatedUserEmail: null,
                        updatedDatetime: null,
                     },
                  ],
                  id: null,
                  dbState: null,
                  insertedUserId: null,
                  insertedUserEmail: null,
                  insertedDatetime: null,
                  updatedUserId: null,
                  updatedUserEmail: null,
                  updatedDatetime: null,
               },
            ],
            passengers: [
               {
                  passenger: {
                     pnrId: null,
                     type: null,
                     paxIndex: null,
                     parentIndex: null,
                     gender: null,
                     birthdate: null,
                     name: null,
                     surname: null,
                     nationality: null,
                     nationalIdentity: null,
                     passport: {
                        serial: null,
                        number: null,
                        country: null,
                        expiry: null,
                     },
                     milesPan: null,
                     hesCode: null,
                     id: null,
                     dbState: null,
                     insertedUserId: null,
                     insertedUserEmail: null,
                     insertedDatetime: null,
                     updatedUserId: null,
                     updatedUserEmail: null,
                     updatedDatetime: null,
                  },
                  etickets: [
                     {
                        pnrId: null,
                        pnr: null,
                        paxId: null,
                        status: null,
                        issueIndex: null,
                        eticketNumber: null,
                        fare: null,
                        taxE: null,
                        taxI: null,
                        airlineTotal: null,
                        markups: null,
                        markdowns: null,
                        grandTotal: null,
                        currency: null,
                        brand: null,
                        baggage: null,
                        marksBreakdown: {
                           sf: null,
                           gc: null,
                           ac: null,
                           ace: null,
                           pc: null,
                           pce: null,
                           vc: null,
                           tf: null,
                           rtf: null,
                           dif: null,
                        },
                        id: null,
                        dbState: null,
                        insertedUserId: null,
                        insertedUserEmail: null,
                        insertedDatetime: null,
                        updatedUserId: null,
                        updatedUserEmail: null,
                        updatedDatetime: null,
                     },
                  ],
                  reissues: [],
                  ancillaries: {},
                  id: null,
                  dbState: null,
                  insertedUserId: null,
                  insertedUserEmail: null,
                  insertedDatetime: null,
                  updatedUserId: null,
                  updatedUserEmail: null,
                  updatedDatetime: null,
               },
            ],
            paymentChoices: [],
            paymentChoiceDetail: {
               note: null,
               enabled: null,
               reversable: null,
               paymentChoices: [],
            },
            ancillaryChoices: {
               divide: null,
               baggage: null,
               wheelchair: null,
               food: null,
               seat: null,
               onlineCheckin: null,
               onlineCheckinUrl: null,
            },
            issueTotal: null,
            reissueTotal: null,
            ancillaryTotal: null,
            paymentFeeTotal: null,
            reverseTotal: null,
            grandTotal: null,
            currency: null,
            id: null,
            dbState: null,
            insertedUserId: null,
            insertedUserEmail: null,
            insertedDatetime: null,
            updatedUserId: null,
            updatedUserEmail: null,
            updatedDatetime: null,
         },
      ],
      vouchers: [],
      tnrs: [],
      rnrs: [],
      error: null,
   },
   uuid: null,
   result: {
      success: null,
      app: null,
      service: null,
      code: null,
      subCode: null,
      message: null,
   },
   id: null,
   ids: null,
};

export default response;
