<template>
   <div class="popup-backdrop">
      <div class="popup-wrapper">
         <div class="popup-inner">
            <div id="popup-close-btn" @click="handlerClosePopup"></div>
            <div class="popup-content">
               <div class="popup-icon">
                  <i class="gts-icon icon-status-info icon-xl" v-if="status == 'info'"></i>
                  <i class="gts-icon icon-status-success icon-xl" v-else-if="status == 'success'"></i>
                  <i class="gts-icon icon-status-warning icon-xl" v-else-if="status == 'warning'"></i>
                  <i class="gts-icon icon-status-custom icon-xl" v-else-if="status == 'custom'"></i>
                  <i class="gts-icon icon-status-confirmation icon-xl" v-else-if="status == 'confirm'"></i>
                  <i class="gts-icon icon-status-price-change icon-xl" v-else-if="status == 'priceChange'"></i>
               </div>
               <div class="popup-text">
                  <template v-if="htmlContentStatus">
                     <div class="gts-popup-html-content-wrapper">
                        <span class="title">{{ title }}</span>
                        <template v-for="(staticHtmlContentItem, i) in staticHtmlContent">
                           <div :key="i" v-html="staticHtmlContentItem.outerHTML"></div>
                        </template>
                     </div>
                  </template>
                  <template>
                     <span class="title">{{ title }}</span>
                     <span class="sub-title" v-html="subTitle"></span>

                     <div class="price-change-wrapper" v-if="status == 'priceChange'">
                        <span class="old-price price-view">
                           <i> {{ $t("priceChange_lblOldPrice") }}</i>
                           <strong>{{ extra.oldPrice }}</strong>
                        </span>
                        <span class="price-difference price-view">
                           <i> {{ $t("priceChange_lblpriceDiff") }}</i>
                           <strong>{{ extra.priceDiff }}</strong>
                        </span>
                        <span class="new-price price-view">
                           <i> {{ $t("priceChange_lblnewPrice") }}</i>
                           <strong>{{ extra.newPrice }}</strong>
                        </span>
                     </div>
                  </template>
               </div>
               <div class="popup-action" v-if="status == 'info' || status == 'success' || status == 'confirm'">
                  <UIButton
                     id="popup-cancel"
                     :className="status + '_outline gts-animation-scale'"
                     :text="typeof customCancel == 'undefined' ? $t('common_cancel') : $t(`customcancel_lbl${customCancel}`)"
                     @click.native="handlerClosePopup()"
                     v-if="status == 'confirm'" />
                  <UIButton id="popup-ok" :className="status + ' gts-animation-scale'" :text="$t('common_ok')" @click.native="handlerOk()" />
               </div>
               <div class="popup-action" v-if="status == 'priceChange'">
                  <UIButton
                     id="go2checkout"
                     :className="status + '_outline gts-animation-scale'"
                     :text="$t('common_cancel')"
                     @click.native="handlerOk()" />
                  <UIButton
                     id="reserveit"
                     v-if="extra.rezBtn"
                     :className="status + ' gts-animation-scale'"
                     :text="$t('popup_lblReserve')"
                     @click.native="handlerOk()" />
               </div>
            </div>
            <!-- <div class="popup-cta">
                    <span><i class="gts-icon icon-close-rounded"></i></span>
                </div> -->
         </div>
      </div>
   </div>
</template>

<script>
import UIButton from "@/components/unitary/Button.vue";
export default {
   name: "UIPopup",
   props: {
      status: { String },
      title: { String },
      subTitle: { String },
      customCancel: { String },
      extra: Object,
      htmlContent: { String },
      bind: { String },
      confirmButtonCb: { Function },
   },
   components: {
      UIButton,
   },
   data() {
      return {
         staticHtmlContent: "",
         htmlContentStatus: false,
      };
   },
   created() {
      const self = this;
      self.$store.commit("app/setIsProgressGifActive", false);

      if (this.htmlContent != "" && this.htmlContent != undefined) {
         self.htmlContentStatus = true;
         let domParser = new DOMParser();
         let htmlContent = domParser.parseFromString(this.htmlContent, "text/html");
         let htmlContentElements = htmlContent.body.children;
         self.staticHtmlContent = htmlContentElements;
      }
   },
   methods: {
      handlerClosePopup() {
         const self = this;
         if (typeof self.confirmButtonCb == "function") {
            self.confirmButtonCb();
         }
         // typeof self.confirmButtonCb == "function" ? self.confirmButtonCb() : "";
         self.$store.state.app.errorStatus.isPopupActive = false;
      },
      handlerOk() {
         const self = this;
         if (typeof self.confirmButtonCb == "function") {
            self.confirmButtonCb();
         }
         self.$store.state.app.errorStatus.isPopupActive = false;
      },
   },
};
</script>

<style lang="scss" scoped>
.popup-backdrop {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   background: rgba($color: #000000, $alpha: 0.3);
   z-index: 999;

   .popup-wrapper {
      width: 84vw;
      background-color: white;
      box-shadow: 0 5px 25px #00000025;
      border-radius: 16px;
      position: relative;

      @media (min-width: 576px) {
         width: 25%;
      }
      @media (min-width: 768px) {
         width: 60vw;
      }
      @media (min-width: 992px) {
         width: 30vw;
      }

      #popup-close-btn {
         //  background: red;
         width: 42px;
         height: 42px;
         border: 1px solid #fff;
         position: absolute;
         right: 2rem;
         top: 1.25rem;
         cursor: pointer;

         &::before,
         &::after {
            opacity: 0.75;
            content: "";
            width: 2.5px;
            height: 24px;
            display: block;
            border-radius: 0.5rem;
            background-color: #363636;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            margin: auto;
         }

         &::before {
            transform: rotate(45deg);
         }
         &::after {
            transform: rotate(-45deg);
         }
      }

      .popup-inner {
         position: relative;
         padding: 2rem 4rem;

         .popup-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @media (min-width: 576px) {
               width: calc(100% - 16px);
            }

            .popup-icon {
               i {
                  font-size: 64px;
               }
            }
            .popup-text {
               display: flex;
               flex-direction: column;
               align-items: center;
               margin: 1rem 0;
               min-width: 200px;

               span.title {
                  margin-bottom: 0.5rem;
                  text-align: center;
               }
               span.sub-title {
                  text-align: center;
                  font-size: var(--large);
               }
               .price-change-wrapper {
                  margin-top: 1rem;
                  // border: 1px dashed #ccc;
                  padding: 0.25rem 1rem;
                  border-radius: 0.5rem;
                  min-width: 270px;
                  span {
                     font-size: var(--large);
                     display: flex;
                     justify-content: space-between;
                     align-items: center;
                     strong {
                        font-weight: 800;
                     }
                     i {
                        font-size: var(--small);
                     }
                     &.price-view {
                        // border: 1px solid #ccc;
                        // background-color: #f6f6f6;
                        padding: 0.25rem 1rem;
                        border-radius: 0.5rem;
                        margin: 0.5rem 0;
                        width: 100%;
                        text-align: right;
                     }
                     &.old-price {
                        background-color: #fbf6ee;
                        opacity: 0.75;
                     }
                     &.new-price {
                        background-color: #eaffe7;
                        // transform: scale(1.1);
                        // transform-origin: top;
                     }
                     @keyframes priceDiff {
                        0% {
                           opacity: 0;
                           transform: scale(1);
                           background: #eef9fb;
                        }
                        100% {
                           opacity: 1;
                           transform: scale(1.05);
                           background: #cceff6;
                        }
                     }

                     &.price-difference {
                        transform-origin: top;
                        animation-name: priceDiff;
                        animation-duration: 0.75s;
                        animation-iteration-count: 5;
                        animation-timing-function: ease;
                        background: #eef9fb;
                        font-weight: 600;
                        font-size: var(--xlarge);
                        i {
                           font-size: var(--normal);
                        }
                     }
                  }
               }

               .gts-popup-html-content-wrapper {
                  padding: 1rem 0;
                  text-align: center;

                  p {
                     font-weight: 800;
                  }
               }
            }

            .popup-action {
               display: flex;
               margin-top: 1rem;

               button {
                  margin-right: 1rem;

                  &:last-child {
                     margin-right: 0;
                  }
               }
            }
         }
         .popup-cta {
            position: absolute;
            top: 16px;
            right: 16px;
         }
      }
   }
}
</style>
