<template>
   <div>
      <label class="switch">
         <input type="checkbox" v-model="internalIsChecked" @change="toggleChange" />
         <span class="slider round"></span>
      </label>
   </div>
</template>

<script>
export default {
   name: "UIToggle",
   props: {
      isChecked: Boolean,
   },
   data() {
      return {
         internalIsChecked: this.isChecked,
      };
   },
   methods: {
      toggleChange(event) {
         this.$emit("toggleChange", event.target.checked);
      },
   },
   watch: {
      isChecked(newValue) {
         this.internalIsChecked = newValue;
      },
   },
};
</script>

<style>
.switch {
   position: relative;
   display: inline-block;
   width: 62px;
   height: 31px;
}
.switch input {
   opacity: 0;
   width: 0;
   height: 0;
}

.slider {
   position: absolute;
   cursor: pointer;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background-color: #ccc;
   -webkit-transition: 0.2s;
   transition: 0.2s;
}

.slider:before {
   position: absolute;
   content: "";
   height: 26px;
   width: 26px;
   left: 4px;
   bottom: 2px;
   background-color: white;
   -webkit-transition: 0.2s;
   transition: 0.2s;
}

input:checked + .slider {
   background-color: #0acc8b;
}

input:focus + .slider {
   box-shadow: 0 0 1px #0acc8b;
}

input:checked + .slider:before {
   -webkit-transform: translateX(26px);
   -ms-transform: translateX(26px);
   transform: translateX(26px);
}

.slider.round {
   border-radius: 34px;
}

.slider.round:before {
   border-radius: 50%;
}
</style>
