<template>
   <div :class="isChecked ? 'partial-payment-wrapper' : 'partial-payment-wrapper deactive'">
      <div class="content-wrapper">
         <div class="partial-payment-icon">
            <i class="gts-icon icon-partial-payment-shadow"></i>
         </div>
         <div class="partial-payment-content">
            <span class="content-title">{{ $t("hotelCheckout_lblPartialPaymentAvailable") }}</span>
            <span class="content-info-deposit spacer">{{ $t("hotelCheckout_lblPartialPaymentUsePartialPaymentAvailableInfo") }}</span>
            <div class="partial-payment-choise">
               <UIToggle :isChecked="internalIsChecked" @toggleChange="sendToggleChange"></UIToggle>
               <span class="content-info">{{ $t("hotelCheckout_lblPartialPaymentUsePartialPayment") }}</span>
            </div>

            <div class="horizontal-divider"></div>

            <!-- as a deposit güncellenecek -->
            <span class="content-info" v-html="firtPaymentInfoText"></span>

            <!-- fiyat ve tarih bold olacak  -->
            <span class="content-info-deposit" v-html="firstDepositText"></span>
            <!-- Rest of the Total payment güncellenecek -->
            <span class="content-info spacer" v-html="LastPaymentInfoText"> </span>
            <span class="content-info-deposit" v-html="secondDepositText"></span>

            <div class="partial-payment-warning">
               <!-- icon değişecek -->
               <i class="gts-icon icon-dark-info-triangle"></i>
               <span>{{ $t("hotelCheckout_lblPartialPaymentCancelInfo") }}</span>
            </div>
            <span class="content-info spacer">{{ $t("hotelCheckout_lblPartialPaymentCancellationTerms") }}</span>
            <span class="content-info-deposit">{{ $t("hotelCheckout_lblPartialPaymentCancellationTermsInfo") }}</span>
            <ul>
               <li>
                  <span class="content-info-deposit" v-html="cancellationText1"></span>
               </li>
               <li>
                  <span class="content-info-deposit" v-html="cancellationText2"></span>
               </li>
               <li>
                  <span class="content-info-deposit" v-html="cancellationText3"></span>
               </li>
            </ul>
         </div>
      </div>
   </div>
</template>
<script>
import moment from "moment";
import UIToggle from "@/components/unitary/form2/UIToggle.vue";

export default {
   name: "CheckoutPartialPaymentDetails",
   props: {
      partialPaymentModel: { type: Object },
      isChecked: Boolean,
   },
   components: {
      UIToggle,
   },
   data() {
      return {
         firstPaymentDate: "",
         secondPaymentDate: "",
         internalIsChecked: this.isChecked,
      };
   },
   methods: {
      sendToggleChange(isChecked) {
         this.$emit("toggleChange", isChecked);
         this.internalIsChecked = isChecked;
      },
   },
   computed: {
      formattedFirstPaymentDate() {
         return moment(this.partialPaymentModel.firstPaymentDate).format("DD.MM.YYYY HH:mm");
      },
      formattedSecondPaymentDate() {
         return moment(this.partialPaymentModel.secondPaymentDate).format("DD.MM.YYYY HH:mm");
      },
      firtPaymentInfoText: function () {
         var firstTextLine = this.$t("hotelCheckout_lblPartialPaymentFirstPayment");
         var secondTextLine = this.$t("hotelCheckout_lblPartialPaymentDeposit");
         var secondTextLineStyled = "<span style='font-weight: 500; font-size:12px'>" + " (" + secondTextLine + ")" + "</span>";

         return firstTextLine + secondTextLineStyled;
      },
      LastPaymentInfoText: function () {
         var firstTextLine = this.$t("hotelCheckout_lblPartialPaymentSecondPayment");
         var secondTextLine = this.$t("hotelCheckout_lblPartialPaymentRemainingPayment");
         var secondTextLineStyled = "<span style='font-weight: 500; font-size:12px'>" + " (" + secondTextLine + ")" + "</span>";

         return firstTextLine + secondTextLineStyled;
      },
      firstDepositText: function () {
         var firstDepositPriceText = this.$options.filters.price(this.partialPaymentModel.firstPaymentPrice, this.partialPaymentModel.currency);
         var firstDateText = this.formattedFirstPaymentDate;
         var firstDatePriceTextBold = "<span style='font-weight: 600;'>" + firstDepositPriceText + "</span>";
         var firstDateTextBold = "<span style='font-weight: 600;'>" + firstDateText + "</span>";
         return this.$t("hotelCheckout_lblPartialPaymentFirstDeposit")
            .replace("#VALUE1#", firstDatePriceTextBold)
            .replace("#VALUE2#", firstDateTextBold + "`");
      },
      secondDepositText: function () {
         var secondDepositPriceText = this.$options.filters.price(this.partialPaymentModel.secondPaymentPrice, this.partialPaymentModel.currency);
         var secondDateText = this.formattedSecondPaymentDate;
         var secondDatePriceTextBold = "<span style='font-weight: 600;'>" + secondDepositPriceText + "</span>";
         var secondDateTextBold = "<span style='font-weight: 600;'>" + secondDateText + "</span>";
         return this.$t("hotelCheckout_lblPartialPaymentRemainingPaymentInfo")
            .replace("#VALUE1#", secondDatePriceTextBold)
            .replace("#VALUE2#", secondDateTextBold + "`");
      },
      cancellationText1: function () {
         //no penalty için fmt değişecek
         var addSpan = "<span style='font-weight: 600;'>";
         var endSpan = "</span>";
         var firstCancellationDateText = this.formattedFirstPaymentDate;
         var firstDateTextBold = "<span style='font-weight: 600;'>" + firstCancellationDateText + "</span>";
         return this.$t("hotelCheckout_lblPartialPaymentCancellationTermsInfoText1")
            .replace("#VALUE#", firstDateTextBold)
            .replace("#VALUE1#", addSpan)
            .replace("#VALUE2#", endSpan);
      },
      cancellationText2: function () {
         var secondDepositPriceText = this.$options.filters.price(this.partialPaymentModel.firstPaymentPrice, this.partialPaymentModel.currency);
         var secondDateText = this.formattedSecondPaymentDate;
         var secondDatePriceTextBold = "<span style='font-weight: 600;'>" + secondDepositPriceText + "</span>";
         var secondDateTextBold = "<span style='font-weight: 600;'>" + secondDateText + "</span>";
         return this.$t("hotelCheckout_lblPartialPaymentCancellationTermsInfoText2")
            .replace("#VALUE2#", secondDatePriceTextBold)
            .replace("#VALUE1#", secondDateTextBold + "`");
      },
      cancellationText3: function () {
         var secondDepositPriceText = this.$options.filters.price(this.partialPaymentModel.secondPaymentPrice, this.partialPaymentModel.currency);
         var secondDateText = this.formattedSecondPaymentDate;
         var secondDatePriceTextBold = "<span style='font-weight: 600;'>" + secondDepositPriceText + "</span>";
         var secondDateTextBold = "<span style='font-weight: 600;'>" + secondDateText + "</span>";
         return this.$t("hotelCheckout_lblPartialPaymentCancellationTermsInfoText3")
            .replace("#VALUE2#", secondDatePriceTextBold)
            .replace("#VALUE1#", secondDateTextBold + "`");
      },
   },
};
</script>

<style lang="scss">
.partial-payment-wrapper {
   display: flex;
   height: auto;
   background: #eefcf3;
   border: 2px solid #29cb96;
   border-radius: 0.5rem;
   .content-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: row;
      padding: 1.5rem;

      .partial-payment-icon {
         padding-right: 1.5rem;

         i {
            height: 60px !important;
            width: 60px !important;
         }
      }
      .partial-payment-content {
         padding-top: 1rem;
         display: flex;
         flex-direction: column;
         .partial-payment-choise {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            span {
               font-weight: 500;
            }
         }
         .content-title {
            font-size: 18px;
            font-weight: 500;
            padding-bottom: 0.5rem;
         }
         .content-info {
            font-size: 16px;
            font-weight: 500;
            &.spacer {
               margin-top: 1rem;
            }
         }
         .content-info-deposit {
            font-size: 14px;
            &.spacer {
               margin-top: 0.5rem;
               margin-bottom: 1rem;
            }
         }
         .partial-payment-warning {
            margin: 1rem 0 1rem 0;
            display: flex;
            flex-direction: row;
            background: #c4c4c42b;
            min-height: 50px;
            height: auto;
            justify-content: flex-start;
            align-items: center;
            border-radius: 0.5rem;
            i {
               margin-top: -1rem;
               margin-left: 1rem;
               min-width: 24px !important;
               min-height: 24px !important;
            }
            span {
               font-size: 14px;
               margin-left: 0.5rem;
            }
            @media (max-width: 768px) {
               height: auto;
            }
         }
         .horizontal-divider {
            width: 100%;
            border: 1px solid #4948482b;
            margin-bottom: 1.5rem;
            margin-top: 1rem;
         }
      }
      ul {
         margin-left: 1.25rem;
      }
      ul li {
         padding: 0.25rem;
         list-style: disc;
      }
   }
   &.deactive {
      filter: grayscale(100%);
      border: 2px solid #f3dcdc;
      background: white !important;
   }
}
</style>
