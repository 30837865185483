<template>
   <div class="task-list-page-container sub-page-wrapper">
      <Aside />
      <Main>
         <Navbar />

         <div class="container-fluid">
            <div class="row">
               <div class="col gts-box mx-3">
                  <h1 class="sub-page-header">{{ $t("taskList_title") }}</h1>

                  <div class="sub-page-row-wrapper row gy-3">
                     <div class="col-12 col-md-6 col-lg-2">
                        <UISelectBox :label="$t('taskList_filter_lblType')" :options="types" v-model="searchQuery.type" light-theme />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UISelectBox :label="$t('taskList_filter_lblStatus')" :options="statuses" v-model="searchQuery.status" light-theme />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIDatePicker2 :label="$t('taskList_filter_lblFromDate')" :min="minDate" :max="maxDate" v-model="searchQuery.date1" />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIDatePicker2 :label="$t('taskList_filter_lblToDate')" :min="minDate" :max="maxDate" v-model="searchQuery.date2" />
                     </div>
                  </div>

                  <!--  CTA -->
                  <div class="sub-page-actions-row row">
                     <div class="sub-pages-actions-wrapper col-12">
                        <UIButton className="default" :text="$t('taskList_filter_btnList')" @click.native="funcListTasks()" />
                     </div>
                  </div>
               </div>
            </div>

            <section class="page-section gts-box" v-if="mainTableOptions.rows.length > 0">
               <GtsGrid :table-options="mainTableOptions" :item-per-page="-1" :title="$t('invoiceList_title')" />
            </section>
         </div>
      </Main>
   </div>
</template>

<script>
import moment from "moment";
import i18n from "@/fmt/i18n.js";
import Popup from "@/gts-popup.js";
import { taskService } from "@/service/index.js";

import Main from "@/components/unitary/Main.vue";
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import GtsGrid from "@/components/utils/GtsGrid.vue";
import UIButton from "@/components/unitary/Button.vue";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import UIDatePicker2 from "@/components/unitary/form2/UIDatePicker2.vue";

export default {
   name: "TaskList",
   components: {
      Aside,
      Navbar,
      Main,
      GtsGrid,
      UIButton,
      UISelectBox,
      UIDatePicker2,
   },
   data() {
      return {
         show: false,
         maxDate: moment().format(this.$constants.dateFormat),
         minDate: moment().subtract(1, "year").format(this.$constants.dateFormat),
         types: this.$GtsDropdownData.ETaskType(),
         statuses: this.$GtsDropdownData.ETaskStatus(),
         searchQuery: {
            date1: moment().subtract(1, "month").format(this.$constants.dateFormat),
            date2: moment().format(this.$constants.dateFormat),
            type: -1, //ALL
            status: -1, //ALL
         },
         mainTableOptions: {
            columns: [
               { label: `${i18n.t("taskList_tblMain_thId")}` },
               { label: `${i18n.t("taskList_tblMain_thOrderId")}` },
               { label: `${i18n.t("taskList_tblMain_thType")}` },
               { label: `${i18n.t("taskList_tblMain_thTaskStatus")}` },
               { label: `${i18n.t("taskList_tblMain_thOrderStatus")}` },
               { label: `${i18n.t("taskList_tblMain_thEntityType")}` },
               { label: `${i18n.t("taskList_tblMain_thEntity")}` },
               { label: `${i18n.t("taskList_tblMain_thNote")}` },
               { label: `${i18n.t("taskList_tblMain_thGsa")}` },
               { label: `${i18n.t("taskList_tblMain_thAgency")}` },
               { label: `${i18n.t("taskList_tblMain_thCreatedUser")}` },
               { label: `${i18n.t("taskList_tblMain_thClosingType")}` },
               { label: `${i18n.t("taskList_tblMain_thUpdatedDateTime")}` },
            ],
            rows: [],
         },
      };
   },
   methods: {
      getTableColumns() {
         return [
            { label: `${this.$i18n.t("taskList_tblMain_thId")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thOrderId")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thType")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thTaskStatus")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thOrderStatus")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thEntityType")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thEntity")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thNote")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thGsa")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thAgency")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thCreatedUser")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thClosingType")}` },
            { label: `${this.$i18n.t("taskList_tblMain_thUpdatedDateTime")}` },
         ];
      },
      updateTableColumns() {
         this.mainTableOptions.columns = this.getTableColumns();
      },
      funcListTasks() {
         const self = this;

         self.show = false;
         self.mainTableOptions.rows.length = 0;
         self.$store.commit("app/setIsProgressGifActive", true);

         let request = {
            orderId: 0,
            date1: self.searchQuery.date1,
            date2: self.searchQuery.date2,
            type: self.searchQuery.type,
            status: self.searchQuery.status,
         };

         taskService.listTasks(request).then((response) => {
            self.$store.commit("app/setIsProgressGifActive", false);

            if (!response.result.success) {
               response.result.service = this.$options.name;
               Popup.result(response.result);
               return;
            }

            response.tasks.map((task) => {
               this.mainTableOptions.rows.push({
                  id: {
                     iteration: [task.id],
                     className: "order-router gts-animation-scale",
                     router: `/order/detail/${task.orderUuid}?taskopen=true`,
                  },
                  orderId: {
                     iteration: [task.orderId],
                     className: "order-router gts-animation-scale",
                     router: `/order/detail/${task.orderUuid}`,
                  },
                  type: {
                     iteration: [task.type],
                     filter: "ETaskType",
                  },
                  status: {
                     iteration: [task.status],
                     className: "badge",
                     filter: "ETaskStatus",
                  },
                  entityStatus: {
                     iteration: [task.entityStatus],
                     className: "badge",
                     filter: this.handlerEntityType(task.entityType),
                  },
                  entityType: {
                     iteration: [task.entityType],
                     filter: "EOdrEntityType",
                  },
                  entity: task.entityIdentifier,
                  note: task.description,
                  gsa: task.gsaName,
                  agency: task.agencyName,
                  createdUser: {
                     iteration: [task.openedUserEmail, this.$options.filters.FormatterDateTime(task.openedDatetime)],
                  },
                  closingType:
                     task.closingType <= 0
                        ? ""
                        : {
                             iteration: [task.closingType],
                             className: "badge",
                             filter: "ETaskClosingType",
                          },
                  updatedDateTime: this.$options.filters.FormatterDateTime(task.updatedDatetime),
                  tags: JSON.stringify(task),
               });
            });

            self.show = true;
         });
      },
      handlerEntityType(entityType) {
         switch (entityType) {
            case 2: //PNR
               return "EFltPnrStatus";
            case 3: //VOUCHER
               return "EHtlVoucherStatus";
            case 4: //TNR
               return "ETrfTnrStatus";
         }
      },
   },
   watch: {
      "$i18n.locale"() {
         this.types = this.$GtsDropdownData.ETaskType();
         this.statuses = this.$GtsDropdownData.ETaskStatus();
         this.updateTableColumns();
      },
      "$store.state.app.splashScreen"() {
         this.types = this.$GtsDropdownData.ETaskType();
         this.statuses = this.$GtsDropdownData.ETaskStatus();
         this.updateTableColumns();
      },
   },
};
</script>

<style lang="scss" scoped>
.page-container {
   .page-inner {
      width: 90%;
      margin: auto;
      .page-form-row {
         display: grid;
         grid-template-columns: repeat(6, 1fr);
         grid-column-gap: 1rem;
      }
      .page-action-row {
         margin-top: 1rem;
         display: flex;
         justify-content: flex-end;
      }
   }
}
</style>
