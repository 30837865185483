<template>
   <div class="ancillary-seatmap-passenger-wrapper-container">
      <div class="ancillary-seat-map-passenger-inner-wrapper">
         <template v-if="Object.keys(seatList).length > 4">
            <template v-for="(passenger, passengerIndex) in seatList">
               <AncillarySeatMapPassengerNarrow :passenger="passenger" :key="passengerIndex" />
            </template>
         </template>

         <template v-else>
            <template v-for="(passenger, pIndex) in seatList">
               <AncillarySeatMapPassenger :passenger="passenger" :key="pIndex" />
            </template>
         </template>
      </div>

      <SeatMapPriceView :passengers="seatList" :segmentIndex="segmentIndex" :flightKey="flightKey" />
   </div>
</template>

<script>
// import UITooltip from "@/components/utils/GtsToolTips.vue";
import SeatMapPriceView from "@/components/structural/flight/order-detail/ancillaries/seatmap2/SeatMapPriceView.vue";
import AncillarySeatMapPassenger from "@/components/structural/flight/order-detail/ancillaries/seatmap2/AncillarySeatMapPassenger.vue";
import AncillarySeatMapPassengerNarrow from "@/components/structural/flight/order-detail/ancillaries/seatmap2/AncillarySeatMapPassengerNarrow.vue";
export default {
   name: "AncillarySeatMapPassengerWrapper",
   props: {
      seatList: Object,
      segmentIndex: Number,
      flightKey: String,
      segmentId: Number,
   },
   components: {
      SeatMapPriceView,
      AncillarySeatMapPassenger,
      AncillarySeatMapPassengerNarrow,
   },
   mounted() {
      // console.log("segmentId", this.segmentId);
      this.$store.commit("seatmap/initialUpdateSeatList", this.segmentId);
   },
};
</script>

<style lang="scss" scoped>
.ancillary-seat-map-passenger-inner-wrapper {
   overflow-y: auto;
   height: 520px;
   padding: 0 1rem;
   padding-bottom: 1rem;
}
</style>
