<template>
   <div class="seat-map-modal-body">
      <!-- <div v-for="(leg, legIndexs) in legs" :key="legIndexs + 5">
         {{ leg }}
      </div> -->

      <!-- STEP 1 -->

      <template v-if="Step == 0">
         <PaymentPurchaseWidget :paymentChoiceDetail="paymentChoiceDetails" entityType="ticket_seat" noTitle headless hidePrevBtn />
      </template>
      <template v-if="Step == 1">
         <SeatMapResult :result="result" />
      </template>

      <!-- <DynamicSegments :step="step.activeStep" :segments="segments[Step]" v-if="showDynamicSegments" /> -->

      <div class="dynamic-wrapper">
         <template v-for="segment in Segments">
            <DynamicSegments :step="step.activeStep" :key="segment.id" :segment="segment" v-if="segment.id == Step" />
         </template>
      </div>
   </div>
</template>

<script>
import PaymentPurchaseWidget from "@/components/structural/payment/PaymentPurchaseWidget.vue";
import SeatMapResult from "@/components/structural/flight/order-detail/ancillaries/seatmap2/SeatMapResult.vue";
// import SeatMapLegSelection from "@/components/structural/flight/order-detail/ancillaries/seatmap2/SeatMapLegSelection.vue";
import DynamicSegments from "@/components/structural/flight/order-detail/ancillaries/seatmap2/DynamicSegments.vue";

export default {
   name: "SeatMapModalBody",
   props: {
      result: Object,
      step: Object,
      legs: Array,
      paymentChoiceDetails: Object,
   },
   components: {
      DynamicSegments,
      PaymentPurchaseWidget,
      SeatMapResult,
   },

   computed: {
      Segments() {
         return this.$store.state.seatmap.segments;
      },
      Step() {
         return this.step.activeStep;
      },
      showDynamicSegments() {
         return this.step.activeStep == 0 || this.step.activeStep == this.step.totalStep - 1 ? false : true;
      },
   },
   methods: {
      handlerSelectLeg(legId) {
         this.$emit("legId", legId);
      },
   },
};
</script>

<style lang="scss" scoped>
.seat-map-modal-body {
   height: 100%;

   .leg-selection {
      border: 1px solid #ccc;
      padding: 0.25rem 1rem;
      cursor: pointer;

      &:hover {
         background-color: #eee;
      }
   }
}
</style>
