<template>
   <div class="hotel-filter-container">
      <!-- RECOMMENDED HOTELS -->
      <!-- <div class="recommended-hotels-container">
         {{ filters.showOnlyRecommendedHotels }}
         <UICheckBox v-model="filters.showOnlyRecommendedHotels" id="input_checkDiscountedHotels" label="önerilen" hotel />
      </div> -->
      <div id="close-mobile-filter" @click="handlerCloseMobileFilter" class="d-lg-none">
         <span> {{ $t("hotelFilter_btnApplyAndClose") }}</span>
      </div>

      <!-- Informations -->
      <FilterInformations
         :informations="{
            reccommendeds: filtersData.recommendeds.recommendeds,
            discounted: filtersData.discounted.discounted,
            isPartialPaymentExist: filtersData.isPartialPaymentExist.isPartialPaymentExist,
         }"
         @click="handlerTriggerFilterBasedSearch" />
      <!-- Prices -->

      <FilterPrices
         :prices="filtersData.prices"
         :price-slider="PriceSliderInformations"
         @click="handlerTriggerFilterBasedSearch"
         @clear="handlerClearPrices" />
      <!-- Boardtypes -->
      <FilterBoardTypes :board-types="filtersData.boardTypes" @click="handlerTriggerFilterBasedSearch" />
      <!-- Facilities  -->
      <FilterFacilities :facilities="filtersData.facilities" @click="handlerTriggerFilterBasedSearch" />
      <!-- Stars -->
      <FilterStars :stars="filtersData.stars" @update-stars="handlerUpdateStarSelection" />
   </div>
</template>

<script>
import { priceMap } from "@/helper/hotel.js";

import FilterInformations from "@/components/structural/hotel/filters/FilterInformations.vue";
import FilterFacilities from "@/components/structural/hotel/filters/FilterFacilities.vue";
import FilterBoardTypes from "@/components/structural/hotel/filters/FilterBoardTypes.vue";
import FilterStars from "@/components/structural/hotel/filters/FilterStars.vue";
import FilterPrices from "@/components/structural/hotel/filters/FilterPrices.vue";
export default {
   name: "HotelFilters",
   components: {
      FilterInformations,
      FilterFacilities,
      FilterBoardTypes,
      FilterStars,
      FilterPrices,
   },
   props: {
      filtersData: { Object },
      filterRequest: { Object },
   },
   data() {
      return {
         filters: this.filtersData,
         priceRange: {
            minPrice: Math.round(this.$store.state.hotel.price.minPrice),
            maxPrice: Math.round(this.$store.state.hotel.price.maxPrice),
         },
      };
   },
   computed: {
      PriceSliderInformations() {
         return {
            pricerange: this.priceRange,
            filterrequest: {
               priceFrom: this.filterRequest.priceFrom,
               priceTo: this.filterRequest.priceTo,
            },
         };
      },
   },

   methods: {
      handlerTriggerFilterBasedSearch() {
         this.$parent.$parent.handlerFilterRequestModelGenerator();
      },
      handlerClearPrices() {
         // console.log("handlerClearPrices");
         this.$parent.$parent.handlerPriceGeneratorSlider(0, 0, false);
         this.$parent.$parent.handlerFilterRequestModelGenerator();
      },
      handlerCloseMobileFilter() {
         const hotelFilterMobile = this.$parent.$parent.$refs.hotelFilterMobile;
         hotelFilterMobile.classList.remove("mobile-filter-active");
      },
      handlerResultCounts(result) {
         result.forEach((item) => {
            //facility count
            item.allFacilities.forEach((facility) => {
               const currentCount = this.filters.facilities[facility].count;
               this.$set(this.filters.facilities[facility], "count", currentCount + 1);
            });

            //boardtype count
            item.boardTypes.forEach((boardtype) => {
               const currentCount = this.filters.boardTypes[boardtype].count;
               this.$set(this.filters.boardTypes[boardtype], "count", currentCount + 1);
            });

            // prices count
            const price = priceMap(item.price.totalAmount);
            const currentCount = this.filters.prices[price].count;
            this.$set(this.filters.prices[price], "count", currentCount + 1);
         });
      },
      handlerUpdateStarSelection(star) {
         if (star != -1) {
            const currentStatus = this.filters.stars[star].checked;
            this.$set(this.filters.stars[star], "checked", !currentStatus);
         }

         this.handlerTriggerFilterBasedSearch();
      },

      handlerIsFilterActive(filters) {
         const filterStatusObj = {};
         const isFilterActive = Object.entries(filters).forEach(([filterKey, filter]) => {
            filterStatusObj[filterKey] = false;

            let result = Object.entries(filter).some(([propKey, prop]) => {
               propKey;
               return prop.checked;
            });

            result ? (filterStatusObj[filterKey] = true) : (filterStatusObj[filterKey] = false);
            return result;
         });

         if (isFilterActive) {
            this.$store.commit("hotel/setHotelFilterStatus", filterStatusObj);
         } else {
            this.$store.commit("hotel/setHotelFilterStatus", filterStatusObj);
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.hotel-filter-container {
   background: #fff;
   padding: 1rem 0.75rem;
   border-radius: 0.5rem;
   position: sticky;
   top: 1rem;
   height: 90vh;
   overflow-y: auto;
   box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

   #close-mobile-filter {
      display: flex;
      justify-content: flex-end;
      span {
         background-color: var(--primary);
         color: #fff;
         display: flex;
         justify-content: center;
         padding: 0.5rem 1rem;
         border-radius: 24rem;
         font-size: var(--large);
         font-weight: 500;
         cursor: pointer;
      }
   }
}
</style>

<style lang="scss">
.filter-section-container {
   border-bottom: 1px solid #dcdcdc;
   margin: 0.5rem 0;
   padding: 0.5rem 0.25rem;
   padding-bottom: 1rem;

   .filter-section-title-wrapper {
      display: flex;
      justify-content: space-between;

      span.filter-section-title {
         display: block;
         margin-bottom: 0.5rem;
         padding-bottom: 0.5rem;
         // border-bottom: 1px solid #ccc;
         font-weight: 500;
         font-size: var(--small);
      }
      span.filter-section-toggle {
         font-size: var(--small);
         font-weight: 500;
         color: var(--accent-primary);
         cursor: pointer;
      }
   }

   .filter-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 0.5rem;
      .g-checkbox-container {
         font-size: var(--small);
      }
      span.filter-count {
         color: var(--txt-ghost);
      }
   }
}
</style>
