<template>
   <div id="flight-baggage-ancillary-container" :class="waiting ? 'waiting-for-response' : ''">
      <!-- PAX LISTS -->
      <!-- {{ r }} - {{ r + 3 }} -->

      <div id="paxes-container">
         <ul ref="paxes" id="paxes-wrapper" :class="TotalLength > 3 ? 'scrollable-section' : ''">
            <template v-for="(pax, paxIndex) in paxes">
               <li :key="paxIndex" class="baggage-ancillary-pax" :style="{ zIndex: paxes.length - paxIndex }">
                  <span class="pax-index">
                     {{ pax.paxIndex }}
                  </span>

                  <div class="pax-informations-wrapper">
                     <!-- Pax Name & Surname -->
                     <div class="pax-infos">
                        <span class="pax-name">
                           {{ pax.name }}
                        </span>
                        <div class="pax-type">
                           {{ $options.filters.EFltPaxType(pax.paxType) }}
                        </div>
                     </div>

                     <!-- Pax Baggage & Route Informations -->
                     <div class="pax-extra-infos">
                        <div class="pax-extra-infos-row" v-for="(flight, flightIndex) in pax.flights" :key="flightIndex">
                           <div class="pax-existing-baggage">{{ flight.baggage }}</div>
                           <div class="pax-route-information">
                              <img :src="`${$constants.cdn}/airline_34x34/${flight.airlineCode}.svg`" />
                              <span class="route-from">{{ flight.from }}</span>
                              <i class="gts-icon icon-arrow-seperator icon-xs"></i>
                              <span class="route-to">{{ flight.to }}</span>
                           </div>
                           <div class="pax-baggage-selection">
                              <UISelectBox
                                 :options="flight.extraBaggage"
                                 v-model="flight.baggageSelection"
                                 light-theme
                                 has-hidden-key
                                 @hidden="
                                    (arg) => {
                                       generateRequestModel(arg, pax.paxId, flight.flightId);
                                    }
                                 " />
                           </div>
                        </div>
                     </div>
                  </div>
               </li>
            </template>
         </ul>
      </div>

      <!-- TOTAL -->

      <div id="flight-baggage-ancillary-total-wrapper">
         <div id="flight-pax-scroller-wrapper">
            <template v-if="TotalLength > 3">
               <div class="virtual-scroller-wrapper down" id="virtual-scroller-down" @click="handlerScroller('down')">
                  <div class="virtual-scroller-txt-wrapper">
                     <i class="g-select-box-arrow gts-icon icon-arrow-down icon-sm"></i>

                     <!-- <span class="txt-bold txt-large">Listeyi Kaydır</span> -->
                  </div>
               </div>

               <div class="virtual-scroller-wrapper up" id="virtual-scroller-up" @click="handlerScroller('up')">
                  <div class="virtual-scroller-txt-wrapper">
                     <i class="g-select-box-arrow gts-icon icon-arrow-up icon-sm"></i>
                     <!-- <span class="txt-bold txt-large">Listeyi Kaydır</span> -->
                  </div>
               </div>
            </template>
         </div>
         <div id="flight-baggage-ancillary-total-inner-wrapper">
            <div class="flight-baggage-ancillary-total-row">
               <div class="flight-baggage-ancillary-total-col">
                  <span class="txt-bold">
                     {{ $t("ancillariesServicesModal_lblExtraComm") }}
                  </span>
               </div>
               <div class="flight-baggage-ancillary-total-col">
                  <UIInput v-model="ancillaryExtraCommission" label="" v-input="{ type: 'numeric', range: [0, 99], required: false, requiredMsg: 'dynamicErrorMessage' }" />
                  <!-- <input type="text" v-model="ancillaryExtraCommission" :data-max="99" v-price /> -->
               </div>
            </div>
            <div class="flight-baggage-ancillary-total-row">
               <div class="flight-baggage-ancillary-total-col">
                  <span class="txt-bold">
                     {{ $t("ancillariesServicesModal_lblGrandTotal") }}
                  </span>
               </div>
               <div class="flight-baggage-ancillary-total-col">
                  <span class="txt-black txt-xxlarge">{{ GrandTotal() | price }}</span>
               </div>
            </div>
            <!-- <span class="txt-bold">
               {{ $t("ancillariesServicesModal_lblGrandTotal") }}
            </span>
            <span class="txt-black txt-xxlarge">{{ GrandTotal() | price }}</span> -->
         </div>
      </div>
   </div>
</template>

<script>
import UIInput from "@/components/unitary/form2/UIInput.vue";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
export default {
   name: "AncillaryFlightBaggage",
   props: {
      paxes: [Object, Array],
   },
   components: {
      UISelectBox,
      UIInput,
   },
   data() {
      return {
         ancillaryExtraCommission: "0",
         requestModel: {},
         scrollTriggered: false,
         waiting: false,
         r: 0,
      };
   },

   computed: {
      TotalLength() {
         return this.paxes.length;
      },
   },
   methods: {
      generateRequestModel(key, paxId, flightId) {
         if (this.requestModel[paxId] === undefined) {
            this.requestModel[paxId] = {
               [flightId]: key,
            };
         } else {
            this.requestModel[paxId][flightId] = key;
         }
      },
      GrandTotal() {
         var total = 0;
         let b = parseFloat(this.ancillaryExtraCommission);

         if (isNaN(b)) {
            b = 0;
            this.ancillaryExtraCommission = 0;
         }

         this.paxes.forEach((pax) => {
            Object.entries(pax.flights).forEach(([key, entry]) => {
               key;
               total += entry.baggageSelection;
            });
         });
         // this.$parent.total = parseInt(total) + parseInt(b);
         this.$parent.agencyExtraCommission = parseFloat(b);
         this.$parent.total = parseFloat(total) + parseFloat(b);
         return parseFloat(total) + parseFloat(b);
      },
      handlerScroller(direction) {
         const paxesScroller = this.$refs.paxes;
         if (direction == "up") {
            paxesScroller.scrollTo({
               top: paxesScroller.scrollTop - 138,
               behavior: "smooth",
            });
         } else {
            paxesScroller.scrollTo({
               top: paxesScroller.scrollTop + 138,
               behavior: "smooth",
            });
         }
      },
   },
   watch: {},
};
</script>

<style lang="scss">
.virtual-scroller-wrapper {
   background-color: #fff;
   display: inline-flex;
   align-items: center;
   padding: 0.5rem 1rem;
   border-radius: 0.25rem;
   border: 1px solid #ccc;
   height: 48px !important;
   overflow: hidden;
   position: relative;
   cursor: pointer;
   transform: scale(1);
   transition-property: transform;
   transition-timing-function: ease-out;
   transition-duration: 0.125s;

   &.up {
      // transform: rotate(180deg);
      margin-left: 0.5rem;
   }

   @keyframes uiHighlight {
      0% {
         background-color: #fff;
         transform: scale(1);
      }

      80% {
         background-color: #363636;
         transform: scale(1.125);
      }
      100% {
         background-color: #363636;
         transform: scale(1);
      }
   }

   &.ui-highlighter {
      background-color: #363636;
      animation-timing-function: ease-in-out;
      animation-name: uiHighlight;
      animation-duration: 0.5s;
      animation-iteration-count: 1;

      i {
         filter: brightness(10);
      }
   }
   .virtual-scroller-txt-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      // transform: translate(0px, 0px);
      height: 48px;
      margin: auto;
      transform: scale(1);
      transition-property: transform;
      transition-timing-function: ease-out;
      transition-duration: 0.125s;
   }

   &:hover {
      background-color: #ececec;
      background-color: #363636;
      transform: scale(1.125);
      i {
         filter: brightness(10);
      }
      .virtual-scroller-txt-wrapper {
         // transform: translate(0px, 40px);
      }
   }
}
</style>
<style lang="scss" scoped>
@keyframes loading {
   0% {
      opacity: 0;
   }
   60% {
      opacity: 0;
   }
   100% {
      opacity: 0.75;
   }
}
$elHeight: 138px;
#flight-baggage-ancillary-container {
   height: 100%;
   // background-color: aliceblue;
   // position: relative;

   #paxes-container {
      // border: 1px solid #ccc;
      position: relative;
      z-index: 1;
      ul {
         max-height: calc(3 * #{$elHeight});

         &.scrollable-section {
            overflow: hidden;
            overflow-y: scroll;
            scroll-behavior: smooth;
         }

         &::-webkit-scrollbar {
            display: none;
         }

         li.baggage-ancillary-pax {
            //  opacity: 0;
            //  transform: translate(100px, 0) scale(0.75);
            border: 2px solid #cacaca;
            border-radius: 1rem;
            margin-bottom: 16px;
            box-sizing: border-box;
            padding: 1rem;
            display: flex;
            position: relative;
            &:nth-child(odd) {
               background-color: #fafbfc;
            }
            &:nth-child(even) {
               background: #fff;
            }

            &:last-child {
               // margin-bottom: 0;
            }

            span.pax-index {
               font-size: 3rem;
               color: var(--txt-black);
               font-family: "Poppins";
               border-right: 1px solid #d3d3d3;
               padding: 0 1rem 0 0.5rem;
               margin-right: 1.5rem;
               margin-top: 0.5rem;
               margin-bottom: 0.5rem;
               min-width: 55px;
            }
            .pax-informations-wrapper {
               display: flex;
               width: 100%;
               .pax-infos {
                  display: flex;
                  width: 30%;
                  flex-direction: column;
                  font-size: var(--large);
                  margin-top: 0.5rem;

                  span.pax-name {
                     font-weight: 500;
                  }
               }
               .pax-extra-infos {
                  width: 70%;
                  .pax-extra-infos-row {
                     display: grid;
                     grid-template-columns: 0.75fr 1.5fr 2fr;
                     align-items: center;
                     //   border-bottom: 1px dotted #ccc;
                     margin-bottom: 0.5rem;
                     grid-column-gap: 1rem;

                     .pax-existing-baggage,
                     .pax-route-information {
                        font-weight: 500;
                     }

                     &:last-child {
                        margin-bottom: 0 !important;
                     }
                  }
                  .pax-route-information {
                     display: grid;
                     grid-template-columns: 32px 1fr 16px 1fr;
                     align-items: center;
                     img {
                        width: 32px;
                     }
                     span {
                        text-align: center;
                     }
                  }
                  .pax-baggage-selection {
                     ::v-deep {
                        .g-select-box {
                           min-height: 40px !important;
                        }
                     }
                  }
               }
            }
         }
      }
      #virtual-scroller {
         background: #eee;
         position: absolute;
         right: -24px;
         top: 0;
         bottom: 0;
         margin: auto;
         width: 6px;
         border-radius: 24rem;
         span {
            width: 8px;
            height: $elHeight;
            display: block;
            background: #b2b2b2;
            position: absolute;
            border-radius: 24rem;
            left: -8px;
            transform-origin: center;
         }
      }
   }

   #flight-baggage-ancillary-total-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 0 1.5rem;
      padding-left: 0;
      // margin-top: 1rem;

      #flight-pax-scroller-wrapper {
      }

      #flight-baggage-ancillary-total-inner-wrapper {
         display: flex;
         flex-direction: column;
         align-items: flex-end;

         .flight-baggage-ancillary-total-row {
            display: flex;
            align-items: center;
            // display: grid;
            // grid-template-columns: 1fr 1fr;

            .flight-baggage-ancillary-total-col {
               ::v-deep {
                  input {
                     border-radius: 8px;
                     border: 1px solid #ccc;
                     max-width: 80px;
                     height: 32px !important;
                     text-align: right;
                  }
               }

               &:last-child {
                  min-width: 150px;
                  display: flex;
                  justify-content: flex-end;
               }
            }
         }
      }
   }

   &.waiting-for-response {
      ul {
         li.baggage-ancillary-pax {
            &:before {
               content: "";
               position: absolute;
               inset: 0 0 0 0;
               background-color: #fff;
               width: 100%;
               z-index: 101;
               opacity: 0.5;
               border-radius: inherit;
               animation-timing-function: ease-in-out;
               animation-name: loading;
               animation-duration: 0.5s;
               animation-iteration-count: infinite;
            }
         }
      }
   }
}
</style>
