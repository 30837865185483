<template>
   <section class="order-detail-payment-wrapper" :class="!headless ? 'gts-box' : 'headless'" v-if="Visible">
      <div class="order-detail-payment-cta" v-if="!headless">
         <!-- w-100 d-flex justify-content-center mb-2 -->
         <template v-if="showLoader">
            <span v-if="!Reversable && entityType == 'flight'">
               <lottie-animation :speed="0.75" path="assets/animations/calculating.json" id="gts-loader" />
            </span>
         </template>
         <!-- {{ entityType }} -->
         <template v-if="paymentChoiceDetail?.enabled">
            <div class="reverse-btn-wrapper" v-if="reversable.withPenalty && reversable.isReversable">
               <i class="gts-icon icon-order-reverse icon-lg"></i>
               <div class="reverse-btn-information-txt-wrapper">
                  <template v-if="calculate.status">
                     <span class="txt-black txt-large"> {{ $t("flightOrderDetail_lblCalculatingPenalty") }} </span>
                     <span class="txt-normal txt-small">{{ $t("flightOrderDetail_lblCalculatingPenaltyTxt") }}</span>
                  </template>
                  <template v-else-if="calculate.isCalculated">
                     <span class="txt-black txt-large"> {{ $t("flightOrderDetail_lblRefundWithPenalty") }} </span>
                     <span class="txt-normal txt-small"> {{ $t("common_total_refund") }}: {{ reversable.refund }} {{ reversable.currency }} </span>
                     <span class="txt-normal txt-small"> {{ $t("common_total_penalty") }}: {{ reversable.penalty }} {{ reversable.currency }} </span>
                  </template>
                  <template v-else>
                     <span class="txt-black txt-large"> {{ $t("flightOrderDetail_lblCancellationWithPenalty") }} </span>
                     <span class="txt-normal txt-small">{{ $t("flightOrderDetail_lblCancellationWithPenaltyTxt") }}</span>
                  </template>
               </div>
               <div class="reverse-btn-action">
                  <template v-if="calculate.status">
                     <lottie-animation :speed="0.75" path="assets/animations/calculating.json" id="reverse-animation" />
                  </template>
                  <template v-else-if="calculate.isCalculated">
                     <button @click="funcReverse">
                        {{ SetReverseButtonText }}
                     </button>
                  </template>
                  <template v-else>
                     <button @click="handlerCalculateReverse">{{ $t("flightOrderDetail_btnCalculatePenalty") }}</button>
                  </template>
               </div>
            </div>
            <!-- FLIGHT reverseButton -->
            <template v-if="!reversable.withPenalty && reversable.isReversable && entityType == 'flight'">
               <span>
                  <UIButton :className="reversable.withPenalty ? 'purple' : 'warning'" :text="SetReverseButtonText" @click.native="funcReverse" />
               </span>
            </template>
            <!-- HOTEL and TRANSFER reverseButton  -->
            <template v-if="Reversable && entityType != 'flight'">
               <UIButton :className="reversable.withPenalty ? 'purple' : 'warning'" :text="SetReverseButtonText" @click.native="funcReverse" />
            </template>
         </template>
      </div>

      <div class="order-detail-payment-inner" v-if="Enabled">
         <span v-if="PaymentChoices.length && !noTitle" class="section-title">{{ $t("paymentComponent_paymentInformations") }}</span>
         <div>
            <PaymentChoices
               ref="paymentChoices"
               :paymentChoices="PaymentChoices"
               :isBalanceUpdate="entityType != 'finance' ? false : true"
               :objKey="ObjKey"
               :warning="Warning"
               @updateAmount="handlerUpdateAmount"
               @updateNote="handlerUpdateNote"
               @click="handleClick"
               :is-headless="headless"
               :hidePrevBtn="hidePrevBtn"
               :partialPaymentEnabled="partialPaymentEnabled"
               :partialPaymentModel="partialPaymentModel"
               :isChecked="internalIsChecked"
               @toggleChange="sendToggleChange" />
         </div>
      </div>

      <div class="order-detail-payment-inner" v-if="!Enabled && Note">
         <div class="w-100 d-flex justify-content-center">
            <UIAlert status="info_orange">
               {{ Note }}
            </UIAlert>
         </div>
      </div>
      <span id="paymentPostContentWrapper" v-html="PaymentPostContent"></span>
   </section>
</template>

<script>
import Popup from "@/gts-popup.js";
import { generateUuid } from "@/utils.js";
import { funcReverseFlight, funcReverseHotel, funcReverseTransfer, funcReverseInquiryFlight, funcReverseRentACar, funcReverseInquiryRentacar } from "./paymentReverseServices.js";
import {
   funcTicketDirectFlight,
   funcTicketBaggages,
   funcTicketReissue,
   funcTicketDirectTransfer,
   funcTicketDirectHotel,
   funcTicketDirectRentACar,
   funcBalanceUpdate,
   funcTicketSeats,
} from "./paymentDirectTicketServices.js";
import { funcTicketReservedFlight, funcTicketReservedHotel, funcTicketReservedTransfer } from "./paymentReservedTicketServices.js";
import UIAlert from "@/components/unitary/Alert.vue";
import UIButton from "@/components/unitary/Button.vue";
import PaymentChoices from "@/components/structural/payment/PaymentChoices.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
   name: "PaymentPurchaseWidget",
   components: {
      UIAlert,
      UIButton,
      PaymentChoices,
      LottieAnimation,
   },
   props: {
      paymentChoiceDetail: Object,
      checkoutRequest: Object,
      funcValidateForm: Function,
      entityType: String,
      entityId: Number,
      noTitle: Boolean,
      headless: Boolean,
      hidePrevBtn: Boolean,
      partialPaymentEnabled: Boolean,
      partialPaymentModel: Object,
      isChecked: Boolean,
   },
   data() {
      return {
         calculate: {
            status: false,
            isCalculated: false,
         },
         paymentEntry: Object,
         paymentPostContent: String,
         paymentUuid: String,
         callback3D: Boolean,
         reversable: Object,
         inquiryUuid: String,
         reverButtonText: String,
         internalIsChecked: this.isChecked,
         showLoader: Boolean,
         showWidget: true,
         amount: 0,
         note: "",
      };
   },
   created() {
      this.paymentEntry = {};
      this.paymentPostContent = "";
      this.paymentUuid = "";
      this.callback3D = false;
      this.showLoader = false;
      this.reversable = {
         withPenalty: false,
         isReversable: false,
      };

      if (this.entityId > 0) this.funcReverseInquiry();
   },

   computed: {
      PaymentPostContent() {
         return this.paymentPostContent.startsWith("http") ? "" : this.paymentPostContent;
      },
      ObjKey() {
         return `${this.entityType}_${this.entityId}`;
      },
      Visible() {
         return (
            this.paymentChoiceDetail == null || this.paymentChoiceDetail?.enabled || this.paymentChoiceDetail.note
            //this.paymentChoiceDetail.note kontrol ediliyor. Length'inin kontrol edilmesine gerek olmadığını düşünüyorum.
            //
            // (this.paymentChoiceDetail.note.length > 0 && this.paymentChoiceDetail.note)
         );
      },
      Enabled() {
         return this.paymentChoiceDetail != null ? this.paymentChoiceDetail?.enabled : false;
      },
      Warning() {
         if (this.paymentChoiceDetail?.warning) {
            return this.$i18n.t(`${this.$options.name}_${this.paymentChoiceDetail?.warning}`);
         }
         return null;
      },
      Note() {
         if (this.paymentChoiceDetail == null) return this.$i18n.t(`${this.$options.name}_LOADING`);
         if (this.paymentChoiceDetail.note != null) return this.$i18n.t(`${this.$options.name}_${this.paymentChoiceDetail.note}`);
         return "";
      },

      Reversable() {
         if (this.entityType == "flight" || this.entityType == "rentacar") return this.reversable.isReversable;
         else if (this.entityType == "hotel") return this.paymentChoiceDetail != null ? this.paymentChoiceDetail.reversable : false;
         else if (this.entityType == "transfer") return this.paymentChoiceDetail != null ? this.paymentChoiceDetail.reversable : false;
         return false;
      },
      SetReverseButtonText() {
         if (this.reversable.withPenalty) {
            return this.$i18n.t(`${this.$options.name}_btnReverseWithPenalty`);
         } else {
            return this.$i18n.t(`${this.$options.name}_btnReverse`);
         }
      },
      PaymentChoices() {
         return this.paymentChoiceDetail != null ? this.paymentChoiceDetail.paymentChoices : [];
      },
   },
   methods: {
      sendToggleChange(isChecked) {
         this.$emit("toggleChange", isChecked);
         this.internalIsChecked = isChecked;
      },
      handlerUpdateAmount(amount) {
         this.amount = amount;
      },
      handlerUpdateNote(note) {
         this.note = note;
      },
      handlerCalculateReverse() {
         const self = this;
         self.calculate.status = true;

         const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;

         setTimeout(() => {
            self.calculate.status = false;
            self.calculate.isCalculated = true;
         }, random(3, 5) * 1000);
      },
      funcReverseInquiry() {
         const self = this;
         if (self.entityType == "flight") funcReverseInquiryFlight(self);
         if (self.entityType == "rentacar") funcReverseInquiryRentacar(self);
         // else if (self.entityType == "transfer") self.funcReverseInquiryTransfer();
      },
      funcReverse() {
         const self = this;
         self.paymentEntry.trxTypeLabel = "REVERSE";
         let title = self.$i18n.t(`${self.$options.name}_popup_title`);
         let subTitle;

         if (self.entityType == "flight" && self.reversable.withPenalty)
            subTitle = self.$i18n
               .t(`${self.$options.name}_popup_${self.paymentEntry.trxTypeLabel}_${self.entityType.toUpperCase()}_W_PENALTY`)
               .replace("#VALUE1#", self.reversable.refund.toString())
               .replace("#VALUE2#", self.reversable.currency);
         else subTitle = self.$i18n.t(`${self.$options.name}_popup_${self.paymentEntry.trxTypeLabel}_${self.entityType.toUpperCase()}`);

         Popup.confirm(title, subTitle, null, "customCancel").then((result) => {
            if (result.success) {
               if (self.entityType == "flight") funcReverseFlight(self, self.entityId);
               else if (self.entityType == "hotel") funcReverseHotel(self);
               else if (self.entityType == "transfer") funcReverseTransfer(self);
               else if (self.entityType == "rentacar") funcReverseRentACar(self);
            }
         });
      },
      funcTicketDirect(trxType) {
         const self = this;
         if (self.entityType == "hotel") funcTicketDirectHotel(self, self.prepareHotelRequest("direct", trxType));
         if (self.entityType == "flight") funcTicketDirectFlight(self, self.prepareFlightRequest("direct", trxType));
         else if (self.entityType == "transfer") funcTicketDirectTransfer(self, self.prepareTransferRequest("direct"));
         else if (self.entityType == "rentacar") funcTicketDirectRentACar(self, self.prepareRentACarRequest("direct"));
         else if (self.entityType == "flight_baggage") funcTicketBaggages(self, self.prepareFlightRequest("ancillary"));
         else if (self.entityType == "flight_reissue") funcTicketReissue(self, self.prepareFlightRequest("reissue"));
         else if (self.entityType == "ticket_seat") funcTicketSeats(self, self.prepeareTicketRequest());
         else if (self.entityType == "finance") funcBalanceUpdate(self, self.prepareFinanceRequest());
      },
      funcTicketReserved() {
         const self = this;
         if (self.entityType == "flight") funcTicketReservedFlight(self, self.prepareFlightRequest("reserve"));
         else if (self.entityType == "hotel") funcTicketReservedHotel(self, self.prepareHotelRequest("reserve"));
         else if (self.entityType == "transfer") funcTicketReservedTransfer(self, self.prepareTransferRequest("reserve"));
      },
      /*@params popupEl(PopupElement): Blank popup element that passes from service functions for 3DPayment */

      funcPost3DRequest(popupEl) {
         // Add backdrop to the payment window
         document.querySelector("#paymentPostContentWrapper").classList.add("payment-window-backdrop");
         const windowPopupElement = popupEl;

         // Set focus to the popup window
         windowPopupElement.focus();
         this.callback3D = false;

         // Check if the popup window is closed at regular intervals
         const checkPopupClosed = setInterval(() => {
            if (windowPopupElement.closed) {
               clearInterval(checkPopupClosed); // Stop checking
               document.querySelector("#paymentPostContentWrapper").classList.remove("payment-window-backdrop");
               this.callback3D = true;
            }
         }, 250);

         // Close the popup window after 5 minutes in all irregular cases
         setTimeout(() => {
            windowPopupElement.close();
         }, 300000);

         // Ensure the popup window doesn't lose focus
         document.body.addEventListener("click", () => {
            windowPopupElement.focus();
         });

         // Handle form submission
         const element = document.getElementsByName("peng3DPostForm")[0];

         if (element) {
            element.addEventListener("submit", () => {
               windowPopupElement.focus();
            });
            element.querySelector("#formTrigger").click();
         }
      },

      prepareTransferRequest(type) {
         let request = this.checkoutRequest;
         if (type == "direct") {
            request.uuid = generateUuid();
            request.trxType = this.callback3D ? 6 /*INQUIRY*/ : this.paymentEntry.trxType;
            request.payment = {
               paymentUuid: this.callback3D ? this.paymentUuid : null,
               paymentType: this.paymentEntry.paymentType,
               vposId: this.paymentEntry.vposId,
               creditCard: {
                  holder: this.paymentEntry.creditcard.holder,
                  pan: this.paymentEntry.creditcard.pan,
                  cvv: this.paymentEntry.creditcard.cvv,
                  year: this.paymentEntry.creditcard.year,
                  month: this.paymentEntry.creditcard.month,
               },
            };
            return request;
         } else {
            return {
               uuid: generateUuid(),
               orderUuid: this.$store.state.order.orderUuid,
               tnrId: this.entityId,
               paymentType: this.paymentEntry.paymentType,
               payment: {
                  paymentUuid: this.callback3D ? this.paymentUuid : null,
                  paymentType: this.paymentEntry.paymentType,
                  vposId: this.paymentEntry.vposId,
                  creditCard: {
                     holder: this.paymentEntry.creditcard.holder,
                     pan: this.paymentEntry.creditcard.pan,
                     cvv: this.paymentEntry.creditcard.cvv,
                     year: this.paymentEntry.creditcard.year,
                     month: this.paymentEntry.creditcard.month,
                  },
               },
            };
         }
      },
      prepareRentACarRequest(type) {
         // console.log("prepareRentACarRequest", type);
         let request = this.checkoutRequest;
         if (type == "direct") {
            request.uuid = generateUuid();
            request.trxType = this.callback3D ? 6 /*INQUIRY*/ : this.paymentEntry.trxType;
            request.paymentInfo = {
               paymentUuid: this.callback3D ? this.paymentUuid : null,
               paymentType: this.paymentEntry.paymentType,
               vposId: this.paymentEntry.vposId,
               creditCard: {
                  holder: this.paymentEntry.creditcard.holder,
                  pan: this.paymentEntry.creditcard.pan,
                  cvv: this.paymentEntry.creditcard.cvv,
                  year: this.paymentEntry.creditcard.year,
                  month: this.paymentEntry.creditcard.month,
               },
            };
            return request;
         }
      },
      prepeareTicketRequest() {
         return {
            uuid: generateUuid(),
            searchUuid: this.$store.state.seatmap.selectSeatQ.searchUuid,
            paymentUuid: this.callback3D ? this.paymentUuid : null,
            paymentType: this.paymentEntry.paymentType,
            vposId: this.paymentEntry.vposId,
            segmentPaxesSeats: this.$store.state.seatmap.selectSeatQ.segmentsPaxesSeats,
            flightsSegmentsPaxesSeats: this.$store.state.seatmap.selectSeatQ.segmentsPaxesSeats,
            agencyCommissionExtra: this.$parent.agencyExtraCommission,
            creditcard: {
               holder: this.paymentEntry.creditcard?.holder,
               pan: this.paymentEntry.creditcard?.pan,
               cvv: this.paymentEntry.creditcard?.cvv,
               year: this.paymentEntry.creditcard?.year,
               month: this.paymentEntry.creditcard?.month,
            },
         };
      },
      prepareFlightRequest(type, trxType) {
         if (type == "ancillary") {
            return {
               uuid: generateUuid(),
               searchUuid: this.$store.state.order.searchUuid,
               paymentUuid: this.callback3D ? this.paymentUuid : null,
               paymentType: this.paymentEntry.paymentType,
               vposId: this.paymentEntry.vposId,
               baggages: this.$parent.baggageRequestModel,
               agencyCommissionExtra: this.$parent.agencyExtraCommission,
               creditcard: {
                  holder: this.paymentEntry.creditcard?.holder,
                  pan: this.paymentEntry.creditcard?.pan,
                  cvv: this.paymentEntry.creditcard?.cvv,
                  year: this.paymentEntry.creditcard?.year,
                  month: this.paymentEntry.creditcard?.month,
               },
            };
         } else if (type == "direct") {
            let request = this.checkoutRequest;
            request.trxType = this.callback3D ? 6 /*INQUIRY*/ : this.paymentEntry.trxType;
            request.payment = {
               paymentUuid: this.callback3D ? this.paymentUuid : null,
               paymentType: this.paymentEntry.paymentType,
               vposId: this.paymentEntry.vposId,
               creditcard:
                  trxType == 2 || trxType == 4 || trxType == 5
                     ? null
                     : {
                          holder: this.paymentEntry.creditcard.holder,
                          pan: this.paymentEntry.creditcard.pan,
                          cvv: this.paymentEntry.creditcard.cvv,
                          year: this.paymentEntry.creditcard.year,
                          month: this.paymentEntry.creditcard.month,
                       },
            };
            return request;
         } else if (type == "reserve") {
            return {
               uuid: generateUuid(),
               orderUuid: this.$store.state.order.orderUuid,
               paymentUuid: this.callback3D ? this.paymentUuid : null,
               pnrId: this.entityId,
               paymentType: this.paymentEntry.paymentType,
               vposId: this.paymentEntry.vposId,
               creditcard: {
                  holder: this.paymentEntry.creditcard.holder,
                  pan: this.paymentEntry.creditcard.pan,
                  cvv: this.paymentEntry.creditcard.cvv,
                  year: this.paymentEntry.creditcard.year,
                  month: this.paymentEntry.creditcard.month,
               },
               callbackUrl: "NOT_USED",
               confirmedPriceDiff: 0,
            };
         } else if (type == "reissue") {
            return {
               paymentUuid: this.callback3D ? this.paymentUuid : null,
               uuid: generateUuid(),
               searchUuid: this.$parent?.reissue.searchUuid,
               paymentType: this.paymentEntry.paymentType,
               creditcard: {
                  holder: this.paymentEntry.creditcard?.holder,
                  pan: this.paymentEntry.creditcard?.pan,
                  cvv: this.paymentEntry.creditcard?.cvv,
                  year: this.paymentEntry.creditcard?.year,
                  month: this.paymentEntry.creditcard?.month,
               },
               vposId: this.paymentEntry.vposId,
            };
         }
      },
      prepareHotelRequest(type, trxType) {
         if (type == "direct") {
            let request = this.checkoutRequest ?? {};
            request.uuid = generateUuid();
            request.trxType = this.callback3D ? 6 /*INQUIRY*/ : this.paymentEntry.trxType;
            request.payment = {
               paymentUuid: this.callback3D ? this.paymentUuid : null,
               paymentType: this.paymentEntry.paymentType,
               vposId: this.paymentEntry.vposId,
               creditcard:
                  trxType == 2
                     ? null
                     : {
                          holder: this.paymentEntry.creditcard.holder,
                          pan: this.paymentEntry.creditcard.pan,
                          cvv: this.paymentEntry.creditcard.cvv,
                          year: this.paymentEntry.creditcard.year,
                          month: this.paymentEntry.creditcard.month,
                       },
            };
            return request;
         } else {
            return {
               uuid: generateUuid(),
               orderUuid: this.$store.state.order.orderUuid,
               voucherId: this.entityId,
               paymentType: this.paymentEntry.paymentType,
               payment: {
                  paymentUuid: this.callback3D ? this.paymentUuid : null,
                  paymentType: this.paymentEntry.paymentType,
                  vposId: this.paymentEntry.vposId,
                  creditCard: {
                     holder: this.paymentEntry.creditcard.holder,
                     pan: this.paymentEntry.creditcard.pan,
                     cvv: this.paymentEntry.creditcard.cvv,
                     year: this.paymentEntry.creditcard.year,
                     month: this.paymentEntry.creditcard.month,
                  },
               },
            };
         }
      },
      prepareFinanceRequest() {
         return {
            trxType: this.callback3D ? 3 : 2,
            amount: this.amount > 0 ? this.amount.toString() : "0",
            note: this.note.length ? this.note.toUpperCase() : "",
            paymentUuid: this.callback3D ? this.paymentUuid : null,
            paymentType: this.paymentEntry.paymentType,
            vposId: this.paymentEntry.vposId,
            currency: "EUR",
            creditcard: {
               holder: this.paymentEntry.creditcard?.holder,
               pan: this.paymentEntry.creditcard?.pan,
               cvv: this.paymentEntry.creditcard?.cvv,
               year: this.paymentEntry.creditcard?.year,
               month: this.paymentEntry.creditcard?.month,
            },
         };
      },
      handleClick(data) {
         const self = this;

         self.paymentEntry = data;

         if (self.funcValidateForm && !self.funcValidateForm()) return;

         if (self.entityId > 0) {
            let title = self.$i18n.t(`${self.$options.name}_popup_title`);
            let subTitle = self.$i18n.t(`${self.$options.name}_popup_${self.paymentEntry.trxTypeLabel}_${self.entityType.toUpperCase()}`);
            Popup.confirm(title, subTitle, null).then((result) => {
               if (result.success) {
                  self.funcTicketReserved();
               }
            });
         } else self.funcTicketDirect(data.trxType);
      },
   },
   watch: {
      showLoader: {
         handler: function (status) {
            if (!status) {
               if (this.reversable.withPenalty || this.reversable.isReversable || (this.paymentChoices && this.paymentChoices.length > 0)) {
                  this.$emit("show", true);
               } else {
                  this.$emit("show", false);
               }
            }
         },
      },
      entityId: function () {
         this.funcReverseInquiry();
      },
      callback3D: function (newVal, oldVal) {
         if (newVal && !oldVal) {
            if (this.entityId > 0) this.funcTicketReserved();
            else this.funcTicketDirect();
         }
      },
   },
};
</script>

<style lang="scss" scoped>
#reverse-animation {
   transform: scale(0.5);
}
#gts-loader {
   transform: scale(0.5);
   filter: hue-rotate(305deg) brightness(0.9);
}
.reverse-btn-wrapper {
   border: 1px solid #eaa2f8;
   background-color: #fff6ff;
   border-radius: 24rem;
   padding: 1rem 1.75rem;
   display: flex;
   align-items: center;
   justify-content: space-between;

   .reverse-btn-information-txt-wrapper {
      display: flex;
      flex-direction: column;
      // line-height: 1.5rem;
      padding-left: 1rem;
      span {
         width: 100%;

         &:last-child {
            line-height: 1.25rem;
            color: #4b4b4b;
         }
      }
   }
   .reverse-btn-action {
      margin-left: 1.5rem;

      button {
         background-color: #c35ce6;
         color: #fff;
         border-radius: 24rem;
         font-weight: 700;
         padding: 0.5rem 1.5rem;
         height: 48px;

         &:hover {
            background-color: #a938d0;
         }
      }
   }
}
.order-detail-payment-wrapper {
   padding-top: 2rem;

   .order-detail-payment-cta {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 5rem;
   }
   .order-detail-payment-inner {
      background: #fff;
      border-radius: 0.5rem;
      /* .reverse-btn-wrapper {
         .reverse-btn-text-wrapper {
         }
      } */
   }
}
</style>

<style lang="scss">
.headless {
   padding-top: 0 !important;
   width: fit-content;
   display: flex;

   .payment-inner-wrapper {
      // width: 700px !important;
      background: #ccc;
      overflow: hidden;
   }

   .paymentChoice-actions-container {
      justify-content: flex-end;
   }

   .paymentChoice-information-boxes {
      @media (min-width: 1200px) {
         width: 750px;
      }
   }
   #paymentChoice-type-container {
      @media (min-width: 1200px) {
         width: auto;
      }
      .paymentChoice-information-boxes {
         // width: fit-content !important;
         @media (min-width: 1200px) {
            width: auto;
         }
      }
   }
   .paymentChoice-information-row {
      display: flex !important;
      justify-content: space-between;
      width: 100%;
      max-width: 100% !important;

      .paymentChoice-information-accent {
         width: fit-content !important;
         min-width: fit-content !important;
      }
   }
}
</style>
