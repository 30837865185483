<template>
   <div id="payment-choices-tab-wrapper">
      <ul id="payment-methods-wrapper">
         <template v-for="(paymentChoice, index) in paymentChoices">
            <li :key="index" @click="changePaymentType(paymentChoice.paymentType)">
               <div class="payment-method" :class="paymentChoice.paymentType == paymentType ? 'active-payment-method' : ''">
                  <template v-if="paymentChoice.paymentType == 2 || paymentChoice.paymentType == 1">
                     <img :src="require(`@/assets/images/${label(paymentChoice.paymentType)}_${$i18n.locale}.svg`)" />
                  </template>
                  <template v-else>
                     <img :src="require(`@/assets/images/${label(paymentChoice.paymentType)}.svg`)" />
                  </template>
               </div>
            </li>
         </template>
      </ul>

      <ul id="payment-tabs-wrapper">
         <template v-for="(paymentChoice, index) in paymentChoices">
            <li v-if="paymentChoice.paymentType == paymentType" :key="index">
               <PaymentChoiceItem
                  :isBalanceUpdate="isBalanceUpdate"
                  :paymentChoice="paymentChoice"
                  @updateAmount="handlerUpdateAmount"
                  @updateNote="handlerUpdateNote"
                  @click="handleClick"
                  ref="paymentChoiceItem"
                  :partialPaymentEnabled="partialPaymentEnabled"
                  :partialPaymentModel="partialPaymentModel"
                  :isChecked="internalIsChecked"
                  @toggleChange="sendToggleChange" />
            </li>
         </template>
      </ul>
   </div>
</template>

<script>
import PaymentChoiceItem from "@/components/structural/payment/PaymentChoiceItem.vue";

export default {
   name: "PaymentChoices",
   components: {
      PaymentChoiceItem,
   },
   props: {
      objKey: String,
      paymentChoices: Array,
      isBalanceUpdate: Boolean,
      isHeadless: Boolean,
      hidePrevBtn: Boolean,
      warning: [Object, String],
      partialPaymentEnabled: Boolean,
      partialPaymentModel: Object,
      isChecked: Boolean,
   },
   data() {
      return {
         paymentType: Number,
         loadingWithSpinner: false,
         internalIsChecked: this.isChecked,
      };
   },
   created() {
      this.paymentType = this.paymentChoices[0]?.paymentType;
      // console.log("#########################");
      // console.log("##### Payment Initialized");
   },

   methods: {
      sendToggleChange(isChecked) {
         this.$emit("toggleChange", isChecked);
         this.internalIsChecked = isChecked;
      },
      label(type) {
         switch (type) {
            case undefined:
               return "cari";
            case 1:
               return "cari";
            case 2:
               return "creditcard";
            case 3:
               return "creditcard";
            case 4:
               return "sofort";
            case 5:
               return "ideal";
         }
         return "";
      },
      changePaymentType(newPaymentType) {
         const self = this;
         self.paymentType = newPaymentType;
      },
      validate() {
         let result = null;
         if (this.paymentType == 2) result = this.$refs.cc[0].validate();
         return result;
      },
      handleClick(data) {
         this.$emit("click", data);
      },
      handlerUpdateAmount(data) {
         this.$emit("updateAmount", data);
      },
      handlerUpdateNote(data) {
         this.$emit("updateNote", data);
      },
   },
};
</script>

<style lang="scss" scoped>
#payment-choices-tab-wrapper {
   padding: 0;
   width: 100%;
   @media (min-width: 768px) {
      padding: 1rem;
      width: 736px;
   }

   ul#payment-methods-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      @media (min-width: 768px) {
         justify-content: flex-start;
      }

      li {
         display: inline-block;
         margin: 0 0.125rem;
         width: calc(152px + 16px);
         height: calc(92px + 16px);

         .payment-method {
            width: 152px;
            height: 92px;
            box-sizing: content-box;
            margin: 0 !important;
            padding: 0 !important;
            cursor: pointer;

            img {
               border: 8px solid transparent;
               border-radius: 24px;
            }

            &.active-payment-method {
               img {
                  border: 8px solid #74fbe5;
               }
            }
         }
      }
   }

   ul#payment-tabs-wrapper {
      margin-top: 2rem;
   }
}
</style>

<style lang="scss" scoped>
.payment-choices-tab-wrapper3 {
   // background: red;
   width: 100%;
   @media (min-width: 576px) {
      flex-direction: row;
   }
   .payment-choices-tab-inner {
      .payment-choices-tabs {
         margin-bottom: 24px;
         border: none;
         display: flex;
         flex-direction: row;

         @media (min-width: 576px) {
            flex-direction: row;
         }
         .payment-choices-tab-list-item {
            // width: 152px;
            // height: 95px;

            @media (min-width: 576px) {
               // width: 152px;
               // height: 95px;
            }
            @media (min-width: 992px) {
               margin-right: 0.5rem;
            }

            &:last-child {
               margin-right: 0;
            }
            & > a.nav-link {
               // width: 100%;
               // height: 100%;
               // padding: 0;
               // margin: 0;
               // outline: 5px solid transparent;
               // border-radius: 15px;
               display: flex;
               margin: 0 !important;
               padding: 0 !important;
               border: 0 !important;

               &.active {
                  // border-color: #33ff6a;
                  // border-color: #74fbe5;
                  // box-shadow: 0 2px 4px rgba(51, 255, 106, 0.5);

                  & > img {
                     border: 8px solid #74fbe5;
                  }
               }
               & > img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border: 8px solid transparent;
                  border-radius: 24px;
               }

               &#CARI-tab {
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                  background-color: #417ee0;
                  font-size: 20px;
                  text-transform: uppercase;
                  color: white;
               }
            }
         }
      }
      .payment-choices-tab-content {
         width: 100%;

         @media (min-width: 576px) {
            // width: 50%;
         }
         .payment-choices-tab-content-item {
            .payment-choices-tab-content-item-inner {
               width: 100%;
               ::v-deep .credit-card-wrapper,
               ::v-deep .balance-update-form-wrapper {
                  width: 100%;
               }

               ::v-deep .gts-alert {
                  margin: 24px 0;
               }
            }
         }
      }
   }
}
</style>
