import { PUBLIC_HTTP, PRIVATE_HTTP } from "./index";
import router from "@/router/index.js";
import Popup from "@/gts-popup.js";

const GLOBAL_STARTUP_ENPOINT = "api/terminal/startup";
const GLOBAL_VERSION_ENPOINT = "/api/terminal/version";

//TODO:fmtlist de buraya alınmalı
const GLOBAL_COUNTRYLIST_ENDPOINT = "api/global/countrylist";
const GLOBAL_AIRLINELIST_ENDPOINT = "api/global/airlinelist";
const GLOBAL_FACILITYLIST_ENDPOINT = "api/global/facilitylist";
const GLOBAL_BOARDTYPELIST_ENDPOINT = "api/global/boardtypelist";

//TODO:user.api altına taşınmalı
const GLOBAL_WORKINGPARAMS_ENDPOINT = "api/user/setworkingparams";

//TODO:notification.api altına taşınabilirler. unread adedi peryodik okunmalı.
const NOTIFICATION_LIST = "api/notification/list";
const NOTIFICATION_REGISTER = "api/notification/register";
const NOTIFICATION_FEEDBACK = "api/notification/feedback";
const NOTIFICATION_UNREAD = "api/notification/unRead";

//TODO:user.api altına taşınmalı
const DIPSLAY_AGENCY_INCENTIVE = "api/agency/getagencykpi";

// AUTH_HTTP;
export var versionService = (requestModel) => {
   const promise = PUBLIC_HTTP.post(GLOBAL_VERSION_ENPOINT, requestModel);
   const responseData = promise.then((response) => {
      if (response.data.result.success) {
         return response.data;
      }
   });
   return responseData;
};

export var startupService = (isPublic, requestModel) => {
   const promise = isPublic ? PUBLIC_HTTP.post(GLOBAL_STARTUP_ENPOINT, requestModel) : PRIVATE_HTTP().post(GLOBAL_STARTUP_ENPOINT, requestModel);

   const responseData = promise.then((response) => {
      if (response.data.result.success) {
         // if (!isPublic) {
         //    response.data.info = {
         //       originalGsa: {
         //          id: 2,
         //          name: "MeeFly Gsa",
         //          title: "MEFLY GBR",
         //          address: "Tübinger Straße 20¶Stutgart",
         //          email: "info@mefly.de",
         //          phone: {
         //             iso: "DE",
         //             country: "+49",
         //             city: "711",
         //             number: "34033090",
         //          },
         //          callcenter: {
         //             iso: "DE",
         //             country: "+49",
         //             city: "711",
         //             number: "34033090",
         //          },
         //          manager: "Dipl. Ing. Erhan Göral , Dipl. Wirt. Fahrettin Yilmaz , Betr. Wirt. ( VWA ) Murat Babatas",
         //          domainName: "test-agent.mefly.de",
         //          cssCode: "mefly",
         //       },
         //       onBehalfOfGsa: {
         //          id: 2,
         //          name: "MeeFly Gsa",
         //          title: "MEFLY GBR",
         //          address: "Tübinger Straße 20¶Stutgart",
         //          email: "info@mefly.de",
         //          phone: {
         //             iso: "DE",
         //             country: "+49",
         //             city: "711",
         //             number: "34033090",
         //          },
         //          callcenter: {
         //             iso: "DE",
         //             country: "+49",
         //             city: "711",
         //             number: "34033090",
         //          },
         //          manager: "Dipl. Ing. Erhan Göral , Dipl. Wirt. Fahrettin Yilmaz , Betr. Wirt. ( VWA ) Murat Babatas",
         //          domainName: "test-agent.mefly.de",
         //          cssCode: "mefly",
         //       },
         //       originalAgency: {
         //          id: -1,
         //          iatiId: 0,
         //          name: null,
         //       },
         //       onBehalfOfAgency: {
         //          id: 2,
         //          iatiId: 2,
         //          name: "MeeFly Acentesi-1",
         //       },
         //       user: {
         //          id: 20,
         //          name: "AYTAÇ",
         //          surname: "GÜÇ",
         //          email: "aytac.guc@gtsfly.de",
         //          phone: {
         //             iso: "TR",
         //             country: "+90",
         //             city: "532",
         //             number: "1112233",
         //          },
         //          language: 1,
         //          timezoneId: 3,
         //          timezoneName: "Europe/Istanbul",
         //          currency: "EUR",
         //       },
         //    };
         // }
         return response.data;
      } else {
         Popup.result(response.data.result);
      }
   });
   return responseData;
};

export var getCountryList = () => {
   const promise = PUBLIC_HTTP.post(GLOBAL_COUNTRYLIST_ENDPOINT, {});
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
         // else
         // {
         //     console.log(error);
         // }
      });

   return responseData;
};

export var getAirlineList = () => {
   const promise = PUBLIC_HTTP.post(GLOBAL_AIRLINELIST_ENDPOINT, {});
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
      });

   return responseData;
};

export var setWorkingParams = (requestModel) => {
   const promise = PRIVATE_HTTP().post(GLOBAL_WORKINGPARAMS_ENDPOINT, requestModel);
   const responseData = promise
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         error;
         // console.log(error);
      });

   return responseData;
};

export var notificationList = (requestModel) => {
   const promise = PRIVATE_HTTP().post(NOTIFICATION_LIST, requestModel);
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
         // else
         // {
         //     console.log(error);
         // }
      });

   return responseData;
};
export var notificationRegister = (requestModel) => {
   const promise = PRIVATE_HTTP().post(NOTIFICATION_REGISTER, requestModel);
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
         // else
         // {
         //     console.log(error);
         // }
      });

   return responseData;
};
export var notificationUnread = (requestModel) => {
   const promise = PRIVATE_HTTP().post(NOTIFICATION_UNREAD, requestModel);
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
         // else
         // {
         //     console.log(error);
         // }
      });

   return responseData;
};
export var notificationFeedback = (requestModel) => {
   const promise = PRIVATE_HTTP().post(NOTIFICATION_FEEDBACK, requestModel);
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
         // else
         // {
         //     console.log(error);
         // }
      });

   return responseData;
};

export var getFacilityList = () => {
   const promise = PUBLIC_HTTP.post(GLOBAL_FACILITYLIST_ENDPOINT, {});
   const responseData = promise
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         error;
         // console.log(error);
      });

   return responseData;
};
export var getBoardTypeList = () => {
   const promise = PUBLIC_HTTP.post(GLOBAL_BOARDTYPELIST_ENDPOINT, {});
   const responseData = promise
      .then((response) => {
         return response.data;
      })
      .catch((error) => {
         error;
         // console.log(error);
      });

   return responseData;
};

export var agencyTarget = () => {
   const promise = PRIVATE_HTTP().post(DIPSLAY_AGENCY_INCENTIVE, {});
   const responseData = promise
      .then((response) => response.data)
      .catch((error) => {
         if (error.response.status === 401) {
            if (router.currentRoute.path != "/login") {
               router.push("/login");
            }
         }
      });

   return responseData;
};
