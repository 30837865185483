<template>
   <div
      class="ancillary-seat-map-passenger-container"
      @click="handlerSelectSeat"
      :class="{ selected: SeatSelected, active: IsPassengerSelectionActive, noPointer: IsPassengerInfant }">
      <div class="passenger-information-container">
         <div class="row">
            <div class="col">
               <span class="info-label">{{ $t("ancillarySeatMap_lblPassenger") }}</span>
               <span
                  >{{ passenger.paxName }} <i>({{ passenger.paxType }} )</i>
               </span>
            </div>
            <div class="col">
               <span class="info-label">{{ $t("ancillarySeatMap_lblPrice") }}</span>

               <span>{{ passenger.price ?? "-" | price }} </span>
            </div>
            <div class="col">
               <span class="info-label">{{ $t("ancillarySeatMap_lblSeat") }}</span>
               <span>{{ passenger.seat }} </span>
            </div>
         </div>
      </div>

      <div class="cancel-seat-btn" :style="{ visibility: SeatSelected && !IsPassengerInfant ? '' : 'hidden' }" @click="handlerCancelSeat">
         <i class="gts-icon icon-cancel-seat-selection"></i>
      </div>
   </div>
</template>

<script>
// import UITooltip from "@/components/utils/GtsToolTips.vue";
export default {
   name: "AncillarySeatMap",
   props: {
      passenger: Object,
   },
   components: {
      // UITooltip,
   },
   computed: {
      IsPassengerSelectionActive() {
         return this.passenger.selected;
      },
      IsPassengerInfant() {
         return this.passenger.paxTypeEnum == 3;
      },
      SeatSelected() {
         return this.passenger.seat != null;
      },
   },
   methods: {
      handlerSelectSeat() {
         if (!this.IsPassengerInfant) {
            this.$store.dispatch("seatmap/updatePaxSelection", this.passenger);
         }
      },

      handlerCancelSeat() {
         this.$store.commit("seatmap/cancelSeatSelection", this.passenger);
      },
   },
};
</script>

<style lang="scss" scoped>
.ancillary-seat-map-passenger-container {
   display: flex;
   // border: 1px solid #ebebeb;
   padding: 0.5rem 0.75rem;
   border-radius: 0.5rem;
   margin: 1rem 0;
   width: fit-content;
   min-width: 350px;
   cursor: pointer;
   // outline-width: 3px;
   outline-color: #eee;
   outline-style: solid;

   &.selected {
      // background-color: var(--accent-primary);
      background-color: #f0faff;
      outline: 1.5px solid #ceedfd;
      color: #3c67b2;

      // color: #fff;
      // outline: 2px solid rgba(51, 184, 255, 0.3882352941);
   }
   &.active {
      // outline: 4px solid #ccc;
      // background-color: #eee;
      // background-color: var(--accent-primary);
      // outline: none;
      // color: #fff;
      // background-color: #ebffed !important;
      // transform: scale(1.05);
      // background-color: #dcf0fa !important;
      // background-color: #f0faff !important;
      outline: 2.5px solid var(--accent-primary);
      color: var(--primary);
      cursor: default !important;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
   }
   &:hover {
      background-color: #ebffed;
      background-color: #eee;
      // outline-color: #ccc;
      // outline-color: var(--accent-primary);
   }

   .passenger-information-container {
      width: 100%;
      // background: red;

      .row {
         margin-bottom: 0.5rem;
         display: grid;
         grid-template-columns: 4fr 2fr 1.5fr 0.5fr;
         grid-gap: 0.25rem;

         &:last-child {
            margin-bottom: 0 !important;
         }
         .col {
            display: flex;
            flex-direction: column;

            span {
               white-space: nowrap;
               font-weight: 500;
               font-size: 1rem;

               &.info-label {
                  font-weight: 400;
                  font-size: 0.85rem;
                  opacity: 0.75;
               }

               i {
                  font-size: var(--xsmall);
               }
            }
         }
      }
   }
   .passenger-cta {
      display: flex;
      align-items: center;
      margin-right: 1rem;

      &.active {
         .passenger-cta-btn {
            &::after {
               background-color: var(--accent-primary);
            }
         }
      }

      .passenger-cta-btn {
         display: block;
         $size: 28px;
         width: $size;
         height: $size;
         background-color: #fff;
         border: 3px solid var(--accent-primary);
         outline: 3px solid #d3f0ff;
         border-radius: 25rem;
         display: flex;
         align-items: center;
         justify-content: center;

         &::after {
            content: "";
            width: 70%;
            height: 70%;
            display: flex;
            border-radius: 50%;
         }
      }
   }
   .cancel-seat-btn {
      display: flex;
      align-items: center;

      // background-color: #3b66ae;
      // height: 48px;
      // padding: 1rem;
      // color: #fff;
      button {
         background-color: #3b66ae;
         // height: 48px;
         padding: 0.65rem 1rem;
         color: #fff;
         border-radius: 0.5rem;
         font-weight: 600;
         font-size: var(--small);
         // background-color: var(--primary);
         // background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMi43MjgiIGhlaWdodD0iMTIuNzI4IiB2aWV3Qm94PSIwIDAgMTIuNzI4IDEyLjcyOCI+CiAgPGcgaWQ9Ikdyb3VwXzEyMDAyIiBkYXRhLW5hbWU9Ikdyb3VwIDEyMDAyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzYuNzcgLTEzMTguNzU0KSByb3RhdGUoNDUpIj4KICAgIDxsaW5lIGlkPSJMaW5lXzExMzAiIGRhdGEtbmFtZT0iTGluZSAxMTMwIiB5Mj0iMTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDk3My41IDkwNi41KSByb3RhdGUoOTApIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIzIi8+CiAgICA8bGluZSBpZD0iTGluZV8xMjY4IiBkYXRhLW5hbWU9IkxpbmUgMTI2OCIgeTI9IjEyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg5NjcuNSA5MDAuNSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2Utd2lkdGg9IjMiLz4KICA8L2c+Cjwvc3ZnPgo=") !important;
         // background-repeat: no-repeat;
         // background-position: center;
         // background-size: cover;
         // width: 32px !important;
         // height: 32px !important;
      }
   }
}
</style>
