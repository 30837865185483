import { PRIVATE_HTTP } from "./index";

const ORDER_SEARCH_FLIGHT = "api/order/searchflightorders";
const ORDER_SEARCH_HOTEL = "api/order/searchhotelorders";
const ORDER_SEARCH_TRANSFER = "api/order/searchtransferorders";
const ORDER_SEARCH_RENTACAR = "api/order/searchrentacarorders";
const ORDER_SEARCH_ENTITIES = "api/order/listentities";
const ORDER_DETAIL = "api/order/detail";

export var searchOrderFlight = (request) => {
   const promise = PRIVATE_HTTP().post(ORDER_SEARCH_FLIGHT, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var searchOrderHotel = (request) => {
   const promise = PRIVATE_HTTP().post(ORDER_SEARCH_HOTEL, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var searchOrderTransfer = (request) => {
   const promise = PRIVATE_HTTP().post(ORDER_SEARCH_TRANSFER, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var searchOrderRentACar = (request) => {
   const promise = PRIVATE_HTTP().post(ORDER_SEARCH_RENTACAR, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var searchOrderEntities = (request) => {
   const promise = PRIVATE_HTTP().post(ORDER_SEARCH_ENTITIES, request);
   return promise.then((response) => {
      return response.data;
   });
};

export var getOrderDetail = (request) => {
   const promise = PRIVATE_HTTP().post(ORDER_DETAIL, request);
   return promise.then((response) => {
      return response.data;
   });
};
