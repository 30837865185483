import { PRIVATE_HTTP } from "./index";
import Popup from "@/gts-popup.js";

const FLIGHT_SALES_REPORT = "api/report/flightsalesreport";
const HOTEL_SALES_REPORT = "api/report/hotelsalesreport";
const TRANSFER_SALES_REPORT = "api/report/transfersalesreportv2";
const RENT_A_CAR_SALES_REPORT = "api/report/rentacarsalesreport";

export var flightSalesReport = (requestModel) => {
   const promise = PRIVATE_HTTP(180000).post(FLIGHT_SALES_REPORT, requestModel);
   const responseData = promise.then((response) => {
      if (response.data.result.success) {
         return response.data;
      } else {
         Popup.result(response.data.result);
      }
   });
   return responseData;
};

export var hotelSalesReport = (requestModel) => {
   const promise = PRIVATE_HTTP().post(HOTEL_SALES_REPORT, requestModel);
   const responseData = promise.then((response) => {
      if (response.data.result.success) {
         return response.data;
      } else {
         Popup.result(response.data.result);
      }
   });
   return responseData;
};

export var transferSalesReport = (requestModel) => {
   const promise = PRIVATE_HTTP().post(TRANSFER_SALES_REPORT, requestModel);
   const responseData = promise.then((response) => {
      if (response.data.result.success) {
         return response.data;
      } else {
         Popup.result(response.data.result);
      }
   });
   return responseData;
};

export var rentACarSalesReport = (requestModel) => {
   const promise = PRIVATE_HTTP().post(RENT_A_CAR_SALES_REPORT, requestModel);
   const responseData = promise.then((response) => {
      if (response.data.result.success) {
         return response.data;
      } else {
         Popup.result(response.data.result);
      }
   });
   return responseData;
};
