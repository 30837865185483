<template>
   <div class="filter-section-container">
      <div class="filter-section-title-wrapper">
         <span class="filter-section-title">{{ $t("hotelFilter_lblInfoTitle") }}</span>
         <span class="filter-section-toggle" @click="handlerClearAll">{{ $t("searchFiltersHotel_deselectAll") }}</span>
      </div>

      <div class="filter-hotel-search-wrapper">
         <input type="text" id="input_searchHotelName" :placeholder="$t('hotelFilter_lblSearchHotel')" v-model="searchHotel" />
         <i class="gts-icon icon-close-rounded icon-xs" @click="handlerClearSearch" v-if="searchHotel.length != 0" />
      </div>
      <div class="filter-item">
         <UICheckBox
            v-model="informations.discounted.checked"
            id="discounted"
            :label="$t('hotelFilter_lblDiscountedHotel')"
            hotel
            @input="handlerCheckClick" />
      </div>
      <div class="filter-item">
         <UICheckBox
            v-model="informations.reccommendeds.checked"
            id="recommended"
            :label="$t('hotelFilter_lblRecommendedHotel')"
            hotel
            @input="handlerCheckClick" />
      </div>
      <div class="filter-item">
         <UICheckBox
            v-model="informations.isPartialPaymentExist.checked"
            id="isPartialPaymentExist"
            :label="$t('hotelFilter_lblPartialPayment')"
            hotel
            @input="handlerCheckClick" />
      </div>
   </div>
</template>

<script>
import UICheckBox from "@/components/unitary/form2/UICheckBox.vue";
export default {
   name: "FilterInformations",
   props: {
      informations: {
         type: Object,
         required: false,
      },
   },
   data() {
      return {
         searchHotel: "",
      };
   },
   components: {
      UICheckBox,
   },
   methods: {
      handlerCheckClick() {
         this.$emit("click", true);
      },
      handlerClearAll() {
         Object.keys(this.informations).forEach((information) => {
            this.$set(this.informations[information], "checked", false);
         });
         this.searchHotel = "";
         this.handlerCheckClick();
      },

      handlerClearSearch() {
         this.searchHotel = "";
      },
   },
   watch: {
      searchHotel: {
         handler: function (txt) {
            const self = this;
            let hotelList = self.$parent.$parent.$parent.hotelList;
            let hotelListStore = self.$store.state.hotel.searchResult;
            let reccommendedHotelList = self.$parent.$parent.$parent.reccommendedList;
            let reccommendedHotelListStore = self.$store.state.hotel.searchResultRecommendedHotel;

            if (txt.length >= 3) {
               self.$parent.$parent.$parent.hotelList = hotelList.filter((row) => {
                  return row.name.toUpperCase().includes(txt.toUpperCase());
               });
               self.$parent.$parent.$parent.reccommendedList = reccommendedHotelList.filter((row) => {
                  return row.name.toUpperCase().includes(txt.toUpperCase());
               });
            }
            if (txt.length == 0) {
               self.$parent.$parent.$parent.hotelList = hotelListStore;
               self.$parent.$parent.$parent.reccommendedList = reccommendedHotelListStore;
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.filter-hotel-search-wrapper {
   position: relative;
   margin-bottom: 0.75rem;
   input {
      border-radius: 4rem;
      outline: none;
      border: 1px solid #ccc;
      padding: 0.35rem 0;
      text-indent: 0.75rem;
      width: 100%;
   }
   i {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 1rem;
      margin: auto;
      cursor: pointer;
   }
}
</style>
