import axios from "axios";
import store from "@/store/index.js";
import ghostLanguage from "@/fmt/languages/ghostLanguage.js";

// const GLOBAL_FMTLIST_ENDPOINT = "/api/global/fmtlist";
const GLOBAL_AIRLINELIST_ENDPOINT = "/api/global/airlinelist";

let getGlobalFmtList = {
   tr: ghostLanguage.tr,
   en: ghostLanguage.en,
   de: ghostLanguage.de,
};

// console.log("ghostLanguage Loaded ########");

// export const globalFmt = () => {
//    axios
//       .post(process.env.VUE_APP_API_URL + GLOBAL_FMTLIST_ENDPOINT, {})
//       .then((response) => {
//          let responseData = response.data;
//          if (response.status == 200) {
//             if (Object.keys(responseData).length != 0 && responseData.result.success && responseData != null) {
//                getGlobalFmtList.tr = responseData.tr;

//                getGlobalFmtList.en = responseData.en;
//                // getGlobalFmtList.en.languageFullName_tr = "Türkçe";
//                // getGlobalFmtList.en.languageFullName_en = "English";
//                // getGlobalFmtList.en.languageFullName_de = "Deutsch";

//                getGlobalFmtList.de = responseData.de;
//                // getGlobalFmtList.de.languageFullName_tr = "Türkçe";
//                // getGlobalFmtList.de.languageFullName_en = "English";
//                // getGlobalFmtList.de.languageFullName_de = "Deutsch";

//                getGlobalFmtList.tr.common_january = "Ocak";
//                getGlobalFmtList.tr.common_february = "Şubat";
//                getGlobalFmtList.tr.common_march = "Mart";
//                getGlobalFmtList.tr.common_april = "Nisan";
//                getGlobalFmtList.tr.common_may = "Mayıs";
//                getGlobalFmtList.tr.common_june = "Haziran";
//                getGlobalFmtList.tr.common_july = "Temmuz";
//                getGlobalFmtList.tr.common_august = "Ağustos";
//                getGlobalFmtList.tr.common_september = "Eylül";
//                getGlobalFmtList.tr.common_october = "Ekim";
//                getGlobalFmtList.tr.common_november = "Kasım";
//                getGlobalFmtList.tr.common_december = "Aralık";
//                getGlobalFmtList.tr.common_jan = "Oca";
//                getGlobalFmtList.tr.common_feb = "Şub";
//                getGlobalFmtList.tr.common_mar = "Mar";
//                getGlobalFmtList.tr.common_apr = "Nis";
//                getGlobalFmtList.tr.common_my = "May";
//                getGlobalFmtList.tr.common_jun = "Haz";
//                getGlobalFmtList.tr.common_jul = "Tem";
//                getGlobalFmtList.tr.common_aug = "Ağu";
//                getGlobalFmtList.tr.common_sep = "Eyl";
//                getGlobalFmtList.tr.common_oct = "Eki";
//                getGlobalFmtList.tr.common_nov = "Kas";
//                getGlobalFmtList.tr.common_dec = "Ara";
//                getGlobalFmtList.tr.common_monday = "Pazartesi";
//                getGlobalFmtList.tr.common_tuesday = "Salı";
//                getGlobalFmtList.tr.common_wednesday = "Çarşamba";
//                getGlobalFmtList.tr.common_thursday = "Perşembe";
//                getGlobalFmtList.tr.common_friday = "Cuma";
//                getGlobalFmtList.tr.common_saturday = "Cumartesi";
//                getGlobalFmtList.tr.common_sunday = "Pazar";

//                getGlobalFmtList.tr.common_mon = "Pzt";
//                getGlobalFmtList.tr.common_tue = "Sal";
//                getGlobalFmtList.tr.common_wed = "Çar";
//                getGlobalFmtList.tr.common_thu = "Per";
//                getGlobalFmtList.tr.common_fri = "Cum";
//                getGlobalFmtList.tr.common_sat = "Cmt";
//                getGlobalFmtList.tr.common_sun = "Paz";

//                getGlobalFmtList.en.common_january = "January";
//                getGlobalFmtList.en.common_february = "February";
//                getGlobalFmtList.en.common_march = "March";
//                getGlobalFmtList.en.common_april = "April";
//                getGlobalFmtList.en.common_may = "May";
//                getGlobalFmtList.en.common_june = "June";
//                getGlobalFmtList.en.common_july = "July";
//                getGlobalFmtList.en.common_august = "August";
//                getGlobalFmtList.en.common_september = "September";
//                getGlobalFmtList.en.common_october = "October";
//                getGlobalFmtList.en.common_november = "November";
//                getGlobalFmtList.en.common_december = "December";
//                getGlobalFmtList.en.common_jan = "Jan";
//                getGlobalFmtList.en.common_feb = "Feb";
//                getGlobalFmtList.en.common_mar = "Mar";
//                getGlobalFmtList.en.common_apr = "Apr";
//                getGlobalFmtList.en.common_may = "May";
//                getGlobalFmtList.en.common_jun = "Jun";
//                getGlobalFmtList.en.common_jul = "Jul";
//                getGlobalFmtList.en.common_aug = "Aug";
//                getGlobalFmtList.en.common_sep = "Sep";
//                getGlobalFmtList.en.common_oct = "Oct";
//                getGlobalFmtList.en.common_nov = "Nov";
//                getGlobalFmtList.en.common_dec = "Dec";
//                getGlobalFmtList.en.common_monday = "Monday";
//                getGlobalFmtList.en.common_tuesday = "Tuesday";
//                getGlobalFmtList.en.common_wednesday = "Wednesday";
//                getGlobalFmtList.en.common_thursday = "Thursday";
//                getGlobalFmtList.en.common_friday = "Friday";
//                getGlobalFmtList.en.common_saturday = "Saturday";
//                getGlobalFmtList.en.common_sunday = "Sunday";

//                getGlobalFmtList.en.common_mon = "Mon";
//                getGlobalFmtList.en.common_tue = "Tue";
//                getGlobalFmtList.en.common_wed = "Wed";
//                getGlobalFmtList.en.common_thu = "Thu";
//                getGlobalFmtList.en.common_fri = "Fri";
//                getGlobalFmtList.en.common_sat = "Sat";
//                getGlobalFmtList.en.common_sun = "Sun";

//                getGlobalFmtList.de.common_january = "Januar";
//                getGlobalFmtList.de.common_february = "Februar";
//                getGlobalFmtList.de.common_march = "März";
//                getGlobalFmtList.de.common_april = "April";
//                getGlobalFmtList.de.common_may = "Mai";
//                getGlobalFmtList.de.common_june = "Juni";
//                getGlobalFmtList.de.common_july = "Juli";
//                getGlobalFmtList.de.common_august = "August";
//                getGlobalFmtList.de.common_september = "September";
//                getGlobalFmtList.de.common_october = "Oktober";
//                getGlobalFmtList.de.common_november = "November";
//                getGlobalFmtList.de.common_december = "Dezember";
//                getGlobalFmtList.de.common_jan = "Jan";
//                getGlobalFmtList.de.common_feb = "Feb";
//                getGlobalFmtList.de.common_mar = "Mär";
//                getGlobalFmtList.de.common_apr = "Apr";
//                getGlobalFmtList.de.common_may = "Mai";
//                getGlobalFmtList.de.common_jun = "Jun";
//                getGlobalFmtList.de.common_jul = "Jul";
//                getGlobalFmtList.de.common_aug = "Aug";
//                getGlobalFmtList.de.common_sep = "Sep";
//                getGlobalFmtList.de.common_oct = "Okt";
//                getGlobalFmtList.de.common_nov = "Nov";
//                getGlobalFmtList.de.common_dec = "Dez";
//                getGlobalFmtList.de.common_monday = "Montag";
//                getGlobalFmtList.de.common_tuesday = "Dienstag";
//                getGlobalFmtList.de.common_wednesday = "Mittwoch";
//                getGlobalFmtList.de.common_thursday = "Donnerstag";
//                getGlobalFmtList.de.common_friday = "Freitag";
//                getGlobalFmtList.de.common_saturday = "Samstag";
//                getGlobalFmtList.de.common_sunday = "Sonntag";

//                getGlobalFmtList.de.common_mon = "Mon";
//                getGlobalFmtList.de.common_tue = "Die";
//                getGlobalFmtList.de.common_wed = "Mit";
//                getGlobalFmtList.de.common_thu = "Donn";
//                getGlobalFmtList.de.common_fri = "Fre";
//                getGlobalFmtList.de.common_sat = "Sam";
//                getGlobalFmtList.de.common_sun = "Sonn";
//             } else {
//                // console.error(":::Global FMT List Response Result Error");
//             }
//             airlinesFmt();
//          }
//       })
//       .catch((error) => {
//          error;
//          // console.error(":::Global FMT List Service Error:", error);
//       });
// };

const airlinesFmt = () => {
   axios
      .post(process.env.VUE_APP_API_URL + GLOBAL_AIRLINELIST_ENDPOINT, {})
      .then((response) => {
         store.state.app.airlineList = response.data.airlines;

         if (response.status == 200) {
            response.data.airlines.forEach((airline) => {
               getGlobalFmtList.tr[`airlineEnum_${airline.iataCode}`] = airline.nameTr;
               getGlobalFmtList.en[`airlineEnum_${airline.iataCode}`] = airline.nameEn;
               getGlobalFmtList.de[`airlineEnum_${airline.iataCode}`] = airline.nameDe;
            });
         }
         // else
         // {
         //     console.error(":::Global FMT List Response Result Error");
         // }
         store.state.app.splashScreen = false;
      })
      .catch((error) => {
         error;
         // console.error(":::Global FMT List Service Error:", error);
      });
};

// globalFmt();
airlinesFmt();
export { getGlobalFmtList };
