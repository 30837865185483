<template>
   <div id="flight-basket-wrapper" v-if="basket">
      <div id="search-uuids-wrapper" v-if="showSearchUuids">
         <div class="search-uuid" v-for="(offer, offerIndex) in basket.offers" :key="offerIndex" @click="handlerCopySearchUuid(offer.searchUuid)">
            {{ offer.searchUuid }}
         </div>
      </div>
      <!-- {{ offer.searchUuid }} -->

      <div class="ticket-inner">
         <section class="ticket-header" @click="handlerUserMadeSelection">
            <h2>
               {{ $t("basketComponentFlight_basket") }}
               <template v-if="offersCount == 1">
                  <template v-for="(offer, offerIndex) in basket.offers">
                     <div class="single-display-text" :key="offerIndex">
                        <span v-if="offer.tripType == 1">{{ $t("basketComponentFlight_single") }}</span>
                        <span v-else>{{ $t("basketComponentFlight_package") }}</span>
                     </div>
                  </template>
               </template>
            </h2>
            <div class="basket-type-switch" v-if="offersCount == 2" @click="handlerOfferType">
               <span :class="step == 0 ? 'active-switch' : ''" @click="handlerUserMadeSelection(0)">{{ $t("basketComponentFlight_single") }} </span>
               <span :class="step == 1 ? 'active-switch' : ''" @click="handlerUserMadeSelection(1)">{{ $t("basketComponentFlight_package") }} </span>
            </div>
            <div class="dotted-line"></div>
         </section>

         <div class="fb-ticket-container">
            <Transition name="fade-checkout" mode="out-in">
               <template v-for="(offer, offerKey, offerIndex) in basket.offers">
                  <div class="fb-ticket" :key="offerIndex" v-if="offerIndex == step">
                     <div class="basket-offer">
                        <template v-if="offer.tripType == 2 && highlightSingle">
                           <div class="cheapest-offer-badge-wrapper" @click="step = 0">
                              <span class="cheapest-offer-badge"> {{ $t("basketComponentFlight_singleOfferIsCheaper") }} </span>
                           </div>
                        </template>
                        <div class="basket-offer-leg-container">
                           <div class="basket-offer-leg" v-for="(leg, legIndex) in offer.legs" :key="legIndex">
                              <div class="basket-offer-segment">
                                 <div class="basket-offer-segment-infos">
                                    <span class="origin-airport">
                                       {{ leg.segments[0].originAirportCode }}
                                    </span>
                                    <i class="gts-icon icon-flight-icon icon-sm"></i>
                                    <span class="destination-airport">
                                       {{ leg.segments[leg.segments.length - 1].destinationAirportCode }}
                                    </span>
                                 </div>
                                 <div class="basket-offer-segment-price">
                                    <span
                                       class="leg-information"
                                       :class="
                                          leg.baggageInfo == '0KG' || leg.baggageInfo == '1PC' || leg.baggageInfo == '-1KG'
                                             ? 'attention'
                                             : 'no-attention'
                                       ">
                                       <i class="gts-icon icon-ancillary-baggage icon-sm"></i>
                                       {{ leg.baggageInfo }}
                                    </span>
                                    <span class="leg-price">{{ leg.price | price(leg.currency) }}</span>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <!-- {{ alertBeforeCheckout }} -->
                        <div class="action-button-wrapper" v-if="basket.checkoutEnabled">
                           <!-- alertBeforeCheckout TRUE -->
                           <template v-if="basket.checkoutEnabled && !alertBeforeCheckout">
                              <button @click="handlerCheckout(offer.searchUuid)">
                                 <div class="loader-arrow-wrapper">
                                    <i class="gts-icon icon-arrow-full icon-sm"></i>
                                 </div>

                                 <p class="total-container">
                                    <span class="total-text">{{ $t("basketComponentFlight_total") }}</span>
                                    <span class="total-price"> {{ offer.price | price }}</span>
                                 </p>
                              </button>
                           </template>
                           <template v-else>
                              <div class="basket-alertbox-wrapper">
                                 <i></i>
                                 <span> {{ $t("basketComponentFlight_baggageInformationChanges") }} </span>
                                 <button id="confirm-changes" @click="alertBeforeCheckout = false">
                                    {{ $t("basketComponentFlight_btnBaggageInformationConfirmation") }}
                                 </button>
                              </div>
                           </template>

                           <button class="disabled-action" v-if="!basket.checkoutEnabled && basket.waitingForRtMlResponse">
                              <div class="loader-arrow-wrapper">
                                 <span class="g-radial-loader"></span>
                              </div>

                              <p class="total-container">
                                 <span class="total-text">{{ $t("basketComponentFlight_total") }}</span>
                                 <span class="total-price"> {{ offer.price | price }}</span>
                              </p>
                           </button>
                        </div>
                        <div class="send-offer" @click="handlerDocumentViewer(2, offer.searchUuid)">
                           {{ $t("basketComponentFlight_sendOffer") }}
                        </div>
                     </div>
                     <section class="ticket-details-wrapper">
                        <div class="leg-offer-wrapper">
                           <div class="leg-offer-container" v-for="(leg, lindex) in offer.legs" :key="lindex">
                              <div class="leg-tab" @click="handlerLegToggler($event, lindex)">
                                 <!-- LEG MAIN -->
                                 <div class="leg-tab-main">
                                    <div class="leg-tab-left">
                                       <span class="leg-number">{{ leg.index }}</span>
                                       <span class="leg-header"> {{ leg.route }}</span>
                                    </div>

                                    <div class="leg-tab-right">
                                       <i class="gts-icon icon-detail-arrow icon-xs"></i>
                                    </div>
                                 </div>
                                 <!-- LEG DETAIL -->
                                 <div class="leg-tab-detail">
                                    <div class="leg-tab-detail-row" v-for="(segment, sindex) in leg.segments" :key="sindex">
                                       <div class="leg-tab-segment-wrapper">
                                          <div class="leg-tab-segment-row">
                                             <span>{{ segment.originAirportCode }} - {{ segment.destinationAirportCode }}</span>
                                             <span
                                                >{{ segment.departureDateTime | TimeFormat }} -
                                                {{ segment.arrivalDateTime | TimeFormat }}
                                             </span>
                                          </div>
                                          <div class="leg-tab-segment-row opacity">
                                             <span class="segment-flight-number">
                                                {{ segment.flightNumber }} -
                                                {{ segment.flightClass }}
                                             </span>
                                             <span class="segment-flight-date">
                                                {{ leg.date | basketDate }}
                                             </span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </section>
                  </div>
               </template>
            </Transition>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "FlightBasket",
   props: {
      basket: Object,
   },
   data() {
      return {
         alertBeforeCheckout: false,
         step: 1,
         offersCount: null,
         userMadeSelection: {
            status: false,
            step: null,
         },
         highlightSingle: false,
         showSearchUuids: false,
      };
   },

   mounted() {
      document.body.addEventListener("keydown", (event) => {
         let held = false;

         if (event.ctrlKey && event.altKey) {
            held = true;
         }

         if (held && event.keyCode == 83) {
            this.showSearchUuids = !this.showSearchUuids;
         }
      });
   },

   methods: {
      handlerCheckout(searchuuid) {
         window.open(`/flight/checkout/${searchuuid}`);
      },
      handlerLegToggler(ev) {
         ev.currentTarget.querySelector(".leg-tab-detail").classList.toggle("show-leg-tab-detail");
      },
      handlerOfferType() {
         this.step == 0 ? (this.step = 1) : (this.step = 0);
      },
      handlerDocumentViewer(type, uuid) {
         this.$store.state.app.documentViewer.request.documentType = type;
         this.$store.state.app.documentViewer.request.identifiers = [{ identifier: uuid, subIdentifiers: [] }];
         this.$store.state.app.documentViewer.request.fileType = 2;
         this.$store.state.app.documentViewer.request.showPrice = false;
         this.$store.state.app.documentViewer.activeProductStatus = 0;
         this.$store.state.app.documentViewer.hasPrice = true;
         this.$store.commit("app/setDocumentViewer", true);
      },
      handlerUserMadeSelection(selection) {
         this.userMadeSelection.status = true;
         if (selection == 0) {
            this.userMadeSelection.step = 0;
         } else if (selection == 1) {
            this.userMadeSelection.step = 1;
         }
      },
      handlerCheapestOffer(obj) {
         const basketPrices = {
            single: 0,
            package: 0,
         };

         Object.keys(obj.offers).forEach((offer) => {
            if (obj.offers[offer].tripType == 1) {
               basketPrices.single = obj.offers[offer].price;
            } else {
               basketPrices.package = obj.offers[offer].price;
            }
         });

         if (basketPrices.single == basketPrices.package) {
            this.userMadeSelection.step = 1;
         } else if (basketPrices.single < basketPrices.package) {
            this.highlightSingle = true;
         } else {
            this.highlightSingle = false;
         }
      },
      handlerCopySearchUuid(uuid) {
         navigator.clipboard.writeText(uuid);
         // console.log(uuid);
      },
      handlerBaggageControls(offers) {
         const legObj = {};

         Object.keys(offers).forEach((offer) => {
            legObj[offer] = [];
            Object.keys(offers[offer].legs).forEach((leg) => {
               legObj[offer].push(offers[offer].legs[leg].baggageInfo);
            });
         });

         Object.keys(legObj).forEach((leg, index) => {
            legObj[leg] = legObj[leg].toString();
            if (index != 0) {
               let prevEl = Object.keys(legObj)[0];
               let currentEl = Object.keys(legObj)[index];

               if (legObj[prevEl] != legObj[currentEl]) {
                  this.alertBeforeCheckout = true;
               }
            }
         });

         //    Object.keys(offers).forEach((offer) => {

         //    Object.keys(offers[offer].legs).forEach((leg) => {
         //       legObj[offer].push(offers[offer].legs[leg].baggageInfo);
         //    });
         // });

         // console.log(legObj);
      },
   },

   watch: {
      basket: {
         handler: function (bskt) {
            if (bskt != null) {
               this.handlerCheapestOffer(bskt);
               this.offersCount = Object.keys(bskt?.offers).length;
               if (this.offersCount == 2) {
                  if (this.userMadeSelection.status) {
                     this.step = this.userMadeSelection.step;
                  } else {
                     this.step = 1;
                  }

                  this.handlerBaggageControls(bskt.offers);
               } else if (this.offersCount == 1) {
                  this.step = 0;
               }
            }
         },
      },
      deep: true,
   },
};
</script>

<style lang="scss">
#flight-basket-desktop {
   // @media (min-width: 1400px) {
   //    min-width: 270px;
   // }
}
</style>

<style lang="scss" scoped>
#flight-basket-wrapper {
   position: sticky;
   overflow: hidden;
   top: 1rem;

   #search-uuids-wrapper {
      background: red;
      position: fixed;
      bottom: 1rem;
      right: 0;
      left: 0;
      margin: auto;
      width: fit-content;
      padding: 0.25rem 0.5rem;
      border-radius: 1rem;
      background-color: #fff;
      border: 2px solid #ccc;

      .search-uuid {
         margin: 0.125rem;
         cursor: copy;
         color: #363636;
         font-weight: 500;
         padding: 0.25rem 1rem;
         border-radius: 1rem;

         &:hover {
            background: #eee;
         }
      }
   }

   .ticket-inner {
      border-radius: 0.5rem;

      background-color: var(--primary);

      section.ticket-header {
         position: relative;
         padding: 1.5rem 1.5rem;
         padding-top: 2rem;
         display: flex;
         justify-content: space-between;

         @media (max-width: 1560px) {
            flex-direction: column;
         }
         cursor: pointer;
         .dotted-line {
            background-image: linear-gradient(to right, #e0e7f0 33%, rgba(255, 255, 255, 0) 0%);
            background-position: left;
            background-size: 12px auto;
            background-repeat: repeat-x;
            display: inline-block;
            width: auto;
            position: absolute;
            bottom: 7px;
            left: 1rem;
            right: 1rem;
            height: 2px;
         }

         &::before,
         &::after {
            content: "*";
            text-indent: -999rem;
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            background: var(--c-mode-background);
            margin: auto;
            bottom: 0;
            border-radius: 50%;
            z-index: 2;
         }

         &::before {
            left: -8px;
         }
         &::after {
            right: -8px;
         }
         h2 {
            font-size: var(--xlarge);
            font-weight: 600;
            color: #fff;
            display: flex;

            span {
               color: var(--accent-secondary) !important;
               font-size: var(--large);
               font-weight: 500;
            }
            .single-display-text {
               margin-left: 0.25rem;
            }
         }

         .basket-type-switch {
            background-color: #184990;
            padding: 0.25rem;
            border-radius: 12rem;
            display: inline-flex;
            margin-left: 0.25rem;
            justify-content: space-between;
            span {
               color: var(--accent-secondary) !important;
               font-size: var(--xsmall);
               font-weight: 600;
               padding: 0.15rem 0.5rem;
               border-radius: 12rem;
               display: flex;
               align-items: center;

               &.active-switch {
                  background-color: var(--accent-secondary);
                  color: #184990 !important;
               }
            }
         }
      }
      .fb-ticket-container {
         padding: 1.5rem;
         padding-bottom: 6rem;
         .basket-offer {
            .cheapest-offer-badge-wrapper {
               height: 25px;
               overflow: hidden;
               margin-bottom: 0.5rem;
               @keyframes switchHighlighter {
                  from {
                     border: 1px solid transparent;
                  }
                  to {
                     border: 1px solid #01ffe5;
                  }
               }

               @keyframes badgeAnimation {
                  from {
                     top: 25px;
                  }
                  to {
                     top: 0;
                  }
               }

               span.cheapest-offer-badge {
                  cursor: pointer;
                  color: var(--accent-secondary);
                  font-weight: 500;
                  position: relative;
                  padding-left: 1.25rem;
                  margin-bottom: 1rem;
                  display: block;
                  animation-name: badgeAnimation;
                  animation-fill-mode: backwards;
                  animation-iteration-count: 1;
                  animation-timing-function: ease-out;
                  animation-duration: 0.25s;

                  @keyframes attention {
                     0% {
                        opacity: 0;
                     }
                     100% {
                        opacity: 1;
                     }
                  }

                  &::before {
                     content: "";
                     opacity: 1;
                     width: 14px;
                     height: 14px;
                     position: absolute;
                     top: 0.35rem;
                     margin: auto;
                     left: 0;
                     border-radius: 50%;
                     background-color: var(--accent-secondary);
                     animation-name: attention;
                     animation-fill-mode: backwards;
                     animation-iteration-count: infinite;
                     animation-timing-function: ease-out;
                     animation-duration: 0.75s;
                  }
               }
            }
            .basket-offer-leg-container {
               .basket-offer-leg {
                  padding: 0.5rem 0;
                  border-bottom: 1px solid #ffffff30;
                  .basket-offer-segment {
                     .basket-offer-segment-infos {
                        display: flex;
                        justify-content: space-between;
                        span {
                           font-weight: 600;
                           font-size: 1.125rem;
                           color: var(--txt-negative);
                        }

                        i {
                           filter: brightness(10);
                        }
                     }
                     .basket-offer-segment-price {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 0.5rem;

                        @keyframes legInfo {
                           0% {
                              opacity: 0;
                              transform: scale(1);
                           }
                           60% {
                              opacity: 1;
                           }
                           80% {
                              opacity: 1;
                              transform: scale(1);
                           }
                           100% {
                              opacity: 1;
                              transform: scale(1.15);
                           }
                        }
                        span.leg-information {
                           color: var(--accent-secondary);
                           font-weight: 500;
                           display: flex;

                           &.attention {
                              animation-name: legInfo;
                              animation-timing-function: ease-out;
                              animation-duration: 1s;
                              animation-iteration-count: infinite;
                           }

                           i {
                              filter: invert(50%) sepia(100%) saturate(180%) hue-rotate(120deg) brightness(100%) contrast(200%);
                           }
                        }
                        span.leg-price {
                           background-color: #194c92;
                           font-size: 1rem;
                           color: #fff;
                           font-weight: 600;
                           padding: 0.25rem 1rem;
                           border-radius: 24rem;
                        }
                     }
                  }
                  &:last-child {
                     border-bottom: none !important;
                  }
               }
            }
         }
         .action-button-wrapper {
            width: 100%;
            margin: 1rem 0;

            button {
               padding: 0.5rem 1.5rem;
               border-radius: 12rem;
               width: 100%;
               margin: 1rem 0;
               box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 8px 0px;
               display: flex;
               align-items: center;
               justify-content: center;
               border: 2px solid #1b4c92;
               transition: all 0.2s ease-in-out;
               background-color: #fff;

               i {
                  position: relative;
                  // left: -0.5rem;
                  margin-right: 0.5rem;
                  transition: all 0.2s ease-in-out;
                  filter: invert(19%) sepia(28%) saturate(5280%) hue-rotate(204deg) brightness(70%) contrast(85%);
                  flex-shrink: 0;
                  transform: scale(1);
                  display: none;
                  @media (min-width: 1700px) {
                     display: block !important;
                  }
               }

               p.total-container {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  position: relative;
                  top: 0;
                  transition-property: top;
                  transition-timing-function: ease-in-out;
                  transition-duration: 0.2s;
                  .total-text {
                     color: #1b4c92;
                     font-size: var(--xsmall);
                     font-weight: 600;
                     transition: opacity, 0.2s, ease-in-out;
                     opacity: 1;
                  }
                  .total-price {
                     font-size: 1.25rem;
                     font-weight: 800;
                     color: #1b4c92;
                  }
               }

               .loader-arrow-wrapper {
                  margin-right: 0.5rem;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  // background-color: gray;
                  .g-radial-loader {
                     border-color: var(--primary);
                     border-width: 3px;
                     width: 28px !important;
                     height: 28px !important;
                  }
               }

               &.disabled-action {
               }
            }

            .basket-alertbox-wrapper {
               background-color: #00000050;
               border-radius: 1rem;
               padding: 1rem;
               display: flex;
               flex-direction: column;
               justify-content: center;
               align-items: center;
               margin-bottom: 1rem;

               i {
                  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOCIgaGVpZ2h0PSIyOCIgdmlld0JveD0iMCAwIDI4IDI4Ij4KICA8ZyBpZD0iR3JvdXBfMTE1MjQiIGRhdGEtbmFtZT0iR3JvdXAgMTE1MjQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC05NjAuODY4IC03MjMuOTE3KSI+CiAgICA8Y2lyY2xlIGlkPSJFbGxpcHNlXzEzMTMiIGRhdGEtbmFtZT0iRWxsaXBzZSAxMzEzIiBjeD0iMTQiIGN5PSIxNCIgcj0iMTQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDk2MC44NjggNzIzLjkxNykiIGZpbGw9IiMwMGZmZTUiLz4KICAgIDxwYXRoIGlkPSJQYXRoXzEwMzY5IiBkYXRhLW5hbWU9IlBhdGggMTAzNjkiIGQ9Ik0xOC42LDE0LjgyM2wtMy43NDMuNDY5LS4xMzQuNjIxLjczNS4xMzZjLjQ4LjExNS41NzUuMjg4LjQ3MS43NjZsLTEuMjA2LDUuNjY5Yy0uMzE3LDEuNDY2LjE3MSwyLjE1NiwxLjMyMSwyLjE1NmEzLjM4NSwzLjM4NSwwLDAsMCwyLjM5NC0uOTc3bC4xNDQtLjY4YTEuODExLDEuODExLDAsMCwxLTEuMTIyLjRjLS40NSwwLS42MTMtLjMxNS0uNS0uODcxWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTU4LjMyNSA3MjAuMDc0KSIgZmlsbD0iIzFhM2E2NyIvPgogICAgPHBhdGggaWQ9IlBhdGhfMTAzNzAiIGRhdGEtbmFtZT0iUGF0aCAxMDM3MCIgZD0iTTE5LjAxOSw5LjUxYTEuNjM1LDEuNjM1LDAsMSwxLTEuNjM1LTEuNjM1QTEuNjM1LDEuNjM1LDAsMCwxLDE5LjAxOSw5LjUxWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTU4LjAxOCA3MjEuOTc1KSIgZmlsbD0iIzFhM2E2NyIvPgogIDwvZz4KPC9zdmc+Cg==");
                  width: 32px;
                  height: 32px;
                  background-repeat: no-repeat;
                  background-size: cover;
                  display: block;
               }
               span {
                  padding-top: 0.5rem;
                  text-align: center;
                  color: var(--accent-secondary);
               }
               #confirm-changes {
                  border: 2px solid #267be6;
                  background-color: #2e53a0;
                  box-shadow: none;
                  color: #fff;
                  font-weight: 700;
                  font-size: var(--small);

                  &:hover {
                     border-width: 2px !important;
                     background-color: #fff !important;
                     color: var(--primary);
                  }
               }
            }

            &:hover {
               button {
                  background-color: #184990;
                  border: 2px solid #184990;

                  i {
                     left: -0.1rem;
                     filter: invert(100%) sepia(100%) saturate(10) hue-rotate(160deg) brightness(100%) contrast(200%);
                  }

                  &.disabled-action {
                     cursor: not-allowed;
                     background-color: #fff;
                     p.total-container {
                        top: 0px !important;
                        .total-text {
                           color: #1b4c92;
                           font-size: var(--xsmall);
                           font-weight: 600;

                           opacity: 2;
                        }
                        .total-price {
                           color: #1b4c92;
                        }
                     }
                  }
               }
               p.total-container {
                  top: -10px;
                  .total-text {
                     color: #1b4c92;
                     font-size: var(--xsmall);
                     font-weight: 600;

                     opacity: 0;
                  }
                  .total-price {
                     color: var(--accent-secondary);
                  }
               }
            }
         }
         .send-offer {
            padding-bottom: 1rem;
            color: #fff;
            text-align: center;
            width: 100%;
            display: block;
            text-decoration: underline;
            font-size: var(--small);
            cursor: pointer;

            &:hover {
               color: var(--accent-secondary);
            }
         }
         .ticket-details-wrapper {
            .leg-offer-wrapper {
               .leg-offer-container {
                  background-color: #2155a0;
                  // background-color: darken(var(--primary), 10%);
                  padding: 0.5rem 0.75rem;
                  border-radius: 12px;
                  color: #ffffff;
                  font-weight: 600;
                  font-size: 1.125rem;
                  margin-bottom: 0.5rem;

                  .leg-tab {
                     .leg-tab-main {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        .leg-tab-left {
                           .leg-number {
                              border-right: 1px solid #ffffff50;
                              padding-right: 0.35rem;
                              margin-right: 0.35rem;
                           }
                        }
                        .leg-tab-right {
                           i {
                              filter: brightness(10);
                           }
                        }
                     }
                     .leg-tab-detail {
                        padding: 0 0.25rem;
                        margin-top: 1rem;
                        display: none;

                        &.show-leg-tab-detail {
                           display: block;
                        }

                        .leg-tab-detail-row {
                           display: flex;
                           justify-content: space-between;
                           border-bottom: 1px dashed #ffffff50;
                           padding-bottom: 0.5rem;
                           margin-bottom: 0.5rem;

                           .leg-tab-detail-leg-route {
                              white-space: nowrap;
                              font-size: 0.85rem;
                              font-weight: 400;
                           }
                           .leg-tab-segment-wrapper {
                              display: flex;
                              flex-direction: column;
                              font-size: 0.85rem;
                              width: 100%;

                              .leg-tab-segment-row {
                                 display: flex;
                                 flex-direction: row;
                                 justify-content: space-between;
                                 width: 100%;
                                 font-weight: 400;

                                 &.opacity {
                                    opacity: 0.6;
                                 }

                                 span {
                                    font-size: var(--xsmall);
                                 }
                              }
                           }
                           &:last-child {
                              border-bottom: none;
                           }
                        }

                        &.display {
                           display: block;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
</style>

<style lang="scss">
#flight-basket-wrapper {
   nav.tabs {
      display: none;
   }
}
</style>
