<template>
   <div class="order-search-page-container sub-page-wrapper">
      <Aside />
      <Main>
         <Navbar />

         <!-- 

        .container-fluid
            .row
               .col gts-box mx-3
                  h1.sub-page-header

                  .order-search-input-wrapper row gy-3
                        # aynı satırda olması istenen input rowları bu wrapper içerisinde olacak
                        # her bir input elemanı col-12 col-md-6 col-lg-2 ön tanımlı class'lar ile responsive uyumlu hale getirilir.

         
        -->

         <div class="container-fluid">
            <div class="row">
               <div class="col gts-box mx-3">
                  <h1 class="sub-page-header">{{ $t("orderSearch_pageTitle") }}</h1>

                  <!-- PRODUCT TYPE -->
                  <div class="sub-page-row-wrapper row gy-3">
                     <div class="col-3 col-md-3 col-lg-1">
                        <UIRadioButton
                           :label="$t('orderSearch_filter_lblFlight')"
                           name="serviceFlight"
                           id="serviceFlight"
                           native-value="1"
                           v-model="product" />
                     </div>
                     <div class="col-3 col-md-3 col-lg-1">
                        <UIRadioButton
                           :label="$t('orderSearch_filter_lblHotel')"
                           name="serviceHotel"
                           id="serviceHotel"
                           native-value="2"
                           v-model="product" />
                     </div>
                     <div class="col-3 col-md-3 col-lg-1">
                        <UIRadioButton
                           :label="$t('orderSearch_filter_lblTransfer')"
                           name="serviceTransfer"
                           id="serviceTransfer"
                           native-value="3"
                           v-model="product" />
                     </div>
                     <div class="col-3 col-md-3 col-lg-1">
                        <UIRadioButton
                           :label="$t('orderSearch_filter_lblRentACar')"
                           name="serviceRentACar"
                           id="serviceRentACar"
                           native-value="4"
                           v-model="product" />
                     </div>
                  </div>

                  <!-- FLIGHT SEARCH  -->
                  <div class="sub-page-row-wrapper row gy-3" v-if="product == 1">
                     <div class="col-3 col-md-3 col-lg-1">
                        <UIRadioButton
                           :label="$t('orderSearch_filter_lblOrderId')"
                           name="rdoFlightOrderId"
                           id="rdoFlightOrderId"
                           native-value="1"
                           v-model="subProduct" />
                     </div>
                     <div class="col-3 col-md-3 col-lg-1">
                        <UIRadioButton label="PNR" name="pnr" id="pnr" native-value="2" v-model="subProduct" />
                     </div>
                     <div class="col-3 col-md-3 col-lg-1">
                        <UIRadioButton
                           :label="$t('orderSearch_filter_lblEticket')"
                           name="rdoEticket"
                           id="rdoEticket"
                           native-value="3"
                           v-model="subProduct" />
                     </div>
                     <div class="col-3 col-md-3 col-lg-1">
                        <UIRadioButton
                           :label="$t('orderSearch_filter_lblSurname')"
                           name="rdoSurname"
                           id="rdoSurname"
                           native-value="4"
                           v-model="subProduct" />
                     </div>
                  </div>

                  <!-- HOTEL SEARCH  -->
                  <div class="sub-page-row-wrapper row gy-3" v-if="product == 2">
                     <div class="col-3 col-md-3 col-lg-1">
                        <UIRadioButton
                           :label="$t('orderSearch_filter_lblOrderId')"
                           name="rdoHotelOrderId"
                           id="rdoHotelOrderId"
                           native-value="1"
                           v-model="subProduct" />
                     </div>
                     <div class="col-3 col-md-3 col-lg-1">
                        <UIRadioButton
                           :label="$t('orderSearch_filter_lblVoucher')"
                           name="rdoVoucher"
                           id="rdoVoucher"
                           native-value="5"
                           v-model="subProduct" />
                     </div>
                     <div class="col-3 col-md-3 col-lg-1">
                        <UIRadioButton
                           :label="$t('orderSearch_filter_lblSurname')"
                           name="rdoHotelSurname"
                           id="rdoHotelSurname"
                           native-value="4"
                           v-model="subProduct" />
                     </div>
                  </div>

                  <!-- TRANSFER SEARCH  -->
                  <div class="sub-page-row-wrapper row gy-3" v-if="product == 3">
                     <div class="col-3 col-md-3 col-lg-1">
                        <UIRadioButton
                           :label="$t('orderSearch_filter_lblOrderId')"
                           name="rdoTransferOrderId"
                           id="rdoTransferOrderId"
                           native-value="1"
                           v-model="subProduct" />
                     </div>
                     <div class="col-3 col-md-3 col-lg-1">
                        <UIRadioButton label="TNR" name="rdoTnr" id="rdoTnr" native-value="5" v-model="subProduct" />
                     </div>
                     <div class="col-3 col-md-3 col-lg-1">
                        <UIRadioButton
                           :label="$t('orderSearch_filter_lblSurname')"
                           name="rdoTransferSurname"
                           id="rdoTransferSurname"
                           native-value="4"
                           v-model="subProduct" />
                     </div>
                  </div>

                  <!-- RENT A CAR SEARCH  -->
                  <div class="sub-page-row-wrapper row gy-3" v-if="product == 4">
                     <div class="col-3 col-md-3 col-lg-1">
                        <UIRadioButton
                           :label="$t('orderSearch_filter_lblOrderId')"
                           name="rdoRentACarOrderId"
                           id="rdoRentACarOrderId"
                           native-value="1"
                           v-model="subProduct" />
                     </div>
                     <div class="col-3 col-md-3 col-lg-1">
                        <UIRadioButton label="RNR" name="rdoRnr" id="rdoRnr" native-value="5" v-model="subProduct" />
                     </div>
                     <div class="col-3 col-md-3 col-lg-1">
                        <UIRadioButton
                           :label="$t('orderSearch_filter_lblSurname')"
                           name="rdoRentACarSurname"
                           id="rdoRentACarSurname"
                           native-value="4"
                           v-model="subProduct" />
                     </div>
                  </div>

                  <!-- FLIGHT SEARCH FORMS -->
                  <div class="sub-page-row-wrapper row gy-3" v-if="product == 1">
                     <!-- FLIGHT ORDER SEARCH -->

                     <KeepAlive>
                        <div class="col-12 col-md-6 col-lg-3" v-if="subProduct == 1">
                           <UIInput
                              :label="$t('orderSearch_filter_lblOrderId')"
                              v-model="searchQuery.flightOrderId"
                              :placeholder="$t('orderSearch_filter_plhOrderId')"
                              v-input="{ type: 'numeric', len: [3, 9], required: true, requiredMsg: $t('common_cannotBeEmpty') }"
                              id="flightOrderId" />
                        </div>
                     </KeepAlive>
                     <!-- FLIGHT PNR SEARCH -->
                     <KeepAlive>
                        <div class="col-12 col-md-6 col-lg-3" v-if="subProduct == 2">
                           <UIInput
                              :label="$t('orderSearch_filter_lblPnr')"
                              v-model="searchQuery.pnr"
                              :placeholder="$t('orderSearch_filter_plhPnr')"
                              v-input="{ type: 'alphanumeric', len: [3, 9], required: true, requiredMsg: $t('common_cannotBeEmpty') }"
                              id="pnr" />
                        </div>
                     </KeepAlive>
                     <!-- FLIGHT ETICKET SEARCH -->
                     <KeepAlive>
                        <div class="col-12 col-md-6 col-lg-3" v-if="subProduct == 3">
                           <UIInput
                              :label="$t('orderSearch_filter_lblEticket')"
                              v-model="searchQuery.eticket"
                              :placeholder="$t('orderSearch_filter_plhEticket')"
                              v-input="{ type: 'alphanumeric', len: [3, 20], required: true, requiredMsg: $t('common_cannotBeEmpty') }"
                              id="eticket" />
                        </div>
                     </KeepAlive>
                     <!-- FLIGHT LAST NAME SEARCH -->
                     <KeepAlive>
                        <div class="last-name-search-wrapper row">
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UIDatePicker2
                                 :label="$t('orderSearch_filter_lblCreationFromDate')"
                                 v-model="searchQuery.flightDate1.value"
                                 :min="searchQuery.flightDate1.min"
                                 :max="searchQuery.flightDate1.max"
                                 custom-header />
                           </div>
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UIDatePicker2
                                 :label="$t('orderSearch_filter_lblCreationToDate')"
                                 v-model="searchQuery.flightDate2.value"
                                 :min="searchQuery.flightDate2.min"
                                 :max="searchQuery.flightDate2.max"
                                 custom-header />
                           </div>
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UISelectBox
                                 :key="refreshKeyZero"
                                 :label="$t('orderSearch_filter_lblPnrStatus')"
                                 id="pnrStatus"
                                 :options="pnrStatuses"
                                 v-model="searchQuery.pnrStatus"
                                 :allow-search="true"
                                 light-theme />
                           </div>
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UISelectBox
                                 :key="refreshKeyZero"
                                 :label="$t('orderSearch_filter_lblProviderList')"
                                 id="flightProvidersList"
                                 :options="flightProviders"
                                 v-model="searchQuery.flightProviderId"
                                 :allow-search="true"
                                 light-theme />
                           </div>
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UIInput
                                 :label="$t('orderSearch_filter_lblSurname')"
                                 :placeholder="$t('orderSearch_filter_plhSurname')"
                                 id="flightSurname"
                                 v-model="searchQuery.flightSurname"
                                 v-input="{ type: 'alphabetic', len: [0, 30], required: false, requiredMsg: $t('common_cannotBeEmpty') }" />
                           </div>
                        </div>
                     </KeepAlive>

                     <!-- FLIGHT LAST NAME SEARCH ENDS-->
                  </div>

                  <!-- HOTEL SEARCH FORMS -->
                  <div class="sub-page-row-wrapper row gy-3" v-if="product == 2">
                     <!-- HOTEL ORDER SEARCH -->
                     <KeepAlive>
                        <div class="col-12 col-md-6 col-lg-3" v-if="subProduct == 1">
                           <UIInput
                              :label="$t('orderSearch_filter_plhOrderId')"
                              v-model="searchQuery.hotelOrderId"
                              :placeholder="$t('orderSearch_filter_plhOrderId')"
                              id="hotelOrderId"
                              v-input="{ type: 'numeric', len: [3, 9], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
                        </div>
                     </KeepAlive>

                     <!-- HOTEL VOUCHER SEARCH -->
                     <KeepAlive>
                        <div class="col-12 col-md-6 col-lg-3" v-if="subProduct == 5">
                           <UIInput
                              :label="$t('orderSearch_filter_lblVoucher')"
                              v-model="searchQuery.voucher"
                              :placeholder="$t('orderSearch_filter_plhVoucher')"
                              id="voucher"
                              v-input="{ type: 'alphanumeric', len: [3, 14], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
                        </div>
                     </KeepAlive>

                     <!-- HOTEL LAST NAME SEARCH -->
                     <KeepAlive>
                        <div class="last-name-search-wrapper row">
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UISelectBox
                                 :key="refreshKeyZero"
                                 :label="$t('orderSearch_filter_lblDateType')"
                                 :options="dateTypes"
                                 v-model="searchQuery.dateType"
                                 light-theme />
                           </div>
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UIDatePicker2
                                 :label="$t('orderSearch_filter_lblCreationFromDate')"
                                 v-model="searchQuery.hotelDate1.value"
                                 :min="searchQuery.hotelDate1.min"
                                 :max="searchQuery.hotelDate1.max"
                                 custom-header />
                           </div>
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UIDatePicker2
                                 :label="$t('orderSearch_filter_lblCreationToDate')"
                                 v-model="searchQuery.hotelDate2.value"
                                 :min="searchQuery.hotelDate2.min"
                                 :max="searchQuery.hotelDate2.max"
                                 custom-header />
                           </div>
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UISelectBox
                                 :key="refreshKeyZero"
                                 :label="$t('orderSearch_filter_lblVoucherStatus')"
                                 id="voucherStatus"
                                 :options="voucherStatuses"
                                 v-model="searchQuery.voucherStatus"
                                 :allow-search="true"
                                 light-theme />
                           </div>
                           <!-- --------------------------------------------------------------------------------------- -->
                           <!-- <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UISelectBox
                                 :label="$t('orderSearch_filter_lblProviderList')"
                                 id="hotelProvider"
                                 :options="hotelProviders"
                                 v-model="searchQuery.hotelProviderId"
                                 :allow-search="true"
                                 light-theme />
                           </div> -->
                           <!-- --------------------------------------------------------------------------------------- -->
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UIInput
                                 :label="$t('orderSearch_filter_lblSurname')"
                                 :placeholder="$t('orderSearch_filter_plhSurname')"
                                 id="hotelSurname"
                                 v-model="searchQuery.hotelSurname"
                                 v-input="{ type: 'alphabetic', len: [0, 30], required: false, requiredMsg: $t('common_cannotBeEmpty') }" />
                           </div>
                        </div>
                     </KeepAlive>

                     <!-- HOTEL LAST NAME SEARCH ENDS-->
                  </div>

                  <!-- TRANSFER SEARCH FORMS -->
                  <div class="sub-page-row-wrapper row gy-3" v-if="product == 3">
                     <!-- TRANSFER ORDER SEARCH -->
                     <KeepAlive>
                        <div class="col-12 col-md-6 col-lg-3" v-if="subProduct == 1">
                           <UIInput
                              :label="$t('orderSearch_filter_plhOrderId')"
                              v-model="searchQuery.transferOrderId"
                              :placeholder="$t('orderSearch_filter_plhOrderId')"
                              id="transferOrderId"
                              v-input="{ type: 'numeric', len: [3, 9], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
                        </div>
                     </KeepAlive>
                     <!-- TRANSFER VOUCHER SEARCH -->
                     <KeepAlive>
                        <div class="col-12 col-md-6 col-lg-3" v-if="subProduct == 5">
                           <UIInput
                              :label="$t('orderSearch_filter_lblTnr')"
                              v-model="searchQuery.tnr"
                              :placeholder="$t('orderSearch_filter_plhTnr')"
                              id="tnr"
                              v-input="{ type: 'alphanumeric', len: [3, 10], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
                        </div>
                     </KeepAlive>

                     <!-- TRANSFER LAST NAME SEARCH -->
                     <KeepAlive>
                        <div class="last-name-search-wrapper row">
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UISelectBox
                                 :key="refreshKeyZero"
                                 :label="$t('orderSearch_filter_lblDateType')"
                                 :options="transferDateTypes"
                                 v-model="searchQuery.transferDateType"
                                 light-theme />
                           </div>
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UIDatePicker2
                                 :label="$t('orderSearch_filter_lblCreationFromDate')"
                                 v-model="searchQuery.transferDate1.value"
                                 :min="searchQuery.transferDate1.min"
                                 :max="searchQuery.transferDate1.max"
                                 custom-header />
                           </div>
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UIDatePicker2
                                 :label="$t('orderSearch_filter_lblCreationToDate')"
                                 v-model="searchQuery.transferDate2.value"
                                 :min="searchQuery.transferDate2.min"
                                 :max="searchQuery.transferDate2.max"
                                 custom-header />
                           </div>
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UISelectBox
                                 :key="refreshKeyZero"
                                 :label="$t('orderSearch_filter_lblTnrStatus')"
                                 id="tnrStatus"
                                 :options="tnrStatuses"
                                 v-model="searchQuery.tnrStatus"
                                 :allow-search="true"
                                 light-theme />
                           </div>
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UISelectBox
                                 :key="refreshKeyZero"
                                 :label="$t('orderSearch_filter_lblProviderList')"
                                 id="transferProvider"
                                 :options="transferProviders"
                                 v-model="searchQuery.transferProviderId"
                                 :allow-search="true"
                                 light-theme />
                           </div>
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UIInput
                                 :label="$t('orderSearch_filter_lblSurname')"
                                 :placeholder="$t('orderSearch_filter_plhSurname')"
                                 id="transferSurname"
                                 v-model="searchQuery.transferSurname"
                                 v-input="{ type: 'alphabetic', len: [0, 30], required: false, requiredMsg: $t('common_cannotBeEmpty') }" />
                           </div>
                        </div>
                     </KeepAlive>

                     <!-- TRANSFER LAST NAME SEARCH ENDS-->
                  </div>

                  <!-- RENT A CAR SEARCH FORMS -->
                  <div class="sub-page-row-wrapper row gy-3" v-if="product == 4">
                     <!-- RENT A CAR ORDER ID SEARCH -->
                     <KeepAlive>
                        <div class="col-12 col-md-6 col-lg-3" v-if="subProduct == 1">
                           <UIInput
                              :label="$t('orderSearch_filter_plhOrderId')"
                              v-model="searchQuery.rentACarOrderId"
                              :placeholder="$t('orderSearch_filter_plhOrderId')"
                              id="rentACarOrderId"
                              v-input="{ type: 'numeric', len: [3, 9], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
                        </div>
                     </KeepAlive>
                     <!-- RENT A CAR RNR SEARCH -->
                     <KeepAlive>
                        <div class="col-12 col-md-6 col-lg-3" v-if="subProduct == 5">
                           <UIInput
                              :label="$t('orderSearch_filter_lblRnr')"
                              v-model="searchQuery.rnr"
                              :placeholder="$t('orderSearch_filter_plhRnr')"
                              id="rnr"
                              v-input="{ type: 'alphanumeric', len: [3, 10], required: true, requiredMsg: $t('common_cannotBeEmpty') }" />
                        </div>
                     </KeepAlive>

                     <!-- RENT A CAR LAST NAME SEARCH -->
                     <KeepAlive>
                        <div class="last-name-search-wrapper row">
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UISelectBox
                                 :key="refreshKeyZero"
                                 :label="$t('orderSearch_filter_lblDateType')"
                                 :options="rentACarDateTypes"
                                 v-model="searchQuery.rentACarDateType"
                                 light-theme />
                           </div>
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UIDatePicker2
                                 :label="$t('orderSearch_filter_lblCreationFromDate')"
                                 v-model="searchQuery.rentACarDate1.value"
                                 :min="searchQuery.rentACarDate1.min"
                                 :max="searchQuery.rentACarDate1.max"
                                 custom-header />
                           </div>
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UIDatePicker2
                                 :label="$t('orderSearch_filter_lblCreationToDate')"
                                 v-model="searchQuery.rentACarDate2.value"
                                 :min="searchQuery.rentACarDate2.min"
                                 :max="searchQuery.rentACarDate2.max"
                                 custom-header />
                           </div>
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UISelectBox
                                 :key="refreshKeyZero"
                                 :label="$t('orderSearch_filter_lblRnrStatus')"
                                 id="rnrStatus"
                                 :options="rnrStatuses"
                                 v-model="searchQuery.rnrStatus"
                                 :allow-search="true"
                                 light-theme />
                           </div>
                           <!-- <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UISelectBox
                                 :label="$t('orderSearch_filter_lblProviderList')"
                                 id="rentACarProvider"
                                 :options="rentACarProviders"
                                 v-model="searchQuery.rentACarProviderId"
                                 :allow-search="true"
                                 light-theme />
                           </div> -->
                           <div class="col-12 col-md-6 col-lg" v-if="subProduct == 4">
                              <UIInput
                                 :label="$t('orderSearch_filter_lblSurname')"
                                 :placeholder="$t('orderSearch_filter_plhSurname')"
                                 id="rentACarSurname"
                                 v-model="searchQuery.rentACarSurname"
                                 v-input="{ type: 'alphabetic', len: [0, 30], required: false, requiredMsg: $t('common_cannotBeEmpty') }" />
                           </div>
                        </div>
                     </KeepAlive>

                     <!-- RENT A CAR LAST NAME SEARCH ENDS-->
                  </div>

                  <!-- SALES PAGES CTA -->
                  <div class="sub-page-actions-row row">
                     <div class="sub-pages-actions-wrapper col-12">
                        <UIButton className="default" :text="$t('common_search')" @click.native="handlerSearchOrder" />
                     </div>
                  </div>
               </div>
            </div>

            <!-- ORDER PAGE INNER -->
            <div class="gts-grid-container">
               <template v-if="tableOptions.rows.length > 0">
                  <section class="page-section">
                     <div class="page-section-inner gts-box">
                        <GtsGrid :table-options="tableOptions" :item-per-page="-1" :title="$t('orderSearch_pageTitle')" />
                     </div>
                  </section>
               </template>
               <template v-if="hotelTableOptions.rows.length > 0">
                  <section class="page-section">
                     <div class="page-section-inner gts-box">
                        <GtsGrid :table-options="hotelTableOptions" :item-per-page="-1" :title="$t('orderSearch_pageTitle')" />
                     </div>
                  </section>
               </template>
               <template v-if="transferTableOptions.rows.length > 0">
                  <section class="page-section">
                     <div class="page-section-inner gts-box">
                        <GtsGrid :table-options="transferTableOptions" :item-per-page="-1" :title="$t('orderSearch_pageTitle')" />
                     </div>
                  </section>
               </template>
               <template v-if="rentACarTableOptions.rows.length > 0">
                  <section class="page-section">
                     <div class="page-section-inner gts-box">
                        <GtsGrid :table-options="rentACarTableOptions" :item-per-page="-1" :title="$t('orderSearch_pageTitle')" />
                     </div>
                  </section>
               </template>
            </div>
         </div>
      </Main>
   </div>
</template>

<script>
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";

import UIRadioButton from "@/components/unitary/form2/UIRadioButton.vue";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import UIInput from "@/components/unitary/form2/UIInput.vue";
import UIDatePicker2 from "@/components/unitary/form2/UIDatePicker2.vue";
import UIButton from "@/components/unitary/Button.vue";
import GtsGrid from "@/components/utils/GtsGrid.vue";
import Popup from "@/gts-popup.js";

// import axios from "axios"

import moment from "moment";
// import i18n from "@/fmt/i18n.js";

import { orderService, agencyService } from "@/service/index.js";

export default {
   name: "OrderSearch",
   components: {
      Aside,
      Navbar,
      Main,

      UIRadioButton,
      UIInput,
      UISelectBox,
      UIDatePicker2,
      UIButton,
      GtsGrid,
   },
   data() {
      return {
         isFirstSearch: true,
         refreshKeyZero: 0,
         searchQuery: {
            pnrStatus: -1,
            flightProviderId: -1,
            eticket: null,
            pnr: null,
            flightOrderId: null,
            flightSurname: null,
            hotelOrderId: null,
            voucher: null,
            dateType: 1,
            flightDate1: {
               min: moment().subtract(1, "year").set("month", 0).set("date", 1).format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().subtract(1, "year").format(this.$constants.dateFormat),
            },
            flightDate2: {
               min: moment().subtract(1, "year").format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().format(this.$constants.dateFormat),
            },
            hotelDate1: {
               min: moment().subtract(1, "year").format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().format(this.$constants.dateFormat),
            },
            hotelDate2: {
               min: moment().subtract(1, "year").format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().format(this.$constants.dateFormat),
            },
            voucherStatus: -1,
            // hotelProviderId: -1,
            hotelSurname: null,
            transferDate1: {
               min: moment().subtract(2, "years").format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().format(this.$constants.dateFormat),
            },
            transferDate2: {
               min: moment().subtract(2, "years").format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().format(this.$constants.dateFormat),
            },
            transferSurname: null,
            tnrStatus: -1,
            tnr: null,
            transferOrderId: -1,
            transferDateType: 1,
            transferProviderId: -1,
            transferFromLocation: -1,
            transferToLocation: -1,
            rentACarOrderId: null,
            rentACarSurname: null,
            rentACarDateType: 1,
            rnrStatus: -1,
            rentACarProviderId: -1,
            rentACarPickUpLocationId: -1,
            rentACarDropOffLocationId: -1,
            rnr: null,
            rentACarDate1: {
               min: moment().subtract(2, "years").format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().format(this.$constants.dateFormat),
            },
            rentACarDate2: {
               min: moment().subtract(2, "years").format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().format(this.$constants.dateFormat),
            },
         },
         product: 1,
         subProduct: 2,
         minDate: moment().subtract(1, "year").format(this.$constants.dateFormat),
         maxDate: moment().format(this.$constants.dateFormat),
         dateTypes: this.$GtsDropdownData.EOdrSearchHotelDateType(),
         transferDateTypes: this.$GtsDropdownData.EReportTransferDateType(),
         rentACarDateTypes: this.$GtsDropdownData.EOdrSearchRentACarDateType(),
         pnrStatuses: this.$GtsDropdownData.EFltPnrStatus(),
         voucherStatuses: this.$GtsDropdownData.EHtlVoucherStatus(),
         tnrStatuses: this.$GtsDropdownData.ETrfTnrStatus(),
         rnrStatuses: this.$GtsDropdownData.ERacRnrStatus(),
         flightProviders: [],
         // hotelProviders: [],
         transferProviders: [],
         // rentACarProviders: [],
         tableOptions: {
            columns: [
               { label: this.$i18n.t(`orderSearch_tblMain_orderId`), field: "orderId" },
               { label: this.$i18n.t(`orderSearch_tblMain_gsaName`), field: "gsaName" },
               { label: this.$i18n.t(`orderSearch_tblMain_agencyName`), field: "agencyName" },
               { label: this.$i18n.t(`orderSearch_tblMain_pnrStatuses`), field: "pnrStatuses" },
               { label: this.$i18n.t(`orderSearch_tblMain_pnrs`), field: "pnrs" },
               { label: this.$i18n.t(`orderSearch_tblMain_etickets`), field: "etickets" },
               { label: this.$i18n.t(`orderSearch_tblMain_nameSurname`), field: "nameSurname" },
               { label: this.$i18n.t(`orderSearch_tblMain_insertedUser`), field: "insertedUser" },
               { label: this.$i18n.t(`orderSearch_tblMain_insertedDate`), field: "insertedDate" },
            ],
            rows: [],
         },
         hotelTableOptions: {
            columns: [
               { label: this.$i18n.t(`orderSearch_tblMain_orderId`), field: "orderId" },
               { label: this.$i18n.t(`orderSearch_tblMain_gsaName`), field: "gsaName" },
               { label: this.$i18n.t(`orderSearch_tblMain_agencyName`), field: "agencyName" },
               { label: this.$i18n.t(`orderSearch_tblMain_voucherStatuses`), field: "voucherStatuses" },
               { label: this.$i18n.t(`orderSearch_tblMain_vouchers`), field: "vouchers" },
               { label: this.$i18n.t(`orderSearch_tblMain_rooms`), field: "rooms" },
               { label: this.$i18n.t(`orderSearch_tblMain_nameSurname`), field: "nameSurname" },
               { label: this.$i18n.t(`orderSearch_tblMain_insertedUser`), field: "insertedUser" },
               { label: this.$i18n.t(`orderSearch_tblMain_insertedDate`), field: "insertedDate" },
            ],
            rows: [],
         },
         transferTableOptions: {
            columns: [
               { label: this.$i18n.t(`orderSearch_tblMain_orderId`), field: "orderId" },
               { label: this.$i18n.t(`orderSearch_tblMain_gsaName`), field: "gsaName" },
               { label: this.$i18n.t(`orderSearch_tblMain_agencyName`), field: "agencyName" },
               { label: this.$i18n.t(`orderSearch_tblMain_tnrStatuses`), field: "tnrStatuses" },
               { label: this.$i18n.t(`orderSearch_tblMain_tnrs`), field: "tnrs" },
               { label: this.$i18n.t(`orderSearch_tblMain_nameSurname`), field: "nameSurname" },
               { label: this.$i18n.t(`orderSearch_tblMain_insertedUser`), field: "insertedUser" },
               { label: this.$i18n.t(`orderSearch_tblMain_insertedDate`), field: "insertedDate" },
            ],
            rows: [],
         },
         rentACarTableOptions: {
            columns: [
               { label: this.$i18n.t(`orderSearch_tblMain_orderId`), field: "orderId" },
               { label: this.$i18n.t(`orderSearch_tblMain_gsaName`), field: "gsaName" },
               { label: this.$i18n.t(`orderSearch_tblMain_agencyName`), field: "agencyName" },
               { label: this.$i18n.t(`orderSearch_tblMain_vehicle`), field: "vehicle" },
               { label: this.$i18n.t(`orderSearch_tblMain_pickUpDateTime`), field: "pickUpDateTime" },
               { label: this.$i18n.t(`orderSearch_tblMain_rnrStatus`), field: "rnrStatus" },
               { label: this.$i18n.t(`orderSearch_tblMain_rnr`), field: "rnr" },
               { label: this.$i18n.t(`orderSearch_tblMain_pickUpLocation`), field: "pickUpLocation" },
               { label: this.$i18n.t(`orderSearch_tblMain_dropOffLocation`), field: "dropOffLocation" },
               { label: this.$i18n.t(`orderSearch_tblMain_nameSurname`), field: "nameSurname" },
               { label: this.$i18n.t(`orderSearch_tblMain_insertedUser`), field: "insertedUser" },
               { label: this.$i18n.t(`orderSearch_tblMain_insertedDate`), field: "insertedDate" },
            ],
            rows: [],
         },
         componentReRender: 0,
         grandTotal: "",
      };
   },
   computed: {
      DatePicker: {
         get() {
            if (this.input.length > 2) {
               return moment(this.input, "DD.MM.YYYY")._d;
            } else {
               return moment()._d;
            }
         },
         set(val) {
            this.input = moment(val).format("DD.MM.YYYY");
         },
      },
   },
   created() {
      const self = this;
      agencyService.listProviders({ type: 1 }).then((res) => {
         res.providers.map((provider) => {
            const rowObj = {
               value: provider.id,
               name: provider.name,
            };
            self.flightProviders.push(rowObj);
         });
         self.flightProviders.unshift({ value: -1, name: self.$options.filters.EFltPnrStatus(-1) });
      });
      agencyService.listProviders({ type: 3 }).then((res) => {
         res.providers.map((provider) => {
            const rowObj = {
               value: provider.id,
               name: provider.name,
            };
            self.transferProviders.push(rowObj);
         });
         self.transferProviders.unshift({ value: -1, name: self.$options.filters.ETrfTnrStatus(-1) });
      });
      // agencyService.listProviders({ type: 4 }).then((res) => {
      //    res.providers.map((provider) => {
      //       const rowObj = {
      //          value: provider.id,
      //          name: provider.name,
      //       };
      //       self.rentACarProviders.push(rowObj);
      //    });
      //    self.rentACarProviders.unshift({ value: -1, name: self.$options.filters.ERacRnrStatus(-1) });
      // });
   },

   methods: {
      updateTransferProviderList() {
         this.transferProviders = [];
         agencyService.listProviders({ type: 3 }).then((res) => {
            res.providers.map((provider) => {
               const rowObj = {
                  value: provider.id,
                  name: provider.name,
               };
               this.transferProviders.push(rowObj);
            });
            this.transferProviders.unshift({ value: -1, name: this.$options.filters.ETrfTnrStatus(-1) });
         });
         this.refreshKeyZero++;
         this.$nextTick(() => {
            this.transferProviders = this.transferProviders.slice();
         });
      },
      updateFlightProviderList() {
         this.flightProviders = [];
         agencyService.listProviders({ type: 1 }).then((res) => {
            res.providers.map((provider) => {
               const rowObj = {
                  value: provider.id,
                  name: provider.name,
               };
               this.flightProviders.push(rowObj);
            });
            this.flightProviders.unshift({ value: -1, name: this.$options.filters.EFltPnrStatus(-1) });
         });
         this.refreshKeyZero++;
         this.$nextTick(() => {
            this.flightProviders = this.flightProviders.slice();
         });
      },
      funcRefreshKey() {
         this.refreshKeyZero++;
      },
      UpdatetableOptions() {
         return [
            { label: this.$i18n.t(`orderSearch_tblMain_orderId`), field: "orderId" },
            { label: this.$i18n.t(`orderSearch_tblMain_gsaName`), field: "gsaName" },
            { label: this.$i18n.t(`orderSearch_tblMain_agencyName`), field: "agencyName" },
            { label: this.$i18n.t(`orderSearch_tblMain_pnrStatuses`), field: "pnrStatuses" },
            { label: this.$i18n.t(`orderSearch_tblMain_pnrs`), field: "pnrs" },
            { label: this.$i18n.t(`orderSearch_tblMain_etickets`), field: "etickets" },
            { label: this.$i18n.t(`orderSearch_tblMain_nameSurname`), field: "nameSurname" },
            { label: this.$i18n.t(`orderSearch_tblMain_insertedUser`), field: "insertedUser" },
            { label: this.$i18n.t(`orderSearch_tblMain_insertedDate`), field: "insertedDate" },
         ];
      },
      updateHotelTableOptions() {
         return [
            { label: this.$i18n.t(`orderSearch_tblMain_orderId`), field: "orderId" },
            { label: this.$i18n.t(`orderSearch_tblMain_gsaName`), field: "gsaName" },
            { label: this.$i18n.t(`orderSearch_tblMain_agencyName`), field: "agencyName" },
            { label: this.$i18n.t(`orderSearch_tblMain_voucherStatuses`), field: "voucherStatuses" },
            { label: this.$i18n.t(`orderSearch_tblMain_vouchers`), field: "vouchers" },
            { label: this.$i18n.t(`orderSearch_tblMain_rooms`), field: "rooms" },
            { label: this.$i18n.t(`orderSearch_tblMain_nameSurname`), field: "nameSurname" },
            { label: this.$i18n.t(`orderSearch_tblMain_insertedUser`), field: "insertedUser" },
            { label: this.$i18n.t(`orderSearch_tblMain_insertedDate`), field: "insertedDate" },
         ];
      },
      updateTransferTableOptions() {
         return [
            { label: this.$i18n.t(`orderSearch_tblMain_orderId`), field: "orderId" },
            { label: this.$i18n.t(`orderSearch_tblMain_gsaName`), field: "gsaName" },
            { label: this.$i18n.t(`orderSearch_tblMain_agencyName`), field: "agencyName" },
            { label: this.$i18n.t(`orderSearch_tblMain_tnrStatuses`), field: "tnrStatuses" },
            { label: this.$i18n.t(`orderSearch_tblMain_tnrs`), field: "tnrs" },
            { label: this.$i18n.t(`orderSearch_tblMain_nameSurname`), field: "nameSurname" },
            { label: this.$i18n.t(`orderSearch_tblMain_insertedUser`), field: "insertedUser" },
            { label: this.$i18n.t(`orderSearch_tblMain_insertedDate`), field: "insertedDate" },
         ];
      },
      updateRentACarTableOptions() {
         return [
            { label: this.$i18n.t(`orderSearch_tblMain_orderId`), field: "orderId" },
            { label: this.$i18n.t(`orderSearch_tblMain_gsaName`), field: "gsaName" },
            { label: this.$i18n.t(`orderSearch_tblMain_agencyName`), field: "agencyName" },
            { label: this.$i18n.t(`orderSearch_tblMain_vehicle`), field: "vehicle" },
            { label: this.$i18n.t(`orderSearch_tblMain_pickUpDateTime`), field: "pickUpDateTime" },
            { label: this.$i18n.t(`orderSearch_tblMain_rnrStatus`), field: "rnrStatus" },
            { label: this.$i18n.t(`orderSearch_tblMain_rnr`), field: "rnr" },
            { label: this.$i18n.t(`orderSearch_tblMain_pickUpLocation`), field: "pickUpLocation" },
            { label: this.$i18n.t(`orderSearch_tblMain_dropOffLocation`), field: "dropOffLocation" },
            { label: this.$i18n.t(`orderSearch_tblMain_nameSurname`), field: "nameSurname" },
            { label: this.$i18n.t(`orderSearch_tblMain_insertedUser`), field: "insertedUser" },
            { label: this.$i18n.t(`orderSearch_tblMain_insertedDate`), field: "insertedDate" },
         ];
      },
      updateTableColumns() {
         this.tableOptions.columns = this.UpdatetableOptions();
         this.hotelTableOptions.columns = this.updateHotelTableOptions();
         this.transferTableOptions.columns = this.updateTransferTableOptions();
         this.rentACarTableOptions.columns = this.updateRentACarTableOptions();
      },
      handlerCancel() {
         // const cancelToken = axios.CancelToken;
      },
      handlerSelectionChange() {
         // console.log("selection");
      },
      Formatter(d) {
         return moment(d).format("DD.MM.YYYY");
      },
      handlerSearchOrder() {
         const self = this;
         //FLIGHT SEARCH WITH LASTNAME
         if (self.product == 1 && self.subProduct == 4) {
            this.$validate().then((res) => {
               if (res.success) {
                  const query = {};
                  this.tableOptions.rows = [];
                  this.hotelTableOptions.rows = [];
                  this.transferTableOptions.rows = [];
                  this.rentACarTableOptions.rows = [];
                  if (self.searchQuery.flightSurname?.length == 0) {
                     query.date1 = moment().format(this.$constants.dateFormat);
                     query.date2 = moment().format(this.$constants.dateFormat);
                     query.pnrStatus = self.searchQuery.pnrStatus;
                     query.providerId = self.searchQuery.flightProviderId;
                     query.surname = null;
                     this.searchQuery.flightDate1.value = moment().format(this.$constants.dateFormat);
                     this.searchQuery.flightDate2.value = moment().format(this.$constants.dateFormat);
                  } else if (self.searchQuery.flightSurname?.length >= 2) {
                     query.date1 = self.searchQuery.flightDate1.value;
                     query.date2 = self.searchQuery.flightDate2.value;
                     query.pnrStatus = self.searchQuery.pnrStatus;
                     query.providerId = self.searchQuery.flightProviderId;
                     query.surname = self.searchQuery.flightSurname;
                  } else {
                     Popup.warning(this.$i18n.t("Popup_lblLessThanTwoCharacter"), this.$i18n.t("Popup_txtLessThanTwoCharacter"));
                  }

                  self.$store.commit("app/setIsProgressGifActive", true);
                  orderService.searchOrderFlight(query).then((res) => {
                     if (res.result.success) {
                        res.orders.map((orderItem) => {
                           const orderRowObj = {
                              order: {
                                 iteration: [orderItem.orderId],
                                 className: "order-router gts-animation-scale",
                                 router: `/order/detail/${orderItem.orderUuid}`,
                              },
                              gsaName: orderItem.gsaName,
                              agencyName: orderItem.agencyName,
                              pnrStatuses: {
                                 iteration: orderItem.pnrStatuses,
                                 className: "badge",
                                 filter: "EFltPnrStatus",
                              },
                              pnrs: {
                                 iteration: orderItem.pnrs,
                              },
                              etickets: {
                                 iteration: orderItem.etickets,
                              },
                              nameSurname: orderItem.nameSurname,
                              insertedUser: orderItem.insertedUserEmail,
                              insertedDate: moment(orderItem.insertedDateTime).format("DD.MM.YYYY"),
                              tags: JSON.stringify(orderItem),
                           };

                           this.tableOptions.rows.push(orderRowObj);
                        });
                        this.tableOptions.rows.reverse();
                        self.$store.commit("app/setIsProgressGifActive", false);
                     }
                  });
               } else {
                  console.log("Error", res.isInputsValid);
               }
            });
         } else {
            this.$validate().then((res) => {
               if (res.success) {
                  const query = {};
                  this.tableOptions.rows = [];
                  this.hotelTableOptions.rows = [];
                  this.transferTableOptions.rows = [];
                  this.rentACarTableOptions.rows = [];

                  self.$store.commit("app/setIsProgressGifActive", true);
                  if (self.product == 1) {
                     if (self.subProduct == 1) {
                        query.orderId = self.searchQuery.flightOrderId;
                     } else if (self.subProduct == 2) {
                        query.pnr = self.searchQuery.pnr == "" ? null : self.searchQuery.pnr;
                     } else if (self.subProduct == 3) {
                        query.eticket = self.searchQuery.eticket == "" ? null : self.searchQuery.eticket;
                     }

                     // FLIGHT SEARCH
                     orderService.searchOrderFlight(query).then((res) => {
                        if (res.result.success) {
                           res.orders.map((orderItem) => {
                              const orderRowObj = {
                                 order: {
                                    iteration: [orderItem.orderId],
                                    className: "order-router gts-animation-scale",
                                    router: `/order/detail/${orderItem.orderUuid}`,
                                 },
                                 gsaName: orderItem.gsaName,
                                 agencyName: orderItem.agencyName,
                                 pnrStatuses: {
                                    iteration: orderItem.pnrStatuses,
                                    className: "badge",
                                    filter: "EFltPnrStatus",
                                 },
                                 pnrs: {
                                    iteration: orderItem.pnrs,
                                 },
                                 etickets: {
                                    iteration: orderItem.etickets,
                                 },
                                 nameSurname: orderItem.nameSurname,
                                 insertedUser: orderItem.insertedUserEmail,
                                 insertedDate: moment(orderItem.insertedDateTime).format("DD.MM.YYYY"),
                                 tags: JSON.stringify(orderItem),
                              };

                              this.tableOptions.rows.push(orderRowObj);
                           });
                           this.tableOptions.rows.reverse();
                           self.$store.commit("app/setIsProgressGifActive", false);
                        }
                     });
                  } else if (self.product == 2) {
                     // IS HOTEL SEARCH
                     if (self.subProduct == 1) {
                        query.orderId = self.searchQuery.hotelOrderId;
                     } else if (self.subProduct == 5) {
                        query.voucher = self.searchQuery.voucher == "" ? null : self.searchQuery.voucher;
                     } else {
                        query.dateType = self.searchQuery.dateType;
                        query.date1 = self.searchQuery.hotelDate1.value;
                        query.date2 = self.searchQuery.hotelDate2.value;
                        query.voucherStatus = self.searchQuery.voucherStatus;
                        query.providerId = -1; //Provider filter disabled
                        // query.providerId = self.searchQuery.hotelProviderId;
                        query.surname = self.searchQuery.hotelSurname == "" ? null : self.searchQuery.hotelSurname;
                     }

                     query.hotelId = -1;

                     // HOTEL SEARCH
                     orderService.searchOrderHotel(query).then((res) => {
                        if (res.result.success) {
                           res.orders.map((orderItem) => {
                              const orderRowObj = {
                                 order: {
                                    iteration: [orderItem.orderId],
                                    className: "order-router gts-animation-scale",
                                    router: `/order/detail/${orderItem.orderUuid}`,
                                 },
                                 gsaName: orderItem.gsaName,
                                 agencyName: orderItem.agencyName,
                                 voucherStatuses: {
                                    iteration: orderItem.voucherStatuses,
                                    className: "badge",
                                    filter: "EHtlVoucherStatus",
                                 },
                                 vouchers: {
                                    iteration: orderItem.vouchers,
                                 },
                                 rooms: {
                                    iteration: orderItem.rooms,
                                 },
                                 nameSurname: orderItem.nameSurname,
                                 insertedUser: orderItem.insertedUserEmail,
                                 insertedDate: moment(orderItem.insertedDateTime).format("DD.MM.YYYY"),
                                 tags: JSON.stringify(orderItem),
                              };

                              this.hotelTableOptions.rows.reverse();
                              this.hotelTableOptions.rows.push(orderRowObj);
                           });
                           self.$store.commit("app/setIsProgressGifActive", false);
                        }
                     });
                  } else if (self.product == 3) {
                     // IS TRANSFER SEARCH
                     if (self.subProduct == 1) {
                        query.orderId = self.searchQuery.transferOrderId;
                     } else if (self.subProduct == 5) {
                        query.tnr = self.searchQuery.tnr == "" ? null : self.searchQuery.tnr;
                     } else {
                        query.dateType = self.searchQuery.transferDateType;
                        query.date1 = self.searchQuery.transferDate1.value;
                        query.date2 = self.searchQuery.transferDate2.value;
                        query.tnrStatus = self.searchQuery.tnrStatus;
                        query.providerId = self.searchQuery.transferProviderId;
                        query.fromLocationId = self.searchQuery.transferFromLocation;
                        query.toLocationId = self.searchQuery.transferToLocation;
                        query.surname = self.searchQuery.transferSurname == "" ? null : self.searchQuery.transferSurname;
                        query.orderId = -1;
                        query.tnr = null;
                     }

                     // TRANSFER SEARCH
                     orderService.searchOrderTransfer(query).then((res) => {
                        if (res.result.success) {
                           res.orders.map((orderItem) => {
                              const orderRowObj = {
                                 order: {
                                    iteration: [orderItem.orderId],
                                    className: "order-router gts-animation-scale",
                                    router: `/order/detail/${orderItem.orderUuid}`,
                                 },
                                 gsaName: orderItem.gsaName,
                                 agencyName: orderItem.agencyName,
                                 tnrStatuses: {
                                    iteration: orderItem.tnrStatuses,
                                    className: "badge",
                                    filter: "ETrfTnrStatus",
                                 },
                                 tnrs: {
                                    iteration: orderItem.tnrs,
                                 },
                                 nameSurname: orderItem.nameSurname,
                                 insertedUser: orderItem.processUserEmail,
                                 insertedDate: moment(orderItem.processDateTime).format("DD.MM.YYYY"),
                                 tags: JSON.stringify(orderItem),
                              };

                              this.transferTableOptions.rows.reverse();
                              this.transferTableOptions.rows.push(orderRowObj);
                           });
                           self.$store.commit("app/setIsProgressGifActive", false);
                        }
                     });
                  } else if (self.product == 4) {
                     // IS RENT A CAR SEARCH
                     if (self.subProduct == 1) {
                        query.orderId = self.searchQuery.rentACarOrderId;
                     } else if (self.subProduct == 5) {
                        query.rnr = self.searchQuery.rnr == "" ? null : self.searchQuery.rnr;
                     } else {
                        query.dateType = self.searchQuery.rentACarDateType;
                        query.date1 = self.searchQuery.rentACarDate1.value;
                        query.date2 = self.searchQuery.rentACarDate2.value;
                        query.rnrStatus = self.searchQuery.rnrStatus;
                        query.providerId = self.searchQuery.rentACarProviderId;
                        query.pickUpLocationId = self.searchQuery.rentACarPickUpLocationId;
                        query.dropOffLocationId = self.searchQuery.rentACarDropOffLocationId;
                        query.surname = self.searchQuery.rentACarSurname == "" ? null : self.searchQuery.rentACarSurname;
                        query.orderId = -1;
                        query.rnr = null;
                     }

                     // RENT A CAR SEARCH
                     orderService.searchOrderRentACar(query).then((res) => {
                        if (res.result.success) {
                           res.orders.map((orderItem) => {
                              const orderRowObj = {
                                 order: {
                                    iteration: [orderItem.orderId],
                                    className: "order-router gts-animation-scale",
                                    router: `/order/detail/${orderItem.orderUuid}`,
                                 },
                                 gsaName: orderItem.gsaName,
                                 agencyName: orderItem.agencyName,
                                 vehicle: orderItem.vehicle,
                                 pickupDateTime: moment(orderItem.pickupDateTime).format("DD.MM.YYYY"),
                                 rnrStatus: {
                                    iteration: [orderItem.rnrStatus],
                                    className: "badge",
                                    filter: "ERacRnrStatus",
                                 },
                                 rnr: {
                                    iteration: [orderItem.rnr],
                                 },
                                 pickUpLocation: orderItem.pickUpLocation,
                                 dropOffLocation: orderItem.dropOffLocation,
                                 nameSurname: orderItem.nameSurname,
                                 insertedUser: orderItem.processUserEmail,
                                 insertedDate: moment(orderItem.processDateTime).format("DD.MM.YYYY"),
                                 tags: JSON.stringify(orderItem),
                              };

                              this.rentACarTableOptions.rows.reverse();
                              this.rentACarTableOptions.rows.push(orderRowObj);
                           });
                           self.$store.commit("app/setIsProgressGifActive", false);
                        }
                     });
                  }
               } else {
                  console.log("Error", res.isInputsValid);
               }
            });
         }
         this.isFirstSearch = false;
      },
   },
   watch: {
      "$i18n.locale"() {
         this.pnrStatuses = this.$GtsDropdownData.EFltPnrStatus();
         this.dateTypes = this.$GtsDropdownData.EOdrSearchHotelDateType();
         this.voucherStatuses = this.$GtsDropdownData.EHtlVoucherStatus();
         this.tnrStatuses = this.$GtsDropdownData.ETrfTnrStatus();
         this.rnrStatuses = this.$GtsDropdownData.ERacRnrStatus();
         this.transferDateTypes = this.$GtsDropdownData.EReportTransferDateType();
         this.rentACarDateTypes = this.$GtsDropdownData.EOdrSearchRentACarDateType();
         this.updateFlightProviderList();
         this.updateTransferProviderList();
         if (this.isFirstSearch == false) {
            this.handlerSearchOrder();
         }
         this.updateTableColumns();
         this.funcRefreshKey();
      },
      product: {
         handler: function (productType) {
            if (productType == "1") {
               this.subProduct = 2;
            } else if (productType == "2") {
               this.subProduct = 5;
            } else if (productType == "3") {
               this.subProduct = 5;
            } else if (productType == "4") {
               this.subProduct = 5;
            }
         },
      },
      "searchQuery.dateType": {
         handler: function (dateType) {
            if (dateType == 1) {
               this.searchQuery.hotelDate1.max = moment().format(this.$constants.dateFormat);
               this.searchQuery.hotelDate2.max = moment().format(this.$constants.dateFormat);
            } else if (dateType == 2) {
               this.searchQuery.hotelDate1.max = moment().add(2, "years").format(this.$constants.dateFormat);
               this.searchQuery.hotelDate2.max = moment().add(2, "years").format(this.$constants.dateFormat);
            }
         },
      },
      "searchQuery.transferDateType": {
         handler: function (transferDateType) {
            if (transferDateType == 1) {
               //SALE
               this.searchQuery.transferDate1.min = moment().subtract(2, "years").format(this.$constants.dateFormat);
               this.searchQuery.transferDate1.max = moment().format(this.$constants.dateFormat);
               this.searchQuery.transferDate2.min = moment().subtract(2, "years").format(this.$constants.dateFormat);
               this.searchQuery.transferDate2.max = moment().format(this.$constants.dateFormat);
            } else if (transferDateType == 2) {
               //TRANSFER
               this.searchQuery.transferDate1.min = moment().subtract(2, "years").format(this.$constants.dateFormat);
               this.searchQuery.transferDate1.max = moment().add(2, "years").format(this.$constants.dateFormat);
               this.searchQuery.transferDate2.min = moment().subtract(2, "years").format(this.$constants.dateFormat);
               this.searchQuery.transferDate2.max = moment().add(2, "years").format(this.$constants.dateFormat);
            }
         },
      },
      "searchQuery.rentACarDateType": {
         handler: function (rentACarDateType) {
            if (rentACarDateType == 1) {
               //PROCESS
               this.searchQuery.rentACarDate1.min = moment().subtract(1, "years").format(this.$constants.dateFormat);
               this.searchQuery.rentACarDate1.max = moment().format(this.$constants.dateFormat);
               this.searchQuery.rentACarDate2.min = moment().subtract(1, "years").format(this.$constants.dateFormat);
               this.searchQuery.rentACarDate2.max = moment().format(this.$constants.dateFormat);
            } else if (rentACarDateType == 2) {
               //PICK UP
               this.searchQuery.rentACarDate1.min = moment().subtract(1, "years").format(this.$constants.dateFormat);
               this.searchQuery.rentACarDate1.max = moment().add(1, "years").format(this.$constants.dateFormat);
               this.searchQuery.rentACarDate2.min = moment().subtract(1, "years").format(this.$constants.dateFormat);
               this.searchQuery.rentACarDate2.max = moment().add(1, "years").format(this.$constants.dateFormat);
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.order-page-wrapper {
   .order-page-inner {
      // width: 90%;
      margin: auto;

      .page-subheader-navigate-wrapper {
         .page-subheader-navigate-inner {
            .ps-product-subtype-forms {
               display: grid;
               grid-template-columns: repeat(5, 2fr);
               grid-column-gap: 1.5rem;
            }

            .ps-action-wrapper {
               display: flex;
               justify-content: flex-end;
               margin-top: 1.5rem;
            }
         }
      }

      ::v-deep {
         .vgt-left-align {
            text-align: left !important;
         }
         .dataTable-order-search-multi-row {
            display: flex;
            flex-direction: column;
            span {
               margin-bottom: 0.5rem;

               &:last-child {
                  margin: 0;
               }
            }
         }
      }
   }
}

.last-name-search-wrapper {
   margin-top: 1rem;
}

.ps-form-row {
   display: grid;
   grid-template-columns: repeat(4, 150px);
   margin-bottom: 1rem;

   > div {
      margin-right: 1rem;
   }
}
</style>
