<template>
   <div class="dynamic-segments-container">
      <div class="dynamic-segments-header" v-if="false">{{ Route }} - {{ FlightNumber }}</div>
      <!-- {{ nameAbbrForMouse }} -->

      <AncillarySeatMapPassengerWrapper :seat-list="SeatList" :segmentIndex="segment.index" :flightKey="segment.flightKey" :segmentId="segment.id" />

      <div class="dynamic-segments-body-wrapper">
         <!-- <button @click="handlerYanyana">ayarla</button> -->
         <ul class="seat-map-generator-informations-wrapper">
            <li>
               <span class="indicator selected"></span>
               <span class="information">{{ $t("ancillarySeatMap_lblSelected") }}</span>
            </li>
            <li>
               <span class="indicator available"></span>
               <span class="information">{{ $t("ancillarySeatMap_lblAvailable") }}</span>
            </li>
            <li>
               <span class="indicator not-available"></span>
               <span class="information">{{ $t("ancillarySeatMap_lblNotAvailable") }}</span>
            </li>
         </ul>
         <SeatMapGenerator :map="segment.rows" />
      </div>
   </div>
</template>

<script>
// import { flightService } from "@/service/index.js";
import AncillarySeatMapPassengerWrapper from "@/components/structural/flight/order-detail/ancillaries/seatmap2/AncillarySeatMapPassengerWrapper.vue";
import SeatMapGenerator from "@/components/structural/flight/order-detail/ancillaries/seatmap2/SeatMapGenerator.vue";
export default {
   name: "DynamicSegments",
   props: {
      step: Number,
      segment: Object,
   },
   components: {
      AncillarySeatMapPassengerWrapper,
      SeatMapGenerator,
   },
   created() {
      this.$store.dispatch("seatmap/selectFirstPax", this.segment);
   },
   beforeDestroy() {
      const self = this;
      // const segmentIndex = self.segment.index;
      const paxes = self.segment.paxes;

      function areAllSeatsAssigned(passengers) {
         for (let key in passengers) {
            if (passengers[key].seat === null) {
               return false;
            }
         }
         return true;
      }

      // function commitSeatAssignments(passengers) {
      //    Object.values(passengers).forEach((passenger) => {
      //       if (passenger.seat !== null) {
      //          console.log(passenger.seat);
      //          self.$store.commit("seatmap/setSegmentsPaxesSeats", {
      //             identifier: segmentIndex,
      //             rowObj: {
      //                [passenger.paxIndex]: passenger.identifier,
      //             },
      //          });
      //       }
      //    });
      // }
      // commitSeatAssignments(paxes);

      if (areAllSeatsAssigned(paxes)) {
         self.$store.commit("seatmap/setStep", { index: self.step, status: true });
      } else {
         self.$store.commit("seatmap/setStep", { index: self.step, status: false });
      }
   },

   computed: {
      SeatList() {
         return this.segment.paxes;
      },

      Route() {
         return `${this.segment.departureAirport} - ${this.segment.arrivalAirport}`;
      },

      FlightNumber() {
         return this.segment.flightNumber;
      },

      nameAbbrForMouse() {
         const nameAbbr = this.$store.state.seatmap.selectedPax.nameAbbr;
         const showAbbr = nameAbbr.length != 0 ? true : false;
         return {
            nameAbbr,
            showAbbr,
         };
      },
   },
   methods: {
      handlerYanyana() {
         console.log("handlerYanyana");
         this.$store.dispatch("seatmap/selectYanyana", this.segment);
      },
   },
};
</script>

<style lang="scss" scoped>
.dynamic-segments-container {
   display: flex;

   ::v-deep {
      .ancillary-seatmap-passenger-wrapper-container {
         margin-right: 2rem;
         padding-right: 1rem;
         border-right: 8px solid #f4f6fa;
      }
   }

   .dynamic-segments-body-wrapper {
      // border: 1px solid red;
      width: 100%;

      .seat-map-generator-informations-wrapper {
         display: flex;
         border-bottom: 2px solid #eee;
         margin-bottom: 1rem;
         padding-bottom: 0.5rem;
         li {
            display: flex;
            align-items: center;
            margin-right: 2rem;

            .indicator {
               display: flex;
               justify-content: center;
               align-items: center;
               border-radius: 4px;
               width: 20px;
               height: 20px;
               margin-right: 0.5rem;

               &.selected {
                  background: var(--primary);
               }
               &.available {
                  background: #c5faca;
               }
               &.not-available {
                  background: #ebebeb;
                  &::after {
                     content: "x";
                     color: #b5b5b5;
                  }
               }
            }
            .information {
               font-size: 0.85rem;
            }
         }
      }
   }
}
</style>
