<template>
   <div id="rent-a-car-ancillaries-container">
      <ul class="details-tabs" v-if="showTabs">
         <li class="active-tab">{{ $t("rentacar_lblAncillaries") }}</li>
         <!-- <li>{{ $t("rentacar_lblTermsOfUse") }}</li> -->
      </ul>
      <ul class="ancillaries-tabs-wrapper">
         <li v-for="(ancillary, index) in ancillariesArr" :key="index">
            <div class="ancillary-tab-wrapper" :class="ancillary.selected || ancillary.count != 0 ? 'selected' : ''">
               <div class="ancillary-icon">
                  <i class="gts-icon icon-lg" :class="'icon-rentacar-' + ancillary.icon"></i>
               </div>
               <div class="ancillary-informations">
                  <template>
                     <div class="ancillary-tooltip" v-if="ancillary.type == 6">{{ ancillary.tooltip }}</div>
                  </template>
                  <span class="ancillary-name"> {{ ancillary.name }}</span>

                  <span class="ancillary-price">
                     {{ ancillary.pricePerDay | price }}
                     <template v-if="ancillary.type != 51"> / {{ $t("rentacar_lblPerDay") }} </template>
                  </span>
               </div>
               <div class="ancillay-actions">
                  <template v-if="IsAugmentable(ancillary.type)">
                     <div class="ancillary-counter">
                        <button
                           :class="ancillary.count > 0 ? '' : 'invisible'"
                           @click="ancillary.count > 0 ? handlerSelectAncillary(ancillary.count, index, ancillary.type, 'dec') : ''">
                           -
                        </button>
                        <span> {{ ancillary.count }}</span>
                        <button
                           :class="passengerCapacity - 3 > ancillary.count ? '' : 'invisible'"
                           @click="
                              passengerCapacity - 3 > ancillary.count ? handlerSelectAncillary(ancillary.count, index, ancillary.type, 'inc') : ''
                           ">
                           +
                        </button>
                     </div>
                  </template>
                  <template v-else>
                     <div
                        @click="ancillary.type != 51 ? handlerSelectAncillary(ancillary.count, index) : undefined"
                        class="ancillary-add"
                        :class="ancillary.count > 0 ? 'selected' : ''">
                        +
                     </div>
                  </template>
               </div>
            </div>
         </li>
      </ul>
   </div>
</template>

<script>
import { ancillaryConversion, ancillaryRequestModelGenerator } from "@/helper/rentacar.js";
export default {
   name: "RentACarAncillaries",
   props: {
      ancillaries: Object,
      type: {
         type: String,
         default: "Search",
      },
      showTabs: {
         type: Boolean,
         default: false,
      },
      passengerCapacity: Number,
   },
   data() {
      return {
         ancillariesArr: [],
         request: {},
      };
   },

   methods: {
      IsAugmentable(type) {
         return type == 1 ? true : false;
      },
      handlerSelectAncillary(count, index, isAugmentable, action) {
         if (this.IsAugmentable(isAugmentable)) {
            if (action == "inc" && this.passengerCapacity - 3 > count) {
               this.ancillariesArr[index].count++;
            } else if (action == "dec" && count != 0) {
               this.ancillariesArr[index].count--;
            }
         } else {
            if (count == 0) {
               this.ancillariesArr[index].count++;
            } else {
               this.ancillariesArr[index].count--;
            }
         }
         this.handlerGenerateAncillaryRequestModel();
      },
      handlerGenerateAncillaryRequestModel() {
         const self = this;
         this.request = ancillaryRequestModelGenerator(self, this.ancillariesArr);
         this.$emit("requestModel", this.request);
      },
   },
   watch: {
      ancillaries: {
         handler: function () {
            const self = this;
            this.ancillariesArr = ancillaryConversion(self, this.ancillaries);
         },
         immediate: true,
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
#rent-a-car-ancillaries-container {
   // background: #fff;
   padding: 1rem;

   @media (max-width: 576px) {
      padding: 1rem 0;
   }

   ul.details-tabs {
      display: flex;
      width: 100%;
      justify-content: center;
      border-bottom: 4px solid #f0f0f0;
      margin-bottom: 1rem;

      li {
         font-weight: 500;
         margin: 0 0.5rem;
         font-size: 1.125rem;
         padding-bottom: 0.25rem;

         &.active-tab {
            color: #36d6a1;
            font-weight: 600;
            position: relative;

            &::after {
               content: "";
               position: absolute;
               top: 100%;
               left: -5px;
               right: -5px;
               background-color: #37d6a1;
               height: 4px;
               border-radius: 24rem;
            }
         }
      }
   }

   ul.ancillaries-tabs-wrapper {
      display: flex;
      flex-wrap: wrap;
      li {
         flex-basis: 50%;
         padding: 1rem;

         @media (max-width: 576px) {
            flex-basis: 100%;
            padding: 0.5rem 1rem;
         }

         .ancillary-tab-wrapper {
            background: #f5f7fa;
            border: 2px solid #dfe3e6;
            border-radius: 0.5rem;
            padding: 0.25rem 1rem;
            height: 72px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &.selected {
               background-color: #e6faf4;
               border: 2px solid #36d6a1;
            }

            .ancillary-icon {
               border-right: 2px solid #ccc;
               padding-right: 1rem;
               margin-right: 1rem;
            }
            .ancillary-informations {
               display: flex;
               flex-direction: column;
               width: 100%;
               position: relative;

               span {
                  &.ancillary-name {
                  }
                  &.ancillary-price {
                     font-weight: 600;
                     color: var(--secondary);
                  }
               }
               .ancillary-tooltip {
                  background: var(--accent-primary);
                  color: #fff;
                  position: absolute;
                  padding: 0.5rem 0.75rem;
                  border-radius: 0.125rem;
                  left: 0;
                  right: 0;
                  bottom: 100%;
                  display: none;
                  width: fit-content;
               }
               &:hover {
                  .ancillary-tooltip {
                     display: flex;
                  }
               }
            }
            .ancillary-counter {
               display: flex;
               align-items: center;
               span {
                  margin: 0 0.25rem;
                  min-width: 32px;
                  display: flex;
                  justify-content: center;
                  font-weight: 500;
                  font-size: 1.25rem;
               }
               button {
                  background-color: #fff;
                  border: 2px solid #cacfd9;
                  font-size: var(--xlarge);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 0.5rem;
                  width: 32px;
                  height: 32px;

                  &.invisible {
                     visibility: visible !important;
                     opacity: 0.5;
                     cursor: not-allowed;
                  }
               }
            }
            .ancillary-add {
               cursor: pointer;
               display: flex;
               align-items: center;
               justify-content: center;
               font-size: 1.5rem;
               width: 32px;
               height: 32px;
               background-color: #fff;
               border-radius: 24rem;
               border: 2px solid #cacfd9;

               &.selected {
                  color: #fff;
                  background-color: #36d6a1;
                  border-color: transparent;
                  transform: rotate(45deg);
               }
            }
         }
      }
   }
}
</style>
