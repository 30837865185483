<template>
   <div id="agency-extra-commission-container" @click="dropdown = true" v-click-outside="handlerOutClick">
      <!-- Z-index trick -->
      <div id="aec-dropdown-box" v-if="dropdown">
         <div class="aec-dropdown-box-wrapper">
            <h6>{{ $t("AgencyExtraCommission_title") }}</h6>
            <div class="aec-input-container">
               <input type="number" min="0" step="1" max="999" v-model="commission" @input="handlerInputChange($event)" />
               <div class="aec-action-container">
                  <button @click="handlerIncrease">
                     <i class="gts-icon icon-arrow-up icon-sm gts-animation"></i>
                  </button>

                  <button @click="handlerDecrease">
                     <i class="gts-icon icon-arrow-down icon-sm gts-animation"></i>
                  </button>
               </div>
            </div>
         </div>
      </div>
      <div id="aec-dropdown">
         <section class="extra-commission-wrapper">
            <span class="extra-commission-alert" v-if="commission > 0"></span>
            <UITooltip
               tooltip-title="Extra Commission"
               tooltip-icon="gts-icon icon-extra-commission icon-navbar gts-animation"
               tooltip-position="bottom"></UITooltip>
         </section>
      </div>
   </div>
</template>

<script>
import UITooltip from "@/components/utils/GtsToolTips.vue";
export default {
   name: "AgencyExtraCommission",
   components: {
      UITooltip,
   },
   data() {
      return {
         dropdown: false,
         commission: 0,
      };
   },

   methods: {
      handlerOutClick() {
         const self = this;
         self.dropdown = false;
      },
      handlerUpdateAgencyCommissionExtra() {
         let product = this.$route.path.replace("/", "");

         if (!isNaN(parseInt(this.commission))) {
            this.$store.commit(`${product}/updateAgencyCommissionExtra`, this.commission);
         } else {
            this.$store.commit(`${product}/updateAgencyCommissionExtra`, 0);
         }
      },
      handlerIncrease() {
         const self = this;
         self.commission < 99 ? self.commission++ : false;
         this.handlerUpdateAgencyCommissionExtra();
      },
      handlerDecrease() {
         const self = this;
         self.commission > 0 ? self.commission-- : false;
         this.handlerUpdateAgencyCommissionExtra();
      },
      handlerInputChange(ev) {
         const self = this;
         self.commission = ev.target.value;
         this.handlerUpdateAgencyCommissionExtra();
      },
   },
   watch: {
      commission: {
         handler: function (newValue, oldValue) {
            const self = this;
            const newNumber = parseInt(newValue);
            const oldNumber = parseInt(oldValue);
            self.commission = parseInt(newValue);
            if (isNaN(newNumber)) {
               self.commission = 0;
            }
            if (newNumber > 999) {
               self.commission = oldNumber;
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
#agency-extra-commission-container {
   position: relative;
   margin: 0 0.75rem;
   height: 100%;
   display: flex;
   align-items: center;
   cursor: pointer;

   #aec-dropdown {
      .extra-commission-wrapper {
         position: relative;
         z-index: 0;

         .extra-commission-alert {
            position: absolute;
            top: 4px;
            right: -12px;
            width: 24px;
            height: 24px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            text-align: center;
            font-weight: 500;
            color: #fff;
            font-size: 0.75rem;
            z-index: 101;

            @keyframes extraCommissionAlert {
               from {
                  transform: scale(0.5);
                  opacity: 0;
               }
               to {
                  transform: scale(1);
                  opacity: 1;
               }
            }

            &:before {
               animation-name: extraCommissionAlert;
               animation-timing-function: ease-out;
               animation-duration: 0.75s;
               animation-iteration-count: infinite;
               content: "";
               position: absolute;
               width: 22px;
               height: 22px;
               background-color: none;
               border: 0.1rem solid #ff4c4c;
               border-radius: 50%;
            }

            &:after {
               content: "";
               width: 12px;
               height: 12px;
               position: absolute;
               background-color: var(--accent-primary);
               background-color: #ff4c4c;
               border-radius: 50%;
            }
         }
      }
   }
   #aec-dropdown-box {
      animation-name: dropdown;
      animation-timing-function: ease-in-out;
      animation-duration: 0.15s;
      position: absolute;
      right: 0;
      margin: auto;
      border-radius: 8px;
      background-clip: padding-box;
      background-color: #fff;
      top: 100%;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px 0px;
      min-width: 200px;
      padding: 1.5rem;

      .aec-dropdown-box-wrapper {
         h6 {
            font-weight: 500;
            font-size: var(--large);
         }
         .aec-input-container {
            margin-top: 1rem;
            display: flex;
            align-items: center;

            input {
               width: 100px;
               border: none;
               outline: none;
               font-size: var(--xxxlarge);
               font-weight: 600;
               color: var(--txt-black);
               border: 1px solid #eee;
               border-radius: 0.5rem;
               text-align: center;
               margin-right: 0.5rem;

               /* Chrome, Safari, Edge, Opera */
               &::-webkit-outer-spin-button,
               &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
               }

               /* Firefox */
               &[type="number"] {
                  -moz-appearance: textfield;
               }
            }

            .aec-action-container {
               display: flex;
               flex-direction: column;
               button {
                  background: transparent;

                  //tablet only
                  @media (min-width: 768px) and (max-width: 991.98px) {
                     transform: scale(1.5);
                     &:first-child {
                        margin-bottom: 0.75rem;
                     }
                  }
               }
            }
         }
      }
      &:before {
         content: "";
         position: absolute;
         top: -6px;
         right: 0.5rem;
         margin: auto;
         width: 0;
         height: 0;
         transform: translateX(-50%);
         border-right: 6px solid transparent;
         border-bottom: 6px solid #fff;
         border-left: 6px solid transparent;
      }
   }
}
</style>
