/***
 * TaskList Enums Start
 * PaymentChoices Enum Start
 * Transaction Enum Start
 * Invoice Enum Start
 * OrderStatus Enum Start
 * VoucherStatus Enum Start
 ***/
import Vue from "vue";
import i18n from "./fmt/i18n";

// Vue.filter("EHtlVoucherStatus", (value) => {
//     if (!value) {
//         return "";
//     }
//     switch (value) {
//         case -1:
//             return { val: value, text: i18n.t("EHtlVoucherStatus_ALL") };
//         case 0:
//             return { val: value, text: i18n.t("EHtlVoucherStatus_UNKNOWN") };
//         case 1:
//             return { val: value, text: i18n.t("EHtlVoucherStatus_NEW") };
//         case 2:
//             return { val: value, text: i18n.t("EHtlVoucherStatus_RESERVED") };
//         case 3:
//             return { val: value, text: i18n.t("EHtlVoucherStatus_BOOKED") };
//         case 4:
//             return { val: value, text: i18n.t("EHtlVoucherStatus_CANCELED") };
//         case 5:
//             return { val: value, text: i18n.t("EHtlVoucherStatus_VOIDED") };
//         case 6:
//             return { val: value, text: i18n.t("EHtlVoucherStatus_REFUNDED") };
//         case 7:
//             return { val: value, text: i18n.t("EHtlVoucherStatus_REBOOKED") };
//         case 8:
//             return { val: value, text: i18n.t("EHtlVoucherStatus_ERROR") };
//     }
// });

/* TaskList Enums Start */
Vue.filter("enumTaskType", (value) => {
   if (!value) {
      return "";
   }

   switch (value) {
      case -1:
         return { val: value, text: i18n.t("enum_all") };
      case 0:
         return { val: value, text: i18n.t("enum_unknown") };
      case 1:
         return { val: value, text: i18n.t("enum_pnr_reverse") };
      case 2:
         return { val: value, text: i18n.t("enum_pnr_reissue") };
      case 3:
         return { val: value, text: i18n.t("enum_pnr_ancillary") };
      case 4:
         return { val: value, text: i18n.t("enum_pnr_correction") };
      case 5:
         return { val: value, text: i18n.t("enum_pnr_divide") };
      case 6:
         return { val: value, text: i18n.t("enum_pnr_open") };
      case 7:
         return { val: value, text: i18n.t("enum_pnr_add") };
      case 8:
         return { val: value, text: i18n.t("enum_pnr_update") };
      case 9:
         return { val: value, text: i18n.t("enum_pnr_update_pax") };
      case 10:
         return { val: value, text: i18n.t("enum_pnr_queue_scan") };
      case 21:
         return { val: value, text: i18n.t("enum_voucher_reverse") };
      case 22:
         return { val: value, text: i18n.t("enum_voucher_reissue") };
      case 23:
         return { val: value, text: i18n.t("enum_voucher_add") };
      case 24:
         return { val: value, text: i18n.t("enum_voucher_note") };
      case 25:
         return { val: value, text: i18n.t("enum_voucher_update") };
      case 26:
         return { val: value, text: i18n.t("enum_voucher_reconfirmation") };
      case 41:
         return { val: value, text: i18n.t("enum_tnr_add") };
      case 42:
         return { val: value, text: i18n.t("enum_tnr_reverse") };
      case 43:
         return { val: value, text: i18n.t("enum_tnr_reissue") };
      case 61:
         return { val: value, text: i18n.t("enum_payment_reverse") };
      case 81:
         return { val: value, text: i18n.t("enum_rnr_add") };
      case 82:
         return { val: value, text: i18n.t("enum_rnr_reverse") };
      case 83:
         return { val: value, text: i18n.t("enum_rnr_reissue") };
      case 84:
         return { val: value, text: i18n.t("enum_rnr_ancillary") };
      case 85:
         return { val: value, text: i18n.t("enum_rnr_update_driver") };
   }
});

Vue.filter("enumTaskSubType", (value) => {
   if (!value) {
      return "";
   }

   switch (value) {
      case -1:
         return { val: value, text: i18n.t("enum_all") };
      case 0:
         return { val: value, text: i18n.t("enum_unknown") };
      case 1:
         return { val: value, text: i18n.t("enum_basi") };
   }
});

Vue.filter("enumTaskStatus", (value) => {
   if (!value) {
      return "";
   }

   switch (value) {
      case -1:
         return { val: value, text: i18n.t("enum_all") };
      case 0:
         return { val: value, text: i18n.t("enum_unknown") };
      case 1:
         return { val: value, text: i18n.t("enum_open") };
      case 2:
         return { val: value, text: i18n.t("enum_closed") };
   }
});

Vue.filter("enumTaskSubStatus", (value) => {
   if (!value) {
      return "";
   }

   switch (value) {
      case -1:
         return { val: value, text: i18n.t("enum_all") };
      case 0:
         return { val: value, text: i18n.t("enum_unknown") };
      case 1:
         return { val: value, text: i18n.t("enum_new") };
      case 2:
         return { val: value, text: i18n.t("enum_processing") };
      case 3:
         return { val: value, text: i18n.t("enum_waiting_on_operator") };
      case 4:
         return { val: value, text: i18n.t("enum_waiting_on_provider") };
      case 5:
         return { val: value, text: i18n.t("enum_waiting_on_agency") };
      case 6:
         return { val: value, text: i18n.t("enum_closed") };
      case 7:
         return { val: value, text: i18n.t("enum_replied_by_provider") };
      case 8:
         return { val: value, text: i18n.t("enum_replied_by_agency") };
      case 9:
         return { val: value, text: i18n.t("enum_reopen") };
   }
});

Vue.filter("enumTaskClosingType", (value) => {
   if (!value) {
      return "";
   }

   switch (value) {
      case -1:
         return i18n.t("enum_all");
      case 0:
         return i18n.t("enum_unknown");
      case 1:
         return i18n.t("enum_completed");
      case 2:
         return i18n.t("enum_cancelled");
      case 3:
         return i18n.t("enum_wont_fix");
      case 4:
         return i18n.t("enum_duplicate");
   }
});

Vue.filter("enumTaskEntityType", (value) => {
   if (!value) {
      return "";
   }

   switch (value) {
      case -1:
         return i18n.t("enum_all");
      case 0:
         return i18n.t("enum_unknown");
      case 1:
         return i18n.t("enum_payment");
      case 2:
         return i18n.t("enum_pnr");
      case 3:
         return i18n.t("enum_hotel");
      case 4:
         return i18n.t("enum_transfer");
      case 5:
         return i18n.t("enum_insurance");
      case 6:
         return i18n.t("enum_invoice");
   }
});
/* TaskList Enums End */

/* PaymentChoices Enum Start */
Vue.filter("enumPaymentChoices", (value) => {
   let newPaymentChoicesList = [];

   value.map((valueItem) => {
      if (!valueItem) {
         return null;
      }

      switch (valueItem.paymentType) {
         case -1:
            newPaymentChoicesList.push({
               label: "ALL",
               text: i18n.t("enum_all"),
               commissionEnabled: valueItem.commissionEnabled,
               paymentType: valueItem.paymentType,
               vposId: valueItem.vposId,
            });
            break;
         case 0:
            newPaymentChoicesList.push({
               label: "UNKNOWN",
               text: i18n.t("enum_unknown"),
               commissionEnabled: valueItem.commissionEnabled,
               paymentType: valueItem.paymentType,
               vposId: valueItem.vposId,
            });
            break;
         case 1:
            newPaymentChoicesList.push({
               label: "CARI",
               text: i18n.t("enum_cari"),
               commissionEnabled: valueItem.commissionEnabled,
               paymentType: valueItem.paymentType,
               vposId: valueItem.vposId,
            });
            break;
         case 2:
            newPaymentChoicesList.push({
               label: "CREDITCARD",
               text: i18n.t("enum_creditCard"),
               commissionEnabled: valueItem.commissionEnabled,
               paymentType: valueItem.paymentType,
               vposId: valueItem.vposId,
            });
            break;
         case 3:
            newPaymentChoicesList.push({
               label: "CREDITCARD_NON_3D",
               text: i18n.t("enum_creditCardNon3d"),
               commissionEnabled: valueItem.commissionEnabled,
               paymentType: valueItem.paymentType,
               vposId: valueItem.vposId,
            });
            break;
         case 4:
            newPaymentChoicesList.push({
               label: "SOFORT",
               text: i18n.t("enum_sofort"),
               commissionEnabled: valueItem.commissionEnabled,
               paymentType: valueItem.paymentType,
               vposId: valueItem.vposId,
            });
            break;
         case 5:
            newPaymentChoicesList.push({
               label: "IDEAL",
               text: i18n.t("enum_ideal"),
               commissionEnabled: valueItem.commissionEnabled,
               paymentType: valueItem.paymentType,
               vposId: valueItem.vposId,
            });
            break;
      }
   });
   return newPaymentChoicesList;
});
/* PaymentChoices Enum End */

/* Transaction Enum Start */
Vue.filter("enumTransactionTrxType", (value) => {
   if (!value) {
      return null;
   }

   return i18n.t(`enum_${value.toLowerCase()}`);
});
/* Transaction Enum End */

/* Invoice Enum Start */
Vue.filter("enumInvoiceIdentifier", (value) => {
   if (!value) {
      return null;
   }

   return i18n.t(`enum_${value.toLowerCase()}`);
});
/* Invoice Enum End */

/* OrderStatus Enum Start */
Vue.filter("enumOrderStatus", (value) => {
   switch (value) {
      case -1:
         return i18n.t("enum_all");
      case 0:
         return i18n.t("enum_unknown");
      case 1:
         return i18n.t("enum_new");
      case 2:
         return i18n.t("enum_reserved");
      case 3:
         return i18n.t("enum_issued");
      case 4:
         return i18n.t("enum_canceled");
      case 5:
         return i18n.t("enum_voided");
      case 6:
         return i18n.t("enum_refunded");
      case 7:
         return i18n.t("enum_reissued");
      case 8:
         return i18n.t("enum_error");
      case 9:
         return i18n.t("enum_open");
      case 10:
         return i18n.t("enum_pending_issued");
      case 11:
         return i18n.t("enum_pending_reserved");
      case 12:
         return i18n.t("enum_pending_canceled");
      case 13:
         return i18n.t("enum_pending_voided");
      case 14:
         return i18n.t("enum_pending_refunded");
      default:
         return null;
   }
});
/* OrderStatus Enum Enum */

/* VoucherStatus Enum Start */
Vue.filter("enumHotelVoucherStatus", (value) => {
   if (!value) {
      return null;
   }

   switch (value) {
      case -1:
         return i18n.t("enum_all");
      case 0:
         return i18n.t("enum_unknown");
      case 1:
         return i18n.t("enum_new");
      case 2:
         return i18n.t("enum_reserved");
      case 3:
         return i18n.t("enum_booked");
      case 4:
         return i18n.t("enum_canceled");
      case 5:
         return i18n.t("enum_voided");
      case 6:
         return i18n.t("enum_refunded");
      case 7:
         return i18n.t("enum_rebooked");
      case 8:
         return i18n.t("enum_error");
      case 9:
         return i18n.t("enum_open");
      case 10:
         return i18n.t("enum_pending");
      default:
         return "boş enum";
   }
});
/* VoucherStatus Enum End */
