<template>
   <div id="scrf-container">
      <UIAlert status="info_blue" :rounded="false">
         <slot>
            <span>{{ $t("requestFormComponent_infoAlert_text") }}</span>
         </slot>
      </UIAlert>
      <div id="scrf-select-box-container" class="col-12 col-md-10 col-lg-4">
         <UISelectBox :label="OrderIdentifier" id="select" @input="handlerSelection" :options="selectList" v-model="selection" light-theme />
      </div>

      <!-- Radio Buttons for PNRs -->
      <div id="scrf-radio-container" class="col-12 col-md-10 col-lg-6" v-if="activeRadioButtonTabs == 'PNR'">
         <UIRadioButton :label="$t('enum_pnr_reverse')" name="task-type" id="cancellation-refund" :native-value="1" v-model="taskType" />
         <UIRadioButton :label="$t('enum_pnr_ancillary')" name="task-type" id="ancillary" :native-value="3" v-model="taskType" v-if="!isReserve" />
         <UIRadioButton :label="$t('enum_pnr_reissue')" name="task-type" id="reissue" :native-value="2" v-model="taskType" />
         <UIRadioButton
            :label="$t('serviceCenter_openTicket')"
            name="task-type"
            id="open-enum_pnr_open"
            :native-value="6"
            v-model="taskType"
            v-if="!isReserve" />
      </div>

      <!-- Radio Buttons for VOUCHERs -->
      <div id="scrf-radio-container" class="col-12 col-md-10 col-lg-6" v-if="activeRadioButtonTabs == 'VOUCHER'">
         <UIRadioButton :label="$t('enum_voucher_reverse')" name="task-type" id="voucher-reverse" :native-value="21" v-model="taskType" />
         <UIRadioButton :label="$t('enum_voucher_reissue')" name="task-type" id="voucher-reissue" :native-value="22" v-model="taskType" />
      </div>

      <!-- Radio Buttons for TNRs -->
      <div id="scrf-radio-container" class="col-12 col-md-10 col-lg-6" v-if="activeRadioButtonTabs == 'TNR'">
         <UIRadioButton :label="$t('enum_tnr_reverse')" name="task-type" id="tnr-reverse" :native-value="42" v-model="taskType" />
         <UIRadioButton :label="$t('enum_tnr_reissue')" name="task-type" id="tnr-reissue" :native-value="43" v-model="taskType" />
      </div>

      <!-- Radio Buttons for RNRs -->
      <div id="scrf-radio-container" class="col-12 col-md-10 col-lg-6" v-if="activeRadioButtonTabs == 'RNR'">
         <UIRadioButton :label="$t('enum_rnr_reverse')" name="task-type" id="rnr-reverse" :native-value="82" v-model="taskType" />
         <UIRadioButton :label="$t('enum_rnr_reissue')" name="task-type" id="rnr-reissue" :native-value="83" v-model="taskType" />
      </div>

      <p id="task-open-information" v-html="TaskOpenInformation($options.filters.enumTaskType(taskType).text, Selection)"></p>

      <div id="scrf-input-container" class="col-12 col-md-10 col-lg-6">
         <textarea
            name=""
            id="addNewNote"
            rows="5"
            v-model="serviceCenterNote"
            :placeholder="$t('requestFormComponent_textareaPlaceholder_label')"></textarea>
         <UIButton className="default" :text="$t('serviceCenterRequestForm_btnSend')" @click.native="handlerCreateTask" />
      </div>
   </div>
</template>

<script>
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import UIButton from "@/components/unitary/Button.vue";
import UIRadioButton from "@/components/unitary/form2/UIRadioButton.vue";
import UIAlert from "@/components/unitary/Alert.vue";
import { generateUuid } from "@/utils.js";
import { tasklistService } from "@/service/index.js";
import Popup from "@/gts-popup.js";
export default {
   name: "ServiceCenterRequestForm",
   props: {
      orderId: [Number, String],
   },
   components: {
      UIButton,
      UIAlert,
      UISelectBox,
      UIRadioButton,
   },
   data() {
      return {
         taskType: null,
         serviceCenterNote: "",
         selectList: [],
         selection: null,
         radioButtonsIdentifiers: {}, // radio buttons identifers for pnr & voucher & tnr & rnr
         activeRadioButtonTabs: null,
         isReserve: false,
         redirectTask: null,
         isOrderPnr: false,
         isOrderVoucher: false,
         isOrderTnr: false,
         isOrderRnr: false,
      };
   },

   computed: {
      Selection() {
         if (this.selectList.length != 0) {
            let selectedIs = this.selectList.filter((list) => {
               return list.value == this.selection;
            });

            return selectedIs[0].name;
         } else {
            return null;
         }
      },
      OrderIdentifier() {
         switch (true) {
            case this.isOrderPnr:
               return this.$i18n.t("requestForm_pnrTitle");
            case this.isOrderVoucher:
               return this.$i18n.t("requestForm_voucherTitle");
            case this.isOrderTnr:
               return this.$i18n.t("requestForm_tnrTitle");
            case this.isOrderRnr:
               return this.$i18n.t("requestForm_rnrTitle");
            default:
               return this.$i18n.t("requestForm_unknownTitle");
         }
      },
   },
   methods: {
      TaskOpenInformation(taskType, selection) {
         const fmt = this.$i18n.t(`serviceCenterRequestForm_taskOpeningInfos`);
         const taskTypeReplace = `<strong>${taskType}</strong>`;
         const selectionReplace = `<strong>${selection}</strong>`;

         return fmt.replace("TASKTYPE", taskTypeReplace).replace("SELECTION", selectionReplace);
      },
      handlerCreateTask() {
         const self = this;
         let query = {
            type: self.taskType,
            orderId: self.orderId,
            description: self.serviceCenterNote,
            entityId: self.selection,
            uuid: generateUuid(),
         };

         tasklistService.addTask(query).then((response) => {
            if (response.result.success) {
               self.redirectTask = response.taskId;
               Popup.success(this.$i18n.t("popup_successTitle"), this.$i18n.t("serviceCenterRequestForm_popupTaskCreate")).confirmed((res) => {
                  if (res.success) {
                     this.$router.push(self.$route.path + "?taskopen=true");
                     window.location.reload();
                  }
               });
               setTimeout(() => {
                  window.location.reload();
               }, 1000);
            }
         });

         tasklistService;
         query;
      },
      handlerSelection(selection) {
         const self = this;
         self.activeRadioButtonTabs = self.radioButtonsIdentifiers[selection];
      },
   },
   watch: {
      orderId: {
         handler: function () {
            const self = this;
            const orderTabs = document.querySelector("#tab_orderDetail");
            const pnrTabs = orderTabs.querySelectorAll("[data-pnr-tabindex]");
            const voucherTabs = orderTabs.querySelectorAll("[data-voucher-tabindex]");
            const tnrTabs = orderTabs.querySelectorAll("[data-transfer-tabindex]");
            const rnrTabs = orderTabs.querySelectorAll("[data-rent-tabIndex]");

            //selectDefaultTab
            self.selection = orderTabs.querySelector(".active").parentElement.dataset.entityid;

            //Listener & setter for tabs
            orderTabs.addEventListener("click", () => {
               self.selection = orderTabs.querySelector(".active").parentElement.dataset.entityid;
            });

            // is Pnr or Voucher or Tnr or Rnr
            switch (true) {
               case pnrTabs.length !== 0:
                  this.isOrderPnr = true;
                  break;
               case voucherTabs.length !== 0:
                  this.isOrderVoucher = true;
                  break;
               case tnrTabs.length !== 0:
                  this.isOrderTnr = true;
                  break;
               case rnrTabs.length !== 0:
                  this.isOrderRnr = true;
                  break;
               default:
                  break;
            }

            //Pushing PNRs to selectbox
            if (this.isOrderPnr) {
               pnrTabs.forEach((pnrTab, index) => {
                  const rowObj = {
                     value: pnrTab.dataset.entityid,
                     name: `${index + 1}.PNR (${pnrTab.dataset.pnrPnr}) - ${pnrTab.dataset.provider}`,
                  };
                  self.radioButtonsIdentifiers[pnrTab.dataset.entityid] = "PNR";
                  self.selectList.push(rowObj);
               });
               this.taskType = 1;
            }

            //Pushing VOUCHERs to selectbox
            if (this.isOrderVoucher) {
               voucherTabs.forEach((voucherTab, index) => {
                  const rowObj = {
                     value: voucherTab.dataset.entityid,
                     name: `${index + 1}.Voucher (${voucherTab.dataset.voucherVoucher})`,
                  };
                  self.radioButtonsIdentifiers[voucherTab.dataset.entityid] = "VOUCHER";
                  self.selectList.push(rowObj);
               });
               this.taskType = 21;
            }

            //Pushing TNRs to selectbox
            if (this.isOrderTnr) {
               tnrTabs.forEach((tnrTab, index) => {
                  const rowObj = {
                     value: tnrTab.dataset.entityid,
                     name: `${index + 1}.Tnr (${tnrTab.dataset.transferTnr})`,
                  };
                  self.radioButtonsIdentifiers[tnrTab.dataset.entityid] = "TNR";
                  self.selectList.push(rowObj);
               });
               this.taskType = 43;
            }

            //Pushing RNRs to selectbox
            if (this.isOrderRnr) {
               rnrTabs.forEach((rnrTab, index) => {
                  const rowObj = {
                     value: rnrTab.dataset.entityid,
                     name: `${index + 1}.Rnr (${rnrTab.dataset.rentRnr})`,
                  };
                  self.radioButtonsIdentifiers[rnrTab.dataset.entityid] = "RNR";
                  self.selectList.push(rowObj);
               });
               this.taskType = 82;
            }
            self.activeRadioButtonTabs = self.radioButtonsIdentifiers[self.selection];
         },
      },
      // selection: {
      //    handler: function (selection) {
      //       const self = this;
      //       const orderTabs = document.querySelector("#tab_orderDetail");

      //       const selectedItem = orderTabs.querySelector(`[data-entityid='${selection}']`).dataset.status;

      //       //if Reserve
      //       if (selectedItem == 2) {
      //          self.isReserve = true;
      //       }
      //    },
      // },
   },
};
</script>

<style lang="scss" scoped>
#scrf-container {
   background: #fff;
   padding: 2rem;

   #scrf-select-box-container {
      margin: 2rem 0;
      padding: 0 !important;
   }

   #scrf-radio-container {
      margin-top: 2rem;
      margin-bottom: 1rem;
      padding: 0 !important;
      display: flex;

      .g-radio-button-container {
         margin: 0 0.5rem;

         &:first-child {
            margin-left: 0;
         }
      }
   }

   #scrf-input-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0 !important;
      textarea#addNewNote {
         width: 100%;
         border-radius: 0.5rem;
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: space-between;
         border: 1px solid #ccc;
         padding: 0.75rem;
         box-sizing: border-box;
         border-radius: 0.5rem;
         min-height: 50px;
         cursor: pointer;
         margin-bottom: 1rem;

         &::placeholder {
            font-size: var(--normal);
            text-transform: capitalize;
         }
      }
   }
   #task-open-information {
      font-size: var(--normal);
      display: inline-block;
      margin-bottom: 0.5rem;
      // padding: 0.125rem 0.5rem;
      // border-radius: 0.5rem;
      // background-color: #ff4c4c;
      color: var(--txt-black) !important;

      strong {
         color: inherit !important;
      }
   }
}
</style>
