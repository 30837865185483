<template>
   <div class="hotel-order-detail-inner-section voucher-summary-wrapper">
      <div class="voucher-summary-inner">
         <div class="voucher-summary-table-wrapper">
            <div class="voucher-summary-table-inner">
               <div class="voucher-summary-table-header">
                  <span>{{ $t("orderDetail_lblVoucherStatus") }}</span>
                  <span>{{ $t("orderDetail_lblProvider") }}</span>
                  <span>{{ $t("orderDetail_lblVoucher") }}</span>
                  <span>{{ $t("orderDetail_lblHotelName") }}</span>
                  <span>{{ $t("orderDetail_lblCheckinDate") }}</span>
                  <span>{{ $t("orderDetail_lblCheckoutDate") }}</span>
                  <span>{{ $t("orderDetail_lblTotalPrice") }}</span>
               </div>
               <div class="voucher-summary-table-content">
                  <span class="voucher-status masked"
                     ><i class="pnr-badge gts-badge-as-dot" :class="'EHtlVoucherStatus-' + summary.status"></i
                     >{{ summary.status | enumHotelVoucherStatus }}</span
                  >
                  <template v-if="summary.providerName == 'GTS_DIRECT'">
                     <span class="masked">
                        {{ $t("hotelOrderDetail_gtsDirect") }}
                     </span>
                  </template>
                  <template v-else>
                     <span class="masked">
                        {{ summary.providerName }}
                     </span>
                  </template>
                  <span class="masked">{{ summary.voucher }}</span>
                  <span class="masked">{{ summary.hotelName }}</span>
                  <span class="masked">{{ summary.checkInDate | fullDateFormat }}</span>
                  <span class="masked">{{ summary.checkOutDate | fullDateFormat }}</span>
                  <span class="masked">{{ summary.total | price(summary.currency) }}</span>
               </div>
            </div>
            <div class="voucher-summary-table-footer">
               <div class="voucher-summary-table-footer-inner">
                  <div class="voucher-summary-table-footer-item">
                     <span class="item-label">{{ $t("orderDetail_lblLastBookingDate") }}</span>
                     <span class="item-text masked"
                        >{{ summary.lastBookingDateTime | fullDateFormat }} - {{ summary.lastBookingDateTime | TimeFormat }}</span
                     >
                  </div>
                  <div class="voucher-summary-table-footer-item">
                     <span class="item-label">{{ $t("orderDetail_lblLastVoidDate") }}</span>
                     <span class="item-text masked"
                        >{{ summary.lastVoidDateTime | fullDateFormat }} - {{ summary.lastVoidDateTime | TimeFormat }}</span
                     >
                  </div>
                  <div class="voucher-summary-table-footer-item">
                     <span class="item-label">{{ $t("orderDetail_lblReserveDate") }}</span>
                     <span class="item-text masked">{{ summary.reserveDateTime | fullDateFormat }} - {{ summary.reserveDateTime | TimeFormat }}</span>
                  </div>
                  <div class="voucher-summary-table-footer-item">
                     <span class="item-label">{{ $t("orderDetail_lblBookingDate") }}</span>
                     <span class="item-text masked">{{ summary.bookingDateTime | fullDateFormat }} - {{ summary.bookingDateTime | TimeFormat }}</span>
                  </div>
                  <div class="voucher-summary-table-footer-item">
                     <span class="item-label">{{ $t("orderDetail_lblReverseDate") }}</span>
                     <span class="item-text masked">{{ summary.reverseDateTime | fullDateFormat }} - {{ summary.reverseDateTime | TimeFormat }}</span>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "VoucherAndRoomInformationSummary",
   props: {
      voucherSummary: { type: Object },
   },
   data() {
      return {
         summary: {},
      };
   },
   created() {
      const self = this;

      self.summary = this.voucherSummary;
   },
   watch: {
      voucherSummary: {
         handler: function (newVoucherSummary) {
            const self = this;
            self.summary = newVoucherSummary;
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.voucher-summary-wrapper {
   margin-bottom: 90px;
   .voucher-summary-inner {
      .voucher-summary-table-wrapper {
         .voucher-summary-table-inner {
            display: block;
            border: 1px solid #c4c4c4;
            border-radius: 10px 10px 0 10px;
            overflow: hidden;
            .voucher-summary-table-header {
               display: grid;
               grid-template-columns: repeat(7, 1fr);
               padding: 16px 42px;
               background-color: #ededed;
               border-bottom: 1px solid #c4c4c4;

               & > span {
                  font-size: 14px;
                  color: #868788;
               }
            }
            .voucher-summary-table-content {
               display: grid;
               grid-template-columns: repeat(7, 1fr);
               padding: 16px 42px;
               background-color: #f5f5f5;

               & > span {
                  font-size: 16px;
                  font-weight: 700;

                  &.voucher-status {
                     position: relative;
                     display: inline-flex;
                     align-items: center;

                     > i {
                        margin-right: 0.5rem;
                     }
                  }
               }
            }
         }
         .voucher-summary-table-footer {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            .voucher-summary-table-footer-inner {
               width: 80%;
               display: grid;
               grid-template-columns: repeat(5, 1fr);
               grid-column-gap: 16px;
               padding: 16px 24px;
               border: 1px solid #c4c4c4;
               border-top: none;
               border-radius: 0 0 8px 8px;
               .voucher-summary-table-footer-item {
                  display: inline-flex;
                  flex-direction: column;
                  border-right: 1px solid #d0d2d4;

                  &:last-child {
                     border-right: none;
                  }
                  & > span {
                     font-size: 14px;
                  }
                  & > span.item-label {
                     margin-bottom: 4px;
                     color: #868788;
                  }
                  & > span.item-text {
                     font-weight: 700;
                  }
               }
            }
         }
         @media (max-width: 767.98px) {
            .voucher-summary-table-inner {
               display: flex;
               .voucher-summary-table-header {
                  display: flex;
                  flex-direction: column;
                  & > span {
                     margin-bottom: 8px;
                  }
               }
               .voucher-summary-table-content {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
               }
            }
            .voucher-summary-table-footer {
               flex-wrap: wrap;
               .voucher-summary-table-footer-inner {
                  display: block;
                  width: 100%;
                  padding: 16px 0px;

                  .voucher-summary-table-footer-item {
                     display: block;
                     border-bottom: 1px solid #d0d2d4;
                     margin-top: 8px;

                     & > span.item-text {
                        margin-left: 6px;
                     }
                     &:last-child {
                        border-bottom: none;
                     }
                  }
               }
            }
         }
      }
   }
}
</style>
