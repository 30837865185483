<template>
   <div class="widget-table-container">
      <div class="widget-table-wrapper">
         <!-- Table CTA Wrapper Start -->
         <div class="widget-table-header-wrapper">
            <!-- Table Tabs End -->
            <div class="widget-table-cta-wrapper">
               <div class="widget-table-actions-wrapper">
                  <button @click="handlerExportPrint">
                     <UITooltip
                        :tooltip-title="$t('dashboardTable_print')"
                        tooltip-icon="gts-icon icon-document-print  gts-animation"
                        tooltip-position="bottom"></UITooltip>
                  </button>

                  <button
                     class="show-online-checkins"
                     ref="checkin"
                     @click="handlerOnlyOnlineCheckIns"
                     v-if="properties?.type == 2 && activeTab == 201">
                     <i class="gts-icon icon-online-checkin gts-animation"></i>
                     {{ $t("dashboardTable_onlyOnlineCheckIns") }}
                  </button>

                  <button
                     class="show-online-reservations"
                     ref="reservations"
                     @click="handlerOnlyPastReservations"
                     v-if="properties?.type == 1 && activeTab == 101">
                     <i class="gts-icon icon-past-reservations gts-animation"></i>
                     <UITooltip :tooltip-title="$t('dashboardTable_last72Hours')" tooltip-position="bottom">{{
                        $t("dashboardTable_onlyPastReservations")
                     }}</UITooltip>
                  </button>
               </div>

               <div class="widget-table-search-wrapper">
                  <input type="text" name="" id="" :placeholder="$t('common_search')" v-model="search" />
                  <i
                     class="gts-icon icon-close-rounded gts-animation icon-xs clear-search"
                     @click="search = ''"
                     :class="search.length == 0 ? 'disabled' : ''"></i>
               </div>
            </div>
         </div>
         <!-- Table CTA Wrapper End -->
         <div class="widget-table-row table-header">
            <template v-for="(title, index) in TableHeader">
               <div class="widget-table-col txt-small" :key="index">
                  <div class="widget-table-header txt-bold">{{ $t(title) }}</div>
               </div>
            </template>
         </div>

         <!-- Table Print Ref -->
         <!-- ref="print" -->

         <PDFexport :table="{ rows: rows, headers: TableHeader }" ref="print" />
         <!-- Table Is Empty -->
         <template v-if="Rows.length == 0">
            <div class="widget-table-row zebra-color no-data">
               <div class="widget-table-col w-100">
                  {{ $t("common_nodata") }}
               </div>
            </div>
         </template>
         <div v-else-if="loading" class="widget-loader-container">
            <div class="widget-loader-wrapper">
               <span class="g-radial-loader"> </span>
            </div>
         </div>
         <template v-for="(item, index) in Rows" v-else>
            <div class="widget-table-row zebra-color" :key="index" @click="handlerRoute($event, item)">
               <div class="widget-table-col">
                  <i v-if="item.entityType == 2 && item.providerType == 3" class="gts-icon icon-charter-flight"></i>
                  <i v-else-if="item.entityType == 2" class="gts-icon icon-dashboard-table-flight"></i>
                  <i v-else-if="item.entityType == 3" class="gts-icon icon-dashboard-table-hotel"></i>
                  <i v-else-if="item.entityType == 4" class="gts-icon icon-dashboard-table-transfer"></i>
                  <i v-else-if="item.entityType == 5" class="gts-icon icon-dashboard-table-rentacar"></i>
               </div>
               <div class="widget-table-col">
                  <span class="txt-small txt-bold"> {{ item.paxFullName }} </span>
               </div>
               <div class="widget-table-col">
                  <span class="txt-small">
                     {{ Provider(item) }}
                  </span>

                  <!-- Route is an array -->

                  <span class="txt-small txt-black" v-if="item.entityType != 2">
                     <UITooltip :tooltip-title="$t(item.route)" tooltip-position="bottom" no-padding>{{ Route(item) }}</UITooltip>
                  </span>

                  <span class="txt-small txt-black" v-else v-for="(route, index) in Route(item)" :key="index">
                     {{ route }}
                  </span>
               </div>
               <div class="widget-table-col">
                  <span class="widget-td-datetime txt-small" :class="item.highlightDate ? 'highlighted-date' : ''">
                     {{ DateTime(item) }}
                  </span>

                  <!-- Is onlineCheckInUrl Exist? We can understand the type of table widget -->
                  <template v-if="item.onlineCheckInUrl">
                     <span class="widget-td-online-checkin online-checkin-router">
                        {{ $t("gtsDataTableComponent_checkIn") }}
                     </span>
                  </template>
               </div>
               <div class="widget-table-col">
                  <span v-if="!item.entity">{{ $t("gtsDataTableComponent_emptyEntity") }}</span>
                  <span
                     v-else
                     class="gts-badge widget-td-status"
                     :class="['widget-td-status-' + item.status, item.entity.length > 6 ? 'overflow-entity' : '']">
                     <i>{{ item.entity.trimmer(7) }}</i>
                  </span>
               </div>
            </div>
         </template>
         <!-- Table Body End -->
         <!-- Table Pagination Start -->

         <!-- Table Pagination End -->
      </div>
      <Pagination
         v-if="!loading && pagination.enabled"
         :pages="pagination.pages"
         :active-page="pagination.activepage"
         @update-page="handlerUpdatePage" />
   </div>
</template>

<script>
import gtsGridPdfStyle from "@/assets/css/gtsGridPdf.js";
import WidgetControls from "@/components/widgets/helpers/widget-helpers.js";
import Pagination from "@/components/widgets/base/Pagination.vue";
import PDFexport from "@/components/widgets/Extras/PDFexport.vue";
import UITooltip from "@/components/utils/GtsToolTips.vue";
export default {
   name: "Table",
   props: {
      properties: [Object, Array],
      rows: [Object, Array],
      activeTab: Number,
      resetPagination: Boolean,
      loading: Boolean,
   },
   components: {
      Pagination,
      PDFexport,
      UITooltip,
   },
   data() {
      return {
         search: "",
         pagination: {
            enabled: true,
            perPage: 5,
            pages: [],
            options: [],
            activepage: 1,
         },
         expiredFlights: false,
         clicked: false,
         showCheckin: false,
      };
   },
   computed: {
      Search() {
         const identifier = this.showCheckin ? this.rows.filter((item) => item.onlineCheckInUrl) : this.rows;
         return identifier.filter((row) => JSON.stringify(row).toUpperCase().includes(this.search.toUpperCase()));
      },
      Rows() {
         this.Pagination;
         let sliceFrom = this.pagination.activepage - 1;
         let sliceTo = this.pagination.activepage;
         let perPage = this.pagination.perPage;
         return this.Search?.slice(perPage * sliceFrom, perPage * sliceTo);
      },
      Pagination() {
         const self = this;
         const count = self.Search?.length;
         const totalPage = Math.ceil(count / self.pagination.perPage);
         if (totalPage == 0) {
            self.pagination.enabled = false;
         } else {
            self.pagination.enabled = true;
            self.pagination.pages.length = 0;
            for (let i = 1; i <= totalPage; i++) {
               self.pagination.pages.push(i);
               const rowObj = {
                  name: i,
                  value: i,
               };
               self.pagination.options.push(rowObj);
            }
         }

         return self.pagination;
      },
      TableHeader() {
         let dynamicReservationsTableHeader = null;
         let dynamicUpcomingsTableHeader = null;
         switch (this.properties.type) {
            case 1:
               dynamicReservationsTableHeader = WidgetControls.reservationsHeader[this.activeTab];
               return dynamicReservationsTableHeader;
            case 2:
               dynamicUpcomingsTableHeader = WidgetControls.upcomingsHeader[this.activeTab];
               return dynamicUpcomingsTableHeader;
            default:
               return {
                  1: "",
                  2: "dashboardPage_widgetNameSurname",
                  3: "dashboardPage_widgetProviderRouteHotelNameVehicle",
                  4: "dashboardPage_widgetTime",
                  5: "dashboardPage_widgetEntity",
               };
         }
      },
   },
   methods: {
      handlerOnlyOnlineCheckIns() {
         // this.showCheckin = !this.showCheckin;
         if (this.clicked) {
            this.$refs.checkin.classList.remove("filter-active");
            this.clicked = false;
            this.showCheckin = false;
         } else {
            this.$refs.checkin.classList.add("filter-active");
            this.clicked = true;
            this.showCheckin = true;
         }
         this.handlerUpdatePage(1);
      },
      handlerUpdatePage(index) {
         if (this.resetPagination) {
            this.pagination.activepage = 1;
            this.$emit("resetPagination");
         } else {
            this.pagination.activepage = index;
         }
      },
      handlerOnlyPastReservations() {
         this.$emit("expiredFlights");
         if (this.clicked) {
            this.$refs.reservations.classList.remove("filter-active");
            this.clicked = false;
         } else {
            this.$refs.reservations.classList.add("filter-active");
            this.clicked = true;
         }
      },
      Route(item) {
         if (item.entityType == 2) {
            var newItem = item.route.split(" ");
            return newItem;
         } else if (item.entityType == 3) {
            return item.route.trimmer(18);
         } else {
            return item.route;
         }
      },
      Provider(item) {
         if (item.entityType == 4) {
            return this.$options.filters.ETrfVehicleType(item.provider);
         } else {
            return item.provider;
         }
      },
      DateTime(item) {
         item;
         if (this.properties.type == 2 && (item.entityType == 3 || item.entityType == 4)) {
            return this.$options.filters.FormatterDate(item.dateTime);
         } else {
            return this.$options.filters.FormatterDD_MM_YYYY_HH_mm(item.dateTime);
         }
      },
      handlerRoute(ev, row) {
         if (ev.target.classList.contains("online-checkin-router")) {
            window.open(row.onlineCheckInUrl, "_blank");
         } else {
            window.open(`/order/detail/${row.orderUuid}`);
         }
      },
      handlerExportPrint() {
         const divToPrint = this.$refs.print.$el;
         const newPrintWindow = window.open("", "Print");
         newPrintWindow.document.write(`<html><head><style>${gtsGridPdfStyle}</style></head><body>${divToPrint.outerHTML}</body></html>`);
         newPrintWindow.print();
         newPrintWindow.close();
      },
   },
   watch: {
      resetPagination: {
         handler: function (status) {
            if (status) {
               this.search = "";
               this.handlerUpdatePage();
            }
         },
      },
      activeTab: {
         handler: function (tab) {
            if (tab != 101 || tab != 201) {
               this.clicked = false;
               this.showCheckin = false;
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.widget-table-container {
   min-height: 580px;
   .widget-table-wrapper {
      .widget-loader-container {
         height: 350px;
         .widget-loader-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8rem;
            .g-radial-loader {
               $size: 42px;
               width: $size;
               height: $size;
               border-width: 4px;
               border-color: #363636;
            }
         }
      }
      .widget-table-header-wrapper {
         display: flex;
         align-items: center;
         // border: 1px solid #ccc;
         ul.table-tabs {
            display: flex;

            li {
               background-color: #eee;
               padding: 0.5rem 1.5rem;
               border-radius: 24rem;
               margin: 0 0.5rem;
               white-space: nowrap;

               &:first-child {
                  margin-left: 0;
               }
               &:last-child {
                  margin-right: 0;
               }
            }
         }
         .widget-table-cta-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .widget-table-actions-wrapper {
               display: flex;
               align-items: center;
               button {
                  background-color: transparent;
                  cursor: pointer;
               }
               .show-online-checkins,
               .show-online-reservations,
               .show-charter-flights {
                  &.filter-active {
                     background-color: #ffdb4d;
                     border-color: transparent !important;
                     color: #fff;
                  }
                  display: flex;
                  align-items: center;
                  height: 100%;
                  border-radius: 0.5rem;
                  padding: 0.5rem 1rem;
                  padding-left: 0.5rem;
                  background: #eaeaea;
                  margin-left: 0.5rem;
                  // border: 2px solid #ccc;
                  color: var(--txt-black) !important;
                  i {
                     width: 24px !important;
                     height: 24px !important;
                     margin-right: 0.5rem;
                  }
                  .gts-tooltip {
                     padding: 0px !important;
                  }
               }
               .day-info {
                  margin-left: 8px;
               }
            }

            .widget-table-search-wrapper {
               position: relative;
               input {
                  width: 200px;
                  border-radius: 24rem;
                  outline: 0;
                  border: 2px solid #ccc;
                  height: 40px;
                  text-indent: 1rem;
                  color: #363636;
                  text-transform: uppercase;
                  font-size: var(--small);
                  background-color: #f6f6f6;

                  &:focus {
                     border-color: var(--accent-primary-lighten);
                     background-color: #98dcff14;
                     font-weight: 500;
                  }

                  &::-webkit-input-placeholder {
                     text-transform: capitalize;
                  }
               }

               .clear-search {
                  cursor: pointer;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 0.75rem;
                  height: fit-content;
                  margin: auto;
                  width: 18px !important;
                  height: 18px !important;

                  &.disabled {
                     opacity: 0;
                  }
               }
            }
         }
      }

      .widget-table-row {
         display: grid;
         grid-template-columns: 1fr 3fr 3fr 3fr 2fr;
         align-content: center;
         border-radius: 24rem;
         min-height: 65px;
         padding: 0.5rem 1rem;
         margin-bottom: 0.75rem;
         // box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;

         &:hover {
            cursor: pointer;
            border-color: var(--accent-primary-lighten) !important;
            background-color: #98dcff14 !important;
         }

         &.table-header {
            background-color: transparent !important;
            padding-bottom: 0;
            min-height: fit-content;
            margin-top: 1rem;
            // grid-template-columns: 0.5fr 2fr 5fr 2fr 2fr !important;

            .widget-table-header {
               // color: #888888;
            }
         }

         &.zebra-color {
            &:nth-child(odd) {
               border: 2px solid transparent;
               background-color: #f0f2f5;
            }
            &:nth-child(even) {
               border: 2px solid #e1e3e6;
            }
         }

         .widget-table-col {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .gts-tooltip {
               justify-content: flex-start !important;
            }
            .widget-td-status {
               cursor: pointer;
               width: 116px;

               &.overflow-entity {
                  overflow: hidden;
               }
               i {
                  font-style: normal !important;
               }

               &-2 {
                  // border: 2px solid #63e9a6;
                  background-color: #03c3ff50;
                  color: #0074b4;

                  &:hover {
                     background-color: #0074b4;
                     color: #fff;
                  }
               }
               &-3 {
                  // border: 2px solid #63e9a6;
                  background-color: #03ff8150;
                  color: #0bb05e;

                  &:hover {
                     background-color: #0bb05e;
                     color: #fff;
                  }
               }
               &-4 {
                  // border: 2px solid #63e9a6;
                  background-color: #ff031350;
                  color: #dc0e0e;

                  &:hover {
                     background-color: #dc0e0e;
                     color: #fff;
                  }
               }
               &-5 {
                  // border: 2px solid #63e9a6;
                  background-color: #ff031350;
                  color: #dc0e0e;

                  &:hover {
                     background-color: #dc0e0e;
                     color: #fff;
                  }
               }
               &-6 {
                  // border: 2px solid #63e9a6;
                  background-color: #ff05974f;
                  color: #b10b6c !important;

                  &:hover {
                     background-color: #b10b6f;
                     color: #fff !important;
                  }
               }
            }
            .widget-td-datetime {
               &.highlighted-date {
                  color: #ff4c4c;
                  font-weight: 600;
                  position: relative;

                  @keyframes attention {
                     0% {
                        opacity: 0;
                     }
                     100% {
                        opacity: 1;
                     }
                  }

                  &::before {
                     content: "";
                     opacity: 0;
                     width: 8px;
                     height: 8px;
                     position: absolute;
                     top: 0.35rem;
                     margin: auto;
                     left: -0.75rem;
                     border-radius: 50%;
                     background-color: #ff4c4c;
                     animation-name: attention;
                     animation-fill-mode: backwards;
                     animation-iteration-count: 10;
                     animation-timing-function: ease-out;
                     animation-duration: 0.75s;
                  }
               }
            }
            .widget-td-online-checkin {
               font-weight: 600;
               color: var(--primary);
               text-decoration: underline !important;
            }
         }

         &.no-data {
            text-align: center;
            grid-template-columns: 1fr;

            .widget-table-col {
               color: #666;
               font-weight: 300;
               font-size: 1.125rem;
            }
         }
      }
   }
}
</style>
