import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";
import Popup from "@/gts-popup.js";
import i18n from "@/fmt/i18n.js";
import firebaseMessaging from "@/firebase.js";
import { generateUuid } from "@/utils.js";
import { useFavicon, useTitle } from "@vueuse/core";
import { refreshToken } from "@/service/refresh.token.js";
import getDeviceInfo from "./device.info";
import { TokenService, globalService, agencyService } from "@/service/index.js";
import RouterList from "./router.list";

Vue.use(VueRouter);

const icon = useFavicon();
const title = useTitle();

const router = new VueRouter({
   mode: "history",
   base: process.env.BASE_URL,
   routes: RouterList,
});

router.beforeEach(async (to, from, next) => {
   const bodyClass = document.body.classList.contains("g-form-invalid");
   bodyClass ? document.body.classList.remove("g-form-invalid") : undefined;

   store.commit("app/setIsProgressGifActive", false);
   store.state.app.progress.message = "";

   if (from === to) {
      return next();
   }

   if (to.name == "Announcement") return next();

   const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.title);
   const nearestWithMeta = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.metaTags);
   const previousNearestWithMeta = from.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.metaTags);

   if (nearestWithTitle) {
      setBrowserTitle(nearestWithTitle.meta.title);
   } else if (previousNearestWithMeta) {
      setBrowserTitle(previousNearestWithMeta.meta.title);
   }

   if (nearestWithMeta) {
      // Eski meta etiketlerini temizle
      Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode.removeChild(el));

      // Yeni meta etiketlerini ekle
      nearestWithMeta.meta.metaTags
         .map((tagDef) => {
            const tag = document.createElement("meta");

            Object.keys(tagDef).forEach((key) => {
               tag.setAttribute(key, tagDef[key]);
            });

            tag.setAttribute("data-vue-router-controlled", "");

            return tag;
         })
         .forEach((tag) => document.head.appendChild(tag));
   }

   if (to.path == "/ResetPassword") {
      TokenService.delJwtInfo();
      return next();
   }

   const publicPages = ["/login", "/register", "/ResetPassword", "/announcement"];
   const authRequired = !publicPages.includes(to.path);
   let loggedIn = !TokenService.isJwtExpired();
   const loggedOut = TokenService.isJwtDeleted();

   if (to.path == "/logout" || loggedOut) {
      TokenService.delJwtInfo();
      localStorage.clear();
      sessionStorage.clear();

      if (to.path == "/login") {
         return next();
      }

      const currentPath = window.location.pathname;
      if (currentPath == "/login") return next();
      else return next({ path: "/login", query: { returnUrl: currentPath } });
   }

   const toRoles = to.meta.roles;
   const fromRoles = from.meta.roles;
   let userRoles = null;
   if (localStorage.getItem("auths")) {
      try {
         userRoles = JSON.parse(localStorage.getItem("auths"));
      } catch {
         // console.warn("Cannot parse auths :", localStorage.getItem("auths"));
      }
   }

   if (loggedIn && to.path == "/login") {
      return next({ path: "/" });
   }

   if (authRequired && !loggedIn) {
      try {
         await refreshToken();
         loggedIn = true;
         return next();
      } catch (error) {
         return next({ path: "/login", query: { returnUrl: to.fullPath } });
      }
   }

   if (toRoles) {
      if (!loggedIn) {
         if (from.name == "Login") {
            return next();
         }
         TokenService.delJwtInfo();
         return next({ path: "/login", query: { returnUrl: to.path } });
      } else if (toRoles.length && userRoles != null && !toRoles.some((role) => userRoles.includes(role))) {
         Popup.warning(`${i18n.t("router_noAuth")}`, `${i18n.t("router_contactForAuth")}`, "", 30000);

         if (fromRoles && !fromRoles.some((role) => userRoles.includes(role))) {
            return next({ path: "/" });
         } else if (from.path == "/") {
            return next({ path: "/" });
         } else {
            return next();
         }
      }
   }

   if (authRequired && store.state.user.user && !store.state.user.user.initiated) {
      try {
         const response = await globalService.startupService(false, {});

         Vue.prototype.$constants.showTargetAchievementIcon = response.info.originalAgency.displayAgencyKpi;
         if (response.info.originalGsa.cssCode) {
            Vue.prototype.$constants.brand = response.info.originalGsa;
         }

         icon.value = `favicons/${Vue.prototype.$constants.brand.cssCode}.png`;
         title.value = Vue.prototype.$constants.brand.title;

         if (response.result.success) {
            store.commit("app/setSpaLastVersion", response.spaVersion);

            try {
               const [listGsasResponse, listAgenciesResponse] = await Promise.all([agencyService.listGsas({}), agencyService.listAgencies({})]);

               if (listGsasResponse.result.success) {
                  store.commit("app/setGsaList", listGsasResponse.gsas);
               }
               if (listAgenciesResponse.result.success) {
                  store.commit("app/setAgencyList", listAgenciesResponse.agencies);
               }
            } catch (error) {
               console.error("Error fetching gsas or agencies:", error);
            }

            const userInfos = {
               language: response.info.user.language,
               name: response.info.user.name,
               surname: response.info.user.surname,
               timezoneId: response.info.user.timezoneId,
               email: response.info.user.email,
               id: response.info.user.id,
               agencyOnBehalfOfId: response.info.onBehalfOfAgency.id,
               gsaOnBehalfOfId: response.info.onBehalfOfGsa.id,
               agencyName: response.info.originalAgency.name,
               agencyIatiId: response.info.originalAgency.iatiId,
               agencyId: response.info.originalAgency.id,
               gsaId: response.info.originalGsa.id,
               gsaName: response.info.originalGsa.name,
               initiated: true,
               originalGsaCssCode: response.originalGsaCssCode,
            };

            store.commit("user/updateUserInformations", userInfos);

            switch (response.userLanguage) {
               case 1:
                  i18n.locale = "tr";
                  break;
               case 2:
                  i18n.locale = "en";
                  break;
               case 3:
                  i18n.locale = "de";
                  break;
               default:
                  i18n.locale = "en";
                  break;
            }

            if (to.meta.agencyRequired && response.originalAgencyId == -1 && response.onBehalfOfAgencyId == -1) {
               if (from && (publicPages.includes(from.path) || from.path == "/" || from.meta.agencyRequired)) {
                  if (!publicPages.includes(from.path)) {
                     setTimeout(() => {
                        Popup.warning(i18n.t("router_chooseAgency"), i18n.t("router_agencyRequired"), "", 30000);
                     }, 500);
                  }
                  return next({ path: "/" });
               } else {
                  Popup.warning(i18n.t("router_chooseAgency"), i18n.t("router_agencyRequired"), "", 30000);
                  return next();
               }
            }

            try {
               const currentToken = await firebaseMessaging.getToken({ vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY });
               if (currentToken && loggedIn) {
                  const deviceInfo = getDeviceInfo();
                  const query = {
                     uuid: generateUuid(),
                     application: 2,
                     token: currentToken,
                     os: deviceInfo.os,
                     osVersion: deviceInfo.osVersion.toString(),
                     browser: deviceInfo.browser,
                     browserVersion: deviceInfo.browserVersion.toString(),
                  };
                  const res = await globalService.notificationRegister(query);
                  if (res.result.success) {
                     store.commit("app/setNotificationCount", res.unreadCount);
                  }
               }
            } catch (error) {
               console.error("Error in Firebase token or notification registration:", error);
            }

            return next();
         } else {
            return next();
         }
      } catch (error) {
         console.error("Error in startupService:", error);
         return next(false);
      }
   } else {
      const userAgencyId = store.state.user.user.agencyId;
      const userAgencyOnBehalfOfId = store.state.user.user.onBehalfOfs.agencyOnBehalfOfId;

      if (to.meta.agencyRequired && userAgencyId == -1 && userAgencyOnBehalfOfId == -1) {
         if (from && (publicPages.includes(from.path) || from.path == "/" || from.meta.agencyRequired)) {
            if (!publicPages.includes(from.path)) {
               setTimeout(() => {
                  Popup.warning(i18n.t("router_chooseAgency"), i18n.t("router_agencyRequired"), "", 30000);
               }, 500);
            }
            return next({ path: "/" });
         } else {
            Popup.warning(i18n.t("router_chooseAgency"), i18n.t("router_agencyRequired"), "", 30000);
            return next();
         }
      }
      return next();
   }
});

function setBrowserTitle(title) {
   document.title = i18n.t("router_title" + title);
}

export default router;
