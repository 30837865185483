<template>
   <div id="router-cards-wrapper">
      <div id="router-cards-inner-container">
         <div class="shortcuts">
            <router-link :disabled="!roles.includes(1)" :event="roles.includes(1) ? 'click' : ''" to="/flight" class="shortcut-flight">
               <div class="icon-wrapper">
                  <i class="gts-icon icon-dashboard-shortcuts-flight"></i>
               </div>
               <span>{{ $t("common_searchFlight") }}</span>
            </router-link>

            <router-link :disabled="!roles.includes(2)" :event="roles.includes(2) ? 'click' : ''" to="/hotel" class="shortcut-hotel">
               <div class="icon-wrapper">
                  <i class="gts-icon icon-dashboard-shortcuts-hotel"> </i>
               </div>
               <span>{{ $t("common_searchHotel") }}</span>
            </router-link>
            <router-link
               :disabled="!roles.includes(3)"
               :event="roles.includes(25) ? 'click' : ''"
               to="/packages"
               class="shortcut-schimetterling"
               @click.native="handlerPackagesPopup">
               <div class="icon-wrapper">
                  <i class="gts-icon icon-dashboard-shortcuts-schimetterling"> </i>
               </div>
               <span>{{ $t("common_searchPackages") }}</span>
            </router-link>

            <router-link :disabled="!roles.includes(3)" :event="roles.includes(3) ? 'click' : ''" to="/transfer" class="shortcut-transfer">
               <div class="icon-wrapper">
                  <i class="gts-icon icon-dashboard-shortcuts-transfer"> </i>
               </div>
               <span>{{ $t("common_searchTransfer") }}</span>
            </router-link>

            <router-link :disabled="!roles.includes(3)" :event="roles.includes(22) ? 'click' : ''" to="/rentacar" class="shortcut-rentacar">
               <div class="icon-wrapper">
                  <i class="gts-icon icon-dashboard-shortcuts-rentacar"> </i>
               </div>
               <span>{{ $t("common_searchRentACar") }}</span>
            </router-link>

            <!-- Add New Shortcut Button Will Be Added Later -->
            <!-- <div class="add-new-shortcut" v-if="true">+</div> -->
         </div>
      </div>
   </div>
</template>

<script>
import Popup from "@/gts-popup.js";
export default {
   name: "RouterCards",
   data() {
      return {
         roles: [],
      };
   },
   mounted() {
      this.roles = JSON.parse(localStorage.getItem("auths"));
   },
   methods: {
      handlerPackagesPopup() {
         Popup.info(this.$i18n.t("Popup_lblPackagesTitle"), this.$i18n.t("Popup_lblPackagesContent"));
      },
   },
};
</script>

<style lang="scss" scoped>
#router-cards-wrapper {
   height: 100%;
   #router-cards-inner-container {
      display: flex;
      justify-content: space-between;
      height: 100%;
      min-height: 100px;
      .shortcuts {
         display: flex;
         justify-content: space-between;
         width: 100%;
         a {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #4da6ff;
            border-radius: 16px;
            line-height: 1.45rem;
            height: 100%;
            width: 100%;
            margin: 0 0.25rem;
            transition-property: all;
            transition-timing-function: ease-in-out;
            transition-duration: 0.05s;

            &.shortcut-flight {
               background-color: var(--primary-flight) !important;
            }
            &.shortcut-hotel {
               background-color: var(--primary-hotel) !important;
            }
            &.shortcut-transfer {
               background-color: var(--primary-transfer) !important;
            }
            &.shortcut-rentacar {
               background-color: var(--primary-rentacar) !important;
            }
            &.shortcut-schimetterling {
               background-color: #e65c8a !important;

               .icon-wrapper {
                  margin-left: 5px;
               }
            }

            span {
               color: #fff;
               font-size: var(--large);
               font-weight: 500;
               width: 100%;
               display: none;
               text-align: center;
               padding-top: 0.125rem;
               @media (min-width: 576px) {
                  display: block;
               }
            }
            &[disabled="disabled"] {
               opacity: 0.5;
               pointer-events: none;
            }

            &:hover {
               // background-color: var(--accent-primary) !important;
               transform: scale(1.05);
            }
            &:first-child {
               margin-left: 0 !important;
            }
            &:last-child {
               margin-right: 0;
            }

            .icon-wrapper {
               height: auto;
               display: flex;
               align-items: flex-end;
               justify-content: center;
               margin-bottom: 0.5rem;
               i {
                  transform: scale(1.125);
                  &.icon-dashboard-shortcuts-flight {
                     width: 31px !important;
                     height: 32px !important;
                  }
                  &.icon-dashboard-shortcuts-hotel {
                     width: 29px !important;
                     height: 32px !important;
                  }
                  &.icon-dashboard-shortcuts-transfer {
                     width: 51px !important;
                     height: 26px !important;
                  }
                  &.icon-dashboard-shortcuts-rentacar {
                     width: 33px !important;
                     height: 32px !important;
                  }
               }
            }
         }
         .add-new-shortcut {
            background-color: #e0ebf8;
            border: 1px dashed #4da6ff;
            border-radius: 0.75rem;
            color: #4da6ff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 3rem;
            padding: 0 1.5rem;
            margin-left: 0.5rem;
         }
      }
   }
}
</style>
