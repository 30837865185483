import store from "@/store/index.js";
import router from "@/router";
import { generateUuid } from "@/utils.js";
import Popup from "@/gts-popup.js";
import { flightService } from "@/service/index.js";
import { hotelService } from "@/service/index.js";
import { transferService, rentacarService } from "@/service/index.js";

function funcReverseInquiryRentacar(self) {
   let request = {
      uuid: generateUuid(),
      rnrId: self.entityId,
   };
   self.showLoader = true;
   rentacarService.rentACarReverseInquiry(request).then((response) => {
      if (response.result.success && [1].includes(response.enabledReverseType)) {
         // console.log(response);
         self.reversable = {
            withPenalty: false,
            isReversable: true,
         };
      }
      if (!response.autoEnabled) {
         self.reversable = {
            withPenalty: false,
            isReversable: false,
         };
      }
      self.showLoader = false;
   });
}
function funcReverseInquiryFlight(self) {
   let request = {
      uuid: generateUuid(),
      orderUuid: store.state.order.orderUuid,
      pnrId: self.entityId,
   };
   self.showLoader = true;
   flightService.flightReverseInquiry(request).then((response) => {
      //console.log(`response:${JSON.stringify(response)}`);
      if (response.result.success && [1, 2, 3, 6].includes(response.enabledReverseType)) {
         //1: "VOIDABLE",2: "REFUNDABLE_WO_PENALTY",3: "REFUNDABLE_WITH_PENALTY",6: "CANCELABLE"
         self.inquiryUuid = response.inquiryUuid;
         self.reversable = {
            withPenalty: false,
            isReversable: true,
         };
         if (response.enabledReverseType == 3)
            self.reversable = {
               withPenalty: true,
               isReversable: true,
               penalty: response.penalty,
               currency: response.currency,
               refund: response.refund,
            };
         if (response.secondsLeft > 0)
            setTimeout(() => {
               self.reversable.isReversable = false;
            }, response.secondsLeft * 1000);
      }
      self.showLoader = false;
   });
}
// export function funcReverseInquiryTransfer(self, flightService) {
//    // const self = this;
//    // let request = {
//    //    uuid: generateUuid(),
//    //    orderUuid: this.$store.state.order.orderUuid,
//    //    tnrId: self.entityId,
//    // };
//    // // console.log(`request:${JSON.stringify(request)}`);
//    // transferService.transferReverseInquiry(request).then((response) => {
//    //    // console.log(`response:${JSON.stringify(response)}`);
//    //    if (response.result.success && [1, 2].includes(response.enabledReverseType)) self.reversable = true;
//    // });
// }
function funcReverseFlight(self, pnrId) {
   let request = {
      uuid: generateUuid(),
      inquiryUuid: self.inquiryUuid,
   };
   let msg = self.$i18n.t(`${self.$options.name}_progressGif_ReverseFlight`);
   store.commit("app/showProgressGifMsg", msg);
   // console.log(`request:${JSON.stringify(request)}`);
   flightService.flightReverse(request).then((response) => {
      // console.log(`response:${JSON.stringify(response)}`);
      store.commit("app/hideProgressGif");
      response.service = "REVERSE_FLIGHT";
      if (response.result.success) {
         router.push(`${router.history.current.path}?pnrId=${pnrId}`);
         window.location.reload();
      } else Popup.result(response.result);
   });
}
function funcReverseHotel(self) {
   let request = {
      uuid: generateUuid(),
      voucherId: self.entityId,
   };
   let msg = self.$i18n.t(`${self.$options.name}_progressGif_ReverseHotel`);
   store.commit("app/showProgressGifMsg", msg);
   // console.log(`request:${JSON.stringify(request)}`);
   hotelService.hotelReverse(request).then((response) => {
      // console.log(`response:${JSON.stringify(response)}`);
      store.commit("app/hideProgressGif");
      response.service = "REVERSE_HOTEL";
      if (response.result.success) window.location.reload();
      else Popup.result(response.result);
   });
}
function funcReverseTransfer(self) {
   let request = {
      uuid: generateUuid(),
      tnrId: self.entityId,
   };
   let msg = self.$i18n.t(`${self.$options.name}_progressGif_ReverseTransfer`);
   store.commit("app/showProgressGifMsg", msg);
   // console.log(`request:${JSON.stringify(request)}`);
   transferService.transferReverse(request).then((response) => {
      // console.log(`response:${JSON.stringify(response)}`);
      store.commit("app/hideProgressGif");
      response.service = "REVERSE_TRANSFER";
      if (response.result.success) window.location.reload();
      else Popup.result(response.result);
   });
}
function funcReverseRentACar(self) {
   let request = {
      uuid: generateUuid(),
      rnrId: self.entityId,
   };
   let msg = self.$i18n.t(`${self.$options.name}_progressGif_ReverseTransfer`);
   store.commit("app/showProgressGifMsg", msg);
   // console.log(`request:${JSON.stringify(request)}`);
   rentacarService.rentACarReverse(request).then((response) => {
      // console.log(`response:${JSON.stringify(response)}`);
      store.commit("app/hideProgressGif");
      response.service = "REVERSE_RENTACAR";
      if (response.result.success) window.location.reload();
      else Popup.result(response.result);
   });
}
export { funcReverseFlight, funcReverseHotel, funcReverseTransfer, funcReverseInquiryFlight, funcReverseRentACar, funcReverseInquiryRentacar };
