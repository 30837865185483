<template>
   <div class="order-detail-view-wrapper order-detail-flight-view">
      <div class="order-detail-view-inner">
         <!--OldPnrInformations Information -->

         <OldPnrInformations :data="pnrObj.pnr" v-if="false" />

         <!--Reservation Information -->
         <ReservationInformation :data="pnrObj.pnr" :status="pnrObj.pnr.status" :show-reoption="ShowReoptionBtn" />

         <!--Flight Information -->

         <FlightInformations :data="pnrObj.flights" />

         <!--Passenger Information -->
         <PassengerInformations :data="pnrObj.passengers" :grand-total="pnrObj" />

         <!-- Order Detail Payment -->
         <PaymentPurchaseWidget entityType="flight" :entityId="pnrObj.pnr.id" :paymentChoiceDetail="PaymentChoiceDetail" @show="handlerShowWidget" />

         <!--Ancillaries-->

         <Ancillaries
            :pnr="pnrObj.pnr"
            :flights="pnrObj.flights"
            :passengers="pnrObj.passengers"
            v-if="ShowAncillaries"
            :ancillary-choices="pnrObj.ancillaryChoices"
            :onlineCheckInUrl="pnrObj.ancillaryChoices.onlineCheckinUrl" />
      </div>
   </div>
</template>

<script>
import OldPnrInformations from "@/components/structural/flight/order-detail/pnr-informations/OldPnrInformations.vue";
import ReservationInformation from "@/components/structural/flight/order-detail/pnr-informations/ReservationInfos.vue";
import FlightInformations from "@/components/structural/flight/order-detail/pnr-informations/FlightInformations.vue";
import PassengerInformations from "@/components/structural/flight/order-detail/pnr-informations/PassengerInformations.vue";
import PaymentPurchaseWidget from "@/components/structural/payment/PaymentPurchaseWidget.vue";
import Ancillaries from "@/components/structural/flight/order-detail/ancillaries/base/Ancillaries.vue";

export default {
   name: "OrderDetailFlightView",
   props: {
      pnrProps: Object,
   },
   components: {
      OldPnrInformations,
      ReservationInformation,
      FlightInformations,
      PassengerInformations,
      PaymentPurchaseWidget,
      Ancillaries,
   },
   data() {
      return {
         pnrObj: this.pnrProps,
         paymentChoices: this.pnrProps.paymentChoiceDetail.paymentChoices,
      };
   },
   computed: {
      ShowReoptionBtn() {
         return this.pnrObj.ancillaryChoices.reoption;
      },
      ShowAncillaries() {
         if (this.pnrObj.ancillaryChoices != undefined) {
            return Object.keys(this.pnrObj.ancillaryChoices).some((key) => {
               return this.pnrObj.ancillaryChoices[key] == true;
            });
         } else {
            return false;
         }
      },
      PaymentChoiceDetail() {
         return this.pnrProps ? this.pnrProps.paymentChoiceDetail : null;
      },
   },
   methods: {
      handlerShowWidget(status) {
         status;
      },
   },
   watch: {
      pnrProps: {
         handler: function (newPnrProps) {
            const self = this;
            self.pnrObj = newPnrProps;
            self.paymentChoices = newPnrProps.paymentChoices;
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.order-detail-view-inner {
   > div {
      margin-bottom: 4rem;
   }
}
</style>
