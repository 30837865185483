<template>
   <div class="hotel-sales-report-page-container sub-page-wrapper">
      <Aside />
      <Main>
         <Navbar />

         <div class="container-fluid">
            <div class="row">
               <div class="col gts-box mx-3">
                  <h1 class="sub-page-header">{{ $t("hotelSalesReport_title") }}</h1>

                  <div class="sub-page-row-wrapper row gy-3">
                     <div class="col-12 col-md-6 col-lg-2">
                        <UISelectBox
                           :key="refreshKey3"
                           :label="$t('hotelSalesReport_filter_lblReportType')"
                           :options="reportTypes"
                           v-model="filters.reportType"
                           light-theme />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIDatePicker2
                           :key="refreshKey1"
                           :label="$t('hotelSalesReport_filter_lblFromDate')"
                           :min="filters.date1.min"
                           :max="filters.date1.max"
                           v-model="filters.date1.value" />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIDatePicker2
                           :key="refreshKey2"
                           :label="$t('hotelSalesReport_filter_lblToDate')"
                           :min="filters.date2.min"
                           :max="filters.date2.max"
                           v-model="filters.date2.value" />
                     </div>
                     <div class="col-12 col-md-6 col-lg-2">
                        <UIMultiSelect
                           :key="htlStatusListKey"
                           :label="$t('hotelSalesReport_filter_lblStatus')"
                           :options="voucherStatusList"
                           v-model="filters.voucherStatuses"
                           light-theme
                           allow-search />
                     </div>
                  </div>

                  <!--  CTA -->
                  <div class="sub-page-actions-row row">
                     <div class="sub-pages-actions-wrapper col-12">
                        <UIButton className="default" :text="$t('hotelSalesReport_filter_btnList')" @click.native="funcHotelSalesReport" />
                     </div>
                  </div>
               </div>
            </div>

            <!-- Summary & Sale Report -->
            <template v-if="reportLoad">
               <!-- Sale Report Summary -->
               <ReportSummary :entityType="'hotel'" :summary="summary" />

               <!-- Sale Result Container -->
               <section class="smart-table-wrapper">
                  <SmartTable :options="mainTableOptions" />
               </section>
            </template>
         </div>
      </Main>
   </div>
</template>

<script>
import moment from "moment";
import i18n from "@/fmt/i18n.js";
import Popup from "@/gts-popup.js";
import { reportService } from "@/service/index.js";
import Main from "@/components/unitary/Main.vue";
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import SmartTable from "@/components/utils/SmartTable/SmartTableComp.vue";
import UIButton from "@/components/unitary/Button.vue";
import UISelectBox from "@/components/unitary/form2/UISelectBox.vue";
import UIDatePicker2 from "@/components/unitary/form2/UIDatePicker2.vue";
import UIMultiSelect from "@/components/unitary/form2/UIMultiSelect.vue";
import ReportSummary from "@/components/unitary/SalesReportSummary.vue";

export default {
   name: "HotelSalesReport",
   components: {
      Aside,
      Navbar,
      Main,
      UISelectBox,
      UIDatePicker2,
      UIButton,
      SmartTable,
      UIMultiSelect,
      ReportSummary,
   },

   data() {
      return {
         isFirstSearch: true,
         reportLoad: false,
         refreshKey1: 1,
         refreshKey2: 5,
         refreshKey3: 7,
         htlStatusListKey: 0,
         reportTypes: this.$GtsDropdownData.EReportHotelDateType(),
         statuses: this.$GtsDropdownData.EHtlVoucherStatus(),
         voucherStatusList: [],
         summary: null,
         filters: {
            reportType: 2, //SALE
            voucherStatuses: [2, 3],
            date1: {
               min: moment().subtract(1, "year").format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().format(this.$constants.dateFormat),
            },
            date2: {
               min: moment().subtract(1, "year").format(this.$constants.dateFormat),
               max: moment().format(this.$constants.dateFormat),
               value: moment().format(this.$constants.dateFormat),
            },
         },
         mainTableOptions: {
            columns: [
               { label: `${i18n.t("hotelSalesReport_tblMain_thOrderId")}`, status: true, identifier: "orderId" },
               { label: `${i18n.t("hotelSalesReport_tblMain_thVoucher")}`, status: true },
               { label: `${i18n.t("hotelSalesReport_tblMain_thVoucherStatus")}`, status: true },
               { label: `${i18n.t("hotelSalesReport_tblMain_thAgencyName")}`, status: true },
               { label: `${i18n.t("hotelSalesReport_tblMain_thHotelName")}`, status: true },
               { label: `${i18n.t("hotelSalesReport_tblMain_thContactName")}`, status: true },
               { label: `${i18n.t("hotelSalesReport_tblMain_thContactSurname")}`, status: true },
               { label: `${i18n.t("hotelSalesReport_tblMain_thCheckinDate")}`, status: true },
               { label: `${i18n.t("hotelSalesReport_tblMain_thCheckoutDate")}`, status: true },
               { label: `${i18n.t("hotelSalesReport_tblMain_thSaleDateTime")}`, status: true },
               { label: `${i18n.t("hotelSalesReport_tblMain_thAgencyCommission")}`, status: true },
               { label: `${i18n.t("hotelSalesReport_tblMain_thTotal")}`, status: true },
               { label: `${i18n.t("hotelSalesReport_tblMain_thRoomCount")}`, status: true },
               { label: `${i18n.t("hotelSalesReport_tblMain_thGuestCount")}`, status: true },
               { label: `${i18n.t("hotelSalesReport_tblMain_thOptionDaysLeft")}`, status: true },
               { label: `${i18n.t("hotelSalesReport_tblMain_thOnUsChannel")}`, status: true },
            ],
            rows: [],
            rules: {
               autoMerge: ["orderId"],
               dominantCell: "orderId",
               showOnMobile: [0, 2, 4, 7],
               highlightedColumns: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
            },
            options: {
               header: {
                  title: this.$options.name,
                  viewOptions: {
                     status: true,
                     perPage: 50,
                     selects: [
                        { name: "50 Order", value: 50 },
                        { name: "100 Order", value: 100 },
                        { name: "All", value: 2000 },
                     ],
                  },
                  viewMode: null,
                  saleDateIndex: 9,
               },
               footer: {
                  pagination: {
                     pages: [],
                     activepage: 1,
                  },
               },
            },
         },
      };
   },
   computed: {
      Direct() {
         return i18n.t("hotelSalesReport_tblMain_tdChannelGts");
      },
      Provider() {
         return i18n.t("hotelSalesReport_tblMain_tdChannelProvider");
      },
   },
   mounted() {
      this.updateHtlStatusList();
   },
   methods: {
      updateHtlStatusList() {
         this.voucherStatusList = [];
         for (let i = 1; i < 21; i++) {
            const rowObj = { value: i, name: this.$options.filters.EHtlVoucherStatus(i) };
            this.voucherStatusList.push(rowObj);
         }
         this.htlStatusListKey += 1;
         this.$nextTick(() => {
            this.voucherStatusList = this.voucherStatusList.slice();
         });
      },
      getTableColumns() {
         return [
            { label: `${i18n.t("hotelSalesReport_tblMain_thOrderId")}`, status: true, identifier: "orderId" },
            { label: `${i18n.t("hotelSalesReport_tblMain_thVoucher")}`, status: true },
            { label: `${i18n.t("hotelSalesReport_tblMain_thVoucherStatus")}`, status: true },
            { label: `${i18n.t("hotelSalesReport_tblMain_thAgencyName")}`, status: true },
            { label: `${i18n.t("hotelSalesReport_tblMain_thHotelName")}`, status: true },
            { label: `${i18n.t("hotelSalesReport_tblMain_thContactName")}`, status: true },
            { label: `${i18n.t("hotelSalesReport_tblMain_thContactSurname")}`, status: true },
            { label: `${i18n.t("hotelSalesReport_tblMain_thCheckinDate")}`, status: true },
            { label: `${i18n.t("hotelSalesReport_tblMain_thCheckoutDate")}`, status: true },
            { label: `${i18n.t("hotelSalesReport_tblMain_thSaleDateTime")}`, status: true },
            { label: `${i18n.t("hotelSalesReport_tblMain_thAgencyCommission")}`, status: true },
            { label: `${i18n.t("hotelSalesReport_tblMain_thTotal")}`, status: true },
            { label: `${i18n.t("hotelSalesReport_tblMain_thRoomCount")}`, status: true },
            { label: `${i18n.t("hotelSalesReport_tblMain_thGuestCount")}`, status: true },
            { label: `${i18n.t("hotelSalesReport_tblMain_thOptionDaysLeft")}`, status: true },
            { label: `${i18n.t("hotelSalesReport_tblMain_thOnUsChannel")}`, status: true },
         ];
      },
      updateTableColumns() {
         this.mainTableOptions.columns = this.getTableColumns();
      },
      funcHotelSalesReport() {
         const self = this;

         self.reportLoad = false;
         self.summary = null;
         self.mainTableOptions.rows.length = 0;
         self.$store.commit("app/setIsProgressGifActive", true);

         let request = {
            dateType: self.filters.reportType,
            statuses: self.filters.voucherStatuses,
            date1: self.filters.date1.value,
            date2: self.filters.date2.value,
         };

         reportService.hotelSalesReport(request).then((response) => {
            self.$store.commit("app/setIsProgressGifActive", false);

            if (!response.result.success) {
               response.result.service = this.$options.name;
               Popup.result(response.result);
               return;
            }
            this.isFirstSearch = false;
            self.summary = response.summary;

            response.vouchers.map((voucher) => {
               const rowObj = {
                  orderId: {
                     text: voucher.orderId,
                     class: `gts-badge smart-table-badge-1`,
                     url: `/order/detail/${voucher.orderUuid}`,
                  },
                  voucher: {
                     text: `${!voucher.voucher ? "" : voucher.voucher}`,
                     class: "",
                  },
                  voucherStatus: {
                     text: this.$options.filters.EHtlVoucherStatus(voucher.voucherStatus),
                     class: `voucher-badge gts-badge EHtlVoucherStatus-${voucher.voucherStatus}`,
                  },
                  agencyName: voucher.agencyName,
                  hotelName: voucher.hotelName,
                  contactName: voucher.contactName,
                  contactSurname: voucher.contactSurname,
                  checkInDate: {
                     text: this.$options.filters.fullDateFormat(voucher.checkInDate),
                     class: "",
                  },
                  checkOutDate: {
                     text: this.$options.filters.fullDateFormat(voucher.checkOutDate),
                     class: "",
                  },
                  saleDateTime: {
                     text: this.$options.filters.fullDateFormat(voucher.saleDateTime),
                     class: "",
                  },
                  agencyCommission: {
                     text: this.$options.filters.price(voucher.agencyCommission),
                     class: "price-display",
                  },
                  total: {
                     text: this.$options.filters.price(voucher.total),
                     class: "price-display",
                  },
                  roomCount: voucher.roomCount,
                  guestCount: voucher.guestCount,
                  optionDaysLeft: voucher.optionDaysLeft,
                  onUsChannel: voucher.onUsChannel ? this.Direct : this.Provider,
               };
               self.mainTableOptions.rows.push(rowObj);
            });
            self.reportLoad = true;
         });
      },
      funcRefreshKeys() {
         this.refreshKey1++;
         this.refreshKey2++;
         this.refreshKey3++;
      },
   },
   watch: {
      "$i18n.locale"() {
         this.reportTypes = this.$GtsDropdownData.EReportHotelDateType();
         this.statuses = this.$GtsDropdownData.EHtlVoucherStatus();
         this.updateHtlStatusList();
         if (this.isFirstSearch == false) {
            this.funcHotelSalesReport();
         }
         this.updateTableColumns();
         this.funcRefreshKeys();
      },
      "filters.reportType"() {
         if (this.filters.reportType == 2) {
            this.filters.date1.value = moment().format(this.$constants.dateFormat);
            this.filters.date2.value = moment().format(this.$constants.dateFormat);
            this.filters.date1.min = moment().subtract(1, "year").format(this.$constants.dateFormat);
            this.filters.date1.max = moment().format(this.$constants.dateFormat);
            this.filters.date2.min = moment().subtract(1, "year").format(this.$constants.dateFormat);
            this.filters.date2.max = moment().format(this.$constants.dateFormat);
         } else if (this.filters.reportType == 3) {
            this.filters.date1.value = moment().format(this.$constants.dateFormat);
            this.filters.date2.value = moment().format(this.$constants.dateFormat);
            this.filters.date1.min = moment().subtract(1, "year").format(this.$constants.dateFormat);
            this.filters.date1.max = moment().add(1, "year").format(this.$constants.dateFormat);
            this.filters.date2.min = moment().subtract(1, "year").format(this.$constants.dateFormat);
            this.filters.date2.max = moment().add(1, "year").format(this.$constants.dateFormat);
         }
         this.funcRefreshKeys();
      },
   },
};
</script>

<style lang="scss" scoped>
.page-container {
   .page-inner {
      width: 90%;
      margin: auto;
      .page-form-row {
         display: grid;
         grid-template-columns: repeat(6, 1fr);
         grid-column-gap: 1rem;
      }
      .page-action-row {
         margin-top: 1rem;
         display: flex;
         justify-content: flex-end;
      }
   }
}
</style>
