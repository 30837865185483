<template>
   <section class="transfer-order-detail-section tnr-information-wrapper">
      <div class="tnr-information-inner">
         <div class="tnr-information-header-wrapper">
            <span class="section-title">{{ $t("orderDetail_lblTransferInfoTitle") }}</span>

            <div class="pdf-action-buttons-wrapper">
               <template v-if="tnr.status == 3">
                  <button id="document-tnr" :class="tnrLoading ? 'loading' : ''" @click="handlerDocumentViewer(6)">
                     <span>{{ $t("orderDetail_btnTnr") }}</span>
                     <span class="g-radial-loader" v-if="tnrLoading"></span>
                     <i class="gts-icon icon-eticket" v-else></i>
                  </button>
               </template>

               <!-- <template v-if="tnrInformation.status == 2">
                  <button id="document-tnr" :class="tnrLoading ? 'loading' : ''" @click="handlerDocumentViewer(7)">
                     <span>{{ $t("orderDetail_btnTnr") }}</span>
                     <span class="g-radial-loader" v-if="tnrLoading"></span>
                     <i class="gts-icon icon-eticket" v-else></i>
                  </button>
               </template> -->
            </div>
         </div>
         <div class="transfer-order-detail-section-content">
            <TransferInformationSummary :tnr="tnr" />
            <LegInformation :orderId="orderId" :pageLoad="pageLoad" :travelers="travelers" :tnrId="tnrId" :legs="legs" />
            <GrandTotal :legPrices="legPrices" :grandTotal="tnr.grandTotal" :reverseTotal="tnr.reverseTotal" :saleTotal="tnr.saleTotal" />
         </div>
      </div>
   </section>
</template>

<script>
import TransferInformationSummary from "@/components/structural/transfer/order-detail/TransferInformationSummary.vue";
import LegInformation from "@/components/structural/transfer/order-detail/LegInformation.vue";
import GrandTotal from "@/components/structural/transfer/order-detail/GrandTotal.vue";

export default {
   name: "TransferAndLegInformation",
   components: {
      TransferInformationSummary,
      LegInformation,
      GrandTotal,
   },
   props: {
      tnrId: Number,
      orderId: Number,
      tnr: Object,
      legs: Object,
      legPrices: Object,
      travelers: Array,
      pageLoad: Boolean,
   },
   data() {
      return {
         tnrLoading: false,
      };
   },
   methods: {
      handlerDocumentViewer(type) {
         const self = this;
         self.tnrLoading = true;
         self.$store.state.app.documentViewer.request.showPrice = false;
         self.$store.state.app.documentViewer.hasPrice = true;
         self.$store.state.app.documentViewer.request.documentType = type;
         self.$store.state.app.documentViewer.request.fileType = 2;
         self.$store.state.app.documentViewer.activeProductStatus = self.tnr.status;
         self.$store.commit("app/setDocumentViewer", true);
      },
   },
   watch: {
      "$store.state.app.documentViewer.isLoaded": {
         handler: function (status) {
            if (status) {
               this.tnrLoading = false;
            }
         },
      },
   },
};
</script>

<style lang="scss" scoped>
.tnr-information-wrapper {
   .tnr-information-inner {
      .transfer-order-detail-section-title {
         margin-bottom: 24px;
         & > h4 {
            font-weight: 700;
         }
      }
   }
}
.tnr-information-header-wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 1.5rem;

   .section-title {
      margin-bottom: 0 !important;
   }

   .pdf-action-buttons-wrapper {
      display: flex;

      button {
         margin: 0 0.5rem;
         height: 44px;
         display: flex;
         align-items: center;
         border-radius: 24rem;
         padding-left: 1.75rem;
         padding-right: 1.125rem;
         font-size: var(--large);
         font-weight: 600;
         transform: scale(1);
         transition-property: transform;
         transition-timing-function: ease-in;
         transition-duration: 0.05s;

         i {
            margin-left: 1rem;
         }

         &:last-child {
            margin-right: 0 !important;
         }

         &#document-tnr {
            background-color: #fff;
            border: 2px solid var(--primary);
            color: var(--primary);

            &:hover {
               background-color: var(--primary);
               border: 2px solid var(--primary);
               color: #fff;

               i {
                  filter: brightness(10);
               }
            }
         }

         &#document-booking-confirmation {
            background-color: var(--success);
            color: #2d4c77;
         }

         &:hover {
            transform: scale(1.1);
         }

         &.loading {
            background-color: var(--accent-primary) !important;
            border: 2px solid var(--accent-primary) !important;
            transform: scale(1.1);
            color: #fff !important;

            .g-radial-loader {
               margin-left: 1rem;
            }
         }
      }
   }
}
</style>
