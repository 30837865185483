<template>
   <div id="checkout-basket-wrapper" class="gts-box with-border">
      <div class="checkout-basket-wrapper-header">
         <span class="section-title">{{ $t("checkoutBasket_basket_infos") }}</span>

         <div clasS="checkout-basket-header-action gts-animation-scale-and-color" @click="showCommission = !showCommission">
            <i id="toggle-password" class="gts-icon icon-eye" v-if="showCommission"></i>
            <i id="toggle-password" class="gts-icon icon-eye-closed" v-else></i>
         </div>
      </div>

      <Transition name="slide" mode="in-out">
         <div class="checkout-basket-container" v-if="showCommission">
            <div class="checkout-input-container">
               <label for="">{{ $t("checkoutBasket_basket_addExtraCommission") }}</label>
               <div class="checkout-basket-input">
                  <input type="text" ref="agencycommission" :data-max="handlerTotal" v-model="AgencyCommission" v-price />
                  <button @click="handlerAddCommission">+</button>
               </div>
            </div>
            <p class="grand-total">
               <span class="grand-total-title masked">{{ $t("common_grandTotal") }}</span>
               <span class="grand-total-amount masked">{{ handlerGrandTotal | price }}</span>
            </p>
            <div class="send-offer masked" @click="handlerDocumentViewer(2)">
               {{ $t("basketComponentFlight_sendOffer") }}
            </div>
         </div>
      </Transition>

      <section class="checkout-basket-information">
         <i class="gts-icon icon-info-circle icon-sm" />
         <span>{{ PnrInformations(handlerPnrCount) }}</span>
      </section>
   </div>
</template>

<script>
import { flightService } from "@/service/index.js";
export default {
   name: "CheckoutBasket",
   data() {
      return {
         showCommission: true,
      };
   },
   computed: {
      AgencyCommission: {
         get() {
            return this.$store.state.flight.checkout.request.agencyCommissionExtra;
         },
         set(val) {
            this.$store.state.flight.checkout.request.agencyCommissionExtra = Number(val);
         },
      },
      handlerGrandTotal() {
         const self = this;
         return self.$store.state.flight.checkout.response.total.grandTotal;
      },
      handlerTotal() {
         const self = this;
         return self.$store.state.flight.checkout.response.total.total;
      },
      handlerPnrCount() {
         const self = this;
         return self.$store.state.flight.checkout.response.pnrCount;
      },
   },
   methods: {
      handlerDocumentViewer(type) {
         this.$store.commit("app/setIsProgressGifActive", true);
         let checkoutUuid = this.$store.state.flight.checkout.request.checkoutUuid;
         this.$store.state.app.documentViewer.request.documentType = type;
         this.$store.state.app.documentViewer.request.identifiers = [{ identifier: checkoutUuid, subIdentifiers: [] }];
         this.$store.state.app.documentViewer.request.fileType = 2;
         this.$store.state.app.documentViewer.request.showPrice = false;
         this.$store.state.app.documentViewer.activeProductStatus = 0;
         this.$store.state.app.documentViewer.hasPrice = true;
         this.$store.commit("app/setDocumentViewer", true);
      },
      PnrInformations(val) {
         const self = this;
         return self.$t("checkoutBasket_basket_pnrInfos").replace("#VALUE#", val);
      },
      handlerAddCommission() {
         const agencyCommissionExtra = Number(this.$refs.agencycommission.value);

         if (this.handlerTotal > agencyCommissionExtra) {
            //set progress gif
            this.$store.commit("app/setIsProgressGifActive", true);
            this.$store.state.app.progress.message = this.$i18n.t("flightCheckout_agencyExtraCCommission");

            // set new uuid for request
            this.$store.commit("flight/updateAgencyCommission");

            let query = this.$store.state.flight.checkout.request;

            query.agencyCommissionExtra = agencyCommissionExtra;
            flightService.checkout(query).then((response) => {
               this.$store.commit("flight/updateCheckoutResponse", response.checkoutResponse);
               this.$store.commit("flight/updateCheckoutRequest", response.checkoutRequest);
               this.$store.commit("flight/updateCheckoutUuid", response.checkoutUuid);
               this.$store.commit("app/setIsProgressGifActive", false);
            });
         }
      },
   },
};
</script>

<style lang="scss" scoped>
#checkout-basket-wrapper {
   padding: 0 !important;
   overflow: hidden;
   // width: 300px;
   position: sticky;
   top: 1.5rem;

   .checkout-basket-wrapper-header {
      padding: 1rem;
      padding-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .section-title {
         margin-bottom: 0 !important;
      }
      .checkout-basket-header-action {
         cursor: pointer;
      }
   }
   .checkout-basket-container {
      padding: 1rem;

      .checkout-input-container {
         .checkout-basket-input {
            display: flex;
            flex-direction: row;
            input {
               height: 48px;
               width: 100%;
               border-radius: 12px;
               border: 1px solid #ccc;
               border-top-right-radius: 0;
               border-bottom-right-radius: 0;
               text-align: right;
               padding: 0 0.5rem;
            }
            button {
               background: #c1cbd5;
               border-radius: 0;
               border-top-right-radius: 12px;
               border-bottom-right-radius: 12px;
               width: 100px;
               height: 48px;
               font-size: 1.5rem;
               line-height: 0;
               color: #fff;

               &:hover {
                  background: var(--accent-primary);
               }
            }
         }
      }

      .grand-total {
         display: flex;
         flex-direction: column;
         margin-top: 2rem;
         .grand-total-title {
            color: var(--accent-primary);
            font-size: var(--normal);
            font-weight: 500;
         }
         .grand-total-amount {
            color: var(--accent-primary);
            font-size: 2rem;
            font-weight: 800;
         }
      }
      .send-offer {
         text-align: center;
         width: 100%;
         display: block;
         text-decoration: underline;
         font-size: var(--small);
         cursor: pointer;

         &:hover {
            color: var(--accent-secondary);
         }
      }
   }

   .checkout-basket-information {
      padding: 1rem 1.5rem;
      display: flex;
      background-color: #3d7ecc;

      i {
         filter: brightness(100);
         flex-shrink: 0;
         margin-right: 0.5rem;
      }
      span {
         color: var(--txt-negative);
         font-weight: 500;
      }
   }
}
</style>
