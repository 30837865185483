<template>
   <section>
      <Table ref="print" id="smarttable">
         <Thead>
            <Tr>
               <Th v-for="(title, index) in Columns" :class="Rules.showOnMobile.includes(index) ? '' : 'hide-on-mobile'" :key="index">
                  <span v-if="index != table.options.header.saleDateIndex" :class="WrapWhiteSpace ? 'ws-wrapped' : ''"> {{ title.label }}</span>
                  <span
                     style="display: flex; align-items: center"
                     @click="handlerSort"
                     v-if="index == table.options.header.saleDateIndex"
                     :class="WrapWhiteSpace ? 'ws-wrapped pointer' : 'pointer'">
                     {{ title.label }} <i class="gts-icon icon-detail-arrow icon-xs" style="margin-left: 4px"></i
                  ></span>
               </Th>
            </Tr>
         </Thead>
         <Tbody>
            <template v-for="(tr, trIndex) in Rows">
               <Tr :key="trIndex" v-if="handlerShowRow(tr, trIndex)">
                  <!-- <Tr :key="trIndex"> -->
                  <template v-for="(td, tdKey, tdIndex) in tr">
                     <Td
                        style="vnd.ms-excel.numberformat: 0"
                        :class="[
                           Rules.showOnMobile.includes(tdIndex) ? '' : 'hide-on-mobile',
                           tr.pnrStatus && tr.pnrStatus.pnrStatus === 2 && Rules.highlightedColumns && Rules.highlightedColumns.includes(tdIndex)
                              ? 'highlighted'
                              : 'clsx',
                        ]"
                        :key="tdIndex"
                        v-if="handlerCellControll(tr, trIndex, tdKey, td).show"
                        :rowspan="handlerCellControll(tr, trIndex, tdKey, td)?.rowspan">
                        <!-- :rowspan="handlerCellControll(tr, trIndex, tdKey, td)?.rowspan" -->
                        <!-- v-if="handlerCellControll(tr, trIndex, tdKey, td).show" -->

                        <!-- Do not add responsive functionalities it broke excel export - Deniz 08.04.2023 ): -->
                        <template v-if="typeof td == 'object'">
                           <span :class="td?.class" @click="td?.url ? handlerRouter(td.url) : undefined">
                              {{ td?.text == "null" ? "" : td?.text }}
                           </span>
                        </template>
                        <template v-else>{{ td }}</template>

                        <!-- {{ handlerShowRow(tr, trIndex) }} -->

                        <!-- v-if="handlerShowRow(tr)" -->
                     </Td>
                  </template>
               </Tr>
            </template>
         </Tbody>
         <Tfoot></Tfoot>
      </Table>
   </section>
</template>

<script>
export default {
   name: "SmartTableBody",
   props: {
      table: Object,
      alternativeTable: Object,
      pagination: Object,
   },
   data() {
      return {
         rowspans: {},
      };
   },
   mounted() {
      this.CreateSmartTable();
   },
   computed: {
      Columns() {
         return this.table.columns;
      },
      Search() {
         const searchKeyword = this.$parent.headerOptions.searchKeyword;

         return this.table.rows.filter((row) => JSON.stringify(row).toLowerCase().includes(searchKeyword.toLowerCase()));
      },
      Rows() {
         return this.Search;
      },
      AutoMergeStatus() {
         // Array.isArray(options.rules.autoMerge) && options.rules.autoMerge.length > 0;
         // return this.table.rules;
         return Array.isArray(this.table.rules.autoMerge) && this.table.rules.autoMerge.length > 0;
      },

      Rules() {
         return this.table.rules;
      },
      DominantCell() {
         return this.table.rules.dominantCell;
      },
      WrapWhiteSpace() {
         return this.table.options.header.title.includes("Hotel");
      },
   },
   methods: {
      CreateSmartTable() {
         this.Generator({
            rows: this.table.rows,
            rules: this.Rules,
         });
      },
      Generator(options) {
         this.table.rows = this.GenerateRows(options.rows);
         const autoMergeStatus = Array.isArray(options.rules.autoMerge) && options.rules.autoMerge.length > 0;
         if (autoMergeStatus) {
            this.GenerateRowsSpanObj(options.rows, options.rules.autoMerge);
            this.GenerateRowSpans();
         }
         this.LoadSmartTable();
      },
      GenerateRows(rows) {
         return rows.map((element) => {
            return {
               ...element,
            };
         });
      },
      GenerateRowsSpanObj(rows, autoMerges) {
         rows.map((element) => {
            const rowEl = typeof element[this.DominantCell] == "object" ? element[this.DominantCell].text : element[this.DominantCell];
            this.$set(this.rowspans, rowEl, { status: false, index: null });
            autoMerges.forEach((identifier) => {
               this.$set(this.rowspans[rowEl], identifier, {});
            });
         });
         this.hanlerRowSpansIndexSetter();
      },

      GenerateRowSpans() {
         this.Rows.forEach((currentRow, index) => {
            const prevRow = this.Rows[index - 1] != undefined ? this.table.rows[index - 1] : false;
            if (prevRow) {
               const currentDominantRowEl =
                  typeof currentRow[this.DominantCell] == "object" ? currentRow[this.DominantCell].text : currentRow[this.DominantCell];
               const prevDominantRowEl = typeof prevRow[this.DominantCell] == "object" ? prevRow[this.DominantCell].text : prevRow[this.DominantCell];

               this.Rules.autoMerge.forEach((identifier) => {
                  //dominantCell'in eşit olması şart
                  if (currentDominantRowEl == prevDominantRowEl) {
                     const dominantCellFirstIndex = this.table.rows.findIndex((el) => el[this.DominantCell] == prevRow[this.DominantCell]);

                     // console.log(dominantCellFirstIndex);

                     const currentRowEl = typeof currentRow[identifier] == "object" ? currentRow[identifier]?.text : currentRow[identifier];
                     const prevRowEl = typeof prevRow[identifier] == "object" ? prevRow[identifier]?.text : prevRow[identifier];
                     if (currentRowEl == prevRowEl) {
                        this.GenerateRowControlList(currentDominantRowEl, identifier, currentRowEl, dominantCellFirstIndex, index);
                     }
                  }
               });
            }
         });
      },
      GenerateRowControlList(dominant, identifier, subIdentifier, dominantCellFirstIndex, index) {
         const subIdentifierExist = this.rowspans[dominant][identifier][subIdentifier] == undefined ? false : true;
         if (!subIdentifierExist) {
            this.$set(this.rowspans[dominant][identifier], subIdentifier, {
               count: 2,
               index: dominantCellFirstIndex,
               lastEl: Number,
            });
         } else {
            this.rowspans[dominant][identifier][subIdentifier].count++;
         }

         if (identifier == this.DominantCell) {
            this.rowspans[dominant][identifier][subIdentifier].lastEl = index;
         }
      },
      GenerateLastRows() {
         Object.entries(this.rowspans).forEach((row) => {
            const rowName = row[0];
            const rowData = row[1][this.DominantCell][rowName]?.lastEl;
            const smartTableBodyWrapper = document.querySelector("#smarttable").querySelectorAll("tbody tr");
            const selectedTr = smartTableBodyWrapper[rowData];
            if (selectedTr != undefined) {
               selectedTr.classList.add("last-tr");
            }
         });
      },
      LoadSmartTable() {
         this.$nextTick(() => {
            this.GenerateLastRows();
         });
      },

      //Helpers
      handlerCellControll(trIdentifier, trIndex, tdKey, td) {
         const searchKeyword = this.$parent.headerOptions.searchKeyword;

         trIdentifier = typeof trIdentifier[this.DominantCell] == "object" ? trIdentifier[this.DominantCell].text : trIdentifier[this.DominantCell];
         td = typeof td == "object" ? td?.text : td;
         tdKey = this.rowspans[trIdentifier]?.[tdKey];

         if (tdKey != undefined && Object.keys(tdKey)?.length > 0) {
            if (trIndex == tdKey?.[td]?.index) {
               return {
                  // rowspan: tdKey[td].count,
                  rowspan: searchKeyword.length >= 3 ? 1 : tdKey[td].count,
                  index: tdKey[td].index,
                  show: true,
               };
            } else {
               return {
                  show: searchKeyword.length >= 3 ? true : false,
                  // show: false,
               };
            }
         } else {
            return {
               show: true,
            };
         }
      },

      handlerShowRow(trIdentifier, trIndex) {
         trIdentifier = typeof trIdentifier[this.DominantCell] == "object" ? trIdentifier[this.DominantCell].text : trIdentifier[this.DominantCell];
         const range = {
            index: this.AutoMergeStatus ? this.rowspans[trIdentifier]?.index : trIndex + 1,
            min: (this.pagination.activepage - 1) * this.pagination.viewMode,
            max: this.pagination.activepage * this.pagination.viewMode,
         };

         return range.index > range.min && range.index <= range.max;
      },

      hanlerRowSpansIndexSetter() {
         const rowArr = Object.keys(this.rowspans).map((span) => {
            return span;
         });

         //sort by dominantCell
         rowArr.reverse();

         // set index
         rowArr.forEach((i, index) => {
            this.rowspans[i].index = index + 1;
         });
      },
      handlerRouter(url) {
         window.open(url, "_blank");
      },
      handlerSort() {
         this.table.rows.reverse();
         this.CreateSmartTable();
      },
   },
   watch: {
      table: {
         handler: function () {
            // console.log("SmartTableBody, Table");
            // this.CreateSmartTable();
            this.LoadSmartTable();
         },
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
.hide-on-mobile {
   display: none !important;
   @media (min-width: 992px) {
      display: table-cell !important;
   }
}
.highlighted {
   background-color: #eff8ff;
}
table {
   width: 100%;
   margin: 1rem 0;
   thead {
      background-color: #f2f2f2;
      border-bottom: 2px solid #ccc;
      span {
         font-weight: 600;
         font-size: var(--small);
         white-space: nowrap;
      }
      .ws-wrapped {
         white-space: normal;
      }
      .pointer {
         cursor: pointer;
      }
   }

   tbody {
      tr {
         &.last-tr {
            border-bottom: 3px dashed #ccc;
            border-bottom: 3px dashed #acacac;
         }
         td {
            mso-number-format: \@;
            padding: 1rem 0.5rem;
            vertical-align: middle;
            border: 1px dotted #e6e6e6;
            border: 1px dotted #b0b0b0;
            font-size: 14px;
            color: var(--txt-black);

            span {
               width: 100%;
               height: auto !important;
               display: block;
               &.price-display {
                  font-weight: 800;
                  text-align: right;
               }
            }

            .ws-pre {
               white-space: pre;
            }
         }

         &:hover {
            border-color: var(--accent-primary-lighten) !important;
            background-color: #98dcff14 !important;
         }

         &:nth-child(even) {
            background-color: #f6f6f6;
         }
      }
   }

   //alt silenecek
   tr {
      th,
      td {
         padding: 1rem;
         border: 1px solid #ccc;
         vertical-align: middle;
      }

      // &:nth-child(even) {
      //    background: aliceblue;
      // }
   }

   tbody {
      td {
         &:last-child {
            // background-color: #eee;
            // display: none;
         }
      }
   }
}
</style>
