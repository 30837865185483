export const EFltPnrStatus = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "NEW",
   2: "RESERVED",
   3: "ISSUED",
   4: "CANCELED",
   5: "VOIDED",
   6: "REFUNDED",
   7: "REISSUED",
   8: "ERROR",
   9: "OPEN",
   10: "PENDING_ISSUED",
   11: "PENDING_RESERVED",
   12: "PENDING_CANCELLED",
   13: "PENDING_VOIDED",
   14: "PENDING_REFUNDED",
};
export const EFltEticketStatus = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "NEW",
   2: "RESERVED",
   3: "ISSUED",
   4: "CANCELED",
   5: "VOIDED",
   6: "REFUNDED",
   7: "REISSUED",
   8: "ERROR",
   9: "OPEN",
   10: "PENDING_ISSUED",
   11: "PENDING_RESERVED",
   12: "PENDING_CANCELLED",
   13: "PENDING_VOIDED",
   14: "PENDING_REFUNDED",
};
export const EFltCabinType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "ECONOMY",
   2: "BUSINESS",
};

export const EFltPaxType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "ADULT",
   2: "CHILD",
   3: "INFANT",
   4: "DISABLED",
   5: "DISABLED_COMPANION",
   6: "EXTRA_SEAT",
   99: "ADULT_WITH_INFANT",
};
export const EFltSsrType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "BAGGAGE",
   2: "SEAT",
   3: "MEAL",
   4: "WHEELCHAIR",
   5: "OTHER",
   6: "CHECKIN_AT_AIRPORT",
   7: "REISSUE_ALLOWANCE",
   8: "CABIN_BAG",
};

export const EFltSsrFoodType = {
   "-1": "ALL",
   0: "UNKNOWN",
   HNML: "HNML", // Hindu Meal
   CHML: "CHML", // Child Meal
   MOML: "MOML", // Moslem Meal
   4: "VGML", // Vegetarian Vegan Meal
   5: "CNML", // Chicken Meal
   6: "FSML", // Fish Meal
   7: "AVML", // Vegetarian Hindu meal
   8: "BBML", // Baby Meal
   9: "BLML", // Bland Meal
   10: "DBML", // Diabetic Meal
   11: "FPML", // Fruit Platter Meal
   12: "GFML", // Gluten Intolerant Meal
   13: "KSML", // Kosher Meal
   14: "LCML", // Low Calorie Meal
   15: "LFML", // Low Fat Meal
   16: "LPML", // Low Protein Meal
   17: "LSML", // Meal without Salt
   18: "NLML", // Meal without Lactose
   19: "SFML", // Sea Food Meal
   20: "VJML", // Vegetarian Jain Meal
   21: "VLML", // Vegetarian Meal Lacto-Ovo
   22: "VOML", // Vegetarian Oriental Meal
   23: "SPML", // Special Meal
};

export const EFltSsrWheelchairType = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "WCHC", // Wheelchair (Pax completely immobile and cannot climb stairs)
   2: "WCHR", // Wheelchair for the Ramp. (Can climb stairs.)
   3: "WCHS", // Wheelchair (Can't climb stairs.)
   4: "WCBD", // Dry Cell battery operated wheelchair
   5: "WCMP", // Wheelchair Manuel (Power Driven)
   6: "WCLB", // Passenger who has own Lithium Battery powered wheelchair
};
export const EFltConnectionType = {
   "-1": "airlineTransfer",
   0: "noneStop",
};
export const EFltAncillaryType = {
   1: "baggage",
   2: "seat",
   3: "meal",
   4: "wheelchair",
   5: "other",
   6: "airport-checkin",
   7: "reissue-allowance",
   8: "cabin-bag",
};

export const EFltSeatTypeEnum = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "AISLE",
   2: "WINDOW",
   3: "STANDARD",
   4: "EXIT",
};

export const EFltSeatLayout = {
   "-1": "ALL",
   0: "UNKNOWN",
   1: "SL_1_1",
   2: "SL_1_2",
   3: "SL_2_2",
   4: "SL_3_3",
   5: "SL_1_1_1",
   6: "SL_1_2_1",
   7: "SL_2_2_2",
   8: "SL_2_3_2",
   9: "SL_2_4_2",
   10: "SL_3_3_3",
   11: "SL_3_4_3",
};
