<template>
   <section class="hotel-order-detail-section price-information-wrapper">
      <div class="price-information-inner">
         <div class="hotel-order-detail-section-title">
            <h4>{{ $t("orderDetail_lblPriceInfoTitle") }}</h4>
         </div>
         <div class="hotel-order-detail-section-content">
            <PriceInformationSummary :priceList="pricesInformation" />
            <div class="hotel-order-detail-inner-section total-price-information-wrapper">
               <PriceWidget :countryCode="country" :totalPriceInfo="totalPriceInformation" label="hotelOrderDetail" />
            </div>
         </div>
      </div>
   </section>
</template>

<script>
import PriceInformationSummary from "@/components/structural/hotel/order-detail/PriceInformationSummary.vue";
import PriceWidget from "@/components/unitary/PriceWidget.vue";

export default {
   name: "PriceInformation",
   components: {
      PriceInformationSummary,
      PriceWidget,
   },
   props: {
      pricesInformation: Array,
      totalPriceInformation: Object,
      countryCode: String,
   },
   data() {
      return {
         country: this.countryCode,
      };
   },
   watch: {
      totalPriceInformation(val) {
         // if expedia provider and price view type is 2, flag is set automatically to true
         if (val && val.priceViewType == 2) {
            this.$store.state.app.documentViewer.request.showCancellationPolicy = true;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.price-information-wrapper {
   .price-information-inner {
      .hotel-order-detail-section-title {
         margin-bottom: 24px;

         & > h4 {
            font-weight: 700;
         }
      }
      .hotel-order-detail-section-content {
         .total-price-information-wrapper {
            width: 100%;
            margin-top: 32px;
         }
      }
   }
}
</style>
