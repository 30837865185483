<template>
   <div class="gts-autocomplete-wrapper gts-custom-scroll" v-click-outside="handlerOutClick">
      <label>{{ label }}</label>
      <div class="gts-autocomplete-inner">
         <div class="search-autocomplete-input">
            <input
               type="text"
               name="input_searchAutocomplete"
               :placeholder="$t('hotelAutocomplete_phDestination')"
               autocomplete="off"
               v-model="search.name"
               @click="handlerClickInput"
               @keydown="handlerKeydownEvents"
               @input="handlerChangeSearchInput" />
            <i class="gts-icon icon-location-point-solid icon-sm" />

            <div class="clear-autocomplete">
               <i v-if="clearStatus" class="gts-icon icon-close-rounded gts-animation icon-xs" @click="clearSelected" />
            </div>
         </div>
         <div class="search-autocomplete-list" v-if="isOpen">
            <div class="search-autocomplete-list-inner">
               <div class="search-autocomplete-list-section lastSearches last-searches-section" v-if="results.searchHistory.length != 0">
                  <div class="search-autocomplete-list-section-icon">
                     <i class="gts-icon icon-latest-searches-point-ac" />
                  </div>
                  <div class="search-autocomplete-list-section-content">
                     <div class="search-autocomplete-list-section-content-title">
                        <span>{{ $t("hotelAutocomplete_lblLastSearches") }}</span>
                     </div>
                     <div class="search-autocomplete-list-section-content-list">
                        <!-- <div
                           class="search-autocomplete-list-section-content-list-item"
                           v-for="(item, index) in results.searchHistory"
                           :key="index"
                           :class="index == arrowNavigation.navIndex && arrowNavigation.navRoot == 0 ? 'highlighted' : 'unselect'"
                           @click="selectSearchResult(item)">
                           <span>{{ item.name }}</span>
                        </div> -->
                        <div
                           class="search-autocomplete-list-section-content-list-item"
                           v-for="(item, index) in formattedSearchResults(this.results.searchHistory)"
                           :key="index"
                           v-html="item.name"
                           @click="selectSearchResult(item, true)"></div>
                     </div>
                  </div>
               </div>
               <div class="search-autocomplete-list-section districtResponse district-section" v-if="results.districtResponse.length != 0">
                  <div class="search-autocomplete-list-section-icon">
                     <i class="gts-icon icon-location-point-ac" />
                  </div>
                  <div class="search-autocomplete-list-section-content">
                     <div class="search-autocomplete-list-section-content-title">
                        <span>{{ $t("hotelAutocomplete_lblDisctrict") }}</span>
                     </div>
                     <div class="search-autocomplete-list-section-content-list">
                        <div
                           class="search-autocomplete-list-section-content-list-item"
                           v-for="(item, index) in formattedSearchResults(this.results.districtResponse)"
                           :key="index"
                           :class="index == arrowNavigation.navIndex && arrowNavigation.navRoot == 0 ? 'highlighted' : 'unselect'"
                           v-html="item.name"
                           @click="selectSearchResult(item)"></div>
                     </div>
                  </div>
               </div>
               <div class="search-autocomplete-list-section hotelResponse hotel-section" v-if="results.hotelResponse.length != 0">
                  <div class="search-autocomplete-list-section-icon">
                     <i class="gts-icon icon-hotel-point-ac" />
                  </div>
                  <div class="search-autocomplete-list-section-content">
                     <div class="search-autocomplete-list-section-content-title">
                        <span>{{ $t("hotelAutocomplete_lblHotel") }}</span>
                     </div>
                     <div class="search-autocomplete-list-section-content-list">
                        <div
                           class="search-autocomplete-list-section-content-list-item"
                           v-for="(item, index) in formattedSearchResults(this.results.hotelResponse)"
                           :key="index"
                           :class="index == arrowNavigation.navIndex && arrowNavigation.navRoot == 1 ? 'highlighted' : 'unselect'"
                           v-html="item.name"
                           @click="selectSearchResult(item)"></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { hotelService } from "@/service/index.js";
import debounce from "lodash.debounce";
import { getCookie, setCookie } from "@/utils";
import i18n from "@/fmt/i18n.js";

export default {
   name: "SearchAutocomplete",
   props: {
      label: String,
   },
   data() {
      return {
         search: {
            name: "",
         },
         isOpen: false,
         results: {
            districtResponse: [],
            hotelResponse: [],
            searchHistory: [],
         },
         isInputFocused: false,
         clearStatus: false,
         searchResponses: [],

         activeId: 0,
         //navRoot hotel ya da district section'larını belirliyor. 0 = district 1 = hotel
         arrowNavigation: {
            navIndex: -1,
            navRoot: 0,
         },
         currentLang: i18n.locale,
      };
   },
   computed: {},
   methods: {
      formattedSearchResults(response) {
         var capitalizedFirstWord = this.search?.name?.[0]?.toLocaleUpperCase(`${this.currentLang}`);
         var restOfTheWord = this.search.name?.slice(1);
         var searchWord = capitalizedFirstWord + restOfTheWord;

         const regex = new RegExp(searchWord, "gi");
         return response.map((result) => {
            const highlightedText = result.name.replace(regex, "<strong style='font-weight:bold!important'>$&</strong>");
            return { ...result, name: highlightedText };
         });
      },
      handlerKeydownEvents(e) {
         const liElHeight = document.querySelector(".search-autocomplete-list-section-content-list-item")?.offsetHeight;
         // dynamic PropName
         var activePropName;
         var inActivePropName;

         if (this.arrowNavigation.navRoot == 0) {
            activePropName = "districtResponse";
            inActivePropName = "hotelResponse";
         } else {
            activePropName = "hotelResponse";
            inActivePropName = "districtResponse";
         }

         if (e.keyCode == 40 || e.keyCode == 39) {
            //ARROW-DOWN VE ARROW-RIGHT KEYS

            if (this.results.districtResponse.length == 0) {
               activePropName = "hotelResponse";
               inActivePropName = "districtResponse";
               this.arrowNavigation.navRoot = 1;
            }

            // navIndex activeProp length'ine eşitlendiğinde navRoot 1 olarak güncelleniyor. HotelResponse'un başlangıcının gösterilmesi istendiği için navIndex 0 olarak güncelleniyor.
            if (this.results[activePropName].length - 1 == this.arrowNavigation.navIndex) {
               this.arrowNavigation.navIndex = 0;
               this.arrowNavigation.navRoot = 1;
               document.querySelector(`.${inActivePropName}`).scrollTop = liElHeight * this.arrowNavigation.navIndex;
               document.querySelector(`.${activePropName}`).scrollTop = liElHeight * this.results[activePropName].length;
               document.querySelector(`.${activePropName}`).scrollTop = 0;
            } else {
               this.arrowNavigation.navIndex++;
               document.querySelector(`.${activePropName}`).scrollTop = liElHeight * this.arrowNavigation.navIndex;
            }
         }
         if (e.keyCode == 38 || e.keyCode == 37) {
            //ARROW-UP VE ARROW-LEFT KEYS

            if (this.arrowNavigation.navRoot == 0 && this.arrowNavigation.navIndex == 0) {
               this.arrowNavigation.navIndex = this.results[activePropName].length - 1;
            }

            // navIndex 0'a eşit olana kadar yukarı çıkılıyor. 0'a eşitlendiğinde navRoot 0'a eşitlenerek districtResponse'a atlanıyor.
            if (this.arrowNavigation.navIndex == 0) {
               this.arrowNavigation.navIndex = this.results[inActivePropName].length - 1;
               this.arrowNavigation.navRoot = 0;

               document.querySelector(`.${inActivePropName}`).scrollTop = liElHeight * this.arrowNavigation.navIndex;
            } else {
               this.arrowNavigation.navIndex--;
               document.querySelector(`.${activePropName}`).scrollTop = liElHeight * this.arrowNavigation.navIndex;
            }
         }
         if (e.keyCode == 13) {
            //ENTER KEY
            this.selectSearchResult(this.results[activePropName][this.arrowNavigation.navIndex]);
         }
      },
      handlerChangeSearchInput: debounce(function (e) {
         var self = this;

         if (e.target.value == "") {
            self.isOpen = false;
            self.arrowNavigation = {
               navIndex: -1,
               navRoot: 0,
            };
            this.$emit("update-to-destination", {});
         } else {
            self.isOpen = true;
         }

         if (e.target.value.length >= 2) {
            var searchAutocompleteReqModel = {
               keyword: self.search.name,
            };
            hotelService.hotelAutocomplete(searchAutocompleteReqModel).then((response) => {
               self.results.districtResponse = response.destinations.filter((destinationItem) => destinationItem.type === 1);
               self.results.hotelResponse = response.destinations.filter((destinationItem) => destinationItem.type === 2);
               self.searchResponses = response.destinations;
               self.activeId = self.searchResponses.length && self.searchResponses[0].id;
            });
         }
      }, 500),
      selectSearchResult(result, isSelectFromHistory = false) {
         var self = this;
         self.clearStatus = true;
         self.search = result;
         self.search.name = this.$options.filters.stripHTML(result.name);
         if (!isSelectFromHistory) self.updateSearchHistory(result);
         this.$emit("update-to-destination", self.search);
         self.isOpen = false;
      },
      clearSelected() {
         const self = this;

         self.clearStatus = false;
         self.search = {};
         this.$emit("update-to-destination", self.search);
      },
      handlerOutClick() {
         const self = this;
         self.isOpen = false;
         self.arrowNavigation = {
            navIndex: -1,
            navRoot: 0,
         };
         self.results.districtResponse = [];
         self.results.hotelResponse = [];
      },
      handlerClickInput() {
         const self = this;
         if (self.results.searchHistory.length > 0) self.isOpen = true;
      },
      setSearchHistory() {
         const self = this;
         let searchHistoryCookie = getCookie("hotelSearchHistory");
         if (searchHistoryCookie) {
            self.results.searchHistory = JSON.parse(searchHistoryCookie);
         }
      },
      updateSearchHistory(newElement) {
         const self = this;

         if (self.results.searchHistory.find((item) => item.id == newElement.id)) return;

         if (self.results.searchHistory.length < 5) {
            self.results.searchHistory.push(newElement);
         } else {
            self.results.searchHistory.shift();
            self.results.searchHistory[4] = newElement;
         }
         let json = JSON.parse(JSON.stringify(self.results.searchHistory));
         setCookie("hotelSearchHistory", JSON.stringify(json), 7, 0);
      },
   },
   created() {
      var self = this;
      self.setSearchHistory();
   },
   watch: {
      "search.name"() {
         if (this.search.name?.length == 0) {
            this.clearSelected();
         }
      },
      "$i18n.locale"() {
         var self = this;
         self.currentLang = i18n.locale;
      },
   },
};
</script>

<style lang="scss" scoped>
.gts-autocomplete-wrapper {
   label {
      margin-bottom: 0.5rem;
      font-weight: 500;
   }
   .gts-autocomplete-inner {
      position: relative;
      .search-autocomplete-input {
         position: relative;
         display: flex;
         align-items: center;

         & > input {
            width: 100%;
            height: 48px;
            border-radius: 0.5rem;
            text-indent: 1rem;
            color: var(--txt-black);
            border: 1px solid #ccc;
            background: #f8f8f8;
            text-transform: capitalize;
            padding-left: 1.125rem;
            font-size: 16px !important;

            &:focus {
               border-color: #00aaff;
               outline: none;
            }

            &::placeholder {
               color: var(--txt-ghost);
            }
         }

         & > i {
            position: absolute;
            right: 12px;
            cursor: pointer;

            &.icon-location-point-solid {
               left: 0.5rem;
            }
         }

         .clear-autocomplete {
            position: absolute;
            right: 1.3px;
            top: 1.1px;
            bottom: 1.1px;
            margin: auto;
            display: flex;
            align-items: center;
            padding: 0 0.75rem;
            border-radius: 0.5rem;
            background-color: #f8f8f8;
            i {
               cursor: pointer;
            }
         }
      }
      .search-autocomplete-list {
         position: absolute;
         left: 0;
         width: max-content;
         max-width: max-content;
         margin-top: 10px;
         background-color: var(--c-primary);
         box-shadow: 3px 3px 10px #0000001a;
         border: 2px solid #ececec;
         border-radius: 8px;
         z-index: 99;

         .search-autocomplete-list-inner {
            display: flex;
            flex-direction: column;

            .search-autocomplete-list-section {
               display: flex;
               justify-content: flex-start;
               align-items: flex-start;
               max-height: 250px;
               padding: 20px;
               border-left: 5px solid transparent;
               border-bottom: 1px solid #e4e4e4;
               overflow-y: auto;
               padding-left: 1rem;

               &:last-child {
                  border-bottom: none;
               }

               &.last-searches-section {
                  background-color: #f7f7f7;
                  border-left-color: #ccc;
                  border-top-left-radius: 4px;
                  border-bottom: 2px dashed #ececec;
                  i {
                     color: #61bff9;
                  }
                  &:hover {
                     // background-color: #edebeb;
                  }

                  .search-autocomplete-list-section-content-list-item {
                     &:hover {
                        color: #363636;
                        background-color: #e1e1e1 !important;
                     }
                  }
               }

               &.city-section {
                  border-left-color: #61bff9;
                  border-top-left-radius: 4px;
                  i {
                     color: #61bff9;
                  }
               }
               &.district-section {
                  border-left-color: #ffbd66;

                  &:hover {
                     background-color: #fff6eb;
                  }

                  i {
                     color: #ffb44f;
                  }
               }
               &.hotel-section {
                  border-left-color: #ff7d66;
                  border-bottom-left-radius: 4px;

                  &:hover {
                     background-color: #ffeeeb;
                  }

                  i {
                     color: #ff7e66;
                  }
               }

               &-icon {
                  margin-right: 24px;
                  & > i {
                  }
               }
               &-content {
                  width: 100%;
                  &-title {
                     margin-bottom: 12px;
                     & > span {
                        color: var(--txt-black);
                        font-weight: 700;
                     }
                  }
                  &-list {
                     &-item {
                        // margin-bottom: 8px;
                        font-size: 14px;
                        $paddingVal: 0.125rem;
                        padding-top: $paddingVal;
                        padding-bottom: $paddingVal;
                        color: var(--txt-black);
                        cursor: pointer;

                        &:last-child {
                           margin-bottom: 0;
                        }
                        & > span {
                           font-size: 14px;
                        }
                        &:hover,
                        &.highlighted {
                           color: #fff;
                           background-color: #4da6ff !important;
                        }
                     }
                  }
               }
            }
         }
      }
   }
   .search-autocomplete-list-section-icon {
      min-width: 64px !important;
      margin-right: 0 !important;
      i.icon-latest-searches-point-ac {
         width: 42px !important;
         height: 42px !important;
      }
      i.icon-location-point-ac {
         height: 48px !important;
         margin-left: 6px !important;
      }
      i.icon-hotel-point-ac {
         height: 40px !important;
         width: 38px !important;
      }
   }
}
</style>
