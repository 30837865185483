<template>
   <div class="yanyana-container">
      <span class="msg">{{ msg }}</span>
   </div>
</template>

<script>
export default {
   name: "YanYana",

   data() {
      return {
         msg: "Diğer kullanıcıları yanyana oturtmak ister misiniz?",
      };
   },
};
</script>

<style lang="scss" scoped>
.yanyana-container {
   width: 100%;
   padding: 0.5rem 1rem;
   background-color: #fff;
   border: 1px solid #ebebeb;
   border-radius: 0.25rem;
   z-index: 999;
}
</style>
