<template>
    <div class="order-error-container">
        <div class="order-error-inner-container">
            <span>{{ $t(`RESPONSE_${orderError.app}_${orderError.service}_${orderError.code}_${orderError.subCode}`) }}</span>
            <div class="order-error-informations">
                <span>{{ orderError.dateTime | FormatterDD_MMMM_YYYY }}</span> -

                <span>{{ orderError.email }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrderError",
    props: {
        orderError: Object,
    },
};
</script>

<style lang="scss" scoped>
.order-error-container {
    border: 1px solid #c8a6de;
    background: #f6edfb;
    border-radius: 0.5rem;
    padding: 0.75rem 1rem;
    margin-top: 1.5rem;

    .order-error-informations {
        span {
            font-weight: 600;
        }
    }
}
</style>
