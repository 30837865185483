<template>
   <div class="hotelCheckout-page-wrapper">
      <Aside />
      <Main>
         <Navbar />

         <div class="container is-widescreen">
            <div class="hotelCheckout-page-inner row">
               <div class="col-12 col-lg-10 hotel-checkout-inner-wrapper">
                  <!-- ROOM INFO  -->
                  <RoomInfo
                     :countryCode="checkoutResponse.countryCode"
                     :customerNationality="checkoutResponse.customerNationality"
                     :checkinDate="checkoutResponse.checkinDate"
                     :checkoutDate="checkoutResponse.checkoutDate"
                     :roomsList="rooms"
                     :contactModel="checkoutRequestModel.contact"
                     :totalPriceInfo="totalPrice"
                     :providerPriceInfo="ProviderPriceDetail"
                     :cancellationPolicies="cancellationPoliciesList"
                     :cancellationPoliciesDate="freeCancellationDate"
                     :lastBookingDate="lastBookingDate"
                     @update-to-rooms="setCheckoutRooms"
                     :searchUuidProps="generatedSearchUuid"
                     :partialPaymentModel="checkoutResponse.partialPaymentCancellationPolicy"
                     :partialPaymentEnabled="checkoutResponse.isPartialPaymentExist" />
                  <!-- CHECKOUT CONTACT -->
                  <!-- <CheckoutContact @update-to-contact="setCheckoutContact" /> -->
                  <CommonCheckoutContact :isShowMail="true" :isBorderavailable="true" @update-to-contact="setCheckoutContact" />

                  <!-- HOTEL INFORMATION -->
                  <HotelDescriptions
                     :providerViewInfo="ProviderPriceDetail"
                     :isExpedia="checkoutResponse.showTermsAndConditions"
                     :providerInstructions="checkoutResponse.providerInstructions"
                     @update-to-hotel-description="setCheckoutHotelDescription" />

                  <!-- PAYMENT -->
                  <PaymentPurchaseWidget
                     entityType="hotel"
                     :checkoutRequest="checkoutRequestModel"
                     :paymentChoiceDetail="PaymentChoiceDetail"
                     :funcValidateForm="funcValidateForm"
                     :partialPaymentModel="checkoutResponse.partialPaymentCancellationPolicy"
                     :partialPaymentEnabled="checkoutResponse.isPartialPaymentExist"
                     :isChecked="partialPaymentDateUpdate"
                     @toggleChange="sendToggleChange" />
               </div>
               <div class="col-lg-2 col-12">
                  <HotelCheckoutBasket v-if="this.$store.state.hotel.basketResponse.isCheckoutBasket" />
               </div>
            </div>
         </div>
      </Main>
   </div>
</template>

<script>
import Aside from "@/components/unitary/Aside.vue";
import Navbar from "@/components/unitary/Navbar.vue";
import Main from "@/components/unitary/Main.vue";

import { hotelService } from "@/service/index.js";

import HotelCheckoutBasket from "@/components/structural/hotel/checkout/CheckoutBasket.vue";
import RoomInfo from "@/components/structural/hotel/checkout/RoomInfo.vue";
// import ExtraServices from '@/components/structural/hotel/checkout/ExtraServices.vue';
// import CheckoutContact from "@/components/structural/hotel/checkout/CheckoutContact.vue";
import HotelDescriptions from "@/components/structural/hotel/checkout/HotelDescriptions.vue";
import PaymentPurchaseWidget from "@/components/structural/payment/PaymentPurchaseWidget.vue";
import CommonCheckoutContact from "@/components/unitary/CommonCheckoutContact.vue";

import Popup from "@/gts-popup.js";
import moment from "moment";

export default {
   name: "HotelCheckoutPage",
   components: {
      Aside,
      Main,
      Navbar,
      HotelCheckoutBasket,
      RoomInfo,
      // ExtraServices,
      // CheckoutContact,
      CommonCheckoutContact,
      HotelDescriptions,
      // PaymentDetail,
      PaymentPurchaseWidget,
   },
   data() {
      return {
         checkoutResponse: Object,
         partialPaymentDateUpdate: false,
         rooms: [],
         totalPrice: {},
         cancellationPoliciesList: [],
         freeCancellationDate: "",
         refundable: false,
         lastBookingDate: "",
         checkoutRequestModel: {
            searchUuid: null,
            basketUuid: null,
            refreshCheckout: false,
            trxType: 1,
            payment: {
               paymentType: 1,
            },
            rooms: [],
            contact: {},
            agencyNote: "",
         },
         generatedSearchUuid: "",
         hotelInformation: {
            name: "",
         },
      };
   },
   computed: {
      PaymentChoiceDetail() {
         return this.checkoutResponse ? this.checkoutResponse.paymentChoiceDetail : null;
      },
      ProviderPriceDetail() {
         return this.checkoutResponse ? this.checkoutResponse.priceDetail : null;
      },
   },
   mounted() {
      var self = this;
      self.checkoutRequestModel.searchUuid = this.$route.params.uuid;
      self.checkoutRequestModel.basketUuid = this.$route.params.basketuuid;
      hotelService
         .hotelCheckout(self.checkoutRequestModel)
         .then((response) => {
            if (!response.result.success) {
               Popup.result(response.result, self.handlerCloseCheckout).then(() => {
                  self.handlerCloseCheckout();
               });
               return;
            } else {
               self.checkoutResponse = response;

               var checkinDate = response.checkinDate;
               var checkoutDate = response.checkoutDate;
               var diffDate = moment(checkoutDate).diff(moment(checkinDate), "days");

               var checkoutBasketModel = {
                  rooms: response.rooms,
                  diffDate: diffDate,
                  totalAmount: response.totalPrice,
                  isCheckoutBasket: true,
                  hotelInformation: {
                     name: response.hotelName,
                     address: response.hotelAddress,
                     star: response.hotelStar,
                  },
               };
               this.$store.commit("hotel/updateBasketResponse", checkoutBasketModel);

               self.rooms = response.rooms;
               self.rooms &&
                  self.rooms.map((roomItem) => {
                     roomItem.checkinDate = response.checkinDate;
                     roomItem.checkoutDate = response.checkoutDate;
                     roomItem.refundable = response.refundable;
                  });
               self.totalPrice = response.totalPrice;
               self.cancellationPoliciesList = response.cancellationPolicies;
               self.freeCancellationDate = response.freeCancellationDate;
               self.lastBookingDate = response.lastBookingDate;
               self.hotelInformation.name = response.hotelName;
            }
            this.$store.commit("hotel/setHotelCheckoutPageLoadStatus", true);
         })
         .then(() => {
            self.generatedSearchUuid = self.checkoutRequestModel.searchUuid;
         });
   },
   methods: {
      sendToggleChange(isChecked) {
         this.partialPaymentDateUpdate = isChecked;
      },
      handlerCloseCheckout() {
         window.close();
      },
      setCheckoutRooms(roomData) {
         var self = this;
         self.checkoutRequestModel.rooms = roomData;
      },
      setCheckoutContact(contactData) {
         var self = this;
         self.checkoutRequestModel.contact = contactData;
      },
      setCheckoutHotelDescription(hotelDescriptionData) {
         var self = this;
         self.checkoutRequestModel.agencyNote = hotelDescriptionData.agencyNote;
      },
      funcValidateForm() {
         var requiredEls = document.querySelectorAll(".v-required");
         var arrayOfNodes = Array.from(requiredEls);
         var first = true;
         var isFormDone = arrayOfNodes.every((el) => {
            let result = el.dataset.isValid === "true";
            el.dataset.allowSubmit = result;
            if (first) {
               el.scrollIntoView();
               first = false;
            }
            return result;
         });
         if (isFormDone) document.body.classList.remove("g-form-invalid");
         else document.body.classList.add("g-form-invalid");
         return isFormDone;
      },
   },
};
</script>

<style lang="scss">
.hotelCheckout-page-wrapper {
   padding-bottom: 10rem;
   .hotelCheckout-page-inner {
      position: relative;
      width: 100%;
      margin: auto;

      .hotel-checkout-inner-wrapper {
         .checkout-section {
            padding: 42px 32px;
            margin-bottom: 24px;
            background-color: white;
            border: 1px solid #cccccc;
            border-radius: 12px;

            &:last-child {
               margin-bottom: 24px;
            }

            .checkout-section-inner {
               .checkout-section-title {
                  margin-bottom: 16px;

                  h4 {
                     font-size: 16px;
                     font-weight: 600;
                     color: #363940;
                  }
               }
            }
         }
      }
   }
}
</style>
