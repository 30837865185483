<template>
   <div id="nav-container">
      <Transition name="apple-left" mode="in-out">
         <HamburgerMenu v-if="hamburgerMenuStatus" />
      </Transition>

      <nav>
         <!-- GTS Logo  -->
         <div id="nav-brand-logo-wrapper">
            <div id="nav-brand-logo">
               <router-link to="/">
                  <img :src="`${this.$constants.cdn}/logo/${$constants.brand.cssCode}-logo.svg`" alt="Global Travel Services" id="gts-logo" />
                  <img
                     :src="`${this.$constants.cdn}/logo/${$constants.brand.cssCode}-logo-mobile.svg`"
                     alt="Global Travel Services"
                     id="gts-logo-mobile" />
               </router-link>
            </div>

            <div id="gsa-brand-logo">
               <img :src="GsaLogo" alt="Global Travel Services - GSA" id="gsa-brand-logo" @error="handlerGsaLogoError($event)" />
            </div>
         </div>

         <!-- Placeholder for later use of "Navbar Search" -->
         <div class="nav-search" v-if="false">
            <UIInput v-model="search" :placeholder="'Search something..'" allow-clear />
         </div>

         <div id="nav-actions">
            <!-- Contact Us -->

            <!-- Call Center & Whatsapp  -->
            <PhoneInformations />

            <!-- Theme Toggler -->
            <ThemeToggler v-if="false" />

            <!-- Agency Extra Commission -->
            <AgencyExtraCommission v-if="agencyExtraCommission" />

            <TargetAchivementVue show-icon v-if="!hideTargetAchievementIcon && this.$constants.showTargetAchievementIcon" />

            <!-- Notification Panel -->
            <NotificationPanel v-if="roles.includes(19)" />

            <!-- Mobile & Tablet basket -->
            <div
               id="mobile-basket-cta-wrapper"
               class="d-xl-none"
               @click="basket.count != 0 ? (basket.status = true) : (basket.status = false)"
               v-if="showMobileBasketIcon">
               <div id="mobile-basket-cta-inner-wrapper" v-if="showMobileBasketIcon">
                  <i class="gts-icon icon-flight-basket icon gts-animation"></i>
                  <span :class="'basket-' + basket.count">{{ basket.count }}</span>
                  <transition name="fade" mode="out-in">
                     <!-- <div id="basket-update-feedback-container" v-if="basket.nofication">Sepete Ürün Eklendi veya Güncellendi</div> -->
                     <div id="basket-update-feedback-container" v-if="basket.nofication">{{ $t("basketComponentFlight_newItemAddedorUpdated") }}</div>
                  </transition>
               </div>
            </div>

            <!-- Language Control Box -->
            <LanguageControlBox />

            <!-- User Control -->
            <UserControlBox
               v-if="userInformations"
               @click.native="$store.state.app.clientInformations.isMobile ? (hamburgerMenuStatus = !hamburgerMenuStatus) : ''"
               :is-mobile-menu="hamburgerMenuStatus" />
         </div>
      </nav>

      <!-- Fixed whitespace gives space to fixed header-->
      <div class="nav-fixed-whitespace" style="height: 100px"></div>
      <!-- Mobile Basket -->
      <Transition name="apple-right" mode="in-out">
         <MobileFlightBasket :basket="basket.response" v-show="basket.status" v-if="$store.state.app.clientInformations.isMobile" />
      </Transition>
   </div>
</template>

<script>
import HamburgerMenu from "@/components/unitary/HamburgerMenu.vue";
import ThemeToggler from "@/components/unitary/ThemeToggler.vue";
import AgencyExtraCommission from "./AgencyExtraCommission.vue";
import NotificationPanel from "./NotificationPanel.vue";
import TargetAchivementVue from "@/pages/TargetAchivement.vue";
import LanguageControlBox from "./LanguageControlBox.vue";
import UserControlBox from "./UserControlBox.vue";
import MobileFlightBasket from "@/components/unitary/MobileFlightBasket.vue";
import PhoneInformations from "@/components/unitary/PhoneInformations.vue";
import UIInput from "@/components/unitary/form2/UIInput.vue";

// import UITooltip from "@/components/utils/GtsToolTips.vue";
// import PhoneInformations from "@/components/unitary/PhoneInformations.vue";

// import NotificationPanel from "./NotificationPanel.vue";

// import MobileFlightFilter from "@/components/unitary/MobileFligtFilter.vue";

export default {
   name: "Navbar",
   components: {
      HamburgerMenu,
      ThemeToggler,
      AgencyExtraCommission,
      NotificationPanel,
      LanguageControlBox,
      UserControlBox,
      MobileFlightBasket,
      PhoneInformations,
      UIInput,
      TargetAchivementVue,
      // UITooltip,
      // HamburgerMenu,
      // MobileFlightBasket,
      // MobileFlightFilter,
   },
   data() {
      return {
         userTheme: "light-theme",
         notificationPanel: false,
         userInformations: false,
         agencyExtraCommission: false,
         showMobileBasketIcon: false,
         hideTargetAchievementIcon: true,
         roles: [],
         hamburgerMenuStatus: false,
         basket: {
            status: false,
            count: 0,
            response: null,
            nofication: false,
         },
         filter: {
            status: false,
         },
      };
   },

   created() {
      const self = this;

      const userInfos = self.$store.state.user.user;
      // self.$store.commit("user/updateUserInformations", userInfos);
      self.userInformations = true;
      switch (userInfos.language) {
         case 1:
            self.$i18n.locale = "tr";
            break;
         case 2:
            self.$i18n.locale = "en";
            break;

         case 3:
            self.$i18n.locale = "de";
            break;

         default:
            self.$i18n.locale = "en";
            break;
      }

      //HORJAR INJECTION
      if (userInfos.id == 748) {
         (function (h, o, t, j, a, r) {
            h.hj =
               h.hj ||
               function () {
                  (h.hj.q = h.hj.q || []).push(arguments);
               };
            h._hjSettings = { hjid: 3671149, hjsv: 6 };
            a = o.getElementsByTagName("head")[0];
            r = o.createElement("script");
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
         })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
      }
   },
   mounted() {
      this.roles = JSON.parse(localStorage.getItem("auths"));
   },
   computed: {
      IsMefly() {
         return this.$store.state.user.user.originalGsaCssCode == "mefly" ? true : false;
      },
      GsaLogo() {
         const gsaId = this.$store.state.user.user.gsaId;
         const gsaLogo = `${process.env.VUE_APP_CDN_URL}/logo_gsa/${gsaId}.svg`;
         return gsaLogo;
      },
      IsLightMode() {
         const activeTheme = localStorage.getItem("user-theme");
         if (activeTheme === "light-theme") {
            return true;
         } else {
            return false;
         }
      },
      loggedUser() {
         return this.$store.state.user.user;
      },
      loggedUserProfileText() {
         if (Object.entries(this.loggedUser).length != 0) {
            const loggedUserName = this.loggedUser.name;
            const loggedUserSurname = this.loggedUser.surname;
            const loggedUserProfileText = loggedUserName.charAt(0) + loggedUserSurname.charAt(0);

            return loggedUserProfileText;
         } else {
            return "";
         }
      },
   },
   methods: {
      handlerGsaLogoError(ev) {
         ev.currentTarget.remove();
      },
      toggleTheme() {
         const activeTheme = localStorage.getItem("user-theme");
         if (activeTheme === "light-theme") {
            this.setTheme("dark-theme");
         } else {
            this.setTheme("light-theme");
         }
      },
      getTheme() {
         return localStorage.getItem("user-theme");
      },
      setTheme(theme) {
         localStorage.setItem("user-theme", theme);
         this.userTheme = theme;
         document.documentElement.className = theme;
      },
      handlerNotificationPanel() {
         const self = this;
         self.notificationPanel = !self.notificationPanel;
      },
   },

   watch: {
      loggedUser: {
         handler: function (newValue) {
            var self = this;
            self.user = newValue;
         },
         deep: true,
      },
      $route: {
         handler: function (route) {
            const self = this;
            if (route.path == "/flight" || route.path == "/rentacar") {
               self.agencyExtraCommission = true;
            } else {
               self.agencyExtraCommission = false;
            }
            if (route.path == "/flight") {
               self.showMobileBasketIcon = true;
            } else {
               self.showMobileBasketIcon = false;
            }
            if (route.path == "/" || route.path == "/dashboard") {
               self.hideTargetAchievementIcon = this.$store.state.app.clientInformations.isTablet ? false : true;
            } else {
               self.hideTargetAchievementIcon = false;
            }
         },
         immediate: true,
      },
      "basket.count": {
         handler: function (count) {
            if (count != 0) {
               setTimeout(() => {
                  this.basket.nofication = true;
               }, 500);
            }

            setTimeout(() => {
               this.basket.nofication = false;
            }, 5000);
         },
      },
   },
};
</script>

<style lang="scss" scoped>
#nav-container {
   // margin-left: -64px;
   @media (min-width: 1200px) {
      margin-left: -64px;
      background-color: red;
   }
   nav {
      height: 80px !important;
      background-color: var(--c-nav-bar);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      align-items: center;
      padding-left: 18px;
      padding-right: 18px;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
      position: fixed;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
      position: fixed;
      @media (min-width: 992px) {
         position: relative;
         padding-left: 32px;
         padding-right: 32px;
         box-shadow: none !important;
      }

      #nav-brand-logo-wrapper {
         display: flex;
         align-items: center;

         #nav-brand-logo {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            // margin-top: 8px;

            #gts-logo-mobile {
               display: flex;
               height: 50px !important;
               @media (min-width: 768px) {
                  display: none;
               }
            }
            #gts-logo {
               display: none;
               @media (min-width: 768px) {
                  display: flex;
               }
            }

            img {
               height: 42px;
               min-height: 42px;

               @media (min-width: 576px) {
                  height: 56px;
               }
            }
         }
      }
      #gsa-brand-logo {
         margin-top: 0.5rem;
         margin-left: 0.5rem;
      }

      #nav-actions {
         height: 100%;
         display: flex;
         // background: lightblue;

         ::v-deep {
            #notification-container,
            #agency-extra-commission-container {
               display: flex;

               @media (min-width: 768px) {
                  display: flex;
               }
            }
         }

         .nav-contact-us-container {
            display: flex;
            align-items: center;
            // border-right: 1px solid #ccc;

            // padding: 0 1rem;
            // margin: 0 1rem;
            .cc-whatsapp-wrapper-mobile {
            }
         }

         #mobile-basket-cta-wrapper {
            margin: 0 1rem;
            display: flex;
            align-items: center;
            position: relative;
            margin-right: 1.5rem;
            cursor: pointer;

            @media (min-width: 768px) {
               margin-right: 1rem;
            }

            #mobile-basket-cta-inner-wrapper {
               i {
                  width: 38px !important;
                  height: 38px !important;
                  margin-top: 0.5rem;

                  @media (min-width: 768px) {
                     width: 28px !important;
                     height: 28px !important;
                     margin-top: 0;
                  }
               }

               span {
                  position: absolute;
                  top: 16px;
                  right: -15px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50%;
                  text-align: center;
                  font-weight: 500;
                  color: #fff;
                  font-size: 1rem;
                  width: 28px;
                  height: 28px;
                  border-radius: 50%;
                  background-color: #ff4c4c;
                  border: 1px solid #fff;

                  &.basket-0 {
                     background-color: #bfbfbf;
                  }
               }

               #basket-update-feedback-container {
                  background: var(--accent-primary);
                  position: fixed;
                  bottom: 0;
                  display: flex;
                  padding: 1.25rem 0;
                  justify-content: center;
                  color: #fff;
                  text-align: center;
                  font-weight: 500;
                  left: 0;
                  right: 0;
                  font-size: var(--large);
                  z-index: 9999;
               }
            }
         }
      }

      #navbar-controllers-wrapper {
         display: none;
         align-items: center;
         justify-content: center;
         height: 80px;

         @media (min-width: 768px) {
            display: flex !important;
         }

         #navbar-action-group {
            display: flex;
            flex-direction: row;
            height: 100%;
            section {
               margin: 0.75rem;
               cursor: pointer;

               &.extra-commission-wrapper {
                  position: relative;
                  z-index: 0;

                  .extra-commission-alert {
                     position: absolute;
                     top: 4px;
                     right: -12px;
                     width: 24px;
                     height: 24px;
                     background-color: #fff;
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     border-radius: 50%;
                     text-align: center;
                     font-weight: 500;
                     color: #fff;
                     font-size: 0.75rem;
                     z-index: 101;

                     @keyframes extraCommissionAlert {
                        from {
                           transform: scale(0.5);
                           opacity: 0;
                        }
                        to {
                           transform: scale(1);
                           opacity: 1;
                        }
                     }

                     &:before {
                        animation-name: extraCommissionAlert;
                        animation-timing-function: ease-out;
                        animation-duration: 0.75s;
                        animation-iteration-count: infinite;
                        content: "";
                        position: absolute;
                        width: 22px;
                        height: 22px;
                        background-color: none;
                        border: 0.1rem solid #ff4c4c;
                        border-radius: 50%;
                     }

                     &:after {
                        content: "";
                        width: 12px;
                        height: 12px;
                        position: absolute;
                        background-color: var(--accent-primary);
                        background-color: #ff4c4c;
                        border-radius: 50%;
                     }
                  }
               }
            }
         }
      }

      #navbar-mobile-wrapper {
         display: flex;
         align-items: center;
         // @media (min-width: 992px) {
         //    display: none !important;
         // }
         .cc-whatsapp-wrapper-mobile {
            margin-right: 1rem;
            cursor: pointer;

            i {
               $iconSize: 38px !important;
               width: $iconSize;
               height: $iconSize;
            }
         }
         #basket {
            margin-right: 1rem;
            position: relative;
            top: -2px;

            span {
               $square: 28px;
               position: absolute;
               width: $square;
               height: $square;
               background: #eee;
               border-radius: 50%;
               bottom: 0;
               right: -8px;
               background-color: #ff4c4c;
               border: 2px solid #fff;
               color: #fff;
               font-size: 1rem;
               font-weight: 500;
               display: flex;
               align-items: center;
               justify-content: center;

               &.basket-0 {
                  background-color: #ccc;
               }
            }

            i.icon-flight-basket {
               width: 32px !important;
            }
         }
         #hamburger-menu {
            display: flex;
            width: 38px;
            background: none;
            position: relative;
            cursor: pointer;
            height: 38px;
            margin: auto 0;

            span {
               width: 100%;
               height: 4px;
               background: var(--gray);
               border-radius: 5px;
               display: block;
               position: absolute;
               top: 17px;

               &:before,
               &:after {
                  width: 100%;
                  height: 4px;
                  background: var(--gray);
                  border-radius: 5px;
                  display: block;
                  position: absolute;
               }

               &:before {
                  content: "";
                  top: -10px;
               }
               &:after {
                  content: "";
                  top: 10px;
               }
            }
         }
      }
   }
   .nav-fixed-whitespace {
      @media (min-width: 992px) {
         display: none;
      }
   }
}
</style>
