export const priceMap = function (price) {
   if (price < 400) {
      return 400;
   } else if (price > 400 && price < 800) {
      return 800;
   } else if (price > 800 && price < 1200) {
      return 1200;
   } else if (price > 1200 && price < 1600) {
      return 1600;
   } else {
      return 1601;
   }
};

export const hotelListGenerator = function (self, hotels) {
   const hotelListArr = [];
   hotels.map((hotel) => {
      const hotelObj = {
         restrictionType: hotel.price.restrictionType,
         restrictionValue: hotel.price.restrictionValue,
         id: hotel.id,
         name: hotel.name,
         hasPhoto: hotel.hasPhoto,
         thumbnailImage: hotel.thumbnailImage,
         topFacilities: hotel.topFacilities,
         allFacilities: hotel.facilities,
         specialOffer: hotel.specialOffer,
         boardTypes: hotel.boardTypes,
         hotelStarCategory: hotel.hotelStarCategory,
         isPartialPaymentExist: hotel.isPartialPaymentExist,
         location: {
            coordinate: {
               lat: hotel.latitude,
               lon: hotel.longitude,
            },
            address: hotel.address,
         },
         score: {
            point: hotel.hotelScore,
            star: hotel.star,
         },
         price: {
            amount: hotel.price.total,
            currency: hotel.price.currency,
            totalAmount: hotel.price.total,
            originalAmount: hotel.price.originalAmount,
            channelId: hotel.price.channelId,
            onUs: self.channels[hotel.price.channelId]?.onUs,
            minStay: false,
            stopStay: false,
            maxStay: false,
         },
         closedHotel: {
            isClosed: hotel.closedHotel.isClosed,
            fromDate: hotel.closedHotel.fromDate,
            toDate: hotel.closedHotel.toDate,
            description: hotel.closedHotel.description,
         },
         offers: {
            recommended: false,
            earlyBooking: false,
            bestPrice: false,
         },
         content: {
            description: null,
            descriptions: null,
            location: {
               coordinate: {
                  lat: hotel.latitude,
                  lon: hotel.longitude,
               },
            },
         },
      };

      hotelListArr.push(hotelObj);
   });

   return hotelListArr;
};

export const generatorFacilitiesModal = function (response, lang) {
   const obj = {};
   response.facilities.map((facility) => {
      obj[facility.id] = {
         groupName: facility[`groupName${lang}`],
         groupId: facility.groupId,
         checked: false,
         count: 0,
         id: facility.id,
         img: `${response.baseUrl}${facility.id}.svg`,
         name: facility[`name${lang}`],
      };
   });
   return obj;
};
