<template>
   <div class="hotel-checkout-basket-wrapper gts-box with-border">
      <div class="hotel-checkout-basket-inner">
         <!-- {{ checkoutBasket }} -->
         <div class="hotel-checkout-basket-inner-wrapper">
            <div class="hotel-checkout-basket-title">
               <span class="title">{{ checkoutBasket.hotelInformation.name }}</span>
               <div class="hotel-checkout-basket-hotel-info">
                  <div class="hotel-star-list">
                     <i
                        v-for="(item, index) in checkoutBasket.hotelInformation.star"
                        :key="'activeStar-' + index"
                        class="gts-icon icon-star active"></i>
                     <i v-for="(item, index) in 5 - checkoutBasket.hotelInformation.star" :key="'star-' + index" class="gts-icon icon-star"></i>
                  </div>
                  <span>{{ checkoutBasket.hotelInformation.address }}</span>
               </div>
            </div>
            <div class="hotel-checkout-basket-content">
               <div class="checkout-basket-room-list">
                  <div class="basket-room-list-item" v-for="(checkoutRoomItem, index) in checkoutBasket.rooms" :key="'r-' + index">
                     <!-- ROOM ITEM NEW -->
                     <div class="room-item-inner">
                        <span class="room-name">
                           {{ checkoutRoomItem.roomName }}
                        </span>

                        <div class="price-and-date-infos">
                           <span class="room-date">{{ checkoutBasket.diffDate }} {{ $t("hotelCheckoutBasket_txtNight") }}</span>
                           <span class="room-price">{{ checkoutRoomItem.price.total | price }} </span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="checkout-basket-total-price" v-if="checkoutBasket.totalAmount">
                  <div class="basket-total-price-inner">
                     <!-- <div class="total-price-title">
                                <span>ödenecek tutar</span>
                            </div> -->
                     <div class="total-price-info">
                        <span class="discount-price" v-if="checkoutBasket.totalAmount.oldAmount != 0"
                           ><s>{{ checkoutBasket.totalAmount.oldAmount }}</s></span
                        >
                        <span class="total-price">{{ checkoutBasket.totalAmount.grandTotal | price(checkoutBasket.totalAmount.currency) }}</span>
                     </div>
                  </div>
               </div>
            </div>

            <div class="hotel-checkout-basket-footer">
               <span class="board-type-title">
                  {{ $t("hotelCheckoutBasket_txtBoardType") }}
               </span>
               <span class="board-type"> {{ boardType }}</span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "HotelCheckoutBasket",
   computed: {
      checkoutBasket() {
         return this.$store.state.hotel.basketResponse;
      },
   },
   data() {
      return {
         boardType: null,
      };
   },
   watch: {
      checkoutBasket(newValue) {
         if (newValue) {
            this.boardType = newValue.rooms[0].board;
         }
      },
   },
};
</script>

<style lang="scss" scoped>
.hotel-checkout-basket-wrapper {
   padding: 0 !important;
   overflow: hidden;
   position: sticky;
   top: 1.5rem;

   .hotel-checkout-basket-inner {
      .hotel-checkout-basket-inner-wrapper {
         display: flex;
         flex-direction: column;
         width: 100%;
         height: 100%;

         .hotel-checkout-basket-title {
            display: inline-flex;
            align-items: flex-start;
            // justify-content: space-between;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
            padding: 16px;
            padding-top: 1.5rem;
            border-bottom: 1px solid #cccccc;
            padding-bottom: 16px;

            & > span.title {
               margin-bottom: 0 !important;
               font-size: 1.35rem;
               font-weight: 700;
               color: #363940;
            }

            & > .hotel-checkout-basket-hotel-info {
               width: 100%;
               display: flex;
               justify-content: space-between;
               align-items: center;
               flex-wrap: wrap;

               & > .hotel-star-list {
                  display: flex;
                  position: relative;
                  top: -0.25rem;
                  i {
                     width: 16px !important;
                     margin-right: 4px;
                     opacity: 0.3;

                     &:last-child {
                        margin-right: 0;
                     }
                  }
                  i.active {
                     opacity: 1;
                     filter: invert(74%) sepia(100%) saturate(805%) hue-rotate(358deg) brightness(103%) contrast(103%);
                  }
               }
               & > span {
                  width: 100%;
                  font-size: var(--small);
                  color: var(--txt-gray);
                  text-decoration: underline;
               }
            }
         }
         .hotel-checkout-basket-content {
            padding: 16px;

            .checkout-basket-room-list {
               .basket-room-list-item {
                  padding: 0.5rem 0.75rem;
                  margin-bottom: 8px;
                  background-color: #f5f5f5;
                  border: 1px solid #e6e6e6;
                  border-radius: 8px;
                  color: #363940;

                  &:last-child {
                     margin-bottom: 0;
                  }

                  .room-item-inner {
                     display: flex;
                     flex-direction: column;

                     span.room-name {
                        font-size: var(--small);
                        font-weight: 500;
                     }
                     .price-and-date-infos {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-weight: 600;
                        span.room-date {
                           font-size: var(--small);
                        }
                        span.room-price {
                           font-size: var(--large);
                        }
                     }
                  }
               }
            }
            .checkout-basket-total-price {
               margin: 24px 0 16px 0;

               .basket-total-price-inner {
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-end;
                  text-align: right;

                  .total-price-title {
                     span {
                        color: #3e6fb3;
                     }
                  }
                  .total-price-info {
                     display: flex;
                     flex-direction: column;

                     .discount-price {
                        font-size: 18px;
                        color: #2e67b8;
                     }
                     .total-price {
                        font-size: 24px;
                        font-weight: 700;
                        color: #2e67b8;
                     }
                  }
               }
            }
         }
         .hotel-checkout-basket-footer {
            display: flex;
            flex-direction: column;
            padding: 1rem;
            background-color: var(--primary);
            span {
               color: #fff;

               &.board-type-title {
                  font-size: var(--small);
               }
               &.board-type {
                  font-weight: 600;
                  font-size: var(--normal);
               }
            }
         }
         .hotel-checkout-basket-cta {
            display: flex;
            align-items: center;
            padding: 16px;
            background-color: #f4f4f4;
            border-radius: 0 0 12px 12px;

            & > i {
               width: 64px !important;
               margin-right: 16px;
               font-size: 32px;
               filter: invert(27%) sepia(49%) saturate(0%) hue-rotate(231deg) brightness(92%) contrast(86%);
            }
            & > p {
               margin: 0;
               font-size: 14px;
               color: #4e4e4e;
            }
         }
      }
   }
}
</style>
