<template>
   <div class="form-item g-select-box-container" :class="dropdown ? 'g-select-active' : ''" v-click-outside="handlerClickOutside">
      <label class="g-select-box-label" :for="id" v-if="label">{{ label }}</label>

      <div class="g-select-box-wrapper">
         <!-- SELECT BOX -->
         <div class="g-select-box" :class="lightTheme ? 'light-theme' : ''" @click="dropdown = !dropdown">
            <div class="g-select-box-display">
               {{ TotalLength.length }} {{ $t("multiSelectComponent_itemSelected") }}
               <!-- {{ value }}
               <span> {{ value.includes(-1) ? options.length : value.length }} {{ $t("multiSelectComponent_itemSelected") }}</span> -->
            </div>
            <i class="g-select-box-arrow gts-icon icon-arrow-down icon-xs"></i>
         </div>

         <!-- SELECT BOX OPTIONS -->
         <div class="g-select-box-options-container" v-if="dropdown">
            <div class="search-box-container">
               <input type="text" v-model="search" />
               <div class="search-action-wrapper">
                  <i class="gts-icon icon-close-rounded gts-animation icon-xs" @click.self="handlerClearInput" v-if="search.length > 0"></i>
                  <button @click="handlerSelectAll(allSelectedProp)" :class="allSelectedProp ? 'deselectall' : 'selectall'">
                     {{ allSelectedProp ? $t("common_deselectAll") : $t("common_selectAll") }}
                  </button>
               </div>
            </div>
            <div class="g-select-box-options-inner-container">
               <div class="g-select-box-option" v-for="(option, index) in Options" :key="index" @click="handlerSelect(option)">
                  <span class="g-select-box-option-name" :class="option.selected ? 'is-selected' : ''" v-html="option.name"></span>
                  <span class="g-select-box-option-is-selected" v-if="option.selected"></span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "UIMultiSelect",
   props: {
      value: Array,
      label: String,
      id: String,
      options: Array,
      allowSearch: Boolean,
      lightTheme: Boolean,
      mergeAllAsOne: Boolean,
      allSelected: Boolean,
   },

   data() {
      return {
         dropdown: false,
         search: "",
         selectList: [],
         allSelectedProp: this.allSelected,
      };
   },

   computed: {
      Options() {
         const self = this;
         if (self.search == "") {
            return self.selectList;
         } else {
            return self.selectList.filter((option) => option.name.toLowerCase().includes(self.search.toLowerCase()));
         }
      },
      TotalLength() {
         return this.selectList.filter((option) => option.selected == true);
      },
   },
   methods: {
      handlerSelect(value) {
         let selectedIndex = this.options.findIndex((item) => item.value == value.value && item.name == value.name);
         this.selectList[selectedIndex].selected = !this.selectList[selectedIndex].selected;
      },
      handlerClickOutside() {
         const self = this;
         self.dropdown = false;
      },
      handlerClearInput() {
         const self = this;
         self.search = "";
         self.dropdown = true;
      },
      handlerSelectAll(val) {
         const self = this;
         self.allSelectedProp = !val;
         if (self.allSelectedProp) {
            self.selectList.forEach((data) => {
               data.selected = true;
            });
         } else {
            self.selectList.forEach((data) => {
               data.selected = false;
            });
         }
      },
   },
   watch: {
      options: {
         handler: function (options) {
            const self = this;
            const isSelected = function (selected) {
               return self.value.filter((item) => item == selected).length > 0 ? true : false;
            };
            options.map((option) => {
               const rowObj = {
                  name: option.name,
                  value: option.value,
                  selected: isSelected(option.value),
               };
               this.selectList.push(rowObj);
            });
         },
      },
      selectList: {
         handler: function (options) {
            this.value.length = 0;
            options.map((option) => {
               if (option.selected) {
                  this.value.push(option.value);
               }
            });
            if (this.mergeAllAsOne && this.value.length == options.length) {
               this.value.length = 0;
               this.value.push(-1);
            }
            this.$emit("input", this.value);
         },
         deep: true,
      },
   },
};
</script>
<style lang="scss" scoped>
.g-select-box-container {
   width: 100%;
   //     width: max-content;
   //     height: max-content;

   .g-select-box-label {
      margin-bottom: 8px;
      font-weight: 500;
   }
   .g-select-box-wrapper {
      position: relative;
      .g-select-box {
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: space-between;
         background: #f8f8f8;
         background: #eaeaea;
         border: 2px solid #ccc;
         padding: 0 0.75rem;
         box-sizing: border-box;
         border-radius: 0.5rem;
         min-height: 48px;
         cursor: pointer;

         .g-select-box-display {
            display: flex;

            span {
               color: var(--txt-black) !important;
            }
         }

         i.g-select-box-arrow {
            margin-left: 0.5rem;
         }
         i.flag-icon {
            margin-right: 0.5rem;
         }

         &.light-theme {
            background: #fff !important;
         }
      }
      .g-select-box-options-container {
         border: 1px solid #ccc;
         background: #fff;
         position: absolute;
         border-radius: 0.5rem;
         left: 0;
         right: 0;
         z-index: 99;
         min-width: 200px;

         .search-box-container {
            position: relative;
            margin: 0 1rem;
            input {
               display: flex;
               align-items: center;
               justify-content: center;
               border: 1px solid #ccc;
               border-radius: 24rem;
               box-sizing: border-box;
               overflow: hidden;
               margin: 0.5rem 0;
               height: 42px;
               width: 100%;
               text-indent: 0.5rem;
               outline: none !important;
               text-transform: capitalize;

               &:focus-visible {
                  border: 1px solid var(--accent-primary);
                  background-color: var(--accent-primary-lightest);
               }
            }

            .search-action-wrapper {
               position: absolute;
               display: flex;
               align-items: center;
               top: 0;
               bottom: 0;
               right: 0.5rem;
               margin: auto;

               button {
                  border-radius: 24rem;
                  font-weight: 500;
                  padding: 0.25rem 1rem;
                  border: none;
                  white-space: nowrap;
                  margin-left: 0.5rem;
                  // width: 140px;

                  &.selectall {
                     background-color: var(--primary);
                     color: #fff;
                  }
                  &.deselectall {
                     background-color: var(--accent-gray);
                  }
               }
            }
         }
         .g-select-box-options-inner-container {
            max-height: 300px;
            overflow: hidden;
            overflow-y: auto;

            .g-select-box-option {
               padding: 0.35rem 1.25rem;
               font-size: var(--small);
               color: var(--txt-black);
               display: flex;
               justify-content: space-between;
               align-items: center;
               border: 1px dotted #eee;
               &:hover {
                  background-color: var(--hover);
                  cursor: pointer;
               }

               .g-select-box-option-name {
                  &.is-selected {
                     // font-weight: 500;
                  }
               }
               .g-select-box-option-is-selected {
                  width: 14px;
                  height: 14px;
                  background: var(--primary);
                  outline: 3px solid #cbe0ff;
                  border-radius: 50%;
                  display: block;
               }
            }
         }
      }
   }

   &.g-select-active {
      .g-select-box-wrapper {
         .g-select-box {
            border: 1px solid var(--accent-primary);
            outline: 0.25rem solid var(--accent-primary-lightest);
            i.g-select-box-arrow {
               animation: rotation180 0.25s 1;
               animation-fill-mode: forwards;
               animation-timing-function: ease-in-out;
            }
         }
      }
   }
}
</style>
