<template>
   <div id="hamburger-menu-container" v-click-outside="handlerClickOutside">
      <div class="hm-inner-wrapper">
         <div id="hm-user-informations-wrapper">
            <span class="hm-profile-photo">{{ (User.name + " " + User.surname) | fakiePhoto }}</span>
            <div class="hm-user-informations-text-wrapper">
               <span class="hm-user-name-surname">{{ User.name + " " + User.surname }}</span>
               <span class="hm-user-email">{{ User.email }}</span>
               <span class="hm-agency-iati-id">{{ User.agencyIatiId }}</span>
            </div>
         </div>

         <div id="hm-on-behalf-of-wrapper">
            <GsaSelection v-if="gsaList" :list="gsaList" :selectedGsa="OnBehalfOfInformations.gsaOnBehalfOfId" :is-disabled="selectionIsDisabled" />
            <AgencySelection
               v-if="agencyList"
               :list="agencyList"
               :selectedAgency="OnBehalfOfInformations.agencyOnBehalfOfId"
               :is-disabled="selectionIsDisabled" />
         </div>

         <div id="hm-sitemap-wrapper">
            <div id="hm-sitemap-general-wrapper">
               <ul>
                  <li>
                     <router-link to="/">
                        <i class="gts-icon icon-aside-dashboard"></i>
                        <span>{{ $t("hamburgerMenu_dashBoard") }}</span>
                     </router-link>
                  </li>
                  <li v-if="roles.includes(1)">
                     <router-link to="/flight">
                        <i class="gts-icon icon-aside-flight-search"></i>
                        <span>{{ $t("hamburgerMenu_flightSearch") }}</span>
                     </router-link>
                  </li>
                  <li v-if="roles.includes(2)">
                     <router-link to="/hotel">
                        <i class="gts-icon icon-aside-hotel-search"></i>
                        <span>{{ $t("hamburgerMenu_hotelSearch") }}</span>
                     </router-link>
                  </li>
                  <li v-if="roles.includes(3)">
                     <router-link to="/transfer">
                        <i class="gts-icon icon-aside-transfer-search"></i>
                        <span>{{ $t("hamburgerMenu_transferSearch") }}</span>
                     </router-link>
                  </li>
                  <li v-if="roles.includes(22)">
                     <router-link to="/rentacar">
                        <i class="gts-icon icon-aside-rentacar-search"></i>
                        <span>{{ $t("hamburgerMenu_rentACarSearch") }}</span>
                     </router-link>
                  </li>
                  <li>
                     <router-link to="/sale/order">
                        <i class="gts-icon icon-aside-orderlist"></i>
                        <span>{{ $t("hamburgerMenu_orderSearch") }}</span>
                     </router-link>
                  </li>
                  <li>
                     <router-link to="/task/taskList">
                        <i class="gts-icon icon-aside-tasklist"></i>
                        <span>{{ $t("hamburgerMenu_serviceCenter") }}</span>
                     </router-link>
                  </li>
                  <li v-if="roles.includes(10)">
                     <router-link to="/report/flightSalesReport">
                        <i class="gts-icon icon-aside-salereport"></i>
                        <span>{{ $t("hamburgerMenu_flightSalesReport") }}</span>
                     </router-link>
                  </li>
                  <li v-if="roles.includes(11)">
                     <router-link to="/report/hotelSalesReport">
                        <i class="gts-icon icon-aside-salereport invisible"></i>
                        <span>{{ $t("hamburgerMenu_hotelSalesReport") }}</span>
                     </router-link>
                  </li>
                  <li v-if="roles.includes(21)">
                     <router-link to="/report/transferSalesReport">
                        <i class="gts-icon icon-aside-salereport invisible"></i>
                        <span>{{ $t("hamburgerMenu_transferSalesReport") }}</span>
                     </router-link>
                  </li>
                  <li v-if="roles.includes(23)">
                     <router-link to="/report/rentACarSalesReport">
                        <i class="gts-icon icon-aside-salereport invisible"></i>
                        <span>{{ $t("hamburgerMenu_rentACarSalesReport") }}</span>
                     </router-link>
                  </li>
                  <li>
                     <router-link to="/">
                        <i class="gts-icon icon-aside-invoice"></i>
                        <span>{{ $t("hamburgerMenu_financeWithSubs") }}</span>
                     </router-link>
                  </li>
                  <li v-if="roles.includes(12)">
                     <router-link to="/finance/invoiceList">
                        <i class="gts-icon icon-aside-invoice invisible"></i>
                        <span>{{ $t("hamburgerMenu_invoiceList") }}</span>
                     </router-link>
                  </li>
                  <li v-if="roles.includes(13)">
                     <router-link to="/finance/accTransactionList">
                        <i class="gts-icon icon-aside-invoice invisible"></i>
                        <span>{{ $t("hamburgerMenu_accTransactionList") }}</span>
                     </router-link>
                  </li>
                  <li v-if="roles.includes(14)">
                     <router-link to="/finance/balanceUpdate">
                        <i class="gts-icon icon-aside-invoice invisible"></i>
                        <span>{{ $t("hamburgerMenu_balanceUpdate") }}</span>
                     </router-link>
                  </li>
                  <li v-if="roles.includes(19)">
                     <router-link to="/notifications">
                        <i class="gts-icon icon-aside-notification"></i>
                        <span>{{ $t("aside_notifications") }}</span>
                     </router-link>
                  </li>
                  <li>
                     <!-- <li>
                        <router-link to="/sale/order">
                            <i class="gts-icon icon-aside-orderlist"></i>
                            <span>{{ $t("hamburgerMenu_orderSearch") }}</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="/task/taskList">
                            <i class="gts-icon icon-aside-tasklist"></i>
                            <span>{{ $t("hamburgerMenu_serviceCenter") }}</span>
                        </router-link>
                    </li>

                    <li>
                        <div class="hamburger-menu-with-sub-wrapper">
                            <div class="hm-with-sub-title">
                                <i class="gts-icon icon-aside-salereport"></i>
                                <span>{{ $t("hamburgerMenu_reportsWithSubs") }}</span>
                            </div>
                            <div class="hm-with-sub-sub-items-wrapper">
                                <router-link to="/report/flightSalesReport">
                                    <i class="gts-icon icon-aside-salereport invisible"></i>
                                    <span>{{ $t("hamburgerMenu_flightSaleReport") }}</span>
                                </router-link>
                                <router-link to="/report/hotelSalesReport">
                                    <i class="gts-icon icon-aside-salereport invisible"></i>
                                    <span>{{ $t("hamburgerMenu_hotelSaleReport") }}</span>
                                </router-link>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="hamburger-menu-with-sub-wrapper">
                            <div class="hm-with-sub-title">
                                <i class="gts-icon icon-aside-invoice"></i>
                                <span>{{ $t("hamburgerMenu_financeWithSubs") }}</span>
                            </div>
                            <div class="hm-with-sub-sub-items-wrapper">
                                <router-link to="/finance/invoiceList">
                                    <i class="gts-icon icon-aside-invoice invisible"></i>
                                    <span>{{ $t("hamburgerMenu_invoiceList") }}</span>
                                </router-link>
                                <router-link to="/finance/accTransactionList">
                                    <i class="gts-icon icon-aside-invoice invisible"></i>
                                    <span>{{ $t("hamburgerMenu_accTransactionList") }}</span>
                                </router-link>
                                <router-link to="/finance/balanceUpdate">
                                    <i class="gts-icon icon-aside-invoice invisible"></i>
                                    <span>{{ $t("hamburgerMenu_balanceUpdate") }}</span>
                                </router-link>
                            </div>
                        </div>
                    </li> -->
                  </li>
               </ul>
            </div>
            <div id="hm-sitemap-user-wrapper">
               <ul>
                  <li>
                     <router-link to="">
                        <i class="gts-icon icon-aside-settings"></i>
                        <span>{{ $t("hamburgerMenu_settings") }}</span>
                     </router-link>
                  </li>
                  <li>
                     <router-link to="/settings?page=profileSettings">
                        <i class="gts-icon icon-aside-settings invisible"></i>
                        <span>{{ $t("hamburgerMenu_profileSettings") }}</span>
                     </router-link>
                  </li>
                  <li>
                     <router-link to="/settings?page=userAuthorization">
                        <i class="gts-icon icon-aside-settings invisible"></i>
                        <span>{{ $t("hamburgerMenu_userAuthorization") }}</span>
                     </router-link>
                  </li>
                  <li>
                     <router-link to="/settings?page=customerManagement">
                        <i class="gts-icon icon-aside-settings invisible"></i>
                        <span>{{ $t("hamburgerMenu_customerManagement") }}</span>
                     </router-link>
                  </li>
                  <li>
                     <router-link to="/settings?page=userSettings">
                        <i class="gts-icon icon-aside-settings invisible"></i>
                        <span>{{ $t("hamburgerMenu_userSettings") }}</span>
                     </router-link>
                  </li>
                  <!-- <li>
                  <router-link to="/">
                     <i class="gts-icon icon-aside-dashboard invisible"></i>
                     <span>{{ $t("hamburgerMenu_darkMode") }}</span>
                  </router-link>
               </li> -->
                  <li>
                     <router-link to="/logout">
                        <i class="gts-icon icon-aside-logout"></i>
                        <span>{{ $t("hamburgerMenu_logOut") }}</span>
                     </router-link>
                  </li>
               </ul>
            </div>
         </div>

         <div id="hm-contact-informations">
            <template v-if="$constants.brand.callcenter">
               <div class="hm-contact-informations-row">
                  <span>Service Center:</span> <span>{{ $constants.brand.callcenter | phone }}</span>
               </div>
            </template>

            <div class="hm-contact-informations-row whatsapp-wrapper" @click="handlerWhatsappMessage" v-if="$constants.brand.cssCode != 'mefly'">
               <span>Whatsapp:</span>
               <span>+49 40 879 699 88</span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import GsaSelection from "@/components/unitary/GsaSelection.vue";
import AgencySelection from "@/components/unitary/AgencySelection.vue";

export default {
   name: "HamburgerMenu",

   components: {
      GsaSelection,
      AgencySelection,
   },
   data() {
      return {
         selectionIsDisabled: false,
         agencyList: null,
         gsaList: null,
         onBehalfOf: {
            gsa: null,
            agency: null,
         },
      };
   },
   created() {
      this.roles = JSON.parse(localStorage.getItem("auths"));
   },

   computed: {
      User() {
         return this.$store.state.user.user;
      },
      OnBehalfOfInformations() {
         return this.$store.state.user.user.onBehalfOfs;
      },
   },
   methods: {
      handlerWhatsappMessage() {
         if (this.$store.state.app.clientInformations.isMobile) {
            window.open(`https://wa.me/+494087969988`);
         } else {
            window.open(`https://web.whatsapp.com/send/?phone=%2B494087969988&text&type=phone_number&app_absent=0`);
         }
      },
      handlerClickOutside(ev) {
         //Hamburger menu close button id
         if (ev.target.id != "ucb-close-mobile-menu") {
            this.$parent._data.hamburgerMenuStatus = false;
         }
      },
   },

   watch: {
      "$route.path": {
         handler: function (path) {
            const isCheckout = path.includes("/checkout");
            const isOrderDetail = path.includes("/order/detail/");
            if (isCheckout || isOrderDetail) {
               this.selectionIsDisabled = true;
            } else {
               this.selectionIsDisabled = false;
            }
         },
         immediate: true,
      },
      "$store.state.app.agencyList": {
         handler: function () {
            const self = this;
            self.onBehalfOf.gsa = self.$store.state.user.user.onBehalfOfs.gsaOnBehalfOfId;
            self.onBehalfOf.agency = self.$store.state.user.user.onBehalfOfs.agencyOnBehalfOfId;
            self.agencyList = self.$store.state.app.agencyList;
            if (self.onBehalfOf.agency && self.onBehalfOf.agency != -1) {
               const selectedAgency = self.agencyList.filter((agency) => agency.id == self.onBehalfOf.agency);
               self.$store.state.user.user.agencyName = selectedAgency[0] ? selectedAgency[0].name : "";
            }
         },
         immediate: true,
         deep: true,
      },
      "$store.state.app.gsaList": {
         handler: function () {
            const self = this;
            self.onBehalfOf.gsa = self.$store.state.user.user.onBehalfOfs.gsaOnBehalfOfId;
            self.onBehalfOf.agency = self.$store.state.user.user.onBehalfOfs.agencyOnBehalfOfId;
            self.gsaList = self.$store.state.app.gsaList;
            if (self.onBehalfOf.gsa && self.onBehalfOf.gsa != -1) {
               const selectedGsa = self.gsaList.filter((gsa) => gsa.id == self.onBehalfOf.gsa);
               self.$store.state.user.user.gsaName = selectedGsa[0] ? selectedGsa[0].name : "";
            }
         },
         immediate: true,
         deep: true,
      },
   },
};
</script>

<style lang="scss" scoped>
$border: 1px solid #ccc;
$sectionGap: 1rem 0;
#hamburger-menu-container {
   position: fixed;
   overflow-y: auto;
   top: 0;
   bottom: 0;
   left: 0;
   width: 75vw;
   background: #fff;
   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
   padding: 1rem 1rem;
   padding-left: 1.5rem;
   z-index: 999;

   .hm-inner-wrapper {
      position: relative;
      height: 100%;
      width: 100%;
   }

   @media (min-width: 576px) {
      width: 50vw;
   }
   @media (min-width: 992px) {
      display: none !important;
   }

   #hm-user-informations-wrapper {
      display: flex;
      border-bottom: $border;
      padding: $sectionGap;

      .hm-profile-photo {
         width: 56px !important;
         height: 56px !important;
         border-radius: 50%;
         background: var(--accent-primary);
         display: flex;
         align-items: center;
         justify-content: center;
         color: #fff;
         font-size: var(--large);
         font-weight: 500;
         border-radius: 50%;
         margin-right: 0.75rem;
      }

      .hm-user-informations-text-wrapper {
         display: flex;
         flex-direction: column;
         .hm-user-name-surname {
            font-weight: 600;
         }
      }
   }

   #hm-on-behalf-of-wrapper {
      padding: $sectionGap;
      border-bottom: $border;
   }
   #hm-contact-informations {
      margin-top: 1.5rem;
      // position: absolute;
      // bottom: 0;
      // left: 0;
      // right: 0;

      .hm-contact-informations-row {
         display: flex;
         justify-content: center;
         background: #eee;
         margin-bottom: 0.5rem;
         padding: 0.75rem 0.5rem;
         border-radius: 24rem;
         border: 1px solid #ccc;
         width: 100%;
         align-items: center;
         cursor: pointer;

         &.whatsapp-wrapper {
            background-color: #24d366;
            color: #fff;
         }

         span {
            font-size: var(--normal);
            font-weight: 800;

            @media (min-width: 768px) {
               font-size: var(--large);
            }

            &:first-child {
               padding-right: 0.5rem;
               font-weight: 500;
            }
         }
      }
   }
   #hm-sitemap-wrapper {
      padding: $sectionGap;

      #hm-sitemap-general-wrapper,
      #hm-sitemap-user-wrapper {
         .icon-aside-transfer-search {
            scale: 1.4;
         }
         li {
            margin: 1rem 0;

            a {
               color: var(--gray);
               display: flex;
               align-items: center;

               span {
                  margin-left: 1.25rem;
                  font-weight: 500;
               }

               &:active {
                  color: var(--accent-primary);
               }
            }
            .hamburger-menu-with-sub-wrapper {
               .hm-with-sub-title {
                  display: flex;
                  align-items: center;
                  color: var(--txt-ghost);

                  span {
                     margin-left: 1.25rem;
                  }
               }

               .hm-with-sub-sub-items-wrapper {
                  // border-left: 1px solid;
               }
            }
         }
      }

      #hm-sitemap-general-wrapper {
         border-bottom: $border;
      }
      #hm-sitemap-user-wrapper {
         font-weight: 500;
      }
   }
}
</style>
