<template>
   <div class="widget-giros-container">
      <div class="widget-giros-header-container">
         <Section class="multiple-table-header-wrapper">
            <div class="multi-table-tabs">
               <template v-for="(tab, index) in giroWidgetTabs">
                  <div
                     class="multi-table-tab"
                     :key="index"
                     :class="{ 'multi-table-active-tab': index === mode, 'disabled-tab': progress }"
                     @click="handlerSwitchGiroType(index, tab.widgetItem)">
                     {{ $t(tab.name) }}
                  </div>
               </template>
            </div>
         </Section>
      </div>
      <div v-if="progress" class="widget-loader-container">
         <div class="widget-loader-wrapper">
            <span class="g-radial-loader"> </span>
         </div>
      </div>
      <div v-else class="widget-giros-inner-container">
         <!-- Giro Table Start -->
         <div class="widget-giros-table-wrapper">
            <template v-for="(giro, index) in GiroTable">
               <div class="giro-table-row" :key="index" :class="mode == 0 ? handlerClass(giro.entityType) : ''">
                  <div class="giro-table-col provider-image">
                     <img v-if="mode == 1" :src="`${$constants.cdn}/provider_34x34/${giro.code}.svg`" class="provider" />
                     <i v-else class="gts-icon" :class="'icon-colorful-' + handlerTableIcon(giro.entityType)"></i>
                  </div>
                  <div class="giro-table-col">
                     <div class="giro-table-informations-wrapper">
                        <div
                           class="giro-table-data"
                           :class="mode == 0 ? handlerClass(giro.entityType) : ''"
                           v-if="(giro.entityType == 2 || giro.entityType == 3) && giro.reservedEntityCount != null">
                           <span> {{ $t("dashboardGiro_reservedEntityCount" + giro.entityType) }} </span>
                           <span> {{ $t("dashboardGiro_reservedEntityCountDesc" + giro.entityType) }}</span>
                           <span class="txt-bold">{{ giro.reservedEntityCount }}</span>
                        </div>
                        <div
                           class="giro-table-data"
                           :class="mode == 0 ? handlerClass(giro.entityType) : ''"
                           v-if="giro.entityType == 2 && giro.reservedPaxCount != null">
                           <span> {{ $t("dashboardGiro_reservedPaxCount" + giro.entityType) }}</span>
                           <span> {{ $t("dashboardGiro_reservedPaxCountDesc" + giro.entityType) }}</span>
                           <span class="txt-bold">{{ giro.reservedPaxCount }}</span>
                        </div>
                        <div class="giro-table-data" :class="mode == 0 ? handlerClass(giro.entityType) : ''" v-if="giro.issuedEntityCount != null">
                           <span> {{ $t("dashboardGiro_issuedEntityCount" + giro.entityType) }}</span>
                           <span> {{ $t("dashboardGiro_issuedEntityCountDesc" + giro.entityType) }}</span>
                           <span class="txt-bold">{{ giro.issuedEntityCount }}</span>
                        </div>
                        <div
                           class="giro-table-data"
                           :class="mode == 0 ? handlerClass(giro.entityType) : ''"
                           v-if="(giro.entityType == 2 || giro.entityType == 3) && giro.issuedPaxCount != null">
                           <span> {{ $t("dashboardGiro_issuedPaxCount" + giro.entityType) }} </span>
                           <span> {{ $t("dashboardGiro_issuedPaxCountDesc" + giro.entityType) }} </span>
                           <span class="txt-bold">{{ giro.issuedPaxCount }}</span>
                        </div>
                        <div
                           class="giro-table-data"
                           :class="mode == 0 ? handlerClass(giro.entityType) : ''"
                           v-if="giro.entityType == 3 && giro.issuedRoomCount != null">
                           <span> {{ $t("dashboardGiro_issuedRoomCount" + giro.entityType) }}</span>
                           <span> {{ $t("dashboardGiro_issuedRoomCountDesc" + giro.entityType) }}</span>
                           <span class="txt-bold">{{ giro.issuedRoomCount }}</span>
                        </div>
                     </div>
                  </div>
                  <div class="giro-table-col giro-net-gross">
                     <div class="giro-table-data" :class="mode == 0 ? handlerClass(giro.entityType) : ''">
                        <span class="txt-black">{{ giro.gross | price }}</span>
                     </div>
                  </div>
               </div>
            </template>

            <Section class="widget-giros-total-wrapper">
               <div class="giro-table-data">
                  <span>{{ $t("dashboardGiro_allProductsTotal") }}</span>
                  <template>
                     <span class="total-view">{{ Total | price }}</span>
                  </template>
               </div>
            </Section>
         </div>
         <!-- Giro Table End -->
         <div class="report-giros-info-and-infographic">
            <!-- Giro Infographic Start -->
            <div class="widget-giros-infographic-wrapper" v-if="showInfographic">
               <apexchart type="bar" :height="mode == 0 ? 330 : 410" :width="dynamicChartWidth" :options="chartOptions" :series="series"></apexchart>
            </div>
            <!-- Giro Infographic End -->
         </div>
      </div>
   </div>
</template>

<script>
import apexchart from "vue-apexcharts";
import { dashboardService } from "@/service/index.js";
import WidgetControls from "@/components/widgets/helpers/widget-helpers.js";
export default {
   name: "Giros",
   props: {
      properties: [Object, Array],
   },
   components: {
      apexchart,
   },
   data() {
      return {
         hideDetails: this.$constants.isMobile,
         showInfographic: false,
         progress: false,
         giroType: 1,
         giroWidgetTabs: [
            { name: "dashboardPage_widgetGiroAll", widgetItem: 300 },
            { name: "dashboardPage_widgetGiroFlight", widgetItem: 301 },
         ],
         mode: 0,
         dynamicChartWidth: 600,
         series: [
            {
               name: this.$i18n.t("dashboardGiro_gross"),
               data: [],
            },
         ],
         chartOptions: {
            chart: {
               height: 350,
               type: "bar",
            },
            colors: ["#4da6ff", "#ffa04d", "#3dc0cc", "#3dcc9c"],
            plotOptions: {
               bar: {
                  distributed: true,
                  borderRadius: 6,
                  columnWidth: "25%",
                  borderRadiusApplication: "end",
               },
            },
            dataLabels: {
               enabled: false,
            },
            stroke: {
               show: true,
               width: 1,
               colors: ["transparent"],
            },
            fill: {
               opacity: [1, 1, 1, 1, 1],
               gradient: {
                  inverseColors: false,
                  shade: "light",
                  type: "vertical",
                  opacityFrom: 0,
                  opacityTo: 1,
                  stops: [0, 100, 0, 100],
               },
            },

            labels: [
               this.$t("dashboardGiro_apexFlight"),
               this.$t("dashboardGiro_apexHotel"),
               this.$t("dashboardGiro_apexTransfer"),
               this.$t("dashboardGiro_apexRentACar"),
            ],
            legend: {
               show: true,

               markers: {
                  width: 12,
                  height: 12,
                  strokeWidth: 0,
                  strokeColor: "#000000",
                  fillColors: ["#4da6ff", "#ffa04d", "#3dc0cc", "#3dcc9c"],
                  radius: 6,
                  customHTML: undefined,
                  onClick: undefined,
                  offsetX: 0,
                  offsetY: 0,
               },
            },
            markers: {
               size: 4,
            },
            xaxis: {
               type: "string",

               labels: {
                  style: {
                     // colors: ["#E65050", "#FAB555", "#508EE6", "#FA7055", "#D9D9D9"],
                     fontSize: "14px",
                     fontWeight: 600,
                  },
               },
            },
            yaxis: {
               type: "Number",
               tickAmount: 6,
               labels: {
                  formatter: function (value) {
                     var val = value.toLocaleString("de-DE", {
                        style: "currency",
                        currency: "EUR",
                        minimumFractionDigits: 2,
                     });
                     return val;
                  },
               },
               min: 5,
            },
            tooltip: {
               shared: true,
               intersect: false,
               y: {
                  formatter: function (y) {
                     var val = y.toLocaleString("de-DE", {
                        style: "currency",
                        currency: "EUR",
                        minimumFractionDigits: 2,
                     });
                     return val;
                  },
               },
            },
         },
      };
   },
   mounted() {
      this.properties.content.forEach((item) => {
         this.series[0].data.push(item.gross);
      });
      this.showInfographic = true;
      this.updateChartWidth();
      window.addEventListener("resize", this.updateChartWidth);
   },
   beforeDestroy() {
      window.removeEventListener("resize", this.updateChartWidth);
   },
   computed: {
      GiroTable() {
         return this.properties.content;
      },
      Total() {
         return this.$store.state.app.reportGirosGrandTotal;
      },
   },
   methods: {
      updateChartWidth() {
         if (window.innerWidth < 1920) {
            this.dynamicChartWidth = 500;
         } else {
            this.dynamicChartWidth = 600;
         }
      },
      handlerTableIcon(entityType) {
         switch (entityType) {
            case 2:
               return "flight";

            case 3:
               return "hotel";

            case 4:
               return "transfer";

            case 5:
               return "rentacar";

            default:
               break;
         }
      },
      handlerClass(entityType) {
         switch (entityType) {
            case 2:
               return "flight";

            case 3:
               return "hotel";

            case 4:
               return "transfer";

            case 5:
               return "rentacar";

            default:
               break;
         }
      },
      handlerSwitchGiroType(index, widgetItem) {
         if (this.mode != index) {
            this.mode = index;
            this.progress = true;
            this.showInfographic = false;
            dashboardService
               .GetReportGiros({
                  widgetItem: widgetItem,
               })
               .then((response) => {
                  this.series[0].data = [];
                  this.$store.commit("app/setGirosGrandTotal", response.grandTotal);
                  this.$parent.$emit("dynamicFeed", {
                     order: this.properties.order,

                     content: WidgetControls.DynamicFeed(response.reportGiros),
                  });
                  if (this.mode == 0) {
                     this.chartOptions.legend.markers.fillColors = ["#4da6ff", "#ffa04d", "#3dc0cc", "#3dcc9c"];
                     this.chartOptions.colors = ["#4da6ff", "#ffa04d", "#3dc0cc", "#3dcc9c"];
                     this.chartOptions.labels = [
                        this.$t("dashboardGiro_apexFlight"),
                        this.$t("dashboardGiro_apexHotel"),
                        this.$t("dashboardGiro_apexTransfer"),
                        this.$t("dashboardGiro_apexRentACar"),
                     ];
                     this.properties.content.forEach((item) => {
                        this.series[0].data.push(item.gross);
                     });
                  } else {
                     this.chartOptions.legend.markers.fillColors = ["#E65050", "#FAB555", "#508EE6", "#FA7055", "#008CFF", "#D9D9D9"];
                     this.chartOptions.colors = ["#E65050", "#FAB555", "#508EE6", "#FA7055", "#008CFF", "#D9D9D9"];
                     this.chartOptions.labels = [
                        this.$t("dashboardGiro_apexTurkishAirlines"),
                        this.$t("dashboardGiro_apexPegasus"),
                        this.$t("dashboardGiro_apexSunexpress"),
                        this.$t("dashboardGiro_apexCorendon"),
                        this.$t("dashboardGiro_apexAjet"),
                        this.$t("dashboardGiro_apexOther"),
                     ];
                     this.properties.content.forEach((item) => {
                        this.series[0].data.push(item.gross);
                     });
                  }
                  setTimeout(() => {
                     this.showInfographic = true;
                  }, 200);
                  this.progress = false;
               });
         }
      },
   },
   watch: {
      "$i18n.locale"() {
         this.showInfographic = false;
         if (this.mode == 0) {
            this.chartOptions.labels = [
               this.$t("dashboardGiro_apexFlight"),
               this.$t("dashboardGiro_apexHotel"),
               this.$t("dashboardGiro_apexTransfer"),
               this.$t("dashboardGiro_apexRentACar"),
            ];
         } else {
            this.chartOptions.labels = [
               this.$t("dashboardGiro_apexTurkishAirlines"),
               this.$t("dashboardGiro_apexPegasus"),
               this.$t("dashboardGiro_apexSunexpress"),
               this.$t("dashboardGiro_apexCorendon"),
               this.$t("dashboardGiro_apexAjet"),
               this.$t("dashboardGiro_apexOther"),
            ];
         }
         setTimeout(() => {
            this.showInfographic = true;
         }, 200);
      },
      deep: true,
      immediate: true,
   },
};
</script>

<style lang="scss" scoped>
.widget-giros-container {
   min-height: 450px;
   .widget-giros-header-container {
      display: flex;
      section.multiple-table-header-wrapper {
         //       border: 1px solid #ccc;
         display: flex;
         justify-content: space-between;
         margin: 0.5rem 0;
         margin-bottom: 2rem;
         height: fit-content;
         width: 50%;

         .multi-table-tabs {
            display: flex;
            align-items: center;
            background: #f2f2f2;
            border-radius: 8px;
            height: 40px;

            .multi-table-tab {
               padding: 0.25rem 0.75rem;
               margin-right: 0.5rem;
               font-weight: 400;
               color: #666666;
               height: 32px;
               cursor: pointer;
               display: flex;
               align-items: center;

               &.multi-table-active-tab {
                  color: #ffffff;
                  font-weight: 500;
                  background-color: #2e3331;
                  border-radius: 8px;

                  small {
                     background-color: #fff;
                  }
               }
               &.disabled-tab {
                  pointer-events: none;
               }
            }
         }
         .multi-table-search {
            input {
               width: 140px;
               border-radius: 24rem;
               outline: 0;
               border: 2px solid #ccc;
               height: 40px;
               text-indent: 1rem;
               color: #363636;
               text-transform: uppercase;
               font-size: var(--small);
               background-color: #f6f6f6;

               &:focus {
                  border-color: var(--accent-primary-lighten);
                  background-color: #98dcff14;
                  font-weight: 500;
               }

               &::-webkit-input-placeholder {
                  text-transform: capitalize;
               }
            }
         }
      }
      .report-giros-info-container {
         display: flex;
         width: 50%;
         flex-direction: row;
         justify-content: flex-end;
         span {
            display: flex;
            justify-content: flex-end;
            font-weight: 600;
            font-size: var(--xsmall);
            width: 21%;
         }
         .gts-tooltip {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 4%;
            height: 40px;
            padding: 0rem !important;
            z-index: 99;
            ::v-deep {
               i.gts-icon {
                  width: 24px !important;
                  height: 24px !important;
               }
            }
         }
      }
   }
   .widget-loader-container {
      height: 350px;
      .widget-loader-wrapper {
         width: 100%;
         display: flex;
         align-items: center;
         justify-content: center;
         padding: 8rem;
         .g-radial-loader {
            $size: 42px;
            width: $size;
            height: $size;
            border-width: 4px;
            border-color: #363636;
         }
      }
   }
   .widget-giros-inner-container {
      display: flex;
      flex-direction: column;

      @media (min-width: 1500px) {
         flex-direction: row;
      }

      .widget-giros-table-wrapper {
         width: 100%;
         @media (min-width: 1500px) {
            width: 50%;
         }
         .total-giro-switch-wrapper {
            display: flex;
            justify-content: flex-end;
            padding: 0 1rem;
            margin-bottom: 1rem;

            .total-giro-switch {
               background-color: #f6f9fd;
               border: 1px solid #dce0e8;
               border-radius: 24rem;
               height: 38px;
               display: flex;
               align-items: center;
               cursor: pointer;
               width: fit-content;

               .total-giro-tab {
                  border-radius: 24rem;
                  padding: 0.125rem 1rem;
                  height: 100%;
                  display: flex;
                  align-items: center;

                  &.active-tab {
                     background-color: var(--primary);
                     color: #fff;
                     font-weight: 500;
                  }
               }
            }
         }
         .widget-giros-total-wrapper {
            margin-top: 1rem;
            // background: red;
            display: flex;
            justify-content: flex-end;

            .giro-table-data {
               display: flex;
               flex-direction: column;
               .total-view {
                  font-weight: 700;
                  font-size: 2rem;
               }
            }

            // .giro-table-row {
            //    border: 0 !important;
            //    background-color: transparent;
            //    .giro-table-informations-wrapper {
            //       border: none !important;
            //    }
            // }
         }
         .giro-table-row {
            border: 1px solid #ccc;
            background: #f6f7f8;
            border-radius: 24rem;
            padding: 0rem 1rem;
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.75rem;

            &.flight {
               background: #4da6ff1a;
               border: 1px solid #4da6ff33;
            }
            &.hotel {
               background: #ffa04d1a;
               border: 1px solid #ffa04d33;
            }
            &.transfer {
               background: #3dc0cc1a;
               border: 1px solid #3dc0cc33;
            }
            &.rentacar {
               background: #3dcc9c1a;
               border: 1px solid #3dcc9c33;
            }

            .giro-table-col {
               display: flex;
               align-items: center;
               width: 100%;

               &.provider-image {
                  width: 48px;
                  flex-shrink: 0;
               }
               &.giro-net-gross {
                  width: 160px !important;
               }
               .provider {
                  flex-shrink: 0;
                  width: 48px;
                  height: 48px;
               }

               .giro-table-data {
                  display: flex;
                  flex-direction: column;

                  &.flight {
                     span {
                        color: var(--primary-flight-darker) !important;
                     }
                  }
                  &.hotel {
                     span {
                        color: var(--primary-hotel-darker) !important;
                     }
                  }
                  &.transfer {
                     span {
                        color: var(--primary-transfer-darker) !important;
                     }
                  }
                  &.rentacar {
                     span {
                        color: var(--primary-rentacar-darker) !important;
                     }
                  }

                  span {
                     &:first-child {
                        font-size: 0.85rem;
                        color: #8a8b8c;
                        line-height: 16px;
                     }
                     &:nth-child(2) {
                        font-size: 0.75rem !important;
                     }
                     &:last-child {
                        font-size: var(--large);
                        color: #363636;
                     }

                     &.total-view {
                        font-size: 1.5rem;
                     }
                  }
               }
               .giro-table-informations-wrapper {
                  border-left: 1px solid #ccc;
                  border-right: 1px solid #ccc;
                  padding: 0.5rem 1rem;
                  margin: 0 1rem;
                  width: 100%;
                  justify-content: space-around;
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr 1fr;
                  // @media (min-width: 768px) {
                  //    justify-content: space-between;
                  // }
               }
            }
         }
      }
      .report-giros-info-and-infographic {
         width: 100%;
         @media (min-width: 1200px) {
            width: 50%;
         }
         .widget-giros-infographic-wrapper {
            @media (min-width: 1200px) {
               display: flex;
               justify-content: flex-end;
            }
         }
      }
   }
}
</style>
